import React, { useState } from 'react';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/all';
import ReactMarkdown from "react-markdown";
import {apiUrl} from "config";

const FaqItem = ({title='',body=''}) => {
  const [toggle, setToggle] = useState(false);
  return (
    <li className="faqs__item">
      <button onClick={() => setToggle(!toggle)}>
        {title}
        {toggle ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
      </button>
      {toggle && (
        <div>
            <ReactMarkdown
                    transformImageUri={(uri) => uri.match(/^\/uploads/) && `${apiUrl}${uri}` || uri}
                    escapeHtml={false}
                    source={body}/>
        </div>
      )}
    </li>
  );
};

export default FaqItem;
