/** Schedule 관련 옵션 */
//Source
export const source = {
    dataFields: [
        {name: 'id', type: 'string'},
        {name: 'schedule_title', type: 'string'},
        {name: 'schedule_description', type: 'string'},
        {name: 'space_resource_category', type: 'string'},
        {name: 'schedule_from', type: 'date'},
        {name: 'schedule_to', type: 'date'},
        {name: 'check_morning', type: 'checkbox'},
        {name: 'check_afternoon', type: 'checkbox'},
        {name: 'author', type: 'array'},
        {name: 'space_resource', type: 'array'},
        {name: 'schedule_equipments', type: 'array'},
        {name: 'confirmed', type: 'boolean'},
        {name: 'style', type: 'string'},
        {name: 'documentfiles', type: 'array'}, //업로드 된 파일
    ],
    dataType: "array",
    id: 'id',
    localData: [],
};

//Schedule Options
export const schedule = {
    appointmentDataFields: {
        id: "id",
        subject: "schedule_title",
        description: "schedule_description",
        location: "space_resource_category",
        from: "schedule_from",
        to: "schedule_to",
        confirmed: "confirmed",
        style: "style",
        check_morning:"morning",
        check_afternoon:"afternoon",
        author: "author",
        space_resource: "space_resource",
        schedule_equipments: "schedule_equipments",
        documentfiles: "documentfiles",
    },
    height: 700,
    resources: {
        colorScheme: "scheme05",
        // dataField: "id",
        source: null
    },
    source: null,
    // views: ['dayView', 'weekView', 'monthView'],
    views: [
        {
            type: "dayView",
            workTime: {
                fromDayOfWeek: 0,
                toDayOfWeek: 7,
                fromHour: 0,
                toHour: 24
            }
        },
        {
            type: "weekView",
            workTime: {
                fromDayOfWeek: 0,
                toDayOfWeek: 7,
                fromHour: 0,
                toHour: 24
            }
        }, "monthView"
    ],
    toolBarRangeFormat: 'yyyy년 MM월 dd일',
    localization: {
        "/": "/",
        ":": ":",
        firstDay: 0,
        days: {
            names: ['일', '월', '화', '수', '목', '금', '토'],
            namesAbbr: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
            namesShort: ['일', '월', '화', '수', '목', '금', '토']
        },
        months: {
            names: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월", ""],
            namesAbbr: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월", ""]
        },
        AM: ["AM", "am", "AM"],
        PM: ["PM", "pm", "PM"],
        eras: [{name: "A.D.", start: null, offset: 0}],
        twoDigitYearMax: 2029,
        patterns: {
            d: "M/d/yyyy",
            D: "dddd, MMMM dd, yyyy",
            t: "h:mm tt",
            T: "h:mm:ss tt",
            f: "dddd, MMMM dd, yyyy h:mm tt",
            F: "dddd, MMMM dd, yyyy h:mm:ss tt",
            M: "MMMM dd",
            Y: "yyyy MMMM",
            S: "yyyy\u0027-\u0027MM\u0027-\u0027dd\u0027T\u0027HH\u0027:\u0027mm\u0027:\u0027ss",
            ISO: "yyyy-MM-dd hh:mm:ss",
            ISO2: "yyyy-MM-dd HH:mm:ss",
            d1: "dd.MM.yyyy",
            d2: "dd-MM-yyyy",
            d3: "dd-MMMM-yyyy",
            d4: "dd-MM-yy",
            d5: "H:mm",
            d6: "HH:mm",
            d7: "HH:mm tt",
            d8: "dd/MMMM/yyyy",
            d9: "MMMM-dd",
            d10: "MM-dd",
            d11: "MM-dd-yyyy"
        },
        agendaDateColumn: "Date",
        agendaTimeColumn: "Time",
        agendaAppointmentColumn: "일정",
        backString: "뒤로",
        forwardString: "앞으로",
        toolBarPreviousButtonString: "이전",
        toolBarNextButtonString: "다음",
        emptyDataString: "데이터가 존재하지 않습니다.",
        loadString: "로딩중...",
        clearString: "초기화",
        todayString: "오늘",
        dayViewString: "일",
        weekViewString: "주",
        monthViewString: "월",
        agendaViewString: "Agenda",
        timelineDayViewString: "Timeline Day",
        timelineWeekViewString: "Timeline Week",
        timelineMonthViewString: "Timeline Month",
        agendaAllDayString: "종일",
        loadingErrorMessage: "The data is still loading and you cannot set a property or call a method. You can do that once the data binding is completed. jqxScheduler raises the 'bindingComplete' event when the binding is completed.",
        editRecurringAppointmentDialogTitleString: "Edit Recurring Appointment",
        editRecurringAppointmentDialogContentString: "Do you want to edit only this occurrence or the series?",
        editRecurringAppointmentDialogOccurrenceString: "Edit Occurrence",
        editRecurringAppointmentDialogSeriesString: "Edit The Series",
        editDialogTitleString: "일정 수정",
        editDialogCreateTitleString: "일정 생성",
        contextMenuEditAppointmentString: "일정 수정",
        contextMenuCreateAppointmentString: "일정 생성",
        editDialogSubjectString: "아이디",
        editDialogLocationString: "대관 대상",
        editDialogFromString: "시작",
        editDialogToString: "종료",
        editDialogAllDayString: "종일",
        editDialogExceptionsString: "예외",
        editDialogResetExceptionsString: "초기화",
        editDialogDescriptionString: "대여 내용",
        editDialogResourceIdString: "등록자",
        editDialogStatusString: "상태",
        editDialogColorString: "색상",
        editDialogColorPlaceHolderString: "색상 설정",
        editDialogTimeZoneString: "시간대",
        editDialogSelectTimeZoneString: "시간대 설정",
        editDialogSaveString: "저장",
        editDialogDeleteString: "삭제",
        editDialogCancelString: "취소",
        editDialogRepeatString: "반복",
        editDialogRepeatEveryString: "반복 횟수",
        editDialogRepeatEveryWeekString: "주",
        editDialogRepeatEveryYearString: "년",
        editDialogRepeatEveryDayString: "일",
        editDialogRepeatNeverString: "반복 안함",
        editDialogRepeatDailyString: "매일",
        editDialogRepeatWeeklyString: "매주",
        editDialogRepeatMonthlyString: "매월",
        editDialogRepeatYearlyString: "매년",
        editDialogRepeatEveryMonthString: "개월",
        editDialogRepeatEveryMonthDayString: "일",
        editDialogRepeatFirstString: "첫번째",
        editDialogRepeatSecondString: "두번째",
        editDialogRepeatThirdString: "세번째",
        editDialogRepeatFourthString: "네번째",
        editDialogRepeatLastString: "마지막",
        editDialogRepeatEndString: "반복 종료",
        editDialogRepeatAfterString: "다음",
        editDialogRepeatOnString: "날짜",
        editDialogRepeatOfString: " ",
        editDialogRepeatOccurrencesString: "회 반복",
        editDialogRepeatSaveString: "Save Occurrence",
        editDialogRepeatSaveSeriesString: "Save Series",
        editDialogRepeatDeleteString: "Delete Occurrence",
        editDialogRepeatDeleteSeriesString: "Delete Series",
        editDialogStatuses: {
            free: "한가함",
            tentative: "임시",
            busy: "바쁨",
            outOfOffice: "휴가기간"
        },
        morning:'오전',
        afternoon:'오후'
    },
}
