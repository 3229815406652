import React, {useState} from 'react';

import BreadCrumb from '../../components/BreadCrumb';
import SubLeftMenu from '../../components/SubLeftMenu';
import route from '../../route';
import { BsArrowRightShort } from 'react-icons/all';
import {Helmet} from "react-helmet";
import IntroUniversity from '../../components/Intro/IntroUniversity';
import IntroPagination from '../../components/Intro/IntroPagination';

import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import * as rQuery from "queries/resource";

const College = () => {
    const [paging, setPaging] = React.useState(1);
    const handleClickPaging = React.useCallback((value) => {
        if (typeof value === 'number') {
            setPaging(value);
            return;
        }
        if (value === 'next') {
            setPaging(prev => prev + 1);
            return;
        }
        if (value === 'prev') {
            setPaging(prev => prev - 1);
        }
    }, [paging]);

    //대학교 관련
    const [univ, setUniv] = useState([]); //대학 목록
    useQuery(rQuery.UNIV_LIST, {
        fetchPolicy: 'no-cache',
        variables: {sort:"univ_name:asc"},
        onCompleted: ({partnersUnivs = []}) => {
            setUniv([...partnersUnivs])
        }
    });
    return (
        <div className="introduction content">
            <Helmet title="참여대학"/>
            <BreadCrumb list={route.depth2} />
            <div className="sub">
                <div className="wrap">
                    <SubLeftMenu
                        title={route.depth2.title}
                        lnbList={route.depth2.list}
                    />
                    <div className="sub__content introduction_content">
                        {/*<h1 className="sub__title introduction_title">*/}
                        {/*    <span className="">일반대학</span>*/}
                        {/*</h1>*/}

                        <div className={'intro__section intro__section-2'}>
                            {/*<div className="wrap">*/}
                            <h3 className={'section-title'}>광주전남권역 참여대학</h3>
                            <IntroUniversity univ={univ} />
                            {/*<IntroPagination
                                paging={paging}
                                handleClickPaging={handleClickPaging}
                            />*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default College;
