//검수 신청서 작성
import React, {useEffect, useState, useCallback} from 'react';
import {useForm} from "react-hook-form/dist/index.ie11";
import moment from 'moment/dist/moment';
import * as api from 'lib/content';
import route from 'route';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';
import Quill from "../../Editor/Quill";

import {question1,question2,question3,question4,question5} from 'surveyData'


const ResultForm = ({
                       onSubmit,
                       data={},
                       mode = '',
                       type,
                       handleCancel,
                       cancelSubmit,
                       apply_files,
                       reload,
                       bigo,
                       setReload,
                       user = '',
                   }) => {
    const { register, handleSubmit, watch, errors, setValue,  clearErrors } = useForm();
    const [comp, setComp] = useState('design')
    const [question, setQuestion] = useState()
    const [logData, setLogData] = useState([])

    useEffect(()=>{
        async function getLogs() {
            try{
                const {data:log_data,
                    status:log_status} = await api.gets({type: 'audit-logs',
                    audit_content:_.get(data,'id'),
                    _sort:"createdAt:desc"
                });
                setLogData(log_data[0])
                setQuestion(_.get(log_data,'0.answer_data'))
                //setValue('audit_result',_.get(log_data,'0.audit_status'))
            }
            catch (e) {
                swal({
                    title: `로그데이터를 받아오지 못했습니다.`, text: e.message,
                    icon: "warning", timer: 3000, buttons: false,
                });
            } finally {
            }
        }
        getLogs()
    },[data])
    return (
        <form onSubmit={handleSubmit()}>
            <div>
                <div className={'audit_top'}>
                    <h3 className={'write_title'}>강좌 검수 결과 조회</h3>
                    <span className={'write_info'}>강좌의 검수된 결과를 확인합니다.</span>
                </div>
                <div className={'audit_middle'}>
                    <div className={'Audit_Form'}>
                        <span className="auditName">강좌명</span>
                        {/*<select>*/}
                        {/*    <option>*/}
                        {/*        선택하세요*/}
                        {/*    </option>*/}
                        {/*</select>*/}
                        <div className={'audit_text'}>{_.get(data,'audit_title')}</div>
                    </div>
                    <div className={'Audit_Form'}>
                        <span className="auditName">개발기관</span>
                        {/*<select>*/}
                        {/*    <option>*/}
                        {/*        선택하세요*/}
                        {/*    </option>*/}
                        {/*</select>*/}
                        <div className={'audit_text'}>{_.get(data,'audit_org')}</div>
                    </div>
                    <div className={'Audit_Form'}>
                        <span className="auditName">총 차시</span>
                        {/*<select>*/}
                        {/*    <option>*/}
                        {/*        선택하세요*/}
                        {/*    </option>*/}
                        {/*</select>*/}
                        <div className={'audit_text'}>{_.get(data,'audit_num')}</div>
                    </div>
                    <div className={'Audit_Form'}>
                        <span className="auditName">교수</span>
                        <div className={'audit_text'}>{_.get(data,'audit_prop')}</div>
                    </div>
                    <div className={'Audit_Form'}>
                        <span className="auditName">단계</span>
                        {/*<select>*/}
                        {/*    <option>*/}
                        {/*        선택하세요*/}
                        {/*    </option>*/}
                        {/*</select>*/}
                        <div className={'audit_text'}>
                            { _.isEmpty(logData) ? '검수 전' :
                            _.isEmpty(_.get(logData,'audit_status'))?'미검수' :
                                _.get(logData,'audit_status') == 'audit_start' ? '재검':
                                    '검수 완료'}
                        </div>
                    </div>
                    <div className={'Audit_Form'}>
                        <span className="auditName">검수자 의견</span>
                        {/*<select>*/}
                        {/*    <option>*/}
                        {/*        선택하세요*/}
                        {/*    </option>*/}
                        {/*</select>*/}
                        <div className={'audit_text'}>
                            {!_.isEmpty(logData) && <Quill isView={true} initialValue={_.get(logData, 'audit_reple')}/>}

                        </div>
                    </div>
                </div>
                <div className={'audit_middle'}>
                    <div className={'Audit_other_box'}>
                        {/*<div className={'Audit_Form part'}>
                            <span>수행단계</span>
                            <div className={'audit_text bottom_audit_text'}>검수영역
                                <span>콘텐츠</span>
                            </div>
                        </div>*/}
                        <div className={' part'}>
                            {/*<span>수행단계</span>*/}
                            <div className={'audit_text bottom_audit_text'}>
                                <span>설계</span>
                                <span>{_.get(logData,'design_score',0)}/40</span>
                            </div>
                        </div>
                        <div className={' part'}>
                            {/*<span>수행단계</span>*/}
                            <div className={'audit_text bottom_audit_text'}>
                                <span>개발</span>
                                <span>{_.get(logData,'development_score',0)}/50</span>
                            </div>
                        </div>
                        <div className={'part'}>
                            {/*<span>수행단계</span>*/}
                            <div className={'audit_text bottom_audit_text'}>
                                <span>검수</span>
                                <span>{_.get(logData,'audit_score',0)}/10</span>
                            </div>
                        </div>
                        <div className={'part'}>
                            {/*<span>수행단계</span>*/}
                            <div className={'audit_text bottom_audit_text'}>
                                <span>총점</span>
                                <span>{_.get(logData,'final_score',0)}/100</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'filter_button'}>
                    <ul>
                        <li className={`tab ` + (comp == 'design' ? 'active' : '')} onClick={() => setComp('design')}>
                            설계
                        </li>
                        <li className={`tab ` + (comp == 'development' ? 'active' : '')} onClick={() => setComp('development')}>
                            개발
                        </li>
                        <li className={`tab ` + (comp == 'audit' ? 'active' : '')} onClick={() => setComp('audit')}>
                            검수
                        </li>
                    </ul>
                </div>
                {
                    !_.isEmpty(question) &&
                    question.map((item,question_index)=>{
                        const {type,contents=[]} = item;
                        let num = 0;
                        return <div className={"row_audit " + type +( type==comp ? ' display' : '')}>
                            <div className="col">
                                <div className="ptable">
                                    <table role="table">
                                        <thead>
                                        <tr role="row">
                                            <th colSpan="1" role="columnheader" className={'th_s_result_s'}>번호
                                            </th>
                                            <th colSpan="1" role="columnheader" className={'th_m_result'}>준거
                                            </th>
                                            <th colSpan="1" role="columnheader" className={'th_l_result'}>검수항목
                                            </th>
                                            <th colSpan="1" role="columnheader" className={'th_s_result'}>평가점수
                                                {
                                                    type != 'design' &&
                                                    <th>5</th>
                                                }
                                                <th>4</th>
                                                <th>3</th>
                                                <th>2</th>
                                                <th>1</th>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody role="rowgroup">
                                        {
                                            contents.map((content,index)=>{
                                                const {titles,type:content_type,answer} = content;
                                                return (
                                                    <>
                                                    {
                                                        !_.isEmpty(answer) &&
                                                        titles.map((title_item,idx)=>{
                                                            setTimeout(()=>setValue(type+'_' + index + '_' + idx,`${answer[idx]}`),1000)
                                                            num = num + 1;
                                                            return(
                                                                <tr role="row" key={title_item}>
                                                                    <td role="cell">{num}</td>
                                                                    {idx == 0 && <td role="cell" rowSpan={titles.length}>{content_type}</td>}
                                                                    <td role="cell" className={'td_p p_result'}>
                                                                        {title_item}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            type != 'design' &&
                                                                            <input type={'radio'} ref={register()}  value={5} name={type+'_'+index+'_'+idx} className={'result_radio disabled'}/>
                                                                        }
                                                                        <input type={'radio'} ref={register()} value={4} name={type+'_'+index+'_'+idx} className={'result_radio disabled'}/>
                                                                        <input type={'radio'} ref={register()} value={3} name={type+'_'+index+'_'+idx} className={'result_radio disabled'}/>
                                                                        <input type={'radio'} ref={register()} value={2} name={type+'_'+index+'_'+idx} className={'result_radio disabled'}/>
                                                                        <input type={'radio'} ref={register()} value={1} name={type+'_'+index+'_'+idx} className={'result_radio disabled'}/>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    </>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    })
                }
                {/*<div className={'audit_middle'}>*/}
                {/*    <div className={'Audit_Form sub_audit_form'}>*/}
                {/*        <span>수행단계</span>*/}
                {/*        <div className={'audit_text'}>강좌신청</div>*/}
                {/*    </div>*/}
                {/*    /!*<div className={'bar_box'}>---</div>*!/*/}
                {/*    <path className={'bar_box'}>----</path>*/}
                {/*</div>*/}

                {/*<div className={'bottom_select'}>
                    <select>
                        <option>
                            50 개씩
                        </option>
                    </select>
                    <p>행 클릭 시 강좌 기초 정보로 이동합니다.</p>
                    <p>정보 확정이 된 후에 검수 위원 배정 버튼 클릭 시 위원 배정이 가능합니다.</p>
                </div>*/}
                {/*<div className="row_audit">*/}
                {/*    <div className="col">*/}
                {/*        <div className="ptable">*/}
                {/*            <table role="table">*/}
                {/*                <thead>*/}
                {/*                <tr role="row">*/}
                {/*                    <th colSpan="1" role="columnheader" className={'th_s'}>단계*/}
                {/*                        <button className="caret1"></button>*/}
                {/*                        <button className="caret2"></button>*/}
                {/*                    </th>*/}
                {/*                    <th colSpan="1" role="columnheader" className={'th_m'}>상태*/}
                {/*                        <button className="caret1"></button>*/}
                {/*                        <button className="caret2"></button>*/}
                {/*                    </th>*/}
                {/*                    <th colSpan="1" role="columnheader" className={'th_l'}>메시지*/}
                {/*                    </th>*/}
                {/*                    <th colSpan="1" role="columnheader" className={'th_s'}>액션*/}
                {/*                    </th>*/}
                {/*                </tr>*/}
                {/*                </thead>*/}
                {/*                <tbody role="rowgroup">*/}
                {/*                <tr role="row">*/}
                {/*                    <td role="cell">최종</td>*/}
                {/*                    <td role="cell">*/}
                {/*                        <span className={'bottom_select'}>*/}
                {/*                            <select className={'bottom_sub_select'}>*/}
                {/*                                <option>*/}
                {/*                                    승인됨*/}
                {/*                                </option>*/}
                {/*                            </select>*/}
                {/*                        </span>*/}
                {/*                    </td>*/}
                {/*                    <td role="cell" className={'td_p'}>*/}
                {/*                        <p>[발신전용][고급해석학의 기초개념]검수가 완료되었습니다.*/}
                {/*                            결과를 확인하여 주시기 바랍니다. 기타 문의사항은 광주전남권역대학원격교육*/}
                {/*                            지원센터(ysb619@jnu.ac.kr) 또는 062-530-5029, 2595로*/}
                {/*                            연락부탁드립니다. 감사합니다.</p>*/}
                {/*                        <span className={'span_1'}>발송일</span><span className={'span_2'}>2020.12.16 T10:20:22.998</span>*/}
                {/*                    </td>*/}
                {/*                    <td role="cell"><button>저장</button></td>*/}
                {/*                </tr>*/}
                {/*                </tbody>*/}
                {/*            </table>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className={'audit_bottom'}>*/}
                {/*    <span>*/}
                {/*        강좌개발계획서*/}
                {/*    </span>*/}
                {/*    <input type={'file'}/>*/}
                {/*</div>*/}
            </div>
            {/*<div className={'audit_footer'}>*/}
            {/*    <button>목록</button>*/}
            {/*    <button>등록</button>*/}
            {/*</div>*/}
        </form>
    )
}


export default ResultForm;
