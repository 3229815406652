import React, {useEffect, useMemo, useState} from 'react';
import {GiCheckMark} from 'react-icons/all';
import moment from 'moment/dist/moment';
import {Link} from 'react-router-dom';
import {apiUrl} from 'config';
import * as api from 'lib/content';
import ReactMarkdown from "react-markdown";

const DetailTable = ({data = {}}) => {
    const [parmas, setParmas] = useState({});
    const [imgView, setImgView] = useState(false);
    useEffect(() => {
        /*console.log(imgView, '이미지');*/
    }, [imgView])

// console.log(data)
    return (
        <div className="programDetail__body">
            <ReactMarkdown className="_row"
                           transformImageUri={(uri) => uri.match(/^\/uploads/) && `${apiUrl}${uri}` || uri}
                           escapeHtml={false}
                           source={data.body}/>
            {_.get(data,'body_config', '')&&
            <div className={'_row'}>
                <h3>구성</h3>
                <button className={imgView ? 'img_btn_gray' : 'img_btn_green'}
                        onClick={() => setImgView(!imgView)}> 강좌계획표 {imgView ? '닫기' : '펼치기'}</button>

               
              <ReactMarkdown className={'open_img'}
                               transformImageUri={(uri) => uri.match(/^\/uploads/) && `${apiUrl}${uri}` || uri}
                               escapeHtml={false}
                               source={data.body_config}/>
            </div>
            }

            <ReactMarkdown className="_row"
                           transformImageUri={(uri) => uri.match(/^\/uploads/) && `${apiUrl}${uri}` || uri}
                           escapeHtml={false}
                           source={data.body_apply}/>

        </div>
    );
};

export default DetailTable;
