import React, {useEffect, useMemo, useState} from 'react';
import * as api from 'lib/content';
import * as cQuery from "queries/content";
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import {useRouteMatch, generatePath, Link} from 'react-router-dom';

const TYPE2 = 'workshopprogram-applies';
const BoardButtonColum = ({data}) => {
    const [surverResult, setSurverResult] = useState({});
    const [buttonLoading, setButtonLoading] = useState(true);

    const [getSurvey_result, {loading: survey_result_loading}] = useLazyQuery(cQuery.SURVEY_RESULT_QUERY, {
        fetchPolicy: 'no-cache',
        onCompleted: ({surveyResults = []}) => {
            if(!_.isEmpty(surveyResults)){
                setSurverResult(_.get(surveyResults,'0'))
            }
        }
    });

    useEffect(()=>{
        if (!_.isEmpty(data)) {
            getSurvey_result({variables: {"where": {"author": _.get(data,'apply_user.id'), "workshopprogram": _.get(data,'appied_program.id')}}})
        }
    },[data])

    if(!survey_result_loading) {
        if (_.get(data,'apply_complete') == 'complete') {
            if(!_.isEmpty(surverResult)){
                return (
                    <>
                        <Link to={`/member/mycontent/workshopsurvey/${_.get(data,'appied_program.id')}`}>
                            <button className='my_survey_button'>설문조사</button>
                        </Link>
                        <Link to={`/member/mycontent/mycertificate/${_.get(data,'appied_program.id')}`}>
                            <button className='my_certi_button'>이수증</button>
                        </Link>
                    </>
                )
            }
            else{
                return (
                    <>
                        <Link to={`/member/mycontent/workshopsurvey/${_.get(data,'appied_program.id')}`}>
                            <button className='my_survey_button'>설문조사</button>
                        </Link>
                        <button className='my_certi_button disabled'>이수증</button>
                    </>
                )
            }
            /*${appied_program.id}*/
        } else {
            return (
                <>
                    <button className='my_survey_button disabled'>설문조사</button>
                    <button className='my_certi_button disabled'>이수증</button>
                </>
            )
        }
    }
    else{
        return(
            <></>
        )
    }
    /*return (

    )*/
}

export default BoardButtonColum;