import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import {Router, Route, Switch} from "react-router-dom";

//Global Import
import 'moment/dist/locale/ko'  //moment 언어설정
import 'lodash';
import 'sweetalert';
import 'utils/tokenAxios';

//Redux 관련
import {Provider} from 'react-redux';
import store from 'store'
import history from 'utils/history'

import RouterApp from 'containers/RouterApp';

//GraphSQL 관련
import {ApolloProvider} from "@apollo/react-hooks";
import client from "utils/apolloClient";

//다국어
import 'language/i18n'
import {CookiesProvider} from 'react-cookie';

/*window.addEventListener ( 'message', function (event) {
    if (event.origin) {
        if (_.get(event.data,'jwt')) {
            const jwt = _.get(event.data,'jwt');
            localStorage.setItem ("jwt",jwt);
        } else {
            if(event.data === 'log_out'){
                localStorage.removeItem ("jwt");
            }
        }
    }
});*/

const rootElement = document.getElementById('root');
ReactDOM.render(
    <Provider store={store} key="provider">
        <CookiesProvider>
            <ApolloProvider client={client}>
                <Router history={history}>
                    <RouterApp/>
                </Router>
            </ApolloProvider>
        </CookiesProvider>
    </Provider>, rootElement
);
