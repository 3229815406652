import React from 'react';
import Quill from 'components/Editor/Quill';

const SlideItem = ({ data }) => {
    //console.log(data)
  return (
      <div className="mainSlider__item" style={{
          background: 'linear-gradient(180deg, #54BDBD, #b5ead9)'
          //background: data.backgroundColor
      }}>
        <div className="wrap">
          <div className="left banner">
              <Quill initialValue={_.get(data,'content')} isView={true}/>
            {/*<p>{data.content}</p>*/}
            <h2>{data.title}</h2>
          </div>
          <div className="right">
            <img src={`https://gnue2.gesturecloud.io/api${data.banner_image.url}`} alt=""/>
          </div>
        </div>
      </div>
  );
};

export default SlideItem;
