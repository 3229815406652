import React, {useEffect,useState} from 'react';
import {useRouteMatch, generatePath, Link} from 'react-router-dom';
import useWindowSize from 'utils/useWindowSize';
import BoardButtonColum from './BoardButtonColum';
import * as cQuery from "queries/content";
import {useQuery, useLazyQuery} from "@apollo/react-hooks";

const BoardCustomList = ({loading = false, data = [], columns = [], totalCount = 0, user={}, isLink = true,answerData=[]}) => {
    const {width: widthSize = window.innerWidth} = useWindowSize();
    const match = useRouteMatch()
    const _data = _.compact(data)
    const [surverResult, setSurverResult] = useState({});

    const [getSurvey_result, {loading: survey_result_loading}] = useLazyQuery(cQuery.SURVEY_RESULT_QUERY, {
        fetchPolicy: 'no-cache',
        onCompleted: ({surveyResults = []}) => {
            if(!_.isEmpty(surveyResults)){
                setSurverResult(_.get(surveyResults,'0'))
            }
        }
    });
    return (
        <div className="board__list">
            <header className="board__list-header">
                <div className="tr">
                    {columns.map((column, idx) => {
                        const {
                            Header = '',
                            className = null,
                            width = 'inherit',
                            minWidth = 'inherit',
                            ...styles
                        } = column
                        return (
                            <div
                                key={idx}
                                {...(className && {className})}
                                {...(widthSize > 1024 && (width || minWidth || styles) && {
                                    style: {
                                        width,
                                        minWidth, ...styles
                                    }
                                })}
                            >{Header}</div>
                        )
                    })}
                </div>
            </header>
            <div className="board__list-body">
                {
                    loading && (<div className={`text-center p-4`}>로딩중...</div>) ||
                    (
                        _data.length && _data.map((item, idx) => {

                            if (!item) return null;
                            const {id = ''} = item;
                            const InnerData = ({columns = [], item = {}, secret=false, type=''}) => {
                                return (<>{
                                    columns.map((column, cidx) => {
                                        const {
                                            Header = '', accessor = '', Cell = null,
                                            width = 'inherit', minWidth = 'inherit', ...styles
                                        } = column
                                        return (
                                            <div
                                                className={Header}
                                                key={cidx}
                                                {...(widthSize > 1024 && (width || minWidth || styles) && {
                                                    style: {
                                                        width,
                                                        minWidth, ...styles
                                                    }
                                                })}>
                                                <span className="col-title">{Header}</span>
                                                {   (type == 'answer' && Header=='번호') ? ''
                                                    : (Header == 'hihi' ? (!secret ? (
                                                            (Cell && Cell({
                                                                row: item,
                                                                idx,
                                                                totalCount
                                                            })) || (accessor && item[accessor])
                                                        ) : '비밀글입니다.') :
                                                        Header == '비고' ? <BoardButtonColum data={item}/>  :((Cell && Cell({
                                                            row: item,
                                                            idx,
                                                            totalCount
                                                        })) || (accessor && item[accessor])))
                                                }
                                            </div>
                                        )
                                    })


                                }
                                </>)
                            }
                            return _.isEmpty(_.get(item, 'password')) ?
                                (

                                    isLink && (
                                        <>
                                            <Link key={idx} to={generatePath(`${match.path}/:id`, {id})} className="tr">
                                                <InnerData columns={columns} item={item}/>
                                            </Link>
                                            {
                                                !_.isEmpty(answerData) &&
                                                answerData.map((answerItem,answerIndex)=>{
                                                    if(_.get(answerItem,'parent_id') == id){
                                                        return (
                                                            <Link key={answerIndex} className="tr answer_tr">
                                                                <InnerData columns={columns} item={answerItem} type={'answer'}/>
                                                            </Link>)
                                                    }
                                                    else{
                                                    }
                                                })
                                            }
                                        </>
                                    ) || (<div key={idx} className="tr"><InnerData columns={columns} item={item} secret={true}/></div>)
                                ):(
                                    !_.isEmpty(_.get(user,'role._id')) ?
                                        (
                                            (_.get(user,'role._id') == '5fed5533258df16ef195ae04' || _.get(user,'id','') == _.get(item,'author.id','')) ?
                                                <Link key={idx} to={generatePath(`${match.path}/:id`, {id})} className="tr">
                                                    <InnerData columns={columns} item={item}/>
                                                </Link>
                                                :<div key={idx} className="tr"><InnerData columns={columns} item={item} secret={true}/></div>
                                        )
                                        :<div key={idx} className="tr"><InnerData columns={columns} item={item} secret={true}/></div>

                                )
                        }) || (<div className={`text-center p-4`}>콘텐츠가 존재하지 않습니다.</div>)
                    )
                }
            </div>
        </div>
    );
};

export default BoardCustomList;
