import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';

import ProgramHowToUse from './HowToUse';
import ProgramStudyProgram from './StudyProgram';
import ProgramStudyProgramDetail from './StudyProgramDetail';
import ProgramSupportProgram from './SupportProgram';
import ProgramSupportProgramDetail from './SupportProgramDetail';
import YoutubeProgram from './YoutubeProgram';
import YoutubeProgramDetail from './YoutubeProgramDetail';
import {NotFound} from 'components/Errors';

const Program = ({match}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => dispatch(actions.init())
    }, []);

    return (
        <Switch>
            <Route path={`${match.path}/howtouse`} render={() => <ProgramHowToUse/>}/>
            <Route path={`${match.path}/studyprogram/:id`} render={() => <ProgramStudyProgramDetail/>}/>
            {/*<Route path={`${match.path}/studyprogram`} render={() => <ProgramStudyProgram/>}/>*/}
            <Route path={`${match.path}/studyprogram/:id`} render={() => <ProgramSupportProgramDetail/>}/>
            {/*<Route path={`${match.path}/supportprogram`} render={() => <ProgramSupportProgram/>}/>*/}
            <Route path={`${match.path}/studyprogram`} render={() => <div className="content" style={{textAlign:'center',fontSize:'2.5rem',fontWeight:'400',margin:'15.26rem'}}>페이지 준비중입니다.</div>}/>
            <Route path={`${match.path}/supportprogram`} render={() => <div className="content" style={{textAlign:'center',fontSize:'2.5rem',fontWeight:'400',margin:'15.26rem'}}>페이지 준비중입니다.</div>}/>
            <Route path={`${match.path}/youtube/:id`} render={() => <YoutubeProgramDetail/>}/>
            <Route path={`${match.path}/youtube`} render={() => <YoutubeProgram/>}/>

            <Route path="*" component={NotFound} status={404}/>
        </Switch>
    );
};

export default Program;
