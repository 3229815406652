import React, {useEffect, useMemo, useState, useCallback} from 'react';
import BreadCrumb from 'components/BreadCrumb';
import SubLeftMenu from 'components/SubLeftMenu';
import * as actions from 'store/actions/content';
import {useSelector, useDispatch} from 'react-redux';
import {Helmet} from "react-helmet";
import {useTranslation} from 'react-i18next'
import moment from 'moment/dist/moment';
import * as api from 'lib/content';
import {apiUrl} from 'config';
import {useRouteMatch, generatePath, Link} from 'react-router-dom';
import route from 'route';
import {BoardHeader, BoardCustomList} from 'components/Board';
import {CustomPagination} from 'components/Custom'

// const TYPE = 'workshopprogram-applies';
const MyStudyProgram = () => {
    const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}));
    const {id = '', username = ''} = user;
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation()
    const {list = [], totalCount = 0, loading} = useSelector(({content}) => ({...content}));
    const [perPage, setPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [filter, setFilter] = useState({});
    const [TYPE, setType] = useState('workshopprogram-applies');
    const [params, setParams] = useState({
        _limit: perPage,
        _start: page * perPage,
        _sort: `createdAt:DESC`,
        appied_program_null:false
    })
    /* const learning_startdate = moment(_.get(applied_data[0], 'appied_program.learning_startdate')).format(`YYYY-MM-DD`);
     const learning_enddate = moment(_.get(applied_data[0], 'appied_program.learning_enddate')).format(`YYYY-MM-DD`);*/

    const pageCount = Math.ceil(totalCount / perPage);
    const bigo_btn1 = '설문조사';
    const bigo_btn2 = '수료증';

    const columns1 = useMemo(() => [
        {
            Header: "번호", accessor: 'id', className: `text-center`, minWidth: '70px',
            Cell: ({row, idx, totalCount}) => {
                const index = totalCount - (page * perPage) - idx;
                return index > 0 && (<div>{index}</div>) || '-'
            }
        },
        {
            Header: "프로그램명",
            accessor: 'appied_program.title',
            className: `text-center`,
            width: 'calc(60% - 190px)',
            Cell: ({row: {appied_program}, idx, totalCount}) => {
                return (<Link to={`../resource/competencyworkshop/${_.get(appied_program,'id')}`}>{_.get(appied_program,'title')}</Link>)
            },
        },
        {
            Header: "장소",
            accessor: 'appied_program.program_address',
            className: `text-center`,
            minWidth: 'calc(40% - 190px)',
            Cell: ({row: {appied_program}, idx, totalCount}) => (<>{_.get(appied_program,'program_address','')}</>)
        },
        {
            Header: "진행기간",
            accessor: ['appied_program.learning_startdate', 'appied_program.learning_enddate'],
            className: `text-center`,
            minWidth: '80px',
            width: 'calc(35% - 190px)',
            Cell: ({
                       row: {appied_program},
                       idx,
                       totalCount
                   }) => {
                return (
                    <>
                        {moment(_.get(appied_program,'learning_startdate','')).format('YYYY-MM-DD HH:mm')}<p/>
                        ~<p/>
                        {moment(_.get(appied_program,'learning_enddate','')).format('YYYY-MM-DD HH:mm')}
                    </>
                )
            }
        },
        {
            Header: "상태",
            accessor: 'apply_status.title',
            className: `text-center`,
            minWidth: '80px',
            width: 'calc(35% - 190px)',
            Cell: ({row: {apply_status}, idx, totalCount}) => {
                return (<span className='standby_button'>{_.get(apply_status,'title','')}</span>
                )
            }
        },
        {
            Header: "비고",
            accessor: [bigo_btn1, bigo_btn2, 'appied_program.id'],
            className: `text-center`,
            minWidth: '80px',
            width: 'calc(35% - 190px)',
            /*Cell: ({row: {appied_program,apply_complete}, idx, totalCount}) => {
                /!*console.log(appied_program)*!/
                console.log(apply_complete,idx)
                if(apply_complete == 'complete'){
                    return(
                        <>
                            <Link to={`/mycontent/workshopsurvey/${appied_program.id}`}><button className='my_survey_button'>{bigo_btn1}</button></Link>
                            <Link to={`/member/mycontent/mycertificate/${appied_program.id}`}><button className='my_certi_button'>{bigo_btn2}</button></Link>
                        </>
                    )
                }else{
                    return(
                        <>
                            <button className='my_survey_button disabled'>{bigo_btn1}</button>
                            <button className='my_certi_button disabled'>{bigo_btn2}</button>
                        </>

                    )
                }
            }*/
        },
    ], [perPage, page])

    const columns2 = useMemo(() => [
        {
            Header: "번호", accessor: 'id', className: `text-center`, minWidth: '70px',
            Cell: ({row, idx, totalCount}) => {
                const index = totalCount - (page * perPage) - idx;
                return index > 0 && (<div>{index}</div>) || '-'
            }
        },
        {
            Header: "제목",
            accessor: ['title', '_id'],
            className: `text-center`,
            width: 'calc(60% - 190px)',
            Cell: ({row: {title = '-', _id = ''}, idx, totalCount}) => {
                return (<Link to={`../resource/consulting/${_id}`}>{title}</Link>)
            },
        },
        {
            Header: "신청자",
            accessor: 'author.name',
            className: `text-center`,
            minWidth: 'calc(40% - 190px)',
            Cell: ({row: {author: {name = '-'} = {}}, idx, totalCount}) => (<>{name}</>)
        },
        {
            Header: "상태",
            accessor: 'status',
            className: `text-center`,
            minWidth: '150px',
            // width: 'calc(35% - 190px)',
            Cell: ({row: {status = '-'}, idx, totalCount}) => {
                return (
                    status == '-' && <>-</>
                    || <span className='standby_button'>{status}</span>
                )
            }
        },
        {
            Header: "등록일",
            accessor: 'createdAt',
            className: `text-center`,
            minWidth: '80px',
            // width: 'calc(35% - 190px)',
            Cell: ({row: {createdAt = 0}, idx, totalCount}) => (<>{moment(createdAt).format('YYYY-MM-DD')}</>)
        }
    ], [perPage, page])

    const [columns, setColumns] = useState(columns1);
    const updateParams = useCallback((data) => {
        const {username = '', id = ''} = user
        setParams({...params, ...data, apply_user: id});
    }, [user]);

    //page 이동
    useEffect(() => {
        const {_start, _limit} = params;
        if ((_limit !== perPage || _start !== page * perPage)) {
            updateParams({...params, _start: page * perPage, _limit: perPage})
        }
    }, [page, perPage])

    useEffect(() => {
        dispatch(actions.listRequest({
            type: TYPE,
            ...params,
            ...filter
        }))
        if (TYPE == 'workshopprogram-applies') {
            setColumns(columns1)
        } else if (TYPE == 'board-consultings') {
            setColumns(columns2)
        }
    }, [params, filter])
    useEffect(() => {
        if (TYPE == 'workshopprogram-applies') {
            setParams({
                _limit: perPage,
                _start: page * perPage,
                _sort: `createdAt:DESC`,
                apply_user: id,
                apply_status: '5ff6b769bf55b944295d63e8',
                appied_program_null:false
            })
        } else if (TYPE == 'board-consultings') {
            setParams({
                _limit: perPage,
                _start: page * perPage,
                _sort: `createdAt:DESC`,
                author: id
            })
        }
    }, [TYPE])
    

    return (
        <div className="myProgram">
            <div className="onair content introduction">
                <Helmet title="마이페이지 > 프로그램 신청 확인"/>
                <BreadCrumb list={route.depth6}/>
                <div className="sub">
                    <div className="wrap">
                        <SubLeftMenu
                            title={route.depth6.title}
                            lnbList={route.depth6.list}
                        />
                        <div className="sub__content">
                            <h1 className="sub__title">
                                <span className="">프로그램 신청 확인</span>
                            </h1>
                            <div className="board">
                                <ul className="consulting_detail_button">
                                    <li className={TYPE == 'workshopprogram-applies' ? 'active' : ''}
                                        onClick={() => setType('workshopprogram-applies')}>워크숍
                                    </li>
                                    <li className={TYPE == 'board-consultings' ? 'active' : ''}
                                        onClick={() => setType('board-consultings')}>컨설팅
                                    </li>
                                </ul>
                                {
                                    !loading &&
                                        <>
                                            <BoardCustomList
                                                isLink={false}
                                                totalCount={totalCount}
                                                loading={loading}
                                                data={list}
                                                columns={columns}/>

                                            <CustomPagination
                                                pageSize={perPage}
                                                pageIndex={page}
                                                pageCount={pageCount}
                                                onPageChange={({selected}) => setPage(selected)}
                                            />
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyStudyProgram;
