import React from 'react';
import ReservationSystemLink from 'components/ReservationSystemLink';

const Index = ({title, tabList = [], tab, onChangeTag, hiddenHeader}) => {
    /*console.log(tabList)*/
    return (
        <div className="rentalHeader">
            {!hiddenHeader && (<h2>{title}</h2>)}
            <div className="rentalHeader__bottom">
                <div className="rentalHeader__tabs">
                    {tabList.map((item, idx) => {
                        //if (idx > 0) return;
                        return (
                            <button
                                key={idx}
                                className={tab === item.id && 'active' || ''}
                                onClick={() => onChangeTag(item.id)}>
                                {item.title}
                            </button>

                        )
                    })}
                </div>
                {!hiddenHeader && (<ReservationSystemLink/>)}
            </div>
        </div>
    );
};

export default Index;
