/** 공지사항 관련 graphql */
import gql from "graphql-tag";

//공지사항 목록
export const NOTICES_QUERY = gql`
query BoardNotices(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
) {
  boardNotices(
      limit: $limit, 
      sort: $sort,
      start: $start,
      where: $where
  ) {
      id
      title
      body
      view_count
      board_notice_publish
      view_permission
      createdAt
      updatedAt
      published_at
      notices
      notice_startdate
      notice_enddate
      size_x
      size_y
      position_x
      position_y
  }
}
`;

//언론보도 목록
export const NEWS_QUERY = gql`
query BoardNews(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
) {
  boardNews(
      limit: $limit, 
      sort: $sort,
      start: $start,
      where: $where
  ) {
      id
      title
      body
      view_count
      board_notice_publish
      view_permission
      createdAt
      updatedAt
      published_at
      notices
      notice_startdate
      notice_enddate
      size_x
      size_y
      position_x
      position_y
  }
}
`;

//프로그램 신청 목록
export const STUDY_PROGRAM_QUERY = gql`
query Studyprogram(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
  ){
    studyprograms(
      limit: $limit,
      sort: $sort,
      start: $start,
      where: $where
    ){
      id
      createdAt
      updatedAt
      title
      apply_startdate
      apply_enddate
      learning_enddate
      learning_startdate
      program_address
      apply_allowednumber
      apply_maxnumber
      excesspermit
      reservationwaiting
      props
      dev_org
      program_category
      thumbnailimages {
        name
        url
        formats
        mime
      }
      attached_file {
        id
        name
        url
        formats
        mime
      }
      user_group{
        id
      }
      url
    }
  }
`;

//지원사업 목록
export const SUPPORT_PROGRAM_QUERY = gql`
query Supportprogram(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
  ){
    supportprograms(
      limit: $limit,
      sort: $sort,
      start: $start,
      where: $where
    ){
      id
      createdAt
      updatedAt
      title
      apply_startdate
      apply_enddate
      learning_enddate
      learning_startdate
      program_address
      apply_allowednumber
      apply_maxnumber
      excesspermit
      reservationwaiting
      thumbnailimages {
        name
        url
        formats
        mime
      }
      attached_file {
        id
        name
        url
        formats
        mime
      }
      user_group{
        id
      }
    }
  }
`;


//파트너 사이트 / 관련 사이트
export const RELATION_SITES_QUERY = gql`
query Relationsitelists(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
){
  relationsitelists(
      limit: $limit, 
      sort: $sort,
      start: $start,
      where: $where
  ) {
    id
    title
    createdAt
    pinned_order
    link
    detalimages {
      name
      url
      formats
      mime
    }
  }
}
`;

//홍보영상 목록
export const PROMOTION_MEDIAS_QUERY = gql`
query Promotionmedias(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
){
  promotionmedias(
      limit: $limit, 
      sort: $sort,
      start: $start,
      where: $where
  ) {
    id
    title
    createdAt
    link
    thumbnailimages {
      name
      url
      formats
      mime
    }
  }
}
`;

// //지역 스토리 구분
export const LOCACTION_CATEGORIES_QUERY = gql`
query Location_categories(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
){
  locationCategories(
      limit: $limit, 
      sort: $sort,
      start: $start,
      where: $where
  ){
    title
  }
}
`;


// 지역 스토리 지역별
export const LOCALSTORY_CATEGORIES_QUERY = gql`
query Localstory_categories(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
){
  localstoryCategories(
      limit: $limit, 
      sort: $sort,
      start: $start,
      where: $where
  ){
    title
  }
}
`;

export const LOCALSTORY_COUNT_QUERY = gql`
query LocalstoryConnections(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
){
  localstoriesConnection(
      limit: $limit,
      sort: $sort,
      start: $start,
      where: $where
  ) {
    aggregate {
      count
    }
    groupBy {
      localstory_category {
        connection{
          values{
            localstory_category{
              id
              title
            }
          }
          aggregate {
            count
          }
        }
      }
    }
  }
}
`;

//메인 배너 이미지
export const MAIN_BANNER_QUERY = gql`
query Main_banners(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
  ){
    mainBanners(
      limit: $limit,
      sort: $sort,
      start: $start,
      where: $where
    ){
      id
      createdAt
      content
      updatedAt
      title
      backgroundColor
      banner_url
      banner_start_date
      banner_end_date
      banner_weight
      banner_image {
        name
        url
        formats
        mime
      }
      banner_mobile_image {
        name
        url
        formats
        mime
      }
    }
  }
 `;

//설문조사 결과
export const EVALUATE_QUERY = gql`
query evaluate(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
){
  evaluates(
      limit: $limit, 
      sort: $sort,
      start: $start,
      where: $where
  ) {
        id
        evaluate_type
        evaluate_result
        name
    }
}
 `;

export const SURVEY_RESULT_QUERY = gql`
query surveyResult(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
) {
  surveyResults(
      limit: $limit, 
      sort: $sort,
      start: $start,
      where: $where
  ) {
    id
    studyprogram {
      id
    }
    workshopprogram{
      id
    }
    author{
      id
    }
  }
}`
