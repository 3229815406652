import React, {useEffect, useMemo, useState} from 'react';
import {Helmet} from "react-helmet";
import {useForm} from "react-hook-form/dist/index.ie11";
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import * as actions from 'store/actions/content';
import moment from 'moment/dist/moment';
import {useTranslation} from 'react-i18next'
import Popup from "reactjs-popup";
import {apiUrl} from "config";
import * as api from 'lib/content';
import {workshopSurveyData1,workshopSurveyData2} from 'surveyData';
//components
import {CustomPagination} from 'components/Custom'
import {BoardHeader_e, BoardList_e} from 'components/Board_e';
import BoardWrite from 'components/Write/BoardWrite_c';

//graphql 관련
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import * as aQuery from "queries/program";


// customapi 관련
import axios from 'axios';
import usePromise from 'utils/usePromise'

import route from 'route';


const WorkshopSurveyData = (data) => {

    const type = 'survey-results';
    const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}));
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(0)
    const [list, setList] = useState({});
    const [reload, setReload] = useState(false);    //open 상태인가?
    const [link,setLink] = useState('');
    const [linkstatus,setLinkStatus] =useState(false)
    const [surverLinkStatus, setSurverLinkStatus] = useState(false);
    const [surverDownlink, setSurverDownlink] = useState({});

    //view 호출해서 program title 가져오기
    const {view, errorMsg = ''} = useSelector(({content}) => ({...content}));


    const title = _.get(view,'detail_one_title');


    const [result, setResult] = useState([]);
    /*const programTitle = "where": _.get(view,'detail_one_title'*/

    // SurveyResult 가져오기
    const [getSurveyResult, {loading:loading_survey}] = useLazyQuery(aQuery.SURVEYRESULT_QUERY, {
        fetchPolicy: 'no-cache',
        onCompleted: ({surveyResults = []}) => {
            if(!_.isEmpty(surveyResults)){
                setResult(surveyResults)

            }
            else{
                setResult([])
            }
        }
    });

    useEffect(() => {
            getSurveyResult({variables: {"where": {"workshopprogram": _.get(view,'id')}}})
}, [])

    useEffect(()=>{
        view && console.log(view,2222)
        user && console.log(user,3333)
    },[view,user])


    const [answerCount,setAnswerCount] = useState([[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0]]);
    const [subAnswer,setSubAnswer] = useState([[],[]]);


    useEffect(() => {
        if (!_.isEmpty(result)) {
            result.map((result_item,index)=>{
                const {survey} = result_item
                survey.map((item, idx) =>{
                    if(idx<4){
                        let copy = [...answerCount];
                        copy[idx][(_.get(item, 'answer')*1)-1] = copy[idx][(_.get(item, 'answer')*1)-1]+1
                        setAnswerCount(copy)
                    }
                    else{

                        if (idx == 4){
                            let copy_sub = [...subAnswer];
                            copy_sub[0][index] = _.get(item, 'answer')
                            setSubAnswer(copy_sub)
                        }else {
                            let copy_sub = [...subAnswer];
                            copy_sub[1][index] =  _.get(item, 'answer')
                            setSubAnswer(copy_sub)
                        }


                    }
                })
            })
        }
    }, [result])


    useEffect(()=>{
        if(!_.isEmpty(answerCount)){
            /*console.log(answerCount)*/
        }
        if(!_.isEmpty(subAnswer)){
            /*console.log(subAnswer)*/
        }
    },[subAnswer])

    //csv 다운로드
    const downhandle = async () =>{
        console.log(`${view.id}`)
        const {data:downlink,status } =await axios.get(`/custom-api/export/csvdown/surveyresult?id=${view.id}`);
        console.log(`https://gj.udec.kr/api/${downlink}`)
        setLink(`https://gj.udec.kr/api/${downlink}`)
        setLinkStatus(true)
    }

    const surveydownhandle = async () =>{
        const {data:surveydownlink,status } = await axios.get(`/custom-api/export/csvdown/surveydown?id=${view.id}`);
        console.log(surveydownlink,3333)
        setSurverDownlink(`https://gj.udec.kr/api/${surveydownlink}`)
        console.log(`https://gj.udec.kr/api/${surveydownlink}`)
        setSurverLinkStatus(true)
    }


    return (

                   <div>
                       {workshopSurveyData1.map((item,idx)=><div key={item.id}>
                           <div className={'survey_que_div'}>
                               <div className={'work_survey_title'}>
                                   {item.no}. {_.get(item, 'title')}
                               </div>
                               <div className={'work_survey q'}>
                                   매우 그렇다
                               </div>
                               <div className={'work_survey an1'}>
                                   {`${answerCount[idx][4]}명`}
                               </div>
                               <div className={'work_survey q'}>
                                   그렇다
                               </div>
                               <div className={'work_survey an2'}>
                                   {`${answerCount[idx][3]}명`}
                               </div>
                               <div className={'work_survey q'}>
                                   보통이다
                               </div>
                               <div className={'work_survey an3'}>
                                   {`${answerCount[idx][2]}명`}
                               </div>
                               <div className={'work_survey q'}>
                                   대체로 그렇지 않다
                               </div>
                               <div className={'work_survey an4'}>
                                   {`${answerCount[idx][1]}명`}
                               </div>
                               <div className={'work_survey q'}>
                                   전혀 그렇지 않다
                               </div>
                               <div className={'work_survey an5'}>
                                   {`${answerCount[idx][0]}명`}
                               </div>
                           </div>


                       </div>)}

                       {workshopSurveyData2.map((item,idx)=>
                           <div key={item.id}>
                               <div className={'survey_que_div'}>
                                   <div className={'work_survey_title'}>
                                        {item.no}. {_.get(item,'title')}
                                   </div>
                                   <div>

                                        {
                                            /*console.log(subAnswer[idx])*/
                                            subAnswer[idx].map((aItem, aIdx)=>
                                                <div>{`- ${aItem}`}</div>
                                            )
                                        }
                                   </div>
                               </div>
                           </div>
                       )}

                       <div className="survey_button_box">
                           <div className="survey_button_box_left">
                               {/*csv다운로드 버튼*/}
                               <button onClick={()=>downhandle()} className="csv_button">csv다운로드 생성
                               </button>
                               {linkstatus &&
                               <a href={link} download className="csv_button_area">csv파일 다운로드</a>
                               }
                           </div>
                           <div className="survey_button_box_right">
                               {/*설문조사 결과 전체 다운로드 버튼*/}
                               <button onClick={()=>surveydownhandle()} className="csv_button">설문조사 결과 전체 다운로드 생성</button>
                               {surverLinkStatus &&
                               <a href={surverDownlink} download className="csv_button_area">설문조사 csv 압축파일 다운로드</a>
                               }
                           </div>
                       </div>
                   </div>

    )
}

export default WorkshopSurveyData;
