import React, {useEffect, useMemo, useState} from 'react';
import BreadCrumb from 'components/BreadCrumb';
import {Helmet} from "react-helmet";
import route from 'route';
import Gallery from 'components/Gallery';
import SubLeftMenu from 'components/SubLeftMenu';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';
import {CustomPagination} from 'components/Custom';
import qs from 'qs';
import {useLocation} from 'react-router-dom';
  
const CklData = () => {
    const dispatch = useDispatch();
    const {search} = useLocation();
    const {list = [], totalCount = 0, loading} = useSelector(({content}) => ({...content}));

    const {page: _page = 0, perPage: _perPage = 6} = qs.parse(search, {ignoreQueryPrefix: true});
    const [perPage, setPerPage] = useState(_perPage && +_perPage || 6)
    const [page, setPage] = useState(_page && +_page || 0)

    //const [perPage, setPerPage] = useState(6)
    //const [page, setPage] = useState(0)
    const [category, setCategory] = useState('webtoon')
    const [params, setParams] = useState({_limit: perPage, _sort: `createdAt:DESC`, _start: page * perPage, classification_eq:category})
    const updateParams = (data) => {
        setParams({...params, ...data});
    };
    const pageCount = Math.ceil(totalCount / perPage);
    const handleChange = (value) => {
        /*console.log(value, '키워드 입력 및 타입 셀렉트 시')*/
    }
    
    useEffect(() => {
        const {_start, _limit, classification_eq} = params;
        if (_limit !== perPage || _start !== page * perPage) {
            updateParams({...params, _start: page * perPage, _limit: perPage, classification_eq:classification_eq})
        }
    }, [page, perPage])

    useEffect(() => {
        dispatch(actions.listRequest({type: 'board-interviews', ...params}))
    }, [params])

    useEffect(() => {
        updateParams({...params, classification_eq:category})
    }, [category])
  return (
    <div className="program type2">
      <Helmet title='CKL 성과물'/>
      <BreadCrumb list={route.dataset} />
      <div className="sub">
        <div className="wrap">
          <SubLeftMenu
            title={route.dataset.title}
            lnbList={route.dataset.list}
          />
          
          <div className="sub__content">
              
      <h1 className="sub__title">
        <span className="">CKL 성과물</span>
      </h1>
      <div className="tabs">
        <button onClick={() => setCategory('webtoon')} to={''} className={category=='webtoon'?'active':''}><span>웹툰연재</span></button>
        <button onClick={() => setCategory('youtube')} to={''} className={category=='youtube'?'active':''}><span>유튜브 영상</span></button>
        <button onClick={() => setCategory('ETC')} to={''} className={(category=='ETC')?'active':''}><span>기 타</span></button>
      </div>

      {loading && (<div className={`text-center p-4`}>로딩중...</div>) ||
      (<div className="programList">
        <div className="programList__list">
          <Gallery list={list} totalCount={totalCount}/>
        </div>
        
          
                
        <CustomPagination
                        pageSize={perPage}
                        pageIndex={page}
                        pageCount={pageCount}
                        onPageChange={({selected}) => setPage(selected)}/>
        </div>)}
      </div>
      </div>
    </div>
    </div>
  );
};

export default CklData;
