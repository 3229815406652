import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {RiArrowDropRightLine} from 'react-icons/all';

const MobileNavItem = ({data}) => {
    const [open, setOpen] = useState(false);
    return (
        <div className="mobileNav__link-item">
            <button onClick={() => setOpen(!open)}>{data.title}</button>
            <div className={`depth ${open ? 'active' : ''}`}>
                {
                    data.list.map(link =>
                    {
                        if(_.isEmpty(link.type)){
                            return(
                                    <Link to={link.url} key={link.title}>
                                        {link.title}
                                        <RiArrowDropRightLine/>
                                    </Link>)
                        }
                        else{
                            return(
                                    <a href={link.url}  key={link.title} activeClassName="active" target={'_blank'}>{link.title}</a>
                            )
                        }
                    })
                }
            </div>
        </div>
    );
};

export default MobileNavItem;
