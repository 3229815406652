/** 로그인 폼 */
import React, {useEffect,useRef,useState} from 'react';
import {useForm, Controller} from "react-hook-form/dist/index.ie11";
import ReactDatePicker, { registerLocale,setDefaultLocale  } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Postcode from 'components/Postcode';
import * as authActions from 'store/actions/auth';
import Policy from 'components/Content/Policy';
import Terms from 'components/Content/Terms';
import MemberPopup from 'components/Popup/MemberPopup';
import ko from 'date-fns/locale/ko';
registerLocale('ko', ko);
// import Popup from "reactjs-popup";
import {organization_data} from 'data';

const defaultValues = {
    useName: "",
    birth: "",
};

const ChangeForm = ({onSubmit, handleCancel, userData}) => {
    const {register, handleSubmit, setValue, errors, watch, getValues,setError,control} = useForm({defaultValues});
    const password = useRef({});
    password.current = watch("password", "");
    const [isPostcode,setPostCode] = useState(false);
    const [usernamedata, setUsernamedata] = useState(null);
    const [date, setDate] = useState(null);
    const allvalue = getValues('service_agree','information_agree','emailSend','smsSend','password');
    const [popup, setPopup] = useState(null);   //팝업 ref
    const [open, setOpen] = useState(false);    //open 상태인가?
    const [loading, setLoading] = useState(true);   //데이터 로딩중인가?
    const handleTogglePostcode = () => {
        setPostCode(!isPostcode)
    };

    const range = (start, end, step=1) => {
        let array = [];
        for (let i = start; i < end; ++i) {
            if (!(i % step)) {
                array.push(i);
            }
        }
        return array;
    }

    const [startDate, setStartDate] = useState(new Date());
    const years = range(1990, moment().year() + 1, 1);
    const months = [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월"
    ];

    const popupClose = () => {
        if (!_.isEmpty(popup)) {
            const {ContentEl = {}, setContentRef, closePopup} = popup;
            if (ContentEl.className && ContentEl.className.indexOf('popup-close-content') === -1) {
                ContentEl.className += ' popup-close-content ';
                setContentRef(ContentEl);
            }
            setTimeout(closePopup, 450);
        }
    }

    useEffect(()=>{
        if(!_.isEmpty(userData)){
            setValue('userName',_.get(userData,'email'))
            setValue('name',_.get(userData,'name'))
            setValue('hp',_.get(userData,'hp'))
            setValue('organization',_.get(userData,'organization'))
            setValue('regiNum',_.get(userData,'user_num'))
            setValue('identity',_.get(userData,'identity'))
            setValue('major',_.get(userData,'major'))
        }
    },userData)

    //registerLocale("ko", ko);
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <input type={'hidden'} name={'id'} value={_.get(userData,'id')} ref={register()}/>
            <div className="form-group">
                <label htmlFor="userName" className="col-form-label font-weight-bold">아이디(이메일)</label>
                <input type="text" id="userName" name="userName"
                       className={`form-control jd_input ${errors.userName && `border-danger` || ``}`}
                       ref={register({required: "이메일은 필수입니다",
                           pattern: {
                               value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                               message: "이메일 형식이 아닙니다."
                           }})}
                       placeholder="아이디"
                       readOnly={true}/>
            </div>

            {/*<div className="form-group">
                <label htmlFor="before_password" className="col-form-label font-weight-bold">현재 비밀번호</label>
                <input type="password" id="before_password" name="before_password"
                       className={`form-control ${errors.password && `border-danger` || ``}`}
                       ref={register({required: "비밀번호는 필수입니다",
                           minLength: {
                               value: 8,
                               pattern: {
                                   value:[/^[0-9A-Za-z\w!~@#$%\^&\*\(\)?+=/]{8,29}$/],
                                   message: "영문, 숫자, 특수기호 포함 8자리 이상 입력해주세요"
                               },
                               message: "영문, 숫자, 특수기호 포함 8자리 이상 입력해주세요"
                           }})
                       }
                       placeholder="비밀번호 입력 (영문, 숫자, 특수기호 포함 8자리 이상)"
                />
                {errors.password && (
                    <small className="text-danger">{errors.password.message}</small>
                )}
            </div>*/}

            <div className="form-group">
                <label htmlFor="after_password" className="col-form-label font-weight-bold">비밀번호 변경</label>
                <input type="password" id="after_password" name="after_password"
                       className={`form-control ${errors.after_password && `border-danger` || ``}`}
                       ref={register({required: "비밀번호는 필수입니다",
                           minLength: {
                               value: 8,
                               pattern: {
                                   value:[/^[0-9A-Za-z\w!~@#$%\^&\*\(\)?+=/]{8,29}$/],
                                   message: "영문, 숫자, 특수기호 포함 8자리 이상 입력해주세요"
                               },
                               message: "영문, 숫자, 특수기호 포함 8자리 이상 입력해주세요"
                           }})}
                       placeholder="비밀번호 입력 (영문, 숫자, 특수기호 포함 8자리 이상)"
                />
                {errors.after_password && (
                    <small className="text-danger">{errors.after_password.message}</small>
                )}
            </div>



            <div className="form-group">
                <label htmlFor="after_password_confirm" className="col-form-label font-weight-bold">비밀번호 변경 확인</label>
                <input type="password" id="after_password_confirm" name="after_password_confirm"
                       className={`form-control ${errors.after_password_confirm && `border-danger` || ``}`}
                       ref={register({required: '비밀번호가 일치하지 않습니다',
                           validate: value =>
                               value === getValues('after_password') || "비밀번호가 일치하지 않습니다"
                           
                       })}
                       placeholder="비밀번호 확인"
                />
                {(errors.after_password_confirm)&&(
                    <small className="text-danger">{errors.after_password_confirm.message}</small>
                )}
            </div>

            <div className="form-group">
                <label htmlFor="name" className="col-form-label font-weight-bold">이름</label>
                <input type="name" id="name" name="name"
                       className={`form-control ${errors.name && `border-danger` || ``}`}
                       ref={register({required: '이름은 필수입니다.'})}
                       placeholder="이름"
                       readOnly={true}
                />
                {(errors.name)&&(
                    <small className="text-danger">{errors.name.message}</small>
                )}
            </div>

            <div className="form-group">
                <label htmlFor="hp" className="col-form-label font-weight-bold">핸드폰 번호</label>
                <input type="hp" id="hp" name="hp"
                       className={`form-control ${errors.hp && `border-danger` || ``}`}
                       ref={register({required: '핸드폰번호는 필수입니다.',
                           pattern: {value: /^\d{3}-\d{3,4}-\d{4}$/, message:"형식이 맞지 않습니다"}})}
                       placeholder="010-1234-5678"
                />
                {(errors.hp)&&(
                    <small className="text-danger">{errors.hp.message}</small>
                )}
            </div>

            <div className="form-group">
                <label htmlFor="organization" className="col-form-label font-weight-bold">소속</label>
                <select
                    type="organization" id="organization" name="organization"
                    ref={register()}
                    className={`form-control`}
                >
                    <option>선택</option>
                    {organization_data.map((item,index)=>
                        <option value={item} key={index}>{item}</option>)}
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="identity" className="col-form-label font-weight-bold">신분</label>
                <select
                    type="identity" id="identity" name="identity"
                    ref={register()}
                    className={`form-control`}
                >
                    <option>선택</option>
                    <option value={'professor'}>교수</option>
                    <option value={'teacher'}>강사</option>
                    <option value={'employee'}>직원</option>
                    <option value={'student'}>학생</option>
                    <option value={'other'}>기타</option>
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="major" className="col-form-label font-weight-bold">학과</label>
                <input type="regiNum" id="major" name="major"
                    // className={`form-control ${errors.regiNum && `border-danger` || ``}`}
                       className={`form-control`}
                       ref={register()}
                       placeholder="학과정보"
                />
                {/*{(errors.regiNum)&&(*/}
                {/*    <small className="text-danger">{errors.regiNum.message}</small>*/}
                {/*)}*/}
            </div>

            <div className="form-group">
                <label htmlFor="regiNum" className="col-form-label font-weight-bold">사번/학번</label>
                <input type="regiNum" id="regiNum" name="regiNum"
                       className={`form-control ${errors.regiNum && `border-danger` || ``}`}
                       ref={register({required: '사번ㆍ학번은 필수입니다.'})}
                       placeholder="학번ㆍ사번"
                />
                {(errors.regiNum)&&(
                    <small className="text-danger">{errors.regiNum.message}</small>
                )}
            </div>

            <div className="btn_submit_wrap">
                <button className="btn_submit">수정하기</button>
            </div>
        </form>)
}


export default ChangeForm;
