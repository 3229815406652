/** 아이디/비밀번호 찾기 */
import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom'
import {Helmet} from "react-helmet";
//import * as api from "lib/users";
import usePromise from "utils/usePromise";
import swal from 'sweetalert'
import * as api from 'lib/auth'

//아이디 찾기
// const getUserId = (email, onSuccess) => async () => {
//     try {
//         const {data: {result, messages}, status} = await api.getFindId(email)
//         if (_.isEmpty(result)) throw messages || '입력하신 정보는 K-Comics 아카데미에 존재하지 않는 회원입니다.';
//         if (status === 204) throw '입력하신 정보는 K-Comics 아카데미에 존재하지 않는 회원입니다.';
//         if (result) {
//             swal({
//                 html: true,
//                 title: "아이디 찾기 성공",
//                 content: {
//                     element: 'div',
//                     attributes: {
//                         innerHTML: `<div class="swal-text" style="margin-top: 0;">${email} 님의 <br/>
//                         아이디는 [<span style="font-weight: bold">${result}</span>] 입니다.</div>`
//                     }
//                 },
//                 icon: "success", timer: 50000, buttons: false,
//             });
//         }
//     } catch (e) {
//         const errorMsg = _.isString(e) ? e.trim() : '입력하신 정보는 K-Comics 아카데미에 존재하지 않는 회원입니다.';
//         swal({
//             title: "아이디 찾기 실패", text: errorMsg,
//             icon: "error", timer: 2000, buttons: false,
//         });
//     }
// }

// const FindId = () => {
//     const [email, setEmail] = useState(null); //아이디
//
//     return (
//         <div className={`form_div`}>
//             <div className="find_id_form">
//                 <div className="title">아이디 찾기</div>
//                 <div className="fm_cont">
//                   <label htmlFor="findemail"></label>
//                     <input
//                         type="text"
//                         className="frm_ipt frm_100"
//                         placeholder="이메일 주소"
//                         title="이메일 주소 입력"
//                         id="findemail"
//                         onChange={e => setEmail(e.target.value)}/>
//                 </div>
//                 <div className="info">
//                     회원가입시 사용했던 이메일 주소를 입력하시면 아이디를 찾을 수 있습니다.
//                 </div>
//                 <div className="btn_submit_wrap">
//                     <button type="button" className="btn_submit" onClick={getUserId(email)}>확인</button>
//                 </div>
//             </div>
//         </div>
//     );
// }

//비밀번호 찾기
const getPasswordToEmail = (id, history) => async () => {
    
    try {
        if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(id))){
            return swal("이메일 형식이 아닙니다.",'','warning')
        }
        const {data, status} = await api.findPassword({email:id});
        console.log(status)
        if(status === 200){
            swal("이메일이 발송되었습니다.",'','success')
        }
    } catch (e){
        return swal("가입되지 않은 이메일입니다.",'','warning')
    } finally{

    }
}

const FindPassword = () => {
    const [id, setId] = useState(null); //아이디
    const history = useHistory();

    return (
        <div className={`form_div`}>
            <div className="find_pass_form">
                <div className="title">비밀번호 찾기</div>
                <div className="fm_cont">
                  <label htmlFor="findpass"></label>
                    <input
                        type="text"
                        className="frm_ipt frm_100"
                        placeholder="아이디"
                        title="아이디 입력"
                        id="findpass"
                        onChange={e => setId(e.target.value)}/>
                </div>
                <div className="info">
                    아이디를 입력하시면 회원가입시 사용했던 이메일로 <br/>
                    새로운 비밀번호를 설정할 수 있는 링크를 발송해드립니다.
                </div>
                <div className="btn_submit_wrap">
                    <button type="button"
                            className="btn_submit"
                            onClick={getPasswordToEmail(id, history)}>확인
                    </button>
                </div>
            </div>
        </div>
    );
}

// 아이디/비밀번호 찾기
const FindInfo = () => {
    return (
        <div className="content">
        <div className="conts_body">
            <Link className="close" title="홈페이지로 이동" to="/"><span className="invisible-btn">홈페이지로 이동</span></Link>
            <Helmet title="비밀번호 찾기"/>
            <h2 className="page_title">비밀번호 찾기</h2>

            {/** 아이디 찾기/아이디 찾기 결과 **/}
            {/*<FindId/>*/}

            {/** 비밀번호 찾기 **/}
            <FindPassword/>
            <div className="link_container">
                <Link to="/member/login" className="find_page_login">로그인</Link>
                <Link to="/member/register/information" className="find_page_regist">회원가입</Link>
            </div>

        </div>
        </div>
    )
}

export default FindInfo;
