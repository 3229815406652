import React from 'react';
import DaumPostcode from 'react-daum-postcode';

class Postcode extends React.Component {
    handleAddress = (data) => {
        //주소, 우편번호, 참고항목 변수
        let [address, postcode, extraAddress] = [data.address, data.zonecode, ''];

        //주소
        if (data.userSelectedType === 'R') { // 사용자가 도로명 주소를 선택했을 경우
            address = data.roadAddress;
        } else { // 사용자가 지번 주소를 선택했을 경우(J)
            address = data.jibunAddress;
        }

        if (data.userSelectedType === 'R') {    // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') { // 건물명이 있고, 공동주택일 경우 추가한다.
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
        }
        this.props.onSubmit({address, postcode, extraAddress});
    }

    render() {
        return (
            <DaumPostcode
                onComplete={this.handleAddress}
                {...this.props}/>
        );
    }
}

export default Postcode;
