import React, {useEffect, useMemo, useState} from 'react';
import BreadCrumb from 'components/BreadCrumb';
import {Helmet} from "react-helmet";
import route from 'route';
import SubLeftMenu from 'components/SubLeftMenu';
import FaqList from 'components/FaqList';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';
import {CustomPagination} from 'components/Custom';

const Faq = () => {

    
    const dispatch = useDispatch();
    const {list = [], totalCount = 0, loading} = useSelector(({content}) => ({...content}));
    const [perPage, setPerPage] = useState(5)
    const [page, setPage] = useState(0)
    const [params, setParams] = useState({_limit: perPage, _sort: `createdAt:DESC`, _start: page * perPage})
    const updateParams = (data) => {
        setParams({...params, ...data});
    };
    const [filter, setFilter] = useState({});
    const pageCount = Math.ceil(totalCount / perPage);
    const [searchParams, setSearchParams] = useState({keyword: '', type: 'title_contains'})
    /*const handleChange = (value) => {
        console.log(value, '키워드 입력 및 타입 셀렉트 시')
    }*/
    //page 이동
    useEffect(() => {
        const {_start, _limit} = params;
        if (_limit !== perPage || _start !== page * perPage) {
            updateParams({...params, _start: page * perPage, _limit: perPage})
        }
    }, [page, perPage])
    

   

    const handleChange = ({keyword = "", type = ""}) => {
      if (!keyword || !type) {
          setFilter({})
          return;
      }

      const filters = type.split(',') || [];
      const _filter = {};
      _.forEach(filters, (field, idx) => {
          _filter[`_where[_or][${idx}][${field}]`] = keyword
      })
      setFilter({..._filter})
  }

    const handleReset =() =>{
        setParams ({_limit: 10, _sort: `createdAt:DESC`, _start: 0})
        setPage(0)
        setFilter({})
        setSearchParams({keyword: '', type: 'title_contains'})
    }

    useEffect(() => {
        dispatch(actions.listRequest({type: 'faqs', ...params, ...filter}))
    }, [params, filter])
  return (
    <div className="onair content">
      <Helmet title="FAQ"/>
      <BreadCrumb list={route.depth5} />
      <div className="sub">
        <div className="wrap">
          <SubLeftMenu
            title={route.depth5.title}
            lnbList={route.depth5.list}
          />
          <div className="sub__content">
            <h1 className="sub__title">
              <span className="">FAQ</span>
            </h1>
            <p className="sub__description">궁금하신 사항을 확인하세요.</p>
            <div className="faqList">
            <div className="board__search">
                <div className="input">
                <input type="text" value={searchParams.keyword} onChange={e => setSearchParams({...searchParams,keyword: e.target.value})} placeholder="검색어를 입력해주세요."/>
                </div>
                <button onClick={() => handleChange(searchParams)}>검색</button>
                <button className="reset__btn" onClick={() =>{
                    handleReset()
                } }>초기화</button>
            </div>
            {loading && (<div className={`text-center p-4`}>로딩중...</div>) ||
            (
<>
            <ul className="faqs">
                <FaqList faqlist={list}/>

            </ul>
                
                <CustomPagination
                        pageSize={perPage}
                        pageIndex={page}
                        pageCount={pageCount}
                        onPageChange={({selected}) => setPage(selected)}/>
            </>)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default Faq;
