/** 회원가입 완료 */
import React, {useEffect} from 'react';
import {Link, Redirect} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import * as authActions from 'store/actions/auth';
import {Helmet} from "react-helmet";

const RegisterComplete = () => {
  return (
    <div className="content">
      <div className="conts">
          <Helmet title="회원가입 완료"/>
          <Link className="close" title="홈페이지로 이동" to="/"><span className="invisible-btn">홈페이지로 이동</span></Link>
          <div className="conts_body">
              {/* 제목 영역 start */}

              <h2 className="page_title">회원가입</h2>
              <div className="join_result_info">회원 가입이 완료 되었습니다!</div>
              {/* 제목 영역 end */}

              <div className="join_result">
                  {/* 데스크탑 기준 왼쪽 상자 start */}
                  <div className="item-complete complete-left">
                      <div className="img">
                          <img src="/assets/images/join_rst_01.png" alt="회원가입 완료"/>
                      </div>
                      <div className="join_result_txt join_result_txt_center">
                          신규 회원 가입을 환영합니다.<br/>
                          간편 회원으로 가입되셨습니다. 간편 회원은 회원가입 절차 및
                          간소화를 위한 광주전남권역 대학원격교육지원센터
                          <br className="no_break"/>고객 서비스입니다.
                      </div>
                      {/*<div className="join_result_txt join_result_txt_small join_result_txt_center">*/}
                      {/*    전남콘텐츠코리아랩 서비스 이용이 가능하지만 <br className="no_break"/>*/}
                      {/*    모든 시스템을 정식적으로 사용하기 위해서<br className="no_break"/> 본인인증 및*/}
                      {/*    추가정보 입력을 완료해야 합니다.*/}
                      {/*</div>*/}
                      <div className="btn_submit_wrap_complete">
                          <Link to="/member/login" className="btn_submit_complete black">
                              간편 회원으로 시작하기!
                          </Link>
                      </div>
                  </div>
                  {/* 데스크탑 기준 왼쪽 상자 end */}

              </div>
          </div>
          {/*  conts_body end  */}
      </div>
    </div>
  );
};

export default RegisterComplete;
