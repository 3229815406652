/**
 * 권한 없음 오류. 403오류
 */
import React from 'react';
import {Link, useHistory} from 'react-router-dom'

const Forbidden = () => {
    const history = useHistory();
    return (
        <div className="container">
            <div className="access_error">
                <div className="description">
                    <div className="title_error">
                        <p className="space">
                            <img src="/assets/images/403.png" alt="403 error"/>
                        </p>
                        <p>403</p>
                    </div>
                    <div className="title">요청하신 페이지에 접근권한이 없습니다.</div>
                </div>
                <div className="sub_description">
                    <div className="sub_1">연결하려는 페이지에 접근권한이 없어 접근이 거부되었습니다.</div>
                    <div className="sub_3">서비스 이용에 불편을 드려 죄송합니다.</div>
                </div>
                <div className="link">
                    <button onClick={() => history.goBack()} className="before_btn">이전페이지</button>
                    <Link className="home_btn" to="/">홈으로</Link>
                </div>
            </div>
        </div>
    );
}

export default Forbidden;
