import React, {useEffect, useMemo, useState, useCallback} from 'react';
import moment from 'moment/dist/moment';
import {Link} from 'react-router-dom';
import {useParams, useHistory} from 'react-router-dom';
import {apiUrl} from 'config';
import Popup from "reactjs-popup";
import {ProgramApplyWrite} from 'components/Write';
import {ApplyComplete} from 'components/Write';
import {useSelector, useDispatch} from 'react-redux';
import * as api from 'lib/content';
import * as actions from 'store/actions/content';

//graphql 관련
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import * as aQuery from "queries/program";

const TYPE = 'studyprograms';
const ProgramDetailTop = ({data = {},type,setReset_data=()=>{}}) => {
    const newurl = window.location.href
    const dispatch = useDispatch();
    const [btnTitle,setBtnTitle] = useState('수강신청하기');
    const {user, isLoggedIn} = useSelector(({auth: {user, isLoggedIn}}) => ({
        user, isLoggedIn
    }));
    const {id} = useParams();
    const [btnState, setBtnState] = useState(false);
    const [mode, setMode] = useState(null);  // '' - 생성 / m - 수정
    const [applis, setApplis] = useState(null);
    const [modeTitle, setModeTitle] = useState('');
    const [applyCount, setApplyCount] = useState(0);
    const [applyOverCount, setApplyOverCount] = useState(0);
    const {program_category} = data;
    const [cw_uuid,setCw_uuid] = useState('');
    const [hiddenProgram,setHiddenProgram] = useState(true);

    //프로그램 신청 완료 관련
    const [applySuccess, setApplySuccess] = useState(false);
    const [loading, setLoading] = useState(true);   //데이터 로딩중인가?
    const [open, setOpen] = useState(true);    //open 상태인가?
    const [reload, setReload] = useState(false);    //open 상태인가?


    //hiddenCourse일경우 수강신청안되게
    useEffect(()=>{
        if(!_.isEmpty(program_category)){
            if(program_category == "hiddencourse"){
                setHiddenProgram(false);
            }else {
                setHiddenProgram(true);
            }
        }
    },[program_category]);



    // 사용자 지원 상태 목록 관련
    const [getApply_study, {loading:apply_loading_study}] = useLazyQuery(aQuery.STUDYPROGRAM_APPLIES_QUERY, {
        fetchPolicy: 'no-cache',
        onCompleted: ({studyprogramApplies = []}) => {
            // 프로그램 신청 데이터를 가져옴
            //console.log('[jarry --studyprogramapply 데이터 ]',studyprogramApplies)
            //console.log('[jarry --studyprogramapply[0] 데이터 ]',studyprogramApplies[0])
            if(studyprogramApplies.length>0){
                setApplis(studyprogramApplies[0])
                // 신청 상태에 따라 모드를 수정Q
                //console.log('[jarry -- 신청 상태 ]',apply_status_category_title)
                //console.log('[jarry -- 모드 변경 ]',mode)

                const {appied_program = {}, apply_files = [], apply_status = {}, apply_user = [], body = '', createdAt = '', id = ''} = studyprogramApplies[0];
                const {apply_status_category_title = ''} = apply_status;
                if (apply_status_category_title != '') {
                    if (apply_status_category_title == 'cancellation_completed' || apply_status_category_title == 'apply_cancael_completed') {
                        setMode('d')
                    } else{
                        setMode('m')
                    }
                } else {
                    setMode('')
                }
            }
            else{
                setApplis([])
                setMode('')
            }
        }
    });
    const [getApply_support,{loading:apply_loading_support}] = useLazyQuery(aQuery.SUPPORT_PROGRAM_APPLIES_QUERY, {
        fetchPolicy: 'no-cache',
        onCompleted: ({supportProgramApplies = []}) => {
            if(supportProgramApplies.length>0){
                setApplis(supportProgramApplies[0])
                const {appied_program = {}, apply_files = [], apply_status = {}, apply_user = [], body = '', createdAt = '', id = ''} = supportProgramApplies[0];
                const {apply_status_category_title = ''} = apply_status;
                if (apply_status_category_title != '') {
                    if (apply_status_category_title == 'cancellation_completed' || apply_status_category_title == 'apply_cancael_completed') {
                        setMode('d')
                    } else {
                        setMode('m')
                    }
                } else {
                    setMode('')
                }
            }
            else{
                setApplis([])
                setMode('')
            }
        }
    });

    const copy = (url) => {
        console.log("copy!!");
        if (!document.queryCommandSupported("copy")) {
            return alert("복사하기가 지원되지 않는 브라우저입니다.");
        }

        const textarea = document.createElement("textarea");
        textarea.value = newurl;
        textarea.style.top = 0;
        textarea.style.left = 0;
        textarea.style.position = "fixed";

        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        alert("클립보드에 복사되었습니다.");
    };






    async function fetchMyAPI(type,id) {
        if(id){
            try {
                const {status:apply_status,data:apply_data} = await api.gets({type:type,appied_program:id,apply_status:'5ff6b769bf55b944295d63e8'})
                const {status:apply_over_status,data:apply_over_data} = await api.gets({type:type,appied_program:id,apply_status:'5ff6b774bf55b944295d63e9'})
                if(apply_status !== 200 || apply_over_status != 200){
                    throw new Error('에러 발생');
                }
                else{
                    setApplyCount(apply_data.length);
                    setApplyOverCount(apply_over_data.length);
                }
            } catch (e) {
                console.log('데이터 로딩 실패',e.message);
            } finally {
            }
        }
    }
    useEffect(() => {
        //console.log(data)
        if(data){
            if(type == 'studyprogram-applies')
            {
                if(user){
                    getApply_study({variables: {"where": {"apply_user": user.id,"appied_program":data.id}}})
                    fetchMyAPI(type,data.id)
                }
            }
            else if(type == 'supportprogram-applies')
            {
                //console.log('!')
                if(user){
                    //getApply_support({variables: {"where": {"apply_user": user.id,"appied_program":data.id}}})
                    fetchMyAPI(type,data.id)
                    //getApplyNumber(type,data.id)
                }
            }
        }
    }, [user,data,reload])

    useEffect(() => {
        if (mode != null) {

            if(user && (mode=='d' || mode=='')) {
                if (user.user_group && data.user_group) {
                    //console.log('.')
                    setBtnTitle('수강신청하기')
                    if (user.user_group == data.user_group.id) {
                        //console.log('?')
                        if(applyCount < data.apply_maxnumber || !data.apply_maxnumber)
                        {
                            setBtnState(true)
                        }
                        else if(data.apply_maxnumber <= applyCount){
                            if(data.excessapplypermit){
                                if(data.reservationwaiting > applyOverCount || !data.reservationwaiting){
                                    setBtnTitle('수강 예약(정원초과)')
                                    setBtnState(true)
                                }
                                else if(data.reservationwaiting <= applyOverCount){
                                    setBtnTitle('수강 종료(예약초과)')
                                    setBtnState(false)
                                }
                            }
                            else{
                                setBtnTitle('수강 종료(정원초과)')
                                setBtnState(false)
                            }
                        }
                    } else {
                        setBtnState(false)
                    }
                }
                else{
                    setBtnTitle('수강신청하기')
                    if(applyCount < data.apply_maxnumber || !data.apply_maxnumber)
                    {
                        setBtnState(true)
                    }
                    else if(data.apply_maxnumber <= applyCount){
                        if(data.excessapplypermit){
                            if(data.reservationwaiting > applyOverCount || !data.reservationwaiting){
                                setBtnTitle('수강 예약(정원초과)')
                                setBtnState(true)
                            }
                            else if(data.reservationwaiting <= applyOverCount){
                                setBtnTitle('수강 종료(예약초과)')
                                setBtnState(false)
                            }
                        }
                        else{
                            setBtnTitle('수강 종료(정원초과)')
                            setBtnState(false)
                        }
                    }
                }
            }
            else if(user && mode=='m'){
                setBtnState(true)
            }
        }
    }, [mode,user,data,applyCount,applyOverCount])


    const modeChange = () => {
    }

    const apply_check = () => {
        setApplySuccess(false)
        setReload(!reload)
    }

    useEffect(()=>{
        if(!_.isEmpty(data)){
            setCw_uuid(_.get(data,'cw_uuid'))
            /*switch(_.get(data,'title')){
                case "고급해석학의 기초 개념":
                    setCw_uuid("3ac14b9338105042fb34d84f3b6cb1ed")
                    break;
                case "대학생의 자기이해와 진로탐색":
                    setCw_uuid("1358b691f12335f56dd1883f2ff74a10")
                    break;
                case "생명과학":
                    setCw_uuid("ceb77fa919de0a15902c0feae2430ace")
                    break;
                case "성공적 대학생활을 위한 자기주도적 학습의 이해":
                    setCw_uuid("42c3c8871148987f005f5b994807cba2")
                    break;
                case "성인간호학":
                    setCw_uuid("3cf2d728104cba79c8d3a8e85d542e06")
                    break;
                case "원격수업역량강화 워크숍":
                    setCw_uuid("e87a8d6aeb169ad821fbee0910f3684d")
                    break;
                case "효과적인 수업자료의 개발":
                    setCw_uuid("b69bd5106b2144b113ad45684c82b336")
                    break;
            }*/
        }
    },[data])


    return (
        <div className="programDetail__top">
            <div className="thumb">
                <img src={apiUrl + _.get(data, 'thumbnailimages.0.url')} alt=""/>
            </div>
            <div className="info">
                <h2>
                    {data.title}
                </h2>
                <p>
                    {/*#장비고, #CNC, #레이저커터*/}
                </p>

                <div className={"infoDetail"}>
                    <table>
                        <thead>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {(!_.isEmpty(_.get(data,'subject')) &&
                                        <div className="_row">
                                            <strong>주제분류</strong>
                                            <div className={'sub_row'}>
                                                <div>{data.subject}</div>
                                                <div>{data.classification}</div>
                                            </div>
                                        </div>
                                    )}
                                </td>
                                <td>
                                    {(!_.isEmpty(_.get(data,'instructor1')) &&
                                        <div className="_row top_row">
                                            <strong>교수자</strong>
                                            <div className={'sub_row'}>
                                                <div>{data.instructor1}</div>
                                                <div>{data.instructor2}</div>
                                                <div>{data.instructor3}</div>
                                            </div>
                                        </div>
                                    )}
                                </td>
                            </tr>
                            <br/>
                        <tr>
                            <td>
                                {(!_.isEmpty(_.get(data,'dev_org')) &&
                                    <div className="_row">
                                        <strong>개발기관</strong> <div className={'sub_row'}>{data.dev_org}</div>
                                    </div>
                                )}
                            </td>
                            <td>
                                {(!_.isEmpty(_.get(data,'chapter')) &&
                                <div className="_row">
                                    <strong>차시</strong> <div className={'sub_row'}>{data.chapter}</div>
                                </div>
                                )}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    {/*{(!_.isEmpty(_.get(data,'apply_startdate')) && !_.isEmpty(_.get(data,'apply_enddate'))) ?*/}
                    {/*    <div className="_row">*/}
                    {/*        <strong>신청 기간</strong> {moment(data.apply_startdate).format(`YYYY-MM-DD`)} ~ {moment(data.apply_enddate).format(`YYYY-MM-DD`)}*/}
                    {/*    </div>*/}
                    {/*:*/}
                    {/*    <></>*/}
                    {/*}*/}

                    {/*{(!_.isEmpty(_.get(data,'learning_startdate')) && !_.isEmpty(_.get(data,'learning_enddate'))) ?*/}
                    {/*    // <div className="_row">*/}
                    {/*    //     <strong>교육 기간</strong> {moment(data.learning_startdate).format(`YYYY-MM-DD`)} ~ {moment(data.learning_enddate).format(`YYYY-MM-DD`)}*/}
                    {/*    // </div>*/}
                    {/*    :*/}
                    {/*    <></>*/}
                    {/*}*/}


                    {/*{(!_.isEmpty(_.get(data,'program_address'))) ?*/}
                    {/*    <div className="_row">*/}
                    {/*        <strong>교육 장소</strong> {data.program_address}*/}
                    {/*    </div>*/}
                    {/*    :*/}
                    {/*    <div className="_row">*/}
                    {/*        <strong>교육 장소</strong> 온라인*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {/*<div className="_row">*/}
                    {/*    <strong>신청/정원</strong> { (data.apply_maxnumber && data.apply_maxnumber>0) ? `${applyCount} / ${data.apply_maxnumber}+ 명` : '무제한' }*/}
                    {/*    /!*{data.excessapplypermit &&'('+applyOverCount+' / '+((data.reservationwaiting && data.reservationwaiting>0)?data.reservationwaiting:'무제한')+')'}*!/*/}
                    {/*</div>*/}

                        {!isLoggedIn &&  <div className={"_row error text_box orange"}>'로그인 후 수강 및 학습진행이 가능합니다'</div>}
                        {user && (user.user_group && data.user_group) &&((user.user_group != data.user_group.id) &&
                            <div className={"_row error text_box orange"}> '수강 대상이 아니므로 수강신청을 할 수 없습니다.'</div>
                        )}
                </div>

                <div className="actions">
                    {
                        !_.isEmpty(applis)  && mode == 'm'  &&
                        <a className="class_link" href={`https://eclass-lms.seoyeong.ac.kr/courses/${cw_uuid}/home`} target={'_blank'}>학습하기</a>
                    }
                    {
                        // 신청 서식파일
                        !_.isEmpty(data) && data.attached_file.length &&
                        (<button className="download"
                                 onClick={(e) => {
                                     const url = `${apiUrl}${_.get(data, 'attached_file.0.url', '')}`;
                                     const filename = _.get(data, 'attached_file.0.name', '');
                                     api.download(url, filename)
                                 }}>신청 양식 <br/>다운로드
                        </button>) || null
                    }
                    {
                        /*(
                            <button onClick={()=>{console.log('학습하기')}} className={"enrolled_study"}>학습하기</button>
                        )*/
                    }

                    {(btnState && hiddenProgram) &&
                    <ProgramApplyWrite
                        trigger={<button className="submit">{mode == 'm' && `수강취소하기` || btnTitle}</button>}
                        id={id}
                        mode={mode}
                        modeChange={modeChange}
                        onSuccess={() => setApplySuccess(true)}
                        setModeTitle={setModeTitle}
                        applis={applis}
                        type={type}
                        reload={reload}
                        setReload={setReload}
                        onFailer={() => {
                            setApplySuccess(true)
                            console.log('ProgramApplyWrite Error')
                        }}
                    />
                    ||

                    <button disabled={true} className="submit">{btnTitle}</button>
                    }
                    {applySuccess &&

                    <Popup open={open} closeOnDocumentClick onClose={apply_check} position="right center">
                        <div className="popup_overlay">
                            <div className="popup_content">
                                <div className="program_popup">
                                    <div className="modal_header">
                                        <h5 className="modal_title">
                                            {modeTitle} 완료
                                        </h5>
                                        <button onClick={apply_check} type="button" className="overlay_close_x close">
                                            {/*<button onClick={close} type="button" className="overlay_close_x close">*/}
                                            <span className="element-invisible" aria-hidden="true">X</span>
                                        </button>
                                    </div>

                                    <div className="modal_body">
                                        {user && user.username &&
                                        <div className="complate_title">
                                            {`${user.username}`} 님 <span
                                            className="compalte_modeTitle">{modeTitle}{_.includes(modeTitle,'취소')&& '가' || '이'} </span>완료되었습니다
                                        </div>
                                        }
                                        <div className="modal_sub_title">
                                            {modeTitle} 상세정보
                                        </div>
                                        <div className="information_complate">
                                            {/*접수번호 : {apply}
                                            신청일시 : {applyData}*/}
                                        </div>
                                        <div className="table_responsive">
                                            <table className="sticky_header">
                                                <thead>
                                                <tr>
                                                    <th rowSpan="2">교육 과정명</th>
                                                    <th rowSpan="2">교육일정</th>
                                                    <th rowSpan="2">장소</th>
                                                    <th rowSpan="2">담당자</th>
                                                </tr>
                                                </thead>
                                            </table>

                                            <table className="sticky_table table_modal">
                                                <thead>
                                                <tr>
                                                    <th rowSpan="2">교육 과정명</th>
                                                    <th rowSpan="2">교육일정</th>
                                                    <th rowSpan="2">장소</th>
                                                    {/*<th rowSpan="2">작성자</th>*/}
                                                </tr>
                                                </thead>

                                                <tbody>
                                                <tr>
                                                    <td>{data.title}</td>
                                                    <td>
                                                        {moment(data.learning_startdate).format(`YYYY-MM-DD`)} ~
                                                        {moment(data.learning_enddate).format(`YYYY-MM-DD`)}
                                                    </td>
                                                    <td>{data.program_address}</td>
                                                    {/*<td>작성자</td>*/}
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="course_overlay_description">
                                        <span>수강신청에 대한 확인은 마이페이지에서 가능합니다</span>
                                    </div>
                                    <div className="course_overlay_footer">
                                        {/*<button className="btn btn_info form-submit">인쇄하기</button>*/}
                                        {/*<Link to="/member/mystudyprogram"></Link>*/}
                                        <button onClick={apply_check} type="button" className="overlay_close">확인완료
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Popup>
                    }


                    <Popup
                        trigger={open => (
                            <button className="share">공유</button>
                        )}
                        position="right center"
                        closeOnDocumentClick
                    >
                        <div className="popup_sns active">
                            <div className="popup_title">
                                <p className="title">SNS에 교육과정을 공유해주세요!</p>
                            </div>
                            <div className="popup_icon">
                                <a target="_blank" href={`http://www.facebook.com/sharer/sharer.php?u=${newurl}`}>
                                    <img src="/assets/images/icon_snsshare-facebook.png" alt="Facebook"/>
                                </a>
                                <a target="_blank" href={`https://twitter.com/intent/tweet?text=${newurl}`}>
                                    <img src="/assets/images/icon_snsshare-twitter.png" alt="Twitter"/>
                                </a>
                                {/*<a target="_blank" href="https://accounts.google.com/">*/}
                                {/*  <img src="/assets/images/icon_snsshare-googleplus.png" alt="Google+"/>*/}
                                {/*</a>*/}
                            </div>

                            <div className="link_copy">
                                <input type="text" value={newurl} readOnly/>
                                <button type="button" value="Copy URL" onClick={copy}>복사하기</button>
                            </div>
                        </div>
                    </Popup>
                    {/*<button className="share">공유</button>*/}

                </div>
            </div>
        </div>
    );
};

export default ProgramDetailTop;
