import React, {useEffect, useState} from 'react';
import Popup from "reactjs-popup";

const MyContentPopup = ({setEventClick = ()=>{},appiedClass='',setAppiedClass =()=>{},programName='',videodetailList='',user=''}) => {

    const [videoitemNum,setVideoItemNum] = useState([]);
    const [totalList,setTotalList] = useState([]);
    let listArray = new Array();
    let totalListArray = new Array();

    const tableStyle = {
        border:"none"
    }

return (<Popup open closeOnDocumentClick position="right center">
    {/*<Popup open={open} closeOnDocumentClick  onClose={setEventPop} position="right center">*/}
    <div className="popup_overlay">
        <div className="popup_content">
            <div className="program_popup">
                <div className="modal_header">
                    <h5 className="modal_title">
                        {programName}
                    </h5>
                    <button onClick={()=> {setEventClick(false)}} type="button" className="overlay_close_x close">
                        {/*<button onClick={close} type="button" className="overlay_close_x close">*/}
                        <span className="element-invisible" aria-hidden="true">X</span>
                    </button>
                </div>

                <div className="modal_body">
                    <div className="information_complate">
                        {/*접수번호 : {apply}
                                                     신청일시 : {applyData}*/}
                    </div>
                    <div className="table_responsive">
                        <div className="progress_table_box_mycontent">
                            <table className="Myclass_progressrate_table">
                                <thead>
                                <tr>
                                    <th colSpan={5}>  {user.name}님 차시별 진행도</th>
                                </tr>
                                <tr>
                                    <th className="th_title">주차</th>
                                    <th className="th_title">제목</th>
                                    {/*<th className="th_title">타입</th>*/}
                                    <th className="th_title">영상 시간</th>
                                    <th className="th_title">수강 시간</th>
                                    <th className="th_title">수강 진도율</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    videodetailList.map((item,idx) =>{
                                        return (

                                            <tr>
                                                <td>
                                                    {idx+1}차시
                                                </td>
                                                <td colSpan={4} style={tableStyle}>
                                                {
                                                    item.map((item2,idx2)=>{
                                                        let attanding_learn_time=_.get(item2,'attanding_learn_time')
                                                        let learn_time=_.get(item2,'learn_time')
                                                        let late_learn_time=_.get(item2,'late_learn_time')
                                                        let title=_.get(item2,'title')
                                                        let result =0
                                                        if(learn_time==undefined) learn_time =0
                                                        if(attanding_learn_time==undefined) attanding_learn_time =0
                                                        if(late_learn_time==undefined) late_learn_time =0
                                                        if(title==undefined) title =''
                                                        if(attanding_learn_time !=0 || late_learn_time!= 0 ) {
                                                            result = parseInt((late_learn_time + attanding_learn_time) / learn_time * 100)
                                                        }

                                                        if(attanding_learn_time == 'undefined'){
                                                           attanding_learn_time = 0;
                                                        }

                                                        return(
                                                            <table className="intable">
                                                                <tr>
                                                                {/*<td className="progressrate_top">*/}
                                                                {/*        <img src="/assets/images/play_icon_ta.png" alt="강의아이콘"/>*/}
                                                                {/*</td>*/}
                                                                <td>
                                                                    <h1>
                                                                        {title}
                                                                    </h1>
                                                                </td>
                                                                <td>
                                                                    <p className="play_time">
                                                                        { parseInt(_.get(item2,'video_length')/3600) !== 0 ? parseInt(_.get(item2,'video_length')/3600)+"시" : "" } {parseInt(_.get(item2,'video_length')%3600/60)}분 {parseInt(_.get(item2,'video_length')%60)}초
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    <p className="play_time">
                                                                        { parseInt(attanding_learn_time/3600) !== 0 ? parseInt(attanding_learn_time/3600)+"시" : "" } {parseInt(attanding_learn_time%3600/60)}분 {parseInt(attanding_learn_time%60)}초
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    {result}%
                                                                </td>
                                                                </tr>
                                                            </table>
                                                        )
                                                    })
                                                }</td>

                                            </tr>
                                        )
                                    })
                                }


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="course_overlay_footer course_pop_bottom">
                    {/*<button className="btn btn_info form-submit">인쇄하기</button>*/}
                    {/*<Link to="/member/mystudyprogram"></Link>*/}
                    <button onClick={()=> {setEventClick (false);}} type="button" className="overlay_close">확인완료
                    </button>
                </div>

            </div>
        </div>
    </div>
</Popup>    );
};

export default MyContentPopup;