import React from 'react';

import BreadCrumb from '../../components/BreadCrumb';
import SubLeftMenu from '../../components/SubLeftMenu';
import route from '../../route';
import { BsArrowRightShort } from 'react-icons/all';
import {Helmet} from "react-helmet";

const Introduction = () => {
  return (
    <div className="introduction content">
      <Helmet title="인사말"/>
      <BreadCrumb list={route.depth1} />
      <div className="sub">
        <div className="wrap">
          <SubLeftMenu
            title={route.depth1.title}
            lnbList={route.depth1.list}
          />
          <div className="sub__content introduction_content">
            <h1 className="sub__title introduction_title">
              <span className="">인사말</span>
            </h1>
            {/*<p className="introduction__title">*/}
            {/*  빛나는 작은아이디어가 <span>글로벌 콘텐츠</span>가 되는 그날까지*/}
            {/*</p>*/}

            <div className="introduction__desc">
              {/*<img src="/assets/images/cks.png" alt=""/>*/}
              {/*<p>*/}
              {/*  시작의 목소리에 귀 기울이고, 성장하는 전남형 인재육성 및 지역의 문제를 해결하는*/}
              {/*  4차 산업혁명시대 선도 콘텐츠 비즈니스 육성*/}
              {/*</p>*/}
            </div>

            <div className="introduction__info">
              <p>내용 준비중입니다.</p>
              {/*<h3>코로나19로 인하여 대학은 새로운 도전에 임하고 있습니다.</h3>
              <p>
                언택트 시대의 도래와 함께 대학교육은 기존 교육방식에 대한 성찰과 새로운 교육 표준(New Normal in Education) 마련이 필요합니다.<br/>
                특히 교육현장에서 원격수업이 본격화되면서 교수학습방법, 교수자의 역할, 학생평가, 학습환경, 교육거버넌스 등 교육체제 전반에 대한 진단과 총체적
                변화가 요구되고 있습니다. 이러한 시대 상황에 발맞추어 광주전남권역 대학원격교육지원센터는 2020년부터 권역 내 대학의 원격수업 활성화를
                지원하기 위해 다양한 지원을 하고 있습니다.
              </p>
              <br/>
              <p>
                광주전남권역 대학원격교육지원센터는 광주전남권역 대학의 원격교육 협력 생태계 구축을 비전으로 삼아 다양한 사업을 추진하고 있습니다.<br/>
                공동 활용을 위한 학습관리시스템(LMS), 스튜디오 등의 원격수업 인프라 구축 및 활용 확산과 함께 이러닝 콘텐츠 공동 개발 및 활용을 통하여 대학 간
                교육 네트워크 구축을 위해 힘쓰고 있습니다.<br/>
                더불어 권역 내 대학의 원격수업 질 제고를 위해 컨설팅 및 원격수업역량강화 워크숍 등을 기획 및 운영하고 있습니다.
              </p>
              <br/>
              <p>
                광주전남권역 대학원격교육지원센터는 대학 간 교류를 통한 상호발전과 교육격차 해소에 앞장서고자 합니다.<br/> 광주전남권역 대학의 원격교육 혁신을
                실천하기 위해 광주전남권역 대학원격교육지원센터가 책임을 다할 수 있도록 깊은 관심과 협조 부탁드립니다.
              </p>
              <br/>
              <p>
                감사합니다.
              </p>*/}

              {/*<div className="row">*/}
              {/*  <div className="col">*/}
              {/*    <div className="img"><img src="/assets/images/intro-img2.png" alt=""/></div>*/}
              {/*    <p>프로그램 운영</p>*/}
              {/*  </div>*/}
              {/*  <div className="col">*/}
              {/*    <div className="img"><img src="/assets/images/intro-img3.png" alt=""/></div>*/}
              {/*    <p>공간지원</p>*/}
              {/*  </div>*/}
              {/*  <div className="col">*/}
              {/*    <div className="img"><img src="/assets/images/intro-img4.png" alt=""/></div>*/}
              {/*    <p>장비지원</p>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>

            {/*<p className="introduction__title">*/}
            {/*  2020년 <span>전남콘텐츠코리아랩</span> 사업 개요*/}
            {/*</p>*/}
            {/*<div className="introduction__future">*/}
            {/*  <h3>J.CKL</h3>*/}
            {/*  <div className="introduction__graph">*/}
            {/*    <div className="_col">*/}
            {/*      <div className="img"><img src="/assets/images/intro-img5.png" alt=""/></div>*/}
            {/*      <h4>전략장르 육성</h4>*/}
            {/*      <div className="info">*/}
            {/*        <p>웹툰 창작아카데미 운영</p>*/}
            {/*        <p>1인 크리에이터 스킬업</p>*/}
            {/*      </div>*/}
            {/*      <strong>부트캠프형 창작자 양성</strong>*/}
            {/*    </div>*/}
            {/*    <div className="_col">*/}
            {/*      <div className="img"><img src="/assets/images/intro-img6.png" alt=""/></div>*/}
            {/*      <h4>마케팅 지원</h4>*/}
            {/*      <div className="info">*/}
            {/*        <p>국내외 쇼케이스</p>*/}
            {/*        <p>콘텐츠 라이센싱 제작 유통</p>*/}
            {/*      </div>*/}
            {/*      <strong>2차 제작 및 유통 확대</strong>*/}
            {/*    </div>*/}
            {/*    <div className="_col">*/}
            {/*      <div className="img"><img src="/assets/images/intro-img7.png" alt=""/></div>*/}
            {/*      <h4>창작기반 확대</h4>*/}
            {/*      <div className="info">*/}
            {/*        <p>창작자 인큐베이팅</p>*/}
            {/*        <p>예비인력 진로체험</p>*/}
            {/*      </div>*/}
            {/*      <strong>콘텐츠 창작인력 발굴</strong>*/}
            {/*    </div>*/}
            {/*  </div>*/}

            {/*  <div className="introduction__result">*/}
            {/*    관광, 생태문화 특화요소 <span><BsArrowRightShort /></span> 창작활동 지원*/}
            {/*  </div>*/}
            {/*</div>*/}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
