import React from 'react';
import {apiUrl} from "config";

const SlideItem = ({ data }) => {
    const {url,name} = data;
  return (
    <div className="slideitem auditSlide">
      <video controls className="auditVideoWrap" >
          <source src={apiUrl + url} />
      </video>
        <p className="auditSlideTitle">
            {name}
        </p>
    </div>
  );
};

export default SlideItem;
