/** 장비소개 목록 */
import React, {useEffect, useMemo, useState} from 'react';
import {Helmet} from "react-helmet";
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';
import {useTranslation} from 'react-i18next'
import route from 'route';


//components
import BreadCrumb from 'components/BreadCrumb';
import SubLeftMenu from 'components/SubLeftMenu';
import EquipmentInfo from 'components/EquipmentInfo';
import {equipmentlist} from 'data';

const Device = () => {
    return (
        <div className="membership">
            <Helmet title="장비소개"/>
            <BreadCrumb list={route.resource}/>
            <div className="sub">
                <div className="wrap">
                    <SubLeftMenu
                        title={route.resource.title}
                        lnbList={route.resource.list}/>
                    <div className="sub__content">
                        <h1 className="sub__title">
                            <span className="">장비소개</span>
                        </h1>

                        {/* 장비 안내 */}
                        <div className="section">
                            <EquipmentInfo size={8} variables={{sort: "createdAt:DESC"}} hiddenHeader={true}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Device;
