import React, {useEffect, useMemo, useState, useCallback} from 'react';



const MultipleSurvey = ({register,courseSurveyData1,workshopSurveyData1,errors}) => {

    // 강좌만족도인지 워크숍만족도인지 체크
    const [surveyData, setsurveyData] = useState([]);

    useEffect(() => {
        if (!_.isEmpty(courseSurveyData1)) {
            setsurveyData(courseSurveyData1);
        }
        else if (!_.isEmpty(workshopSurveyData1)) {
            setsurveyData(workshopSurveyData1);
        }
    }, [surveyData]);


    return (
        <div>
            {
                surveyData.map((item,index)=>
                    <div key={item.id}>
                        <input type={'hidden'} name={`survey[${_.get(item,'no') - 1}].name`} ref={register({required:'필수값입니다.'})} value={_.get(item,'title')}/>
                        {(item.type == 'multiple' &&
                            <div className={'survey_question'}>
                                <h1>{_.get(item,'no')}. {item.title}</h1>
                                <div>
                                    <label>
                                        <input type={'radio'} value={5} className={'survey_q1'} name={`survey[${_.get(item,'no') - 1}].answer`}
                                               ref={register({required:'필수값입니다.'})}/>
                                        매우 그렇다
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type={'radio'} value={4} className={'survey_q1'} name={`survey[${_.get(item,'no') - 1}].answer`}
                                               ref={register({required:'필수값입니다.'})}/>
                                        그렇다
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type={'radio'} value={3} className={'survey_q1'} name={`survey[${_.get(item,'no') - 1}].answer`}
                                               ref={register({required:'필수값입니다.'})}/>
                                        보통이다
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type={'radio'} value={2} className={'survey_q1'} name={`survey[${_.get(item,'no') - 1}].answer`}
                                               ref={register({required:'필수값입니다.'})}/>
                                        대체로 그렇지 않다
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type={'radio'} value={1} className={'survey_q1'} name={`survey[${_.get(item,'no') - 1}].answer`}
                                               ref={register({required:'필수값입니다.'})}/>
                                        전혀 그렇지 않다
                                    </label>
                                </div>
                            </div>

                        ) ||
                        (item.type == 'multiple2' &&
                            <div className={'survey_question'}>
                                <h1>{_.get(item,'no')}. {item.title}</h1>
                                <div>
                                    <label>
                                        <input type={'radio'} className={'survey_q1'} name={`survey[${_.get(item,'no') - 1}].answer`} ref={register({required:'필수값입니다.'})}
                                               value={'취,창업준비'}/>
                                        취,창업준비
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type={'radio'} className={'survey_q1'} name={`survey[${_.get(item,'no') - 1}].answer`} ref={register({required:'필수값입니다.'})}
                                               value={'직무능력향상'}/>
                                        직무능력향상
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type={'radio'} className={'survey_q1'} name={`survey[${_.get(item,'no') - 1}].answer`} ref={register({required:'필수값입니다.'})}
                                               value={'대학 학점 취득 및 플립러닝 활용'}/>
                                        대학 학점 취득 및 플립러닝 활용
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type={'radio'} className={'survey_q1'} name={`survey[${_.get(item,'no') - 1}].answer`} ref={register({required:'필수값입니다.'})}
                                               value={'대학 진학 대비 및 전공 탐색'}/>
                                        대학 진학 대비 및 전공 탐색
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type={'radio'} className={'survey_q1'} name={`survey[${_.get(item,'no') - 1}].answer`} ref={register({required:'필수값입니다.'})}
                                               value={'개인적 흥미·관심 및 자기계발'}/>
                                        개인적 흥미·관심 및 자기계발
                                    </label>
                                </div>
                                {_.get(errors,`survey.${_.get(item,'no') - 1}.answer`) && (
                                    <small className="text-danger">{_.get(errors,`survey.${_.get(item,'no') - 1}.answer.message`)}</small>
                                )}
                            </div>
                        )}
                    </div>
                )
            }

        </div>


    );
};

export default MultipleSurvey;