import React, {useEffect, useMemo, useState, useCallback} from 'react';
import * as actions from 'store/actions/content';
import {Helmet} from "react-helmet";
import {useSelector, useDispatch} from 'react-redux';
import * as api from 'lib/content';
import {apiUrl} from 'config';
import route from 'route';
import moment from 'moment/dist/moment';


const CourseCertificate = ({user={},programData={}}) => {
    const {author,studyprogram,createdAt,survey_complete_num} = _.get(programData,'0')

    const [resultData, setResultData] = useState([]);
    const [certificateLoading,setCertificateLoading] = useState(true)
    useEffect(()=>{
        async function getSurveyResult() {
            const {data:study_data,study_loading} = await api.gets({type:'studyprograms', id:_.get(studyprogram, 'id')})
            if(_.isEmpty(study_data)){
                swal({
                    title: ('설문조사를 진행하지 않았습니다.'), text: ('설문조사 미진행'),
                    icon: "warning", timer: 3000, buttons: false,
                }).then(()=>{
                    history.push(`/`);
                })
            }
            else{
                if(!_.isEmpty(study_data)){
                    setResultData(study_data)
                    setCertificateLoading(study_loading)
                }
            }
        }
        if(!_.isEmpty(studyprogram)){
            getSurveyResult()
        }
    },[studyprogram])

    return (
        <div className={'certificate_back'}>
            {/*<img className={'certi_badge'} src={'/assets/images/certificate_badge.png'}/>*/}
            <div className={'certificate_inner'}>
                <div>
                    <div className={'print_one'}>
                        <p className={'print_num'}>발급번호 : {`${moment().format("YYYY")} - ${_.get(studyprogram,'id')} - ${survey_complete_num}`}</p>
                        {/*<p>출력일시 : {moment().format("YYYY.MM.DD")}</p>*/}
                    </div>
                    <h1 className={'print_title'}>이수증</h1>
                </div>

                <div>
                    <div className={'print_two'}>
                        <p>
                            {/*<div>성 명</div> : {_.get(author,'nickname')}<br/>*/}
                            {/*<div>소 속</div> : {_.get(author,'organization')}<br/>*/}
                            <div className="print_blocks">
                                <div className="leftBox">성 명</div><div className="rightBox">{_.get(author,'nickname')}</div>
                            </div>
                            <div className="print_blocks">
                                <div className="leftBox">소 속 명</div><div className="rightBox">{_.get(author,'organization')}</div>
                            </div>
                            <div className="print_blocks">
                                <div className="leftBox">강 좌 명</div><div className="rightBox">{_.get(studyprogram,'title')}</div>
                            </div>
                            <div className="print_blocks">
                                <div className="leftBox">기 간</div>
                                <div className="rightBox">
                                    {moment(_.get(studyprogram,'learning_startdate')).format('YYYY - MM - DD')} ~ {moment(_.get(studyprogram,'learning_enddate')).format('YYYY - MM - DD')}
                                </div>
                            </div>
                            <div className="print_blocks">
                                <div className="leftBox">총 차 시</div><div className="rightBox">{_.get(studyprogram, 'chapter')}</div>
                            </div>
                            {/*<div>기 간</div> : {moment(_.get(studyprogram,'learning_startdate')).format('YYYY - MM - DD')} ~ {moment(_.get(studyprogram,'learning_enddate')).format('YYYY - MM - DD')}<br/>*/}
                            {/*<div>총 차 시</div> : {_.get(studyprogram, 'chapter')}<br/>*/}
                   {/* <div>학 습 시 간</div> : ~~시간*/}</p>
                    </div>
                    <div className={'print_three'}>
                        위 과정을 성실히 이수하여<br/>
                        이 증서를 수여합니다.
                    </div>
                    <div className={'print_day'}>
                        <p>{moment().format("YYYY년　MM월　DD일")}</p>
                    </div>
                </div>

                <div className={'print_footer'}>
                    <h1>광주전남권역&nbsp;대학원격교육지원센터장</h1>
                    <img src={'/assets/images/certi_stamp.png'}/>
                </div>
            </div>
        </div>
    );
};

export default CourseCertificate;
