import React from 'react';
import { Route, Switch } from 'react-router-dom';
import University from './University';
import College from './College';

const Varsity = ({ match }) => {
    return (
        <Switch>
            {/*<Route path={`${match.path}/university`} render={() => <div className="content" style={{textAlign:'center',fontSize:'2.5rem',fontWeight:'400',margin:'15.26rem'}}>페이지 준비중입니다.</div>} />*/}
            {/*<Route path={`${match.path}/college`} render={() => <div className="content" style={{textAlign:'center',fontSize:'2.5rem',fontWeight:'400',margin:'15.26rem'}}>페이지 준비중입니다.</div>} />*/}
            <Route path={`${match.path}/university`} render={() => <University/>}/>
            <Route path={`${match.path}/college`} render={() => <College/>}/>
        </Switch>
    );
};

export default Varsity;
