/** 공지사항 상세 */
import React, {useEffect, useMemo, useState, useCallback} from 'react';
import {Helmet} from "react-helmet";
import {useParams, useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';
import Quill from "components/Editor/Quill";
import * as api from 'lib/content';
import {apiUrl} from 'config';
import BreadCrumb from 'components/BreadCrumb';
import route from 'route';
import SubLeftMenu from 'components/SubLeftMenu';
import {BoardDetail} from 'components/Board';
import BoardWrite from 'components/Write/WorkshopWrite';
import qs from 'qs';
import {useForm, Controller} from "react-hook-form/dist/index.ie11";

//새로추가한 임포트
import Popup from "reactjs-popup";
import {ProgramApplyWrite} from 'components/Write';
import {ApplyComplete} from 'components/Write';
import WorkshopDeleteEdit from 'components/Write/WorkWrite/WorkshopDeleteEdit';
import {Link} from 'react-router-dom';
import moment from 'moment/dist/moment';
import BoardCustomColum from 'components/Board/BoardCustomColum';
import {BoardHeader, BoardList} from 'components/Board';

import ReactMarkdown from "react-markdown";
//
import {useLocation} from 'react-router-dom';

//graphql 관련
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import * as cQuery from "queries/content";
import * as aQuery from "queries/program";

// customapi 관련
import axios from 'axios';
import usePromise from 'utils/usePromise'

import {SurveyResultPopup} from 'components/Popup/SurveyResultPopup';

const TYPE = `workshopprograms`;
const TYPE2 = `workshopprogram-applies`;

const WorkShopDetail = ({
                            type, setReset_data = () => {
    }
                        }) => {
    const defaultValues = {
    };

    //// view 호출 선언문
    const {view, errorMsg = ''} = useSelector(({content}) => ({...content}));
    const {user, isLoggedIn} = useSelector(({auth: {user, isLoggedIn}}) => ({
        user, isLoggedIn
    }));

    //// data에 초기값 view 설정
    const [data, setData] = useState('');
    const history = useHistory();
    const [comp, setComp] = useState('program')
    const dispatch = useDispatch();
    const [category, setCategory] = useState('yeokryangganghwawokeusyop')
    const [completionUser, setCompletionUser] = useState(0)
    const {id} = useParams();
    const [listdata ,setListData] =useState([]);


    // apply 신청자 수 추출
    const [filter, setFilter] = useState({});
    const {search} = useLocation();
    const {page: _page = 0, perPage: _perPage = 10} = qs.parse(search, {ignoreQueryPrefix: true});
    const [perPage, setPerPage] = useState(_perPage && +_perPage || 10)
    const [page, setPage] = useState(_page && +_page || 0)
    const {list = [], totalCount = 0} = useSelector(({content}) => ({...content}));
    const [params, setParams] = useState({_limit: perPage, _sort: `createdAt:DESC`, _start: page * perPage})
    useEffect(() => {
        dispatch(actions.listRequest({
            type: TYPE2,
            ...params,
            ...filter
        }))
    }, [params, filter]);


    //수강신청하기
    const newurl = window.location.href
    const [btnTitle, setBtnTitle] = useState('신청하기');
    const [btnState, setBtnState] = useState(false);
    const [mode, setMode] = useState(null);  // '' - 생성 / m - 수정
    const [applis, setApplis] = useState(null);
    const [modeTitle, setModeTitle] = useState('');
    const [applyCount, setApplyCount] = useState(0);
    const [applyOverCount, setApplyOverCount] = useState(0);
    const [program_type, setProgram_type] = useState('워크숍');

    //프로그램 신청 완료 관련
    const [applySuccess, setApplySuccess] = useState(false);
    const [open, setOpen] = useState(true);    //open 상태인가?
    const [reload, setReload] = useState(false);    //open 상태인가?
    const [loading, setLoading] = useState(true);   //데이터 로딩중인가?

    // workshopDetail 변경 관련
    const [parmas, setParmas] = useState({});
    /* "_where[0][apply_user.email]=user.id&_where[1][appied_program.title]=data.id": _.get(user, 'id')*/
    const [imgView, setImgView] = useState(true);

    const [applied_data, setApplied_data] = useState('');
    const [applyComplete, setApplyComplete] = useState('');
    const [statusMode, setStatusMode] = useState('off');
    const [statusChange, setStatusChange] = useState('can');
    const [defaultId, setDefaultId] = useState('');
    const [link,setLink] = useState('');
    const [linkstatus,setLinkStatus] =useState(false)
    //설문조사 결과
    const [surverResult, setSurverResult] = useState({});
    const [surverDownlink, setSurverDownlink] = useState({});
    const [surverLinkStatus, setSurverLinkStatus] = useState(false);

    // 수강생 데이터 페이징 기능
    const [listStart ,setListStart] =useState(0);

    useEffect(() => {
        dispatch(actions.viewRequest({type: TYPE, id}))
    }, [id, reload])


    //view값 변경할때마다 새로운값 지정
    useEffect(() => {
        setData(view)
    }, [view])


    //컨텐츠 데이터 로딩 실패시
    useEffect(() => {
        if (!loading && _.isEmpty(view) && errorMsg) {
            history.push('/resource/competencyworkshop');
        }
    }, [view, loading, errorMsg]);


    const updateParams = (data) => {
        setParams({...params, ...data});
    };

    const [getSurvey_result, {loading: survey_result_loading}] = useLazyQuery(cQuery.SURVEY_RESULT_QUERY, {
        fetchPolicy: 'no-cache',
        onCompleted: ({surveyResults = []}) => {
            if(!_.isEmpty(surveyResults)){
                setSurverResult(_.get(surveyResults,'0'))
                console.log(_.get(surveyResults,'0'))
            }
        }
    });

    // 사용자 지원 상태 목록 관련 ///=
    const [getApply_study, {loading: apply_loading_study}] = useLazyQuery(aQuery.WORKSHOP_APPLIES_QUERY, {
        fetchPolicy: 'no-cache',
        onCompleted: ({workshopprogramApplies = []}) => {
            if (workshopprogramApplies.length > 0) {
                setApplis(workshopprogramApplies[0])
                const {
                    appied_program = {},
                    apply_status = {},
                    apply_user = [],
                    body = '',
                    createdAt = '',
                    id = '',
                    apply_complete=''
                } = workshopprogramApplies[0];
                setApplyComplete(apply_complete)
                setDefaultId(id)
                const {apply_status_category_title = ''} = apply_status;
                if (apply_status_category_title != '') {
                    if (apply_status_category_title == 'cancellation_completed' || apply_status_category_title == 'apply_cancael_completed') {
                        setMode('d')
                    } else {
                        setMode('m')
                    }
                } else {
                    setMode('')
                }
                setLoading(apply_loading_study)
            } else {
                setApplis([])
                setMode('')
                setLoading(apply_loading_study)
            }
        }
    });


    async function fetchMyAPI(type, id) {
        if (id) {
            try {
                const {status: apply_status, data: apply_data} = await api.gets({
                    type: type,
                    appied_program: id,
                    apply_status: '5ff6b769bf55b944295d63e8'
                })
                if (apply_status !== 200) {
                    throw new Error('에러 발생');
                } else {
                    setApplyCount(apply_data.length);
                    setApplied_data(apply_data);
                }
            } catch (e) {
                console.log('데이터 로딩 실패', e.message);
            } finally {
            }

        }
    }

    useEffect(() => {
        async function count_users(type, id) {
            if (id) {
                try {
                    const {status: apply_status, data: apply_data} = await api.count({
                        type: type,
                        appied_program: id,
                        apply_status: '5ff6b769bf55b944295d63e8'
                    })
                    if (apply_status !== 200) {
                        throw new Error('에러 발생');
                    } else {
                        console.log(apply_data, '수강인원')
                        setCompletionUser(apply_data)
                    }
                } catch (e) {
                    console.log('데이터 로딩 실패', e.message);
                } finally {
                }

            }
        }

        if (!_.isEmpty(data)) {
            if (!_.isEmpty(user)) {
                getApply_study({variables: {"where": {"apply_user": user.id, "appied_program": data.id}}})
                getSurvey_result({variables: {"where": {"author": user.id, "workshopprogram": data.id}}})
                fetchMyAPI(TYPE2, data.id);
                count_users(TYPE2, data.id);
                // fetchMyAPI2(TYPE, data.id);
            } else {
                setLoading(false);
            }
        }

    }, [user, data, reload]);


    ////apply_status_category_title 모듈
    useEffect(() => {
        if (!_.isEmpty(data) && !_.isEmpty(user)) {
            if (!_.isEmpty(applied_data)) {
                setStatusMode('on')
                if (statusMode == 'on') {
                    if (_.get(data, 'id') == _.get(applied_data, '0.appied_program.id')) {
                        if (_.get(applied_data, '0.apply_status.id') == '5ff6b769bf55b944295d63e8') {
                            //신청이 됐을 때
                            setStatusChange('com');
                        } else if (_.get(applied_data, '0.apply_status.id') == '5ff6b7a6bf55b944295d63ed') {
                            //취소완료가 됐을 때
                            setStatusChange('can');
                        } else if (_.isEmpty(_.get(applied_data, '0.apply_status.id'))) {
                            setStatusChange('can');
                        }
                    }
                }
            } else {
                setStatusChange('can');
            }
        }

    }, [user, data, applied_data, statusChange, reload, statusMode]);


    useEffect(() => {

        if (!_.isEmpty(data) && !_.isEmpty(user)) {
            if (mode != null) {
                if (user && (mode == 'd' || mode == '')) {
                    if (user.user_group && data.user_group) {
                        setBtnTitle('수강신청하기')
                        if (user.user_group == data.user_group.id) {
                            if (applyCount < data.apply_maxnumber || !data.apply_maxnumber) {
                                setBtnState(true)
                            } else if (data.apply_maxnumber <= applyCount) {
                                if (data.excessapplypermit) {
                                    if (data.reservationwaiting > applyOverCount || !data.reservationwaiting) {
                                        setBtnTitle('수강 예약(정원초과)')
                                        setBtnState(true)
                                    } else if (data.reservationwaiting <= applyOverCount) {
                                        setBtnTitle('수강 종료(예약초과)')
                                        setBtnState(false)
                                    }
                                } else {
                                    setBtnTitle('수강 종료(정원초과)')
                                    setBtnState(false)
                                }
                            }
                        } else {
                            setBtnState(false)
                        }
                    } else {
                        setBtnTitle('수강신청하기')
                        if (applyCount < data.apply_maxnumber || !data.apply_maxnumber) {
                            setBtnState(true)
                        } else if (data.apply_maxnumber <= applyCount) {
                            if (data.excessapplypermit) {
                                if (data.reservationwaiting > applyOverCount || !data.reservationwaiting) {
                                    setBtnTitle('수강 예약(정원초과)')
                                    setBtnState(true)
                                } else if (data.reservationwaiting <= applyOverCount) {
                                    setBtnTitle('수강 종료(예약초과)')
                                    setBtnState(false)
                                }
                            } else {
                                setBtnTitle('수강 종료(정원초과)')
                                setBtnState(false)
                            }
                        }
                    }
                } else if (user && mode == 'm') {
                    setBtnState(true)
                }
            }
        }

    }, [mode, user, data, applyCount, applyOverCount]);


    const apply_check = () => {
        setApplySuccess(false)
        setReload(!reload)
    }
    const modeChange = () => {
    }

    // 워크숍 기간 끝나면 설문조사 버튼
    const today = moment().format(`YYYY-MM-DD HH:mm:ss`);
    const learning_startdate = moment(_.get(applied_data[0], 'appied_program.learning_startdate')).format(`YYYY-MM-DD HH:mm:ss`);
    const learning_enddate = moment(_.get(applied_data[0], 'appied_program.learning_enddate')).format(`YYYY-MM-DD HH:mm:ss`);
    const apply_startdate = moment(_.get(view, 'apply_startdate')).format(`YYYY-MM-DD HH:mm:ss`);
    const apply_enddate = moment(_.get(view, 'apply_enddate')).format(`YYYY-MM-DD HH:mm:ss`);
    const [state_now, setState_now] = useState('');
    //워크샵 survey 페이지로 이동하는 게시글id값
    const linkId = _.get(data, 'id');
    //워크숍 접수중일때만 신청가능
    useEffect(() => {
        if (today > apply_startdate && today < apply_enddate) {
            setState_now('접수중');
        } else if (today == apply_startdate || today == apply_enddate) {
            setState_now('접수중');
        } else {
            setState_now('접수중아님');
        }
    }, [user, apply_startdate, apply_enddate, reload]);


    // 상세일정 및 신청하기 - 날짜 포맷팅
    const learning_startdate_bt = moment(_.get(data, 'learning_startdate')).format(`YYYY-MM-DD(ddd) HH:mm`);
    const learning_enddate_bt = moment(_.get(data, 'learning_enddate')).format(`YYYY-MM-DD(ddd) HH:mm`);
    const apply_startdate_bt = moment(_.get(data, 'apply_startdate')).format(`YYYY-MM-DD(ddd) HH:mm`);
    const apply_enddate_bt = moment(_.get(data, 'apply_enddate')).format(`YYYY-MM-DD(ddd) HH:mm`);

    // CSV 다운로드 기능
    const listopen = async () =>{
        const {data:datas,status }=await axios.get(`/custom-api/export/csvdown?id=${id}&start=${listStart}`);
        setListData(datas)
    }

    // 페이징 기능

    useEffect(()=>{

        if(listStart<0){
            setListStart(0)
            listopen()
        }else if(listStart>parseInt(completionUser/50)){
            setListStart(parseInt(completionUser/50))
            listopen()
        }else{
            listopen()
        }
    },[listStart])


    useEffect(()=>{
        if(!_.isEmpty(listdata)){
            listopen()
        }
    },[reload])
    const downhandle = async () =>{
        const {data:downlink,status }=await axios.get(`/custom-api/export/csvdown/down?id=${id}`);
        setLink(`https://gj.udec.kr/api/${downlink}`)
        setLinkStatus(true)
    }

    const surveydownhandle = async () =>{
        const {data:surveydownlink,status } = await axios.get(`/custom-api/export/csvdown/surveydown?id=${id}`);
        console.log(surveydownlink,3333)
        setSurverDownlink(`https://gj.udec.kr/api/${surveydownlink}`)
        console.log(`https://gj.udec.kr/api/${surveydownlink}`)
        setSurverLinkStatus(true)
    }


    const { register, handleSubmit, watch, errors, setValue,  clearErrors ,control} = useForm({defaultValues});


    const test = () => async (formData) => {
        const {user} = formData
        console.log(user)
        try{
            const parmas={apply_complete:'complete'}
            let status = [];
            for(var i = 0; i < user.length; i++){
                if(user[i] != false){
                    const {data:updateData,status:updateStatus} = await api.update({type:TYPE2,id:user[i],...parmas});
                    status.push(updateStatus)
                }
            }
            if(status.indexOf(400) != -1 || status.indexOf(403) != -1 || status.indexOf(500) != -1 || status.indexOf(404) != -1){
                throw new Error()
            }
            else{
                swal({
                    title: `이수 처리 완료`, text: '선택한 수강생의 이수 처리가 완료 됐습니다.',
                    icon: "success", timer: 10000, buttons: false,
                });
            }
        }
        catch (e){
            swal({
                title: `이수여부 변경 실패`, text: e.message,
                icon: "warning", timer: 3000, buttons: false,
            });
        }
        finally {
            setReload(!reload)
        }
    }

    return (

        <div className="onair content introduction">
            <Helmet title="역량강화 워크숍"/>
            <BreadCrumb list={route.depth4}/>
            <div className="sub">
                <div className="wrap">
                    <SubLeftMenu
                        title={route.depth4.title}
                        lnbList={route.depth4.list}/>
                    <div className="workshop_detail_wrap">
                        <div className="sub__content introduction_content page-resource-competencyworkshop">
                            {/*<h1 className="sub__title mb_0 introduction_title">*/}
                            {/*    <span className="">역령강화 워크숍</span>*/}
                            {/*</h1>*/}
                            {/*<BoardDetail type={TYPE} data={view} id={id} setReload={setReload} reload={reload}/>*/}

                            {/*<h1 className="mb_0">*/}
                            {/*    <span className="">{_.get(view,'title')}</span>*/}
                            {/*</h1>*/}
                            <h2>{data.title}</h2>
                            <div className="boardDetail consulting_detail">
                                <div>
                                    <h2 className="boardDetail__title consulting_title">신청대상
                                    </h2>
                                    <div className={'consulting_sub'}>
                                        <p>{data.body_apply}</p>
                                    </div>
                                    <h2 className="boardDetail__title consulting_title">신청형태
                                    </h2>
                                    <div className={'consulting_sub'}>
                                        <p>{data.apply_unit}</p>
                                    </div>
                                </div>

                                <div>
                                    <h2 className="boardDetail__title consulting_title">장소
                                    </h2>
                                    <div className={'consulting_sub'}>
                                        <p>{data.program_address}</p>

                                    </div>
                                    <h2 className="boardDetail__title consulting_title">문의
                                    </h2>
                                    <div className={'consulting_sub'}>
                                        <p>{data.inquiry}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="workshop_detail_info">
                                {/*///////////////////////변경///////////////////////////*/}
                                <div className="programDetail__body">
                                    {/*<ReactMarkdown className="_row"*/}
                                    {/*               transformImageUri={(uri) => uri.match(/^\/uploads/) && `${apiUrl}${uri}` || uri}*/}
                                    {/*               escapeHtml={false}*/}
                                    {/*               source={data.body}/>*/}
                                    {_.get(data, 'body_config', '') &&
                                    <div className={'_row'}>
                                        <h3>개요</h3>
                                        <button className={imgView ? 'img_btn_gray' : 'img_btn_green'}
                                                onClick={() => setImgView(!imgView)}> 개요 {imgView ? '' : ''}
                                            <img className={'toggle_arrow'} src={'/assets/images/workshop_arrow.png'}/>
                                        </button>

                                        <ReactMarkdown className={imgView ? 'open_img' : 'close_img'}
                                                       transformImageUri={(uri) => uri.match(/^\/uploads/) && `${apiUrl}${uri}` || uri}
                                                       escapeHtml={false}
                                                       source={data.body_config}/>
                                    </div>
                                    }

                                    {/*<ReactMarkdown className="_row"
                                                   transformImageUri={(uri) => uri.match(/^\/uploads/) && `${apiUrl}${uri}` || uri}
                                                   escapeHtml={false}
                                                   source={data.body_apply}/>*/}

                                    <div className={'_row'}>
                                        <ReactMarkdown
                                            transformImageUri={(uri) => uri.match(/^\/uploads/) && `${apiUrl}${uri}` || uri}
                                            escapeHtml={false}
                                            source={data.body_detail}/>


                                        <div className={'detail__body'}>
                                            <div className={'detail_one'}>
                                                <div className={'detail_title'}>프로그램 일정</div>
                                                <div className={'detail_info'}>
                                                    <p>
                                                        <ReactMarkdown
                                                            transformImageUri={(uri) => uri.match(/^\/uploads/) && `${apiUrl}${uri}` || uri}
                                                            escapeHtml={false}
                                                            source={data.title}/>
                                                    </p>

                                                    <span>
                                                            {[learning_startdate_bt, ' ~ ', learning_enddate_bt]}
                                                        {/*<ReactMarkdown
                                                                 transformImageUri={(uri) => uri.match(/^\/uploads/) && `${apiUrl}${uri}` || uri}
                                                                 escapeHtml={false}
                                                                 source={data.learning_startdate}/>*/}
                                                        </span>
                                                </div>
                                            </div>
                                            <div className={'detail_two'}>
                                                <div className={'detail_title'}>신청기간</div>
                                                <div className={'detail_info'}>
                                                    <p>
                                                        {[apply_startdate_bt, ' 부터']} <br/>
                                                        {[apply_enddate_bt, ' 까지']}
                                                        {/*<ReactMarkdown
                                                                transformImageUri={(uri) => uri.match(/^\/uploads/) && `${apiUrl}${uri}` || uri}
                                                                escapeHtml={false}
                                                                source={data.detail_two}/>*/}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={'detail_three'}>
                                                <div className={'detail_title'}>신청현황</div>
                                                <div className={'detail_info'}>
                                                    <p>
                                                        <BoardCustomColum data={data}/>
                                                        {
                                                            /*<ReactMarkdown
                                                            transformImageUri={(uri) => uri.match(/^\/uploads/) && `${apiUrl}${uri}` || uri}
                                                            escapeHtml={false}
                                                            source={completionUser + data.detail_three_p}/>*/}
                                                    </p>

                                                    <ReactMarkdown
                                                        transformImageUri={(uri) => uri.match(/^\/uploads/) && `${apiUrl}${uri}` || uri}
                                                        escapeHtml={false}
                                                        source={data.detail_three_span}/>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!loading &&
                                <div className={'detail_bottom'}>
                                    {
                                        (!isLoggedIn) ?
                                            <div className={"_row error text_box orange"}>로그인 후 프로그램 신청이 가능합니다</div> :
                                            (state_now != '접수중' && (mode == 'd' || _.isEmpty(mode))) ?
                                                <div className={"_row error text_box orange"}>접수 기간이 아닙니다.</div> :
                                                (!_.isEmpty(mode)) ?
                                                    ((mode == 'm') ?
                                                        ((today > learning_enddate) ?
                                                            <>
                                                                <Link className={applyComplete=='complete' ? '' : 'none' }  to={`/member/mycontent/workshopsurvey/${linkId}`}>
                                                                    <button
                                                                        className={'work_survey_btn'}>설문조사하기</button>
                                                                </Link>
                                                                {
                                                                    !_.isEmpty(surverResult) ?
                                                                        <Link to={{pathname:`/member/mycontent/mycertificate/${linkId}`, state:"resource"}} className={`work_certi_btn`}>이수증
                                                                        발급</Link>
                                                                        :
                                                                         <button className={`work_certi_btn disabled`} onClick={()=>{
                                                                             swal({
                                                                                 title: `설문조사 미실시`, text: '설문조사 후에 발급이 가능합니다.',
                                                                                 icon: "warning", timer: 3000, buttons: false,
                                                                             });
                                                                         }}>
                                                                             이수증 발급
                                                                         </button>
                                                                }
                                                                <div className={'survey_info'}>* 이수증을 발급받으려면, 설문조사를 완료하셔야 합니다.</div>
                                                            </>
                                                            :

                                                            <ProgramApplyWrite
                                                                trigger={<button
                                                                    className="workshop_cancel_btn">신청취소</button>}
                                                                id={id}
                                                                defaultId={defaultId}
                                                                applied_data={applied_data}
                                                                mode={'can'}
                                                                modeChange={modeChange}
                                                                onSuccess={() => setApplySuccess(true)}
                                                                setModeTitle={setModeTitle}
                                                                applis={applis}
                                                                type={TYPE2}
                                                                reload={reload}
                                                                setReload={setReload}
                                                                onFailer={() => {
                                                                    setApplySuccess(true)
                                                                    console.log('ProgramApplyWrite Error')
                                                                }}
                                                            />)
                                                        :

                                                        (completionUser >= data.apply_maxnumber) ?
                                                            <div className={"_row error text_box orange"}>정원초과입니다.</div>
                                                            :
                                                            <BoardWrite
                                                                setReload={setReload}
                                                                reload={reload}
                                                                type={TYPE2}
                                                                mode={mode}
                                                                id={defaultId}
                                                                trigger={<button
                                                                    className="application_button">신청하기</button>}
                                                                title={"역량강화 워크숍 신청하기"}
                                                                user={user}
                                                                data={data}
                                                            />)
                                                    :
                                                    (completionUser >= data.apply_maxnumber) ?
                                                    <div className={"_row error text_box orange"}>정원초과입니다.</div>
                                                        :
                                                        <BoardWrite
                                                            setReload={setReload}
                                                            reload={reload}
                                                            type={TYPE2}
                                                            mode={mode}
                                                            id={defaultId}
                                                            trigger={<button
                                                                className="application_button">신청하기</button>}
                                                            title={"역량강화 워크숍 신청하기"}
                                                            user={user}
                                                            data={data}
                                                        />

                                    }


                                    {
                                        _.get(user,'role.name') =="Admin" &&

                                            <>
                                                <button onClick={()=>listopen()} type="button" className="workshop_cancel_btn">수강생 데이터 보기</button>
                                                <form onSubmit={handleSubmit(test())}>
                                                {
                                                    !_.isEmpty(listdata) &&
                                                        <div className="apply_user_data_wrap">
                                                            <div className="table_wrap">
                                                            <table className="apply_user_data_table">
                                                                <thead>
                                                                <tr>
                                                                    <td><input type={'checkbox'} onChange={(e)=>{
                                                                        listdata.map((item,index)=>{
                                                                            setValue(`user[${index}]`,e.target.checked)
                                                                        })
                                                                    }}/></td>
                                                                    <td>이름</td>
                                                                    <td>사번 및 학번</td>
                                                                    <td>신분</td>
                                                                    <td className="email_sec">이메일</td>
                                                                    <td>소속</td>
                                                                    {/*<td>신청상태</td>*/}
                                                                    <td>이수 여부</td>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                        {
                                                            listdata && listdata.map((doc,idx)=>{
                                                                let {apply_complete,_id:id=''} = _.get(doc,'apply') // 이수 완료
                                                                let {email='-',organization='-',name='-',user_num='-',identity='학생'} = _.get(doc,'userdata[0]') // 유저소속, 이메일,유저이름,사번
                                                                // let {title='-'}= _.get(doc,'statusdata[0]')
                                                                if(apply_complete=='complete'){
                                                                    apply_complete ='이수완료'
                                                                }else{
                                                                    apply_complete ='미완료'
                                                                }

                                                                switch (identity) {
                                                                    case "student":
                                                                        identity ='학생'
                                                                        break;
                                                                    case "professor":
                                                                        identity ='교수'
                                                                        break;
                                                                    case "teacher":
                                                                        identity ='강사'
                                                                        break;
                                                                    case "employee":
                                                                        identity ='직원'
                                                                        break;
                                                                    case "other":
                                                                        identity ='기타'
                                                                        break;
                                                                }
                                                                return(
                                                                        <tr key={id}>
                                                                            <td className={'first_td'}><input type={'checkbox'} value={id}  ref={register()} name={`user[${idx}]`}/></td>
                                                                            <td>{name}</td>
                                                                            <td>{user_num}</td>
                                                                            <td>{identity}</td>
                                                                            <td>{email}</td>
                                                                            <td>{organization}</td>
                                                                            {/*<td>{title}</td>*/}
                                                                            <td>{apply_complete}</td>
                                                                        </tr>
                                                                )
                                                            })
                                                        }
                                                                </tbody>
                                                            </table>
                                                            </div>
                                                            <div>
                                                                {/*<button type={'button'} onClick={()=>setListStart(listStart-1)}>아래========|</button>*/}
                                                                {/*<button type={'button'} onClick={()=>setListStart(listStart+1)}>|==========위</button>*/}
                                                            </div>
                                                            <div className={'button_container'}>
                                                                <button className={'change_button'}>이수 완료 변경하기</button>
                                                                <SurveyResultPopup
                                                                    trigger={<button type={'button'} className={'survey_result_button'}>설문조사 결과보기</button>}
                                                                    data={surverResult}
                                                                />
                                                            </div>
                                                       </div>

                                                }

                                                </form>




                                                <div className="workshop_edit_zone">
                                                  <WorkshopDeleteEdit type={TYPE} data={data} setReload={setReload} reload={reload}/>
                                                    <button onClick={()=>downhandle()} className="csv_button">csv다운로드 생성
                                                    </button>
                                                    {linkstatus &&
                                                    <a href={link} download className="csv_button_area">csv파일 다운로드</a>
                                                    }
                                                </div>

                                                {/*<div className="workshop_edit_zone">*/}
                                                {/*    <button onClick={()=>surveydownhandle()} className="csv_button">설문조사 결과 전체 다운로드 생성</button>*/}
                                                {/*    {surverLinkStatus &&*/}
                                                {/*    <a href={surverDownlink} download className="csv_button_area">설문조사 csv 압축파일 다운로드</a>*/}
                                                {/*    }*/}
                                                {/*</div>*/}
                                            </>
                                    }

                                </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default WorkShopDetail;
