/** 로그인 폼 */
import React, {useEffect} from 'react';
import {useForm} from "react-hook-form/dist/index.ie11";

const defaultValues = {
    id: '',
    password: '',
};

const LoginForm = ({onSubmit, handleCancel}) => {
    const {register, handleSubmit, setValue, errors, watch, getValues} = useForm({defaultValues});

    return (<>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
                <label htmlFor="identifier" className="col-form-label font-weight-bold">아이디(이메일)</label>
                <input type="text" id="identifier" name="identifier"
                       className={`form-control ${errors.name && `border-danger` || ``}`}
                       ref={register({required: true})}
                       placeholder="아이디"/>
                {errors.identifier && (errors.identifier.type === 'required' && (
                    <small className="text-danger">아이디는 필수입니다.</small>
                ))}
            </div>
            <div className="form-group">
                <label htmlFor="password" className="col-form-label font-weight-bold">비밀번호</label>
                <input type="password" id="password" name="password"
                       className={`form-control ${errors.name && `border-danger` || ``}`}
                       ref={register({required: true})}
                       placeholder="비밀번호"/>
                {errors.password && (errors.password.type === 'required' && (
                    <small className="text-danger">비밀번호는 필수입니다.</small>
                ))}
            </div>
            <div className="btn_submit_wrap">
                {/*<button className="btn_submit">SSO 로그인</button>*/}
                <button className="btn_submit">로그인</button>
            </div>
        </form>
    </>)
}


export default LoginForm;
