import React, {useEffect, useMemo, useState, useCallback} from 'react';
import BreadCrumb from 'components/BreadCrumb';
import SubLeftMenu from 'components/SubLeftMenu';
import * as actions from 'store/actions/content';
import {useSelector, useDispatch} from 'react-redux';
import {Helmet} from "react-helmet";
import {useTranslation} from 'react-i18next'
import moment from 'moment/dist/moment';
import * as api from 'lib/content';
import {apiUrl} from 'config';
import {useRouteMatch, generatePath, Link} from 'react-router-dom';
import route from 'route';
import {BoardHeader, BoardList} from 'components/Board';
import {CustomPagination} from 'components/Custom'

// const TYPE = 'board-consultings';
const MyConsulting = () => {
    const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}));
    const [TYPE,setType] = useState('board-consultings');
    const {id = '', username = ''} = user;
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation()
    const {list = [], totalCount = 0, loading} = useSelector(({content}) => ({...content}));
    const [perPage, setPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [filter, setFilter] = useState({});
    const [params, setParams] = useState({
        _limit: perPage,
        _start: page * perPage,
        _sort: `createdAt:DESC`,
        author: id,
    })

   /* const learning_startdate = moment(_.get(applied_data[0], 'appied_program.learning_startdate')).format(`YYYY-MM-DD`);
    const learning_enddate = moment(_.get(applied_data[0], 'appied_program.learning_enddate')).format(`YYYY-MM-DD`);*/

    const pageCount = Math.ceil(totalCount / perPage);

    const columns = useMemo(() => [
        /*{
            Header: "구분", accessor: ['appied_program.learning_startdate', 'appied_program.learning_enddate'],
            accessor: 'appied_program.learning_enddate',
            className: `text-center`,
            // minWidth: '150px',
            minWidth: '35px',
            Cell: ({
                       row: {appied_program: {learning_startdate = null, learning_enddate = null} = {}},
                       idx,
                       totalCount
                   }) => {
                return (<div className="small">{idx+1}</div>)
            },
        },*/
        {
            Header: "번호",
            accessor: 'idx',
            className: `text-center`,
            width: 'calc(60% - 190px)',
            // minWidth: 'calc(40% - 110px)',
            padding: '0 15px',
            // whiteSpace: 'nowrap',
            // overflow: 'hidden',
            // textOverflow: 'ellipsis',
            Cell: ({row: {appied_program: {program_category = '-'} = {}}, idx, totalCount}) => {
                return (<Link to={`/program/consulting/${id}`}>{idx+1}</Link>)
            },
        },
        {
            Header: "프로그램명",
            accessor: 'title',
            className: `text-center`,
            width: 'calc(60% - 190px)',
            // minWidth: 'calc(40% - 110px)',
            padding: '0 15px',
            // whiteSpace: 'nowrap',
            // overflow: 'hidden',
            // textOverflow: 'ellipsis',
            Cell: ({row: {title='-'}, idx, totalCount}) => {
                return (<Link to={`/program/consulting/${id}`}>{title}</Link>)
            },
        },
        {
            Header: "장소",
            accessor: 'place',
            className: `text-center`,
            minWidth: 'calc(40% - 190px)',
            Cell: ({row: {place='-'}, idx, totalCount}) => (<>{place}</>)
        },
        {
            Header: "인원정원",
            accessor: ['max_people'],
            className: `text-center`,
            minWidth: 'calc(35% - 190px)',
            Cell: ({row: {max_people=0}, idx, totalCount}) => (<>{[max_people]}</>)
        },
        {
            Header: "상태",
            accessor: 'status',
            className: `text-center`,
            minWidth: '80px',
            // width: 'calc(35% - 190px)',
            Cell: ({row: {status='-'}, idx, totalCount}) => (<>{status}</>)
        },
        {
            Header: "접수 현황",
            accessor: 'now_people',
            className: `text-center`,
            minWidth: '80px',
            // width: 'calc(35% - 190px)',
            Cell: ({row: {now_people=0}, idx, totalCount}) => (<>{now_people}</>)
        }/*,
        {
            Header: "이수시간",
            accessor: 'study_time',
            className: `text-center`,
            minWidth: '80px',
            // width: 'calc(35% - 190px)',
            Cell: ({row: {appied_program: {chapters = '-'} = {}}, idx, totalCount}) => (<>-</>)
        },*/
        // {
        //     Header: "신청서식",
        //     accessor: 'apply_files',
        //     className: `text-center`,
        //     minWidth: '200px',
        //     Cell: ({row: {apply_files = []}, idx, totalCount}) => {
        //         if (!apply_files.length) return (<>-</>)
        //         return (<>{
        //             apply_files.map(({id = '', name = '', url = ''}) => (
        //                 <button key={id}
        //                         onClick={() => api.download(`${apiUrl}${url}`, name)}
        //                         className="small btn btn-dark">{name}</button>
        //             ))}
        //         </>)
        //     }
        // },
    ], [perPage, page])

    const updateParams = useCallback((data) => {
        const {username = '', id = ''} = user
        // setParams({...params, ...data, "apply_user.id": id});
        setParams({...params, ...data, author: id});
    }, [user]);

    const handleChange = ({keyword = "", type = ""}) => {
        const {id = '', username = ''} = user;
        if (!keyword || !type) {
            setFilter({})
            setParams({
                _limit: 10,
                _sort: `createdAt:DESC`,
                _start: 0,
                author: id
            })
            setPage(0)
            return;
        }


        const filters = type.split(',') || [];
        const _filter = {};
        _.forEach(filters, (field, idx) => {
            _filter[`_where[_or][${idx}][${field}]`] = keyword
        })
        setFilter({..._filter})
    }

    const handleReset = () => {
        const {id = '', username = ''} = user;
        setParams({
            _limit: 10,
            _sort: `createdAt:DESC`,
            _start: 0,
            author: id
        })
        setPage(0)
        setPerPage(10)
        setFilter({})
    }

    //page 이동
    useEffect(() => {
        const {_start, _limit} = params;
        if ((_limit !== perPage || _start !== page * perPage)) {
            updateParams({...params, _start: page * perPage, _limit: perPage})
        }
    }, [page, perPage])

    useEffect(() => {
        dispatch(actions.listRequest({
            type: TYPE,
            ...params,
            // ...filter
        }))
        console.log(TYPE,222)
        console.log(params,333)
        console.log(filter,444)
    }, [params, filter])
    useEffect(()=>{
        list && console.log(list,1111)
        TYPE && console.log(TYPE)
    },[list,TYPE])

    return (
        <div className="myProgram">
            <div className="onair content introduction">
                <Helmet title="마이페이지 > 프로그램 신청 확인"/>
                <BreadCrumb list={route.depth6}/>
                <div className="sub">
                    <div className="wrap">
                        <SubLeftMenu
                            title={route.depth6.title}
                            lnbList={route.depth6.list}
                        />
                        <div className="sub__content">
                            <h1 className="sub__title">
                                <span className="">컨설팅 확인</span>
                            </h1>
                            <div className="board">
                                <div>
                                    <button onClick={()=>setType('workshopprogram-applies')}>워크숍</button>
                                    <button onClick={()=>setType('board-consultings')}>컨설팅</button>
                                </div>
                                {/*<BoardHeader totalCount={totalCount}*/}
                                {/*             title={'프로그램 신청 확인'}*/}
                                {/*             onChange={handleChange}*/}
                                {/*             onReset={handleReset}*/}
                                {/*             fields={[*/}
                                {/*                 {title: '과정명', value: 'appied_program.title_contains'},*/}
                                {/*             ]}*/}
                                {/*/>*/}
                                <BoardList
                                    isLink={false}
                                    totalCount={totalCount}
                                    loading={loading}
                                    data={list}
                                    columns={columns}/>
                                <CustomPagination
                                    pageSize={perPage}
                                    pageIndex={page}
                                    pageCount={pageCount}
                                    onPageChange={({selected}) => setPage(selected)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyConsulting;
