
import React, {useEffect, useState, useCallback} from 'react';
import {useForm, Controller} from "react-hook-form/dist/index.ie11";
import moment from 'moment/dist/moment';
import * as api from 'lib/content';
import route from 'route';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';
import Quill from "../../Editor/Quill";
import ko from 'date-fns/locale/ko';


import ReactDatePicker, { registerLocale,setDefaultLocale  } from "react-datepicker";
registerLocale('ko', ko);


const defaultValues = {
    appied_program: {
        _id:'',
    },
    attached_files:[]
};
const WriteForm = ({onSubmit,data,mode='',type,handleCancel,cancelSubmit,apply_files,reload,bigo,setReload,user=''}) => {
    const [validation,setValidation] = useState({});
    const [status,setStatus] =useState('');
    const [secret,setSecret] =useState(false);
    const [filesApplys, setFilesApplys]=useState();



    const { register, handleSubmit, watch, errors, setValue,  clearErrors ,control} = useForm({defaultValues});
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="workshop_apply_form_wrap">
                <div className="reg_form">
                    <div className="input_box">
                        <div className="modal_sub_work_title">
                            성명
                        </div>
                        <div className="input_container">
                            <input type={"text"} name="name"
                                   placeholder={'성명을 적어주세요'}
                                   ref={
                                register(
                                    {
                                        required: '성명을 적어주세요'
                                    }
                                )
                            }
                                   defaultValue={_.get(user,'name')}
                            />
                            {errors.name && (
                                <small className="text-danger">{errors.name.message}</small>
                            )}
                        </div>
                    </div>
                    <div className="input_box">
                        <div className="modal_sub_work_title">
                            학번/사번
                        </div>
                        <div className="input_container">
                            <input type={"text"} name="user_num"
                                   placeholder={'학번/사번을 적어주세요'}
                                   ref={
                                       register(
                                           {
                                               required: '학번/사번을 적어주세요'
                                           }
                                       )
                                   }
                                   defaultValue={_.get(user,'user_num').toString()}
                            />
                            {errors.user_num && (
                                <small className="text-danger">{errors.user_num.message}</small>
                            )}
                        </div>
                    </div>
                    <div className="input_box">
                        <div className="modal_sub_work_title">
                            소속
                        </div>
                        <div className="input_container">
                            <input type={"text"} name="organization"
                                   placeholder={'소속을 적어주세요'}
                                   ref={
                                       register(
                                           {
                                               required: '소속을 적어주세요'
                                           }
                                       )
                                   }
                                   defaultValue={_.get(user,'organization')}
                            />
                            {errors.organization && (
                                <small className="text-danger">{errors.organization.message}</small>
                            )}
                        </div>
                    </div>

                    <div className="input_box">
                        <div className="modal_sub_work_title">
                            휴대폰번호
                        </div>
                        <div className="input_container">
                            <input type={"text"} name="hp"
                                   placeholder={'휴대폰번호를 적어주세요'} ref={
                                register(
                                    {
                                        pattern: {
                                            value: /^[0-9]/i,
                                            message: "숫자만 적어주세요"
                                        },
                                        required: '휴대폰번호를 적어주세요'
                                    }
                                )
                            }
                                   defaultValue={_.get(user,'hp')}
                            />
                            {errors.hp && (
                                <small className="text-danger">{errors.hp.message}</small>
                            )}
                        </div>
                    </div>

                    <div className="input_box">
                        <div className="modal_sub_work_title">
                            이메일주소
                        </div>
                        <div className="input_container">
                            <input type={"text"} name="email"
                                   placeholder={'이메일주소를 적어주세요'} ref={
                                register(
                                    {
                                        required: '이메일주소를 적어주세요'
                                    }
                                )
                            }
                                   defaultValue={_.get(user,'email')}
                            />
                            {errors.email && (
                                <small className="text-danger">{errors.email.message}</small>
                            )}
                        </div>
                    </div>


                    <div className="input_box">
                        <div className="modal_sub_work_title privacy">
                            개인정보제공 활용동의
                        </div>
                        <div className="input_container">
                            <div className={'info_privacy'}>
                                법령에 따라 개인을 고유하게 구별하기 위하여 부여된 모든 식별정보(성명, 소속, 휴대폰, 이메일 등)의 수집, 이용에 대한 동의를 받고 있습니다.<br/>
                                신청 시 기재되는 모든 개인정보는 사업진행을 위하여 수집 및 이용될 수 있습니다.<br/>
                                또한 대학평가관련 자료 요청시 교내 관련부서에 자료가 제공될 수 있으며, 철저하게 관리 될 예정입니다.<br/>
                                수집된 개인정보는 5년 경과(대학 평가 관련 자료 요청 기간) 후 즉시 파기됩니다.<br/>
                                위와 관련하여 본인의 개인고유식별정보 수집, 이용에 관한 내용을 숙지하였고 이에 동의한다면 해당 란에 체크해 주십시오.
                            </div>
                            <div className="check_box_wrap">
                                <label><input type={'checkbox'} name={'noLimit'} style={{width: "auto"}}
                                              className="input_check" ref={register({
                                    required: '개인정보활용에 동의해주세요.'
                                })}/>개인정보 활용에 동의합니다.</label>
                            </div>
                            {errors.noLimit && (
                                <small className="text-danger">{errors.noLimit.message}</small>
                            )}

                        </div>
                    </div>

                    <div className="course_overlay_footer">
                        <button className="btn btn_info form-submit workshop">{mode=='m' ? '수정하기' : '신청하기'}</button>
                        <button onClick={handleCancel} type="button" className="overlay_close">돌아가기</button>
                    </div>
                </div>
            </div>
        </form>
    )
}


export default WriteForm;
