export const mainSlider = [];
export const mainBoard = [];
export const programlist = [
    {
        id: 'work_01',
        startDate: '05.01(토) 10:00',
        endDate: '06.01(화) 18:00',
        status: true,
        title: '원격수업역량강화 워크숍',
        thumbnail: '/assets/images/classDesign.png',
        url: 'http://34.84.15.163:6061/module/course/work_01'
        // dday: '7'
    },
    {
        id: 'dev_01',
        startDate: '05.01(토) 10:00',
        endDate: '06.01(화) 18:00',
        status: true,
        title: '효과적인 수업자료의 개발',
        thumbnail: '/assets/images/onlineClass.png',
        url: 'http://34.84.15.163:6061/module/course/dev_01'
        // dday: '7'
    }
    /* {
      id: '1',
      startDate: '11.25 10:00(화)',
      endDate: '12.09(수) 18:00',
      status: true,
      title: '소프트웨어 마스터링』 LED 감성 아크릴 램프 제작과정',
      thumbnail: '/assets/images/dummyimg2.png',
      url: '/',
      dday: '7'
    },
    {
      id: '2',
      startDate: '11.25 10:00(화)',
      endDate: '12.09(수) 18:00',
      status: true,
      title: '전남 콘랩 팝업스토어 2020',
      thumbnail: '/assets/images/dummyimg3.png',
      url: '/',
      dday: '7'
    },
    {
      id: '3',
      startDate: '11.25 10:00(화)',
      endDate: '12.09(수) 18:00',
      status: false,
      title: '소프트웨어 마스터링』 LED 감성 아크릴 램프 제작과정',
      thumbnail: '/assets/images/dummyimg4.png',
      url: '/',
      dday: '7'
    },
    {
      id: '4',
      startDate: '11.25 10:00(화)',
      endDate: '12.09(수) 18:00',
      status: true,
      title: '청년창업을 위한 클래스 목공장비교육',
      thumbnail: '/assets/images/dummyimg5.png',
      url: '/',
      dday: '7'
    },
    {
      id: '7',
      startDate: '11.25 10:00(화)',
      endDate: '12.09(수) 18:00',
      status: true,
      title: '청년창업을 위한 클래스 2020 세라믹 클래스',
      thumbnail: '/assets/images/dummyimg6.png',
      url: '/',
      dday: '7'
    },
    {
      id: '8',
      startDate: '11.25 10:00(화)',
      endDate: '12.09(수) 18:00',
      status: false,
      title: '소프트웨어 마스터링』 LED 감성 아크릴 램프 제작과정',
      thumbnail: '/assets/images/dummyimg8.png',
      url: '/',
      dday: '7'
    },

    {
      id: '39',
      startDate: '11.25 10:00(화)',
      endDate: '12.09(수) 18:00',
      status: true,
      title: '소프트웨어 마스터링』 LED 감성 아크릴 램프 제작과정',
      thumbnail: '/assets/images/dummyimg7.png',
      url: '/',
      dday: '7'
    },
    {
      id: '18',
      startDate: '11.25 10:00(화)',
      endDate: '12.09(수) 18:00',
      status: false,
      title: '소프트웨어 마스터링』 LED 감성 아크릴 램프 제작과정',
      thumbnail: '/assets/images/dummyimg3.png',
      url: '/',
      dday: '7'
    },

    {
      id: '29',
      startDate: '11.25 10:00(화)',
      endDate: '12.09(수) 18:00',
      status: true,
      title: '소프트웨어 마스터링』 LED 감성 아크릴 램프 제작과정',
      thumbnail: '/assets/images/dummyimg5.png',
      url: '/',
      dday: '7'
    } */
];
export const businesslist = [
    {
        id: '1',
        startDate: '11.25 10:00(화)',
        endDate: '12.09(수) 18:00',
        status: true,
        title: '소프트웨어 마스터링』 LED 감성 아크릴 램프 제작과정',
        thumbnail: '/assets/images/dummyimg6.png',
        url: '',
        dday: '7'
    },
    {
        id: '2',
        startDate: '11.25 10:00(화)',
        endDate: '12.09(수) 18:00',
        status: true,
        title: '[지원사업] 문화콘텐츠 지원 사업설명회',
        thumbnail: '/assets/images/dummyimg7.png',
        url: '',
        dday: '7'
    },
    {
        id: '3',
        startDate: '11.25 10:00(화)',
        endDate: '12.09(수) 18:00',
        status: false,
        title: '[지원사업] 콘텐츠스타트업 지원사업 2020',
        thumbnail: '/assets/images/dummyimg8.png',
        url: '',
        dday: '7'
    }
];
export const reservationlist = [
    {
        title: '09:00 2층 테크랩(장**)',
        date: '2020-12-01',
        color: '#3E5599'
    },
    {
        title: '18:00 1층 쇼룸(권**)',
        date: '2020-12-08',
        color: '#3E5599'
    }
];
export const spacelist = [];
export const equipmentlist = [
    {
        items: [
            {
                id: '1',
                url: '',
                status: true,
                thumbnail: '/assets/images/dummyimg10.png',
                title: 'Versatile 광원시스템',
                len: 1
            },
            {
                id: '2',
                url: '',
                status: true,
                thumbnail: '/assets/images/dummyimg11.png',
                title: '카메라렌즈',
                len: 1
            },
            {
                id: '3',
                url: '',
                status: true,
                thumbnail: '/assets/images/dummyimg12.png',
                title: '마이크',
                len: 1
            },
            {
                id: '4',
                url: '',
                status: true,
                thumbnail: '/assets/images/dummyimg13.png',
                title: '스핀들샌더',
                len: 1
            },
            {
                id: '5',
                url: '',
                status: true,
                thumbnail: '/assets/images/dummyimg14.png',
                title: '카메라 삼각대 및 악세사',
                len: 1
            },
            {
                id: '6',
                url: '',
                status: true,
                thumbnail: '/assets/images/dummyimg15.png',
                title: '리소품 조명장',
                len: 1
            },
            {
                id: '7',
                url: '',
                status: true,
                thumbnail: '/assets/images/dummyimg16.png',
                title: '수입대패',
                len: 1
            },
            {
                id: '8',
                url: '',
                status: true,
                thumbnail: '/assets/images/dummyimg17.png',
                title: '밴드쏘',
                len: 1
            }
        ]
    },
    {
        items: [
            {
                id: '9',
                url: '',
                status: true,
                thumbnail: '/assets/images/dummyimg10.png',
                title: 'Versatile 광원시스템',
                len: 1
            },
            {
                id: '10',
                url: '',
                status: true,
                thumbnail: '/assets/images/dummyimg11.png',
                title: '카메라렌즈',
                len: 1
            },
            {
                id: '11',
                url: '',
                status: true,
                thumbnail: '/assets/images/dummyimg12.png',
                title: '마이크',
                len: 1
            },
            {
                id: '12',
                url: '',
                status: true,
                thumbnail: '/assets/images/dummyimg13.png',
                title: '스핀들샌더',
                len: 1
            },
            {
                id: '13',
                url: '',
                status: true,
                thumbnail: '/assets/images/dummyimg14.png',
                title: '카메라 삼각대 및 악세사',
                len: 1
            },
            {
                id: '14',
                url: '',
                status: true,
                thumbnail: '/assets/images/dummyimg15.png',
                title: '리소품 조명장',
                len: 1
            },
            {
                id: '15',
                url: '',
                status: true,
                thumbnail: '/assets/images/dummyimg16.png',
                title: '수입대패',
                len: 1
            },
            {
                id: '16',
                url: '',
                status: true,
                thumbnail: '/assets/images/dummyimg17.png',
                title: '밴드쏘',
                len: 1
            }
        ]
    }

];


export const organization_data = [
    '고구려대학교',
    '광신대학교',
    '광양보건대학교',
    '광주가톨릭대학교',
    '광주과학기술원',
    '광주교육대학교',
    '광주대학교',
    '광주보건대학교',
    '광주여자대학교',
    '국립목포대학교',
    '기독간호대학교',
    '남부대학교',
    '동강대학교',
    '동신대학교',
    '동아보건대학교',
    '목포가톨릭대학교',
    '목포과학대학교',
    '목포해양대학교',
    '서영대학교',
    '세한대학교',
    '송원대학교',
    '순천대학교',
    '순천제일대학교',
    '영산선학대학교',
    '전남과학대학교',
    '전남대학교',
    '전남도립대학교',
    '조선간호대학교',
    '조선대학교',
    '조선이공대학교',
    '청암대학교',
    '초당대학교',
    '한려대학교',
    '한영대학교',
    '호남대학교',
    '호남신학대학교',
    '기타'
]