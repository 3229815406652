import React, {useEffect, useState, useCallback} from 'react';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import * as api from 'lib/content';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/users';
import {CustomPagination} from 'components/Custom';

const email_send_click = (email_array) => async() =>{
    console.log(email_array)
    /*const {status} = await api.emailSend({to:["zz123a@naver.com"],
    from:"joelrobuchon@strapi.io",
    cc: "helenedarroze@strapi.io",
    bcc: "ghislainearabian@strapi.io",
    replyTo: "annesophiepic@strapi.io",
    subject: "Use strapi email provider successfully",
    text: "Hello world!",
    html: "Hello world!"})*/
    //console.log(status)
}
const email_get = (emailArray = [],totalCounts=0,pageCount=0,setPage=()=>{},userGetApi=()=>{}) =>{
    console.log(pageCount)
    for(var i = 0; i < 3; i++){
        (function(x,setPage,userGetApi){
            setTimeout(function(){
                const {data: user_array} = userGetApi()
                email_send_click(user_array)
                //console.log(x);
            }, 1000*x);
        })(i,setPage,userGetApi);
        //setPage(i)
    }
    //console.log(email_names)
    //console.log(totalCounts)
}

const EmailTest = () => {
  const dispatch = useDispatch();
  const[emailArray,setEmailArray] = useState(null);
  const [perPage, setPerPage] = useState(12)
  const [page, setPage] = useState(0)
  const [totalCounts, setTotalCounts] = useState(0)
  const [params, setParams] = useState({_limit: perPage, _sort: `createdAt:DESC`, _start: page * perPage})
  const pageCount = Math.ceil(totalCounts / perPage);
  const updateParams = (data) => {
      setParams({...params, ...data});
  };
  //page 이동
  useEffect(() => {
      const {_start, _limit, classification_eq} = params;
      if (_limit !== perPage || _start !== page * perPage) {
          updateParams({...params, _start: page * perPage, _limit: perPage})
      }
  }, [page, perPage])
  async function userGetApi() {
      try {
          const {data:apply_data,status:status} = await api.gets({type:'users',...params})
          const {data:totalCount} = await api.count({type:'users',...params})
          if(status !== 200){
              throw new Error('에러 발생');
          }
          else{
              //console.log('데이터는 가져오긴 함')
              //console.log(page)
              //console.log(apply_data)
              setEmailArray(apply_data);
              setTotalCounts(totalCount);
          }
      } catch (e) {
          console.log('데이터 로딩 실패',e.message);
      } finally {
      }
  }
  //useEffect(()=>{
  //  console.log(params)
  //  userGetApi()
  //},[params])
  return (
    <div>
    <Helmet title="테스트 페이지"/>
        <Link className="close" title="홈페이지로 이동" to="/"><span className="invisible-btn">홈페이지로 이동</span></Link>
            <h2 className="page_title">이메일 발송 테스트</h2>
            <div className="join_wrap">
                <div className="join_info">간단한 정보 입력만으로 간편하게 회원 가입 하세요!</div>
                <div className="btn_join_go">
                    {/*totalCounts != 0 &&
                        <CustomPagination
                            pageSize={perPage}
                            pageIndex={page}
                            pageCount={pageCount}
                            onPageChange={({selected}) => setPage(selected)}
                        />
                    */}
                    <button title="이메일로 회원가입" onClick={() => email_get(emailArray,totalCounts,pageCount,setPage,userGetApi)}>
                        <i className="xi-mail-o"></i>이메일 발송
                    </button>
                </div>
            </div>
    </div>
  );
};

export default EmailTest;
