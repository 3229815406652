import React, {useState, useEffect, useCallback, useLayoutEffect} from 'react';
import {BrowserRouter as Router, Route, Switch,useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import * as authActions from 'store/actions/auth';
import LoadingBar from 'react-redux-loading-bar'

import 'styles/style.scss';

import IntroFooter from 'components/Intro/IntroFooter'

import {
    Header, Footer, MobileNav, MobileUserMenu
} from 'components/Layout';
import Quick from 'components/Quick';

import Home from './Home';
import About from './About';
import Program from './Program';
import Resource from './Resource';
import Onair from './Onair';
import Dataset from './Dataset';
import Member from './Member';
import Content from './Content';
import Varsity from './Varsity';
import Lms from './Lms';
import Community from './Community';
import Sublms from './Sublms';
import Admin from './Admin';

import ScrollToTop from 'components/ScrollToTop';
import useWindowPosition from 'utils/useWindowPosition';

// Head Metadata
import {Helmet} from "react-helmet";
import config from "config";

const RouterApp = () => {
    const dispatch = useDispatch();
    
    const { pathname } = useLocation();
    const [openUserMenu, setOpenUserMenu] = useState(false);
    const [openMobileNav, setOpenMobileNav] = useState(false);
    const [pageClass, setPageClass] = useState(null);

    const handleChangeOpenUserMenu = useCallback((bool) => {
        setOpenUserMenu(bool);
    }, [openUserMenu]);

    const handleChangeOpenMobileNav = useCallback((bool) => {
        setOpenMobileNav(bool);
    }, [openMobileNav]);

    useEffect(()=>{
        setPageClass(_.chain(location.pathname)
        .split('/')
        .filter()
        .map((name, idx, array) => {
            return (idx > 0) ? `page-${array[idx - 1]}-${name}` : `page-${name}`
        }).join(' ').value())
    },[pathname])
    //나의 유저 정보 Load
    useEffect(() => {
        dispatch(authActions.authorization());
    }, []);

    return (
        <div className={`wrapper ${(pageClass) ? 'page ' + pageClass : ''} ${useWindowPosition() > 10 ? 'fixed' : ''}` }>
            <LoadingBar style={{position: 'fixed', top: 0, zIndex: 999999}}/>
            <Helmet {...config.app.head}/>

            <ScrollToTop/>
            <Header
                onChangeOpenUserMenu={handleChangeOpenUserMenu}
                onChangeOpenMobileNav={handleChangeOpenMobileNav}
                pageClass={pageClass}
            />
            <MobileNav openMobileNav={openMobileNav} onChangeOpenMobileNav={handleChangeOpenMobileNav}/>
            <MobileUserMenu openUserMenu={openUserMenu} onChangeOpenUserMenu={handleChangeOpenUserMenu}/>
            <Quick/>

            {/*<div className="content">*/}
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route path="/about" component={About}/>
                    <Route path="/program" component={Program}/>
                    <Route path="/resource" component={Resource}/>
                    <Route path="/board" component={Onair}/>
                    <Route path="/dataset" component={Dataset}/>
                    <Route path="/member" component={Member}/>
                    <Route path="/content" component={Content}/>
                    <Route path="/university" component={Varsity}/>
                    <Route path="/lms" component={Lms}/>
                    <Route path="/community" component={Community}/>
                    <Route path="/sublms" component={Sublms}/>
                    <Route path="/admin" component={Admin}/>
                </Switch>
            {/*</div>*/}
            <IntroFooter/>
        </div>
    );
};

export default RouterApp;
