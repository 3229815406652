import React from 'react';
import { Route, Switch } from 'react-router-dom';

import DatasetCklDataDetail from './CklDataDetail';
import DatasetCklData from './CklData';
import DatasetLocalDataDetail from './LocalDataDetail';
import DatasetLocalData from './LocalData';

const Dataset = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/ckldata/:id`} render={() => <DatasetCklDataDetail />} />
      <Route path={`${match.path}/ckldata`} render={() => <DatasetCklData />} />
      <Route path={`${match.path}/localdata/:id`} render={() => <DatasetLocalDataDetail />} />
      <Route path={`${match.path}/localdata`} render={() => <DatasetLocalData />} />
    </Switch>
  );
};

export default Dataset;
