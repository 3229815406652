// 프로그램 신청
import React, {useEffect, useMemo, useState} from 'react';
import BreadCrumb from '../../components/BreadCrumb';
import route from '../../route';
import {programlist} from '../../data';
import ProgramList from '../../components/ProgramList';
import Pagination from '../../components/Pagination';
import {useRouteMatch, generatePath, Link} from 'react-router-dom';
import SubLeftMenu from '../../components/SubLeftMenu';
import {useTranslation} from 'react-i18next'
import {Helmet} from "react-helmet";
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';
import moment from 'moment/dist/moment';
import {CustomPagination} from 'components/Custom';
import {apiUrl} from "config";

const TYPE = 'studyprograms';
const ExellentContent = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch()
    const {list = [], totalCount = 0, loading} = useSelector(({content}) => ({...content}));
    const [perPage, setPerPage] = useState(12)
    const [page, setPage] = useState(0)
    const [params, setParams] = useState({_limit: perPage, _sort: `createdAt:desc`, _start: page * perPage})
    const [category, setCategory] = useState('usuireoninggangjwa')

    // const [category, setCategory] = useState([
    //     {
    //         key: '',
    //         value: '전체',
    //         ative: true
    //     },
    //     {
    //         key: 'software',
    //         value: '소프트웨어',
    //         ative: false
    //     },
    //     {
    //         key: 'hardware',
    //         value: '하드웨어',
    //         ative: false
    //     },
    //     {
    //         key: 'business',
    //         value: '비즈니스',
    //         ative: false
    //     },
    //     {
    //         key: 'entrepreneurship',
    //         value: '창업',
    //         ative: false
    //     },
    //     {
    //         key: 'webtoon',
    //         value: '웹툰',
    //         ative: false
    //     }
    // ])

    const {t} = useTranslation()
    const pageCount = Math.ceil(totalCount / perPage);

    const updateParams = (data) => {
        setParams({...params, ...data});
    };

    const handleChange = (value) => {
        /*console.log(value, '키워드 입력 및 타입 셀렉트 시')*/

    }

    //page 이동
    useEffect(() => {
        const {_start, _limit, classification_eq} = params;
        if (_limit !== perPage || _start !== page * perPage) {
            updateParams({...params, _start: page * perPage, _limit: perPage})
        }
    }, [page, perPage])

    useEffect(() => {
        const filter = category && {program_category_eq:category} || {}
        dispatch(actions.listRequest({type: TYPE, ...params,...filter}))
    }, [params,category])

    // useEffect(() => {
    //     updateParams({...params, program_category_eq:category})
    // }, [category])
    // console.log(list);
    // console.log(apiUrl);
    // // console.log(`${apiUrl}/${list.thumbnailimages.url}`);
    // console.log(route.program)

    return (
        <>
            <div className="program content introduction">
                <Helmet title="우수이러닝 강좌"/>
                <BreadCrumb list={route.depth3}/>
                <div className="sub">
                    <div className="wrap">
                        <SubLeftMenu
                            title={route.depth3.title}
                            lnbList={route.depth3.list}
                        />
                        <div className="sub__content introduction_content">
                            <h1 className="sub__title introduction_title">
                                <span className="">우수이러닝 강좌</span>
                            </h1>
                            {/*<div className="tabs">*/}
                            {/*    <Link to={''} className={'active'}><span>전체</span></Link>*/}
                            {/*    <button className={'active'}>전체</button>*/}
                            {/*    {Object.entries(category).map((item, idx) =>*/}
                            {/*        <button*/}
                            {/*            key={idx}*/}
                            {/*            onClick ={() =>updateCatagory(item[1].key)}*/}
                            {/*            className={item[1].ative==true && 'active' || ''}*/}
                            {/*        >*/}
                            {/*            <span>{item[1].value}</span></button>*/}
                            {/*    )}*/}
                            {/*</div>*/}
                            {loading && (<div className={`text-center p-4`}>로딩중...</div>) ||
                            <>
                                <ProgramList
                                    path={match.path}
                                    loading={loading}
                                    list={list}
                                    headerHidden={true}
                                />
                                <CustomPagination
                                    pageSize={perPage}
                                    pageIndex={page}
                                    pageCount={pageCount}
                                    onPageChange={({selected}) => setPage(selected)}
                                />
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ExellentContent;
