
import React, {useEffect, useState, useCallback} from 'react';
import {useForm} from "react-hook-form/dist/index.ie11";
import moment from 'moment/dist/moment';
import * as api from 'lib/content';
import route from 'route';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';
import Quill from "../../Editor/Quill";


const defaultValues = {
    appied_program: {
        _id:'',
    },
    attached_files:[]
};
const file_del = (type,id,reload,setReload) => async() =>{
    try {
        const {status} = await api.del({type:type,id:id})
        if(status !== 200){
            throw new Error('에러 발생');
        }
        else{
            setReload(!reload)
            swal({
                title: `파일 삭제 완료`,
                icon: "success", timer: 10000, buttons: false,
            });
        }
    } catch (e) {
        swal({
            title: `파일 삭제 실패`, text: e.message,
            icon: "warning", timer: 3000, buttons: false,
        });
    } finally {
    }
}
const file_type_check = (value) => {
    //const {files: files} = data
    var type_check = true
    Object.entries(value).map((item, idx)=>{
        var file_kind = item[1].name.lastIndexOf('.')
        var file_name = item[1].name.substring(file_kind+1,item[1].length)
        var file_type = file_name.toLowerCase()
        var check_file_type = ['pdf','hwp','doc','docx','pptx','ppt']
        if(check_file_type.indexOf(file_type)==-1) {
            type_check = false;
            /*swal({
                title: `파일 유형이 맞지 않습니다.`, text: 'pdf,hwp,dox,docs,pptx,ppt 형식의 파일만 업로드할 수 있습니다.',
                icon: "warning", timer: 3000, buttons: false,
            });*/
        }
    })
    if(type_check == false){
        return '파일형식이 맞지 않습니다.';
    }
}
const WriteForm = ({onSubmit,data,mode='',type,handleCancel,cancelSubmit,apply_files,reload,bigo,setReload,user=''}) => {
    const [validation,setValidation] = useState({});
    const [status,setStatus] =useState('');
    const [secret,setSecret] =useState(false);
    const [filesApplys, setFilesApplys]=useState();
    // const [description, setDescription] = useState('');

    /*useEffect(() =>{
        setFilesApplys(apply_files)
        if(data.enroll_attache_force){
            if(apply_files && apply_files.length>0){
                setValidation({validate: (value) => file_type_check(value)})
            }
            else{
                setValidation({required: '파일을 등록해주세요',
                    validate: (value) => file_type_check(value)})
            }
        }
        else{
            setValidation({validate: (value) => file_type_check(value)})
        }
    },[apply_files,data])*/

    const { register, handleSubmit, watch, errors, setValue,  clearErrors } = useForm({defaultValues});
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="reg_form">
            <div className="modal_sub_title">
                제목
            </div>
            <div className="input_container">
                <input type={"text"} name="title" ref={
                    register(
                        {
                            required: '제목을 적어주세요'
                        }
                    )
                }
                       defaultValue={_.get(data,'title')}
                />
                {errors.title && (
                    <small className="text-danger">{errors.title.message}</small>
                )}
            </div>
            {/*<div className="input_container">
            <label><input onClick={()=>setSecret(!secret)} type={"checkbox"} className={'secret_checkbox'}/><span>비밀글</span></label>
            </div>*/}
            {/*{secret && <div className="modal_sub_title">
                비밀번호
            </div>}
            <div className="input_container">
                {secret && <input type={"password"} name="password" ref={
                    register(
                        {
                            required: '비밀번호를 입력해주세요.'
                        }
                    )
                }
                />}
                {errors.password && (
                    <small className="text-danger">{errors.title.message}</small>
                    )}
            </div>*/}

            <div className="modal_sub_title">
                내용
            </div>
            {/*// 2. 비고작성 부분*/}
            <div className="input_container">
                <Quill initialValue={_.get(data, 'body')}
                       onChange={(content, delta, source, editor) => {
                           if (errors.bigo && errors.bigo.type === 'required') {
                               clearErrors('bigo');
                           }
                           if (editor.getLength() === 1) {
                               setValue('bigo', '');
                           } else {
                               setValue('bigo', content)
                           }}}/>
                {/*<div>*/}
                <div style={{display: 'none'}}>
                    <textarea defaultValue={_.get(data, 'body')} name={'bigo'} ref={register({required:true})} />
                </div>
                {/*<Quill onChange={setDescription} initialValue={_.get(data, 'body')}/>*/}
                {/*<div style={{display: 'none'}}>*/}
                {/*    <textarea name="bigo" ref={register({required: '내용을 작성해주세요'})}*/}
                {/*              value={description}*/}
                {/*              readOnly*/}
                {/*    />*/}
                {/*</div>*/}
                {errors.bigo &&
                    errors.bigo.type === 'required' && (
                        <small className="text-danger description">필수 항목입니다.</small>)
                }
            </div>
            <div className="modal_sub_title">
                첨부파일
            </div>
            {/*// 1. 사용자에게 파일 첨부를 받는다*/}
            <div className="input_container">
                {/*<input type="hidden" name="id" value={_.get(data,'id')}  ref={register({ required: true })} />*/}
                <input type="file" multiple name="files" ref={
                    register()
                }  />
                {errors.files && (
                    <small className="text-danger">{errors.files.message}</small>
                )}
                {(!_.isEmpty(_.get(data,'attached_file',[])) && mode=='m') && <div className="files_container">
                    <span className="files_title">업로드 된 파일</span>
                    {_.get(data,'attached_file').map((item,index)=><span className="file_once" key={index}><span className="files_item">{item.name}</span> <span className="files_delete" onClick={file_del('upload/files',item.id,reload,setReload)}>삭제</span></span>)}

                </div>}
            </div>
            <div className="course_overlay_footer">
                <button className="btn btn_info form-submit" >신청하기</button>
                <button onClick={handleCancel} type="button" className="overlay_close">취소하기</button>
            </div>
           </div>
        </form>
    )
}


export default WriteForm;
