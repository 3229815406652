import React from 'react';
import { Link } from 'react-router-dom';

import {apiUrl} from 'config'

const CollageList2 = [
    {
        id: 1,
        name: '고구려대학교',
        number: '061-330-7400',
        img: '/assets/images/uni-1.jpg',
        location: '전문대학',
        year: '2016'
    },
    {
        id: 2,
        name: '광양보건대학교',
        number: '061-760-1400',
        img: '/assets/images/uni-3.jpg',
        location: '전문대학',
        year: '2016'
    },
    {
        id: 3,
        name: '광주과학보건대학교',
        number: '062-958-7500',
        img: '/assets/images/uni-8.jpg',
        location: '전문대학',
        year: '2016'
    },
    {
        id: 4,
        name: '기독간호대학교',
        number: '062-650-8000',
        img: '/assets/images/uni-10.jpg',
        location: '전문대학',
        year: '2016'
    },
    {
        id: 5,
        name: '동강대학교',
        number: '062-520-2114',
        img: '/assets/images/col-img-2.png',
        location: '전문대학',
        year: '2016'
    },
    {
        id: 6,
        name: '동아보건대학교',
        number: '061-470-1700',
        img: '/assets/images/uni-13.png',
        location: '전문대학',
        year: '2016'
    },
    {
        id: 7,
        name: '목포과학대학교',
        number: '061-278-8651',
        img: '/assets/images/col-img-9.png',
        location: '전문대학',
        year: '2016'
    },
    {
        id: 8,
        name: '서영대학교',
        number: '062-520-5114',
        img: '/assets/images/uni-18.png',
        location: '전문대학',
        year: '2016'
    },
    {
        id: 9,
        name: '순천제일대학교',
        number: '061-740-1234',
        img: '/assets/images/uni-22.jpg',
        location: '전문대학',
        year: '2016'
    },
    {
        id: 10,
        name: '전남과학대학교',
        number: '061-360-5000',
        img: '/assets/images/uni-13.png',
        location: '전문대학',
        year: '2016'
    },
    {
        id: 11,
        name: '전남도립대학교',
        number: '061-380-8411',
        img: '/assets/images/uni-26.jpg',
        location: '전문대학',
        year: '2016'
    },
    {
        id: 12,
        name: '조선간호대학교',
        number: '062-231-7000',
        img: '/assets/images/uni-27.jpg',
        location: '전문대학',
        year: '2016'
    },
    {
        id: 13,
        name: '조선이공대학교',
        number: '061-230-8114',
        img: '/assets/images/uni-29.jpg',
        location: '전문대학',
        year: '2016'
    },
    {
        id: 14,
        name: '청암대학교',
        number: '061-740-7100',
        img: '/assets/images/col-img-7.png',
        location: '전문대학',
        year: '2016'
    },
    {
        id: 15,
        name: '한영대학교',
        number: '061-650-4000',
        img: '/assets/images/uni-33.PNG',
        location: '전문대학',
        year: '2016'
    }
];

const IntroUniversity = ({univ=[]}) => {
    const newurl = window.location.href;
    const strurl = newurl.indexOf('university');
    const url = newurl.substring(0,strurl);

    return (
        <div className={'intro__collage'}>
            {
                univ.map(collage => {
                    return (
                        <a target={'_blank'} href={url+'sublms/'+collage.univ_code} className={'collage-item'} key={collage.id}>
                            <div className="thumb">
                                <img src={apiUrl + _.get(collage,'univ_logo.0.url')} alt=""/>
                            </div>
                            {/*<div className="info">
                                <div className={'summery'}>
                                    {collage.location}
                                </div>
                                <ul>
                                    <li className={'name'}>{collage.name}</li>
                                    <li className={'number'}>
                                        <a href={'tel:020-1540-1548'}>{collage.number}</a>
                                    </li>
                                </ul>
                            </div>*/}
                        </a>
                    );
                })
            }
        </div>
    );
};

export default IntroUniversity;
