import React, {useState, useEffect} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {MainSlide, PartnersSlide} from 'components/Slide';
import MainBoard from 'components/MainBoard';
import ProgramList from 'components/ProgramList';
import * as actions from 'store/actions/auth';
import ReservationCalendar from 'components/ReservationCalendar';
import SpaceInfo from 'components/SpaceInfo';
import EquipmentInfo from 'components/EquipmentInfo';
import IntroCollages from '../../components/Intro/IntroCollages';
import IntroPagination from '../../components/Intro/IntroPagination';
import {CustomPagination} from 'components/Custom';
import { Link, useLocation } from 'react-router-dom';
import route from 'route';
import {useDispatch, useSelector} from "react-redux";
import {useCookies} from 'react-cookie'

import {reservationlist, equipmentlist, programlist} from 'data';

//예약캘린더
import ScheduleCalendar from 'components/ScheduleCalendar';

//graphql 관련
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import * as cQuery from "queries/content";
import * as rQuery from "queries/resource";

const quicklist = [
    // {
    //     icon: '/assets/images/quick_icon_1.png',
    //     title: '회원가입',
    //     url: '/member/register'
    // },
    {
        icon: '/assets/images/quick_icon_2.png',
        title: '학습관리시스템',
        url: '/lms/university'
    }
    // {
    //     icon: '/assets/images/quick_icon_3.png',
    //     title: '스튜디오예약',
    //     url: '/resource/studio'
    //     //url: '/program/supportprogram'
    // },
    // {
    //   icon: '/assets/images/quick_icon_4.png',
    //   title: '공간/장비예약',
    //   url: ''
    // },
    // {
    //     icon: '/assets/images/quick_icon_4.png',
    //     title: '콘텐츠품질관리',
    //     url: '/content/auditcontent'
    //     //url: '/about/introduction'
    // },
    // {
    //     icon: '/assets/images/quick_icon_5.png',
    //     title: 'Q&A',
    //     //url: '#'
    //     url: '/community/qna'
    //
    // },
    // {
    //     icon: '/assets/images/quick_icon_6.png',
    //     title: '공지사항',
    //     url: '/community/notice'
    // }
];

const univLms = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    const {id} = useParams();
    const [cookies, setCookie, removeCookie] = useCookies(['isQuickMenu']);
    const location = useLocation()
    const {user, isLoggedIn} = useSelector(({auth: {user, isLoggedIn}}) => ({
        user, isLoggedIn
    }));
    const [toggle, setToggle] = useState((
        _.isUndefined(cookies.isQuickMenu) || cookies.isQuickMenu == 'true'
    ) && true || false);

    // useEffect(()=>{
    //     console.log(id)
    // },[id])

    useEffect(() => {
        _.isUndefined(cookies.isQuickMenu) && setCookie('isQuickMenu', false, {path: '/'});
    }, []);

    const handleToggle = (isToggle) => {
        setCookie('isQuickMenu', isToggle, {path: '/'});
        setToggle(isToggle);
    }

    const [paging, setPaging] = React.useState(0);
    const [totalCount, setTotalPaging] = React.useState(0);
    useEffect(()=>{
        /*console.log(paging)*/
    },[paging])
    const handleClickPaging = React.useCallback((value) => {
        if (typeof value === 'number') {
            setPaging(value);
            return;
        }
        if (value === 'next') {
            setPaging(prev => {
                if(prev == 3){
                    return 0
                }
                else{
                    return prev + 1;
                }
            });
            return
        }
        if (value === 'prev') {
            setPaging(prev => {
                if(prev == 0){
                    return 3
                }
                else{
                    return prev - 1
                }
            });
            return
        }
    }, [paging]);


    //메인 페이지 배너 관련
    const [mainbannerList, setMainbannerList] = useState([]);
    useQuery(cQuery.MAIN_BANNER_QUERY, {
        fetchPolicy: 'network-only',
        variables: {sort: "banner_weight:ASC"},
        onCompleted: ({mainBanners = []}) => setMainbannerList([...mainBanners])
    });

    //공지사항 관련
    const [noticeList, setNoticeList] = useState([]);
    useQuery(cQuery.NOTICES_QUERY, {
        fetchPolicy: 'network-only',
        variables: {sort: "createdAt:ASC"},
        onCompleted: ({boardNotices = []}) => setNoticeList([...boardNotices])
    });

    //센터소식 관련
    const [newsList, setNewsList] = useState([]);
    useQuery(cQuery.NEWS_QUERY, {
        fetchPolicy: 'network-only',
        variables: {sort: "createdAt:ASC"},
        onCompleted: ({boardNews = []}) => setNewsList([...boardNews])
    });


    //프로그램 목록 관련
    const [studyList, setStudyList] = useState([]);
    useQuery(cQuery.STUDY_PROGRAM_QUERY, {
        fetchPolicy: 'network-only',
        variables: {limit: 9, sort: "weight:asc"},
        onCompleted: ({studyprograms = []}) => setStudyList([...studyprograms])
    });

    //지원사업 목록 관련
    const [supportList, setSupportList] = useState([]);
    useQuery(cQuery.SUPPORT_PROGRAM_QUERY, {
        fetchPolicy: 'network-only',
        variables: {limit: 3, sort: "createdAt:DESC"},
        onCompleted: ({supportprograms = []}) => setSupportList([...supportprograms])
    });

    //파트너쉽 사이트 관련
    const [relationSites, setRelationSites] = useState([]);
    useQuery(cQuery.RELATION_SITES_QUERY, {
        fetchPolicy: 'network-only',
        variables: {sort: "pinned_order:ASC,createdAt:DESC"},
        onCompleted: ({relationsitelists = []}) => setRelationSites([...relationsitelists])
    });

    //공간/장비 예약 관련
    const [schedules, setSchedules] = useState([]); //스케쥴 목록
    const [getSchedules] = useLazyQuery(rQuery.SCHEDULE_SPACES_QUERY, {
        fetchPolicy: 'no-cache',
        onCompleted: ({scheduleSpaces = []}) => setSchedules([...scheduleSpaces])
    });

    //대학교 관련
    const [univ, setUniv] = useState([]); //대학 목록
    useQuery(rQuery.UNIV_LIST, {
        fetchPolicy: 'no-cache',
        variables: {sort:"univ_name:asc",start:paging*10,limit:10},
        onCompleted: ({partnersUnivs = []}) => {
            setUniv([...partnersUnivs])
        }
    });
    useQuery(rQuery.UNIV_LIST, {
        fetchPolicy: 'no-cache',
        variables: {sort:"univ_name:asc"},
        onCompleted: ({partnersUnivs = []}) => {
            setTotalPaging(partnersUnivs.length)
        }
    });


    const handleLogout = () => {
        dispatch(actions.logout())
        history.push('/')
    }

    return (
        <>
            <div className={`quick_sublms ${toggle && 'active'}`}>

                <div className="quick__content">
                    <div className="quick__header">
                        {/*전남콘텐츠코리아랩<br/>*/}
                        {/*오신 것 환영합니다*/}
                        {id == 'kwangshin' && (
                            <img src={'/assets/images/kwangshin.png'} alt="퀵메뉴 로고"/>
                        )}
                        {id == 'dongac' && (
                            <img src={'/assets/images/dongac.png'} alt="퀵메뉴 로고"/>
                        )}
                        {id == 'scjc' && (
                            <img src={'/assets/images/scjc.png'} alt="퀵메뉴 로고"/>
                        )}
                        {id == 'mcu' && (
                            <img src={'/assets/images/mcu.png'} alt="퀵메뉴 로고"/>
                        )}
                        {id == 'honamtheological' && (
                            <img src={'/assets/images/honamtheological.png'} alt="퀵메뉴 로고"/>
                        )}
                    </div>
                    {/*<h2 className="quick__menu">QUICK MENU</h2>*/}
                    <div className="quick__body">
                        {/** 회원가입 링크 */}
                        {/*{(!user || !isLoggedIn) && (*/}
                        {/*    <Link to={`/member/register/information`}>*/}
                        {/*        <span><img src={`/assets/images/quick_icon_1.png`} alt=""/></span> 회원가입*/}
                        {/*    </Link>*/}
                        {/*)}*/}

                            <a href={`https://eclass-lms.seoyeong.ac.kr/member/${id}`}>
                                <span><img src={'/assets/images/left_icon.png'} alt=""/></span> 학습관리시스템
                            </a>
                    </div>
                    {/*<div className="quick__foot">*/}
                    {/*    <h2>*/}
                    {/*        <img src={'/assets/images/quick_icon_7.png'} alt="문의 연락처"/> 문의*/}
                    {/*    </h2>*/}
                    {/*    <h3>*/}
                    {/*        062-530-5075 ,2595*/}
                    {/*    </h3>*/}
                    {/*    <br/>*/}
                    {/*    <h4>문의시간</h4>*/}
                    {/*    <p>*/}
                    {/*        월~금 09시~18시<br/>*/}
                    {/*        주말 및 공휴일 휴무*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                </div>
            </div>

            <header className="header">
                <div className="header__top">
                    <div className="wrap_sublms">
                        {/*<div className="header__gnb">*/}
                        {/*    <Index data={route.depth1} />*/}
                        {/*    <Index data={route.depth2} />*/}
                        {/*    <Index data={route.depth3} />*/}
                        {/*    <Index data={route.depth4} />*/}
                        {/*    <Index data={route.depth5} />*/}
                        {/*</div>*/}
                        <div>
                            <Link to="#">센터소개</Link>
                            <Link to="#">학습관리</Link>
                            <Link to="#">콘텐츠</Link>
                            <Link to="#">원격수업 지원</Link>
                            <Link to="#">커뮤니티</Link>
                        </div>
                        {/*{(!user || !isLoggedIn) && (<>*/}
                        {/*    <Link to="/member/login">로그인</Link>*/}
                        {/*    <Link to="/member/register/information">회원가입</Link>*/}
                        {/*</>)}*/}
                        {(!user || !isLoggedIn) && (<div>
                            <Link to="/member/login">로그인</Link>
                            <Link to="/member/register/information">회원가입</Link>
                        </div>)}
                        {user && isLoggedIn && (<div>
                        <span className={`mr-3 text-white`}>
                            {user.name && `${user.name} 님` || `${user.email} 님`}
                        </span>
                            <button onClick={handleLogout}>로그아웃</button>
                            <Link to="/member/mystudyprogram">마이페이지</Link>
                        </div>)}
                        {/*<div>
                            <Link to="#">로그인</Link>
                            <Link to="#">회원가입</Link>
                        </div>*/}

                        {/*{user && isLoggedIn && (<>*/}
                        {/*        <span className={`mr-3 text-white`}>*/}
                        {/*            {user.name && `${user.name} 님` || `${user.email} 님`}*/}
                        {/*        </span>*/}
                        {/*    <button onClick={handleLogout}>로그아웃</button>*/}
                        {/*    <Link to="/member/mystudyprogram">마이페이지</Link>*/}
                        {/*</>)}*/}
                    </div>
                </div>
            </header>



            <div className={'main-wrap'}>
                <div className="home">

                    {/* 비쥬얼 */}
                    <MainSlide bannerlist={mainbannerList}/>


                    {/* 게시판 */}

                    {/*<MainBoard notices={noticeList} news={newsList}/>*/}

                    <div className={'mainBoard_sublms'}>
                        <div className={'wrap'}>
                            <div className="col col1">
                                <div className="top">
                                    {/*<button className={type === 'notice' ? 'active' : ''} onClick={() => onChangeType('notice')}>공지사항</button>*/}
                                    {/*<button className={type === 'news' ? 'active' : ''} onClick={() => onChangeType('news')}>센터 소식</button>*/}
                                    <h1>공지사항
                                        <button className={'overlay_close_x close'}>
                                            <span className="element-invisible" aria-hidden="true">+</span>
                                        </button>
                                    </h1>
                                </div>
                                <div className="contbox boardbox">
                                    <div className="list">
                                        {/*{*/}
                                        {/*    !_.isEmpty(list)*/}
                                        {/*        ? list.map(item => <Item key={item.id} type={type} data={item}/>*/}
                                        {/*        )*/}
                                        {/*        : <p>등록된 글이 없습니다.</p>*/}
                                        {/*}*/}
                                        <Link to={'#'} className="link">2021학년도 제1차 원격수업역량강화 워크숍 개최<p>2021.05.13</p></Link>
                                        <Link to={'#'} className="link">광주전남권역 대학원격교육지원센터 1차년도 콘텐츠 무료 공개<p>2021.05.13</p></Link>
                                        <Link to={'#'} className="link">학습관리시스템 매뉴얼 (학습자용)<p>2021.05.13</p></Link>
                                        <Link to={'#'} className="link">학습관리시스템 매뉴얼 (교수자용)<p>2021.05.13</p></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col col2">
                                <div className="top">
                                    {/*<button className={type === 'notice' ? 'active' : ''} onClick={() => onChangeType('notice')}>공지사항</button>*/}
                                    {/*<button className={type === 'news' ? 'active' : ''} onClick={() => onChangeType('news')}>센터 소식</button>*/}
                                    <h1>이용안내
                                        <button className={'overlay_close_x close'}>
                                            <span className="element-invisible" aria-hidden="true">+</span>
                                        </button>
                                    </h1>

                                </div>
                                <div className="contbox boardbox">
                                    <div className="list">
                                        {/*{*/}
                                        {/*    !_.isEmpty(list)*/}
                                        {/*        ? list.map(item => <Item key={item.id} type={type} data={item}/>*/}
                                        {/*        )*/}
                                        {/*        : <p>등록된 글이 없습니다.</p>*/}
                                        {/*}*/}
                                        <Link to={'#'} className="link">2021학년도 제1차 원격수업역량강화 워크숍 개최<p>2021.05.13</p></Link>
                                        <Link to={'#'} className="link">광주전남권역 대학원격교육지원센터 1차년도 콘텐츠 무료 공개<p>2021.05.13</p></Link>
                                        <Link to={'#'} className="link">학습관리시스템 매뉴얼 (학습자용)<p>2021.05.13</p></Link>
                                        <Link to={'#'} className="link">학습관리시스템 매뉴얼 (교수자용)<p>2021.05.13</p></Link>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="col col--right">*/}
                            {/*    <div className="top">*/}
                            {/*        <button className="top">*/}
                            {/*            이용안내*/}
                            {/*        </button>*/}
                            {/*    </div>*/}
                            {/*    <div className="contbox boardbox">*/}
                            {/*        <div className="list">*/}
                            {/*            /!*{*!/*/}
                            {/*            /!*    !_.isEmpty(list)*!/*/}
                            {/*            /!*        ? list.map(item => <Item key={item.id} type={type} data={item}/>*!/*/}
                            {/*            /!*        )*!/*/}
                            {/*            /!*        : <p>등록된 글이 없습니다.</p>*!/*/}
                            {/*            /!*}*!/*/}
                            {/*            <Link to={'/sublms'} className="link">2021학년도 제1차 원격수업역량강화 워크숍 개최</Link>*/}
                            {/*            <Link to={'/sublms'} className="link">광주전남권역 대학원격교육지원센터 1차년도 콘텐츠 무료 공개</Link>*/}
                            {/*            <Link to={'/sublms'} className="link">학습관리시스템 매뉴얼 (학습자용)</Link>*/}
                            {/*            <Link to={'/sublms'} className="link">학습관리시스템 매뉴얼 (교수자용)</Link>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>


                    {/*<div className={'intro__section intro__section-2'}>*/}
                    {/*    <div className="wrap">*/}
                    {/*        <h3 className={'section-title'}>광주전남권역 대학안내</h3>*/}
                    {/*        <IntroCollages univList={univ}/>*/}
                    {/*        <IntroPagination*/}
                    {/*            paging={paging}*/}
                    {/*            handleClickPaging={handleClickPaging}*/}
                    {/*            totalCountPaging={Math.ceil(totalCount/10)}*/}
                    {/*        />*/}

                    {/*        /!*<CustomPagination*/}
                    {/*        pageSize={10}*/}
                    {/*        pageIndex={paging}*/}
                    {/*        pageCount={(totalCount/10)}*/}
                    {/*        onPageChange={({selected}) => setPaging(selected)}/>*!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/* 전남콘텐츠코리아랩 프로그램은 ~ing : */}
                    {/*<div className="programBlock">*/}
                    {/*    <div className="wrap">*/}
                    {/*        <ProgramList*/}
                    {/*            path={'http://34.84.15.163:6061/module/course/'}*/}
                    {/*            list={studyList}*/}
                    {/*            title={'공개강좌'}*/}
                    {/*            description={'GJUDEC에서 제공하는 공개강좌를 만나보세요.'}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/* 전남콘텐츠랩 지원사업 */}
                    {/*<div className="section section--business">
                <div className="wrap">
                    <ProgramList
                        path={'/program/supportprogram'}
                        list={supportList}
                        title={'전남콘텐츠랩 지원사업'}
                    />
                </div>
            </div>*/}

                    {/* 공간장비 예약캘린더 */}
                    {/*<div className="section">
                <div className="wrap">
                    <ReservationCalendar events={reservationlist}/>

                    <div className="calendar mb-3" style={{height: '50px'}}>
                        <h2 className="calendar__title">공간/장비 예약캘린더</h2>
                        <div className={`calendar-status`} style={{right: 0}}>
                            <div className={`calendar-status-confirmed`}>승인</div>
                            <div className={`calendar-status-disprove`}>미승인</div>
                        </div>
                    </div>
                    <ScheduleCalendar data={schedules} getData={getSchedules}/>
                </div>
            </div>*/}

                    {/* 공간 안내 */}
                    {
                        false && (
                            <div className="section">
                                <div className="wrap">
                                    <SpaceInfo/>
                                </div>
                            </div>
                        )
                    }

                    {/* 장비 안내 */}
                    {
                        false && (
                            <div className="section">
                                <div className="wrap">
                                    <EquipmentInfo/>
                                </div>
                            </div>
                        )
                    }

                    {/* 관련 기관 */}
                    <div className="section section--relation">
                        {/*<div className="wrap">
                    <PartnersSlide />
                </div>*/}
                    </div>
                </div>
            </div>
        </>
    );
};

export default univLms;
