/** 프로그램 신청 상세 팝업*/
import React, {useEffect, useState, useCallback,useRef} from 'react';
import Popup from "reactjs-popup";
import {useDispatch, useSelector} from "react-redux";
import {CustomLoading} from "components/Custom";
import {showLoading, hideLoading} from 'react-redux-loading-bar'
import moment from 'moment/dist/moment';
import ProgressBar from './ProgressBar';
import WriteForm from './WriteForm';
import AuditForm from './AuditForm';
import AuditStatusForm from './AuditStatusForm';
import ResultForm from './ResultForm';
import AuditContentUpForm from './AuditContentUpForm';
import * as actions from 'store/actions/content';
import * as api from 'lib/content';

//graphql 관련
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import * as aQuery from "queries/program";
import { APPLY_STATUS_CATEGORIES_QUERY } from '../../../queries/program';



async function handleDelete({type,id,setReload,popupClose,reload}){
    try {
        const result_data = await api.del({type:'audit-contents',id:id});
        const {data, status} = result_data;
        if(status !== 200){
            throw new Error('에러 발생');
        }
        swal({
            title: '콘텐츠 삭제 완료', text: '콘텐츠 삭제가 완료됐습니다.',
            icon: "success", timer: 3000, buttons: false,
        });

    } catch (e) {
        // 수강 신청 실 프로세스
        swal({
            title: `콘텐츠 삭제 실패`, text: e.message,
            icon: "warning", timer: 3000, buttons: false,
        });
    } finally {
        setReload(!reload)
        popupClose && popupClose();
        //window.location.reload(true)
    }
}


const hello = ({type,popupClose,user,mode,setReload,reload,id,data}) => async (formData) => {

    const {answer_data,bigo,id} = formData;
    console.log(bigo)
    const {attached_files,content_files} = data;
    // const params = {audit_title,audit_prop:audit_props,audit_org:audit_orgs,year,month,audit_num:num};
    let scores = [0,0,0];
    let final_score = 0;
    answer_data.map((item,index)=> {
        const {contents} = item
        contents.map((contents_item)=>{
            const {answer} = contents_item
            answer.map((answer_item)=>{
                scores[index] = scores[index] + (answer_item * 1)
                final_score = final_score + (answer_item * 1);
            })
        })
    })


    let params = {audit_status:'audit_start',content_files,attached_files,answer_data,audit_reple:bigo,status:'audit_end',design_score:scores[0],development_score:scores[1],audit_score:scores[2],final_score,audit_content:id};

    try {

        const result_data = await api.create({type:'audit-logs',...params});
        const {data, status} = result_data;

        if(status !== 200){
            throw new Error('에러 발생');
        }

        swal({
            title: (`검수${mode =='m' ? ' 수정' : ''} 완료`), text:`검수${mode =='m' ? ' 수정이' : '가'} 완료되었습니다.`,
            icon: "success", timer: 3000, buttons: false,
        });
    } catch (e) {
        // 수강 신청 실 프로세스
        swal({
            title: `게시글 등록/수정 실패`, text: e.message,
            icon: "warning", timer: 3000, buttons: false,
        });
    } finally {
        //dispatch(hideLoading())
        //팝업 닫기
        setReload(!reload)
        popupClose && popupClose();
    }
}

const audit_result_status = ({type,popupClose,user,mode,setReload,reload,id,datas}) => async (formData) => {

    const {audit_result,id} = formData;
    let params = {audit_status:!_.isEmpty(audit_result) ? audit_result : null};
    if(_.isEmpty(id)){
        swal({
            title: `검수 기록이 없습니다.`, text: '검수를 진행한 후에 승인처리를 해주세요.',
            icon: "warning", timer: 3000, buttons: false,
        });
        return
    }
    console.log(_.get(datas,'audit_author.email'));
    console.log(datas);
    // return

    try {
        const result_data = await api.update({type:'audit-logs',id:id,...params});
        const {data, status} = result_data;
        if(status !== 200){
            throw new Error('에러 발생');
        }
        let mail_text = ''
        if(audit_result == 'audit_end'){
            mail_text = `신청하신 ${_.get(data,'audit_title')} 강좌의 검수가 완료되었습니다. 기타 문의사항은 광주전남권역 대학원격교육지원센터 hey@jnu.ac.kr 또는 062-530-2595로 연락 부탁드립니다. 감사합니다. 광주전남권역 대학원격교육지원센터 드림`
        }
        else if (audit_result == 'audit_start'){
            mail_text = `신청하신 ${_.get(data,'audit_title')} 강좌의 검수가 완료되었습니다. 결과 확인 후 강좌 수정 및 보완해 주세요. 기타 문의사항은 광주전남권역 대학원격교육지원센터 hey@jnu.ac.kr 또는 062-530-2595로 연락 부탁드립니다. 감사합니다. 광주전남권역 대학원격교육지원센터 드림`
        }

        const parames = {
            //to:[`hey@jnu.ac.kr`,`ysb619@jnu.ac.kr`],
            to:`${_.get(datas,'audit_author.email')}`,
            from: 'operator@gj.udec.kr',
            subject: `품질관리 검수 결과 알림`,
            text:mail_text
            //text: `${_.get(user,'organization')}에서 ${_.get(data,'audit_title')} 강좌의 ${callStatus} 신청합니다.`,
            //html: `<div style="color:yellow">테스트</div>`,
        }
        console.log(parames);

        const {status:email_status} = await api.emailSend({...parames });

        if(email_status != 200){
            swal({
                title: `이메일 발송 실패`, text: '이메일이 발송되지 않았습니다.',
                icon: "warning", timer: 3000, buttons: false,
            });
            return
        }

        swal({
            title: (`검수${mode =='m' ? ' 수정' : ''} 완료`), text:`검수${mode =='m' ? ' 수정이' : '가'} 완료되었습니다.`,
            icon: "success", timer: 3000, buttons: false,
        });
    } catch (e) {
        // 수강 신청 실 프로세스
        swal({
            title: `게시글 등록/수정 실패`, text: e.message,
            icon: "warning", timer: 3000, buttons: false,
        });
    } finally {
        //dispatch(hideLoading())
        //팝업 닫기
        setReload(!reload)
        popupClose && popupClose();
        //window.location.reload(true)
    }
}


const AuditPopup = ({trigger, type='', title='', id = '', mode = '',onSuccess=()=>{}, onFailure=()=>{}, setModeTitle='',applis,reload,setReload,data={}}) => {
        const now = new Date();
        const progressNum = useRef();
        const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}));
        /*const {id:userId ,username} =user;*/
        const dispatch = useDispatch();
        const [loading, setLoading] = useState(true);   //데이터 로딩중인가?
        const [open, setOpen] = useState(false);    //open 상태인가?
        // <ProgressBar percentCompleted={percentCompleted}/>
        const [progress,setProgress] = useState(0);
        let percentCompleted = 0;

        useEffect(()=>{
        },[progress])

        const handleSubmit = ({type,popupClose,user,mode,setReload,reload,id,loading,setLoading}) => async (formData) => {
            const {audit_props,audit_title,month,num,audit_orgs,year,files=[],content_files=[],content_files2=[],content_files3=[]} = formData;
            // const params = {audit_title,audit_prop:audit_props,audit_org:audit_orgs,year,month,audit_num:num};
            let params = {audit_title,audit_prop:audit_props,audit_org:audit_orgs,year,month,audit_num:num,audit_author:user};
            //return false
            console.log('?');
            setLoading(false);

            //프로그레스 진행상황
            const config = {
                onUploadProgress: progressEvent => {
                    percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                    if(percentCompleted >= 100){
                        setProgress(100);
                        setTimeout(()=> {
                            setProgress(101);
                        }, 1000);
                    }else {
                        setProgress(percentCompleted);
                    }
                }
            }

            try {
                let result_data = null;
                if(mode == ''){
                    params = {...params, status:'audit_start'}
                    result_data = await api.create({type:'audit-contents',...params },[{key:"attached_files",files},{key:"content_files",files:content_files},{key:"content_files",files:content_files2},{key:"content_files",files:content_files3}],config );
                }
                if(mode == 'm'){
                    result_data = await api.update({type:'audit-contents',id:id,...params},[{key:"attached_files",files},{key:"content_files",files:content_files},{key:"content_files",files:content_files2},{key:"content_files",files:content_files3}]);
                }
                if(_.isEmpty(result_data)){
                    console.log('로딩 중..')
                }

                const {data, status} = result_data;
                if(status !== 200){
                    throw new Error('에러 발생');
                }

                swal({
                    title: (mode=='m' ? '게시글 수정 완료' : `게시글 작성 완료`), text: (mode=='m' ? '게시물 수정이 완료되었습니다.' : '게시물 작성이 완료되었습니다.'),
                    icon: "success", timer: 3000, buttons: false,
                });

            } catch (e) {
                // 수강 신청 실 프로세스
                swal({
                    title: `게시글 등록/수정 실패`, text: e.message,
                    icon: "warning", timer: 3000, buttons: false,
                });
            } finally {
                //dispatch(hideLoading())
                //팝업 닫기
                setReload(!reload)
                popupClose && popupClose();
            }
        }

        return (
            <>

                {!loading ? <div className={'loading'}></div> : ''}
                {
                    (progress <= 100) && (progress > 0) ? <ProgressBar progress={progress} setProgress={setProgress} /> : <></>
                }

            <Popup
                trigger={trigger}
                position="right center"
                onOpen={() => setOpen(true)}
                onClose={() => {
                    setLoading(true)
                    setOpen(false)
                }}
                modal
                closeOnDocumentClick={false}>
                {popupClose => {
                    //if (loading) return null
                    return (<div className="popup_overlay">
                        <div className="popup_content audit_popup popResize">
                            <div className="program_popup audit">

                                <div className="modal_header">
                                    <h5 className="modal_title">
                                        {title}
                                    </h5>
                                    <button onClick={popupClose} type="button" className="overlay_close_x close">
                                        {/*<button onClick={close} type="button" className="overlay_close_x close">*/}
                                        <span className="element-invisible" aria-hidden="true">X</span>
                                    </button>
                                </div>
                                <div className="modal_body">
                                    {
                                        type == 'write' &&
                                        <WriteForm
                                            onSubmit={handleSubmit({type,popupClose,user,mode,setReload,reload,id,setLoading})}
                                        />
                                    }
                                    {
                                        type == 'content_upload' &&
                                        <AuditContentUpForm
                                            data={data}
                                            onSubmit={handleSubmit({type,popupClose,user,mode,setReload,reload,id,setLoading})}
                                        />
                                    }
                                    {
                                        type == 'audit' &&
                                        <AuditForm
                                            data={data}
                                            onSubmit={hello({type,popupClose,user,mode,setReload,reload,id,data})}
                                            onDelete={()=>handleDelete({type,id:data.id,setReload,reload,popupClose})}
                                        />
                                    }
                                    {
                                        type == 'result' &&
                                        <ResultForm data={data}/>
                                    }
                                    {
                                        type == 'edit' &&
                                        <AuditForm
                                            data={data}
                                            onSubmit={hello({type,popupClose,user,mode:'m',setReload,reload,id,data})}
                                            type={'edit'}
                                            onDelete={()=>handleDelete({type,id:data.id,setReload,reload,popupClose})}
                                        />
                                    }
                                    {
                                        type == 'audit_register' &&
                                        <AuditStatusForm data={data} onSubmit={audit_result_status({type,popupClose,user,mode:'m',setReload,reload,id,datas:data})}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>)
                }}
            </Popup>
            </>

        )
    }
;

export default AuditPopup;
