import React, { useRef, useEffect } from "react";

const CustomKakaoMap = () => {

    const {kakao} = window;

    useEffect(() => {
        mapscript();
    }, []);

    const mapscript = () => {
        let container = document.getElementById("map");
        let options = {
            center: new kakao.maps.LatLng(35.16518284197787, 126.9263618825787),
            level: 5,
        };
        //map
        const map = new kakao.maps.Map(container, options);

        //마커가 표시 될 위치
        let markerPosition = new kakao.maps.LatLng(
            35.16518284197787, 126.9263618825787
        );

        // 마커를 생성
        let marker = new kakao.maps.Marker({
            position: markerPosition,
        });

        // 마커를 지도 위에 표시
        marker.setMap(map);
    };



    return (<>
            <div
                id="map"
                style={{ width: "100%", height: "500px"}}

            ></div>

            <div className="map__info">
                <div className="map__info-header">
                    <h2>광주교육대학교</h2>
                    <p>
                        (61204) 광주광역시 북구 필문대로55(풍향동)
                    </p>
                </div>
                <ul>
                    <li>
                        <h3 className="i1">전화번호</h3>
                        <p>
                            062-520-4114
                        </p>
                    </li>
                </ul>
            </div>

        </>
    )
}

export default CustomKakaoMap;