/** 행사 후기 */
import React, {useEffect, useMemo, useState} from 'react';
import {Helmet} from "react-helmet";
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import * as actions from 'store/actions/content';
import moment from 'moment/dist/moment';
import {useTranslation} from 'react-i18next'
import {useForm} from "react-hook-form/dist/index.ie11";
import {useParams} from 'react-router-dom';

//components
import {CustomPagination} from 'components/Custom'
import BreadCrumb from 'components/BreadCrumb';
import SubLeftMenu from 'components/SubLeftMenu';
import {BoardHeader, BoardList} from 'components/Board';
import BoardWrite from 'components/Write/BoardWrite';
import EvalPopup from 'components/Popup/EvalFinishPopup';

import qs from 'qs';
import route from 'route';
import {survey1, survey2, survey3, survey4} from 'surveyData';
import * as api from 'lib/content';

//graphql 관련
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import * as cQuery from "queries/content";

// 기본정보 설문 데이터
import {organization_data} from 'data'

const Evaluate = () => {
    const {id = ''} = useParams();
    const [surveyData, setSurveyData] = useState(survey1);
    const [evalType, setEvalType] = useState(id);
    const [surveyType, setSurveyType] = useState('');
    const [openValue, setOpenValue] = useState(false);
    const [formDatas, setFormDatas] = useState([]);
    const [eval_name, setName] = useState('');
    const [mode,setMode] = useState('');
    const [type, setType] = useState('')
    const [DefaultValue,setDefaultValue] = useState('');
    const [defaultId,setDefaultId] = useState('');


    const {register, handleSubmit, watch, errors, setValue, clearErrors} =
        useForm();

    async function fetchMyAPI(survey_type) {
        try {
            const {status:survey_status,data:surveyQuestions} = await api.gets({type:'survey-questions',survey_type})
            console.log(surveyQuestions,'서베이퀘스쳔')

            setSurveyData(surveyQuestions)
            // const {status:apply_over_status,data:apply_over_data} = await api.gets({type:type,appied_program:id,apply_status:'5ff6b774bf55b944295d63e9'})
            if(apply_status !== 200 || apply_over_status != 200){
                throw new Error('에러 발생');
            }
            else{
                setApplyCount(apply_data.length);
                setApplyOverCount(apply_over_data.length);
            }
        } catch (e) {
            console.log('데이터 로딩 실패',e.message);
        } finally {
        }
    }



    //타입 지정
        useEffect(() => {
            if (id == 1) {
                fetchMyAPI('onrainsueopyeokryangjindandogu')
                setType('onrainsueopyeokryangjindandogu');
                //setSurveyData(survey1)
            } else if (id == 2) {
                fetchMyAPI('grasha-Riechmanntichingseutailgeomsa')
                setType('grasha-Riechmanntichingseutailgeomsa');
                //setSurveyData(survey2)
            } else if (id == 3) {
                fetchMyAPI('sueopjagajindanchekeuriseuteu')
                setType('sueopjagajindanchekeuriseuteu');
                //setSurveyData(survey3)
            } else if (id == 4) {
                fetchMyAPI('yeokryangjindanpyeongga')
                setType('yeokryangjindanpyeongga');
                //setSurveyData(survey4)
            }
        }, [id]
        )



    const {user, isLoggedIn, loading: loginLoading} = useSelector(({auth}) => ({...auth}))

    const setData = ({values,sub_type=''}) => {
            /*console.log(values)*/
        if(!_.isEmpty(values)){
            values.map((item,index)=>{
                if(!_.isEmpty(item)){
                    setValue(`answer.${type}.${sub_type}[${index}]`,item);
                }
            })
        }
    }

    const [infoData, setInfoData] = useState()

    const [getEvaluate, {loading:apply_loading_study}] = useLazyQuery(cQuery.EVALUATE_QUERY, {
        fetchPolicy: 'no-cache',
        onCompleted: ({ evaluates = []}) => {
            /*console.log(evaluates)*/
            if(!_.isEmpty(evaluates)){
                setMode('m')
                const {evaluate_result = {},name='',evaluate_type='',id=''} = _.get(evaluates,'0')
                setFormDatas(evaluate_result)
                setSurveyType(evaluate_type)
                setName(name)
                setDefaultId(id)
                if(type != 'yeokryangjindanpyeongga'){
                    const first_eval = _.get(_.get(evaluate_result,type,{}),'first');
                    const second_eval = _.get(_.get(evaluate_result,type,{}),'second');
                    const third_eval = _.get(_.get(evaluate_result,type,{}),'third');
                    const fourth_eval = _.get(_.get(evaluate_result,type,{}),'fourth');
                    const fifth_eval = _.get(_.get(evaluate_result,type,{}),'fifth');
                    const none = _.get(_.get(evaluate_result,type,{}),'none');
                    setData({values:first_eval,sub_type:'first'});
                    setData({values:second_eval,sub_type:'second'});
                    setData({values:third_eval,sub_type:'third'});
                    setData({values:fourth_eval,sub_type:'fourth'});
                    setData({values:fifth_eval,sub_type:'fifth'});
                    setData({values:none,sub_type:'none'});
                }
                else{
                    const first_eval_a = _.get(_.get(evaluate_result,type,{}),'first_a');
                    const first_eval_b = _.get(_.get(evaluate_result,type,{}),'first_b');
                    const first_eval_c = _.get(_.get(evaluate_result,type,{}),'first_c');
                    const second_eval_d = _.get(_.get(evaluate_result,type,{}),'second_d');
                    const second_eval_e = _.get(_.get(evaluate_result,type,{}),'second_e');
                    const second_eval_f = _.get(_.get(evaluate_result,type,{}),'second_f');
                    const third_eval_g = _.get(_.get(evaluate_result,type,{}),'third_g');
                    const third_eval_h = _.get(_.get(evaluate_result,type,{}),'third_h');
                    const third_eval_i = _.get(_.get(evaluate_result,type,{}),'third_i');
                    const none = _.get(_.get(evaluate_result,type,{}),'none');
                    setData({values:first_eval_a,sub_type:'first_a'});
                    setData({values:first_eval_b,sub_type:'first_b'});
                    setData({values:first_eval_c,sub_type:'first_c'});
                    setData({values:second_eval_d,sub_type:'second_d'});
                    setData({values:second_eval_e,sub_type:'second_e'});
                    setData({values:second_eval_f,sub_type:'second_f'});
                    setData({values:third_eval_g,sub_type:'third_g'});
                    setData({values:third_eval_h,sub_type:'third_h'});
                    setData({values:third_eval_i,sub_type:'third_i'});
                    setData({values:none,sub_type:'none'});

                    // 기본정보 설문 자동채우기
                    const {yeokryangjindanpyeongga} = evaluate_result;
                    const {info} = yeokryangjindanpyeongga;
                    setInfoData(info)
                    if (!_.isEmpty(info)){
                        setValue(`answer.${type}.info.univ`,info.univ);
                        setValue(`answer.${type}.info.sex`,info.sex);
                        setValue(`answer.${type}.info.spot`,info.spot);
                        setValue(`answer.${type}.info.career[0]`,info.career[0]);
                        setValue(`answer.${type}.info.career[1]`,info.career[1]);
                        setValue(`answer.${type}.info.major`,info.major);
                        /*setValue(`answer.${type}.info.method[0]`,info.method[0]);
                        setValue(`answer.${type}.info.method[1]`,info.method[1]);*/
                        setValue(`answer.${type}.info.participation`,info.participation);
                        setValue(`answer.${type}.info.evaluation`,info.evaluation);
                        setValue(`answer.${type}.info.avg_student`,info.avg_student);

                        setValue(`study`,info.method);
                    }

                }
            }
        }
    });

        

    useEffect(()=>{
        if(!loginLoading){
            getEvaluate({variables: {"where": {"author":user.id,"evaluate_type":type}}})
        }
    },[loginLoading,type])

    useEffect(() => {
        if (!loginLoading) {
            if (_.isEmpty(user)) {
                swal({
                    title: '로그인이 필요한 서비스입니다.', text: '로그인을 해주세요.',
                    icon: 'warning', timer: 3000, buttons: false,
                }).then(() => {
                    history.goBack();
                })
            }
        }
    }, [user])

    const [selectUniv,setSelectUniv] = useState([])
    const handleSelectChange = e => {
        console.log(e.target.value)
        setSelectUniv(e.target.value)
    }

    const onSubmit = () => async (formData) => {
        const {name = '', organize = '', email = '', info_collect = true, answer = {}, evaluate_type = 1,survey_type, univ=selectUniv, sex='', spot='', career_y='', career_m='', major='', study='', lecture_method1='', lecture_method2='', participation='', evaluation='', avg_student=''} = formData;
        let apply_answer = _.get(answer,survey_type,{},univ, sex, spot, career_y, career_m, major, study, participation, evaluation, avg_student)
        setName(name)
        let eval_name = '';
        let evaluate_result = {};
        if (id == 1) {
            eval_name = '수업 자가진단 체크리스트'
        } else if (id == 2) {
            eval_name = '티칭스타일 검사'
        } else if (id == 3) {
            eval_name = '온라인수업 역량 진단도구'
        } else if (id == 4) {
            eval_name = '교수 역량 진단도구'
        }

        console.log('formData???',formData)

        const params = {name, organize, email, author:user, info_collect,evaluate_type:survey_type, evaluate_result: {...answer}, eval_name};
        //return false
        let text = '';
        let icon = '';
        let title = '';

        try {
            let result_data = null;
            if(mode == 'm'){
                result_data = await api.update({type: 'evaluates',id:defaultId, ...params});
                text = '수정 완료'
            }
            else{
                result_data = await api.create({type: 'evaluates', ...params});
                text = '제출 완료'
            }

            const {data, status} = result_data;
            if (status !== 200) {
                throw new Error('에러 발생');
            }
            icon = 'success'
        } catch (e) {
            icon = 'warning'
            title = '설문조사 작성 실패'
            text = e.message
        } finally {
            swal({
                title: title, text: text,
                icon: icon, timer: 3000, buttons: false,
            }).then(
                () => {
                    if (icon == 'success') {
                        setOpenValue(true)
                        setFormDatas(answer)
                        setSurveyType(survey_type)
                    } else {
                        setOpenValue(false)
                    }
                }
            )
        }
    }


    {
        if (!loginLoading) {
            if (_.isEmpty(user)) return (<div className="onair content introduction">
                    <Helmet title="역량진단평가"/>
                    <BreadCrumb list={route.depth4}/>
                    <div className="sub">
                        <div className="wrap">
                            <SubLeftMenu
                                title={route.depth4.title}
                                lnbList={route.depth4.list}/>
                        </div>
                    </div>
                </div>
            )
        }
    }

    // 문항 6. 두가지만 선택가능/우선순위 출력
    const [checkItem, setCheckItem] = useState([]);

    const handleChange = e => {
        if (!_.isEmpty(infoData)){
            console.log(checkItem)
            setCheckItem(infoData.method)
        }
        if (watch('study').length < 3){
            if (e.target.checked == true){
                setCheckItem([...checkItem, e.target.value])
            }else {
                /*let index = checkItem.indexOf([e.target.value]);*/
                setCheckItem(watch('study'))
            }

        }else {
            alert('최대 2개 까지만 선택 가능합니다.')
            e.target.checked = false;
        }
    }


    // 다음버튼 클릭 시 하단 설문 open
    const [openQuestion, setOpenQuestion] = useState(false);
    const [openClass, setOpenClass] = useState('eval_que_container_one')

    // 전공계열 select option
    const majorList = ['경영계열', '간호계열', '공학계열', '농업계열', '법학계열', '교육학계열', '사회과학계열', '의학·수의학계열', '약학계열', '예술계열', '의·치학계열', '인문학계열', '체육학계열', '기타']


    /**/
    return (
        <div className="onair content introduction">
            <Helmet title="역량진단평가"/>
            <BreadCrumb list={route.depth4}/>
            <div className="sub">
                <div className="wrap">
                    <SubLeftMenu
                        title={route.depth4.title}
                        lnbList={route.depth4.list}/>
                    <div className="sub__content introduction_content">
                        <h1 className="sub__title introduction_title">
                            <span className="">역량 진단평가</span>
                        </h1>{/*

                            <ul className="sub_filter">
                                <li className={'tab' + (evalType==1 ? ' active' : '')} onClick={()=>setEvalType(1)}>
                                    수업분석자가진단 체크리스트
                                </li>
                                <li className={'tab' + (evalType==2 ? ' active' : '')} onClick={()=>setEvalType(2)}>
                                    티칭 스타일 자가분석
                                </li>
                                <li className={'tab' + (evalType==3 ? ' active' : '')} onClick={()=>setEvalType(3)}>
                                    온라인 수업 역량 진단도구
                                </li>
                            </ul>*/}
                        <div>
                            <form onSubmit={handleSubmit(onSubmit())}>
                                <div className="dr_wrapper dr table_answer question7">
                                    <div className={'writer_input'}>
                                        <p className={'input_title'}>이름</p>
                                        <input className={'name'} name={'name'} type={'text'}
                                               defaultValue={_.get(user, 'name', '')}
                                               ref={register(
                                                   {
                                                       required: true
                                                   }
                                               )}
                                        />
                                        <p className={'bar'}>
                                            |
                                        </p>
                                        <p className={'input_title'}>소속</p>
                                        <input className={'organize'} name={'organize'} type={'text'}
                                               defaultValue={_.get(user, 'organization', '')}
                                               ref={register()}
                                               readOnly
                                        />
                                        <p className={'bar'}>
                                            |
                                        </p>
                                        <p className={'input_title'}>이메일</p>
                                        <input className={'email'} name={'email'} type={'text'}
                                               defaultValue={_.get(user, 'email', '')}
                                               ref={register()}
                                        />
                                    </div>
                                    <div className={'checkbox_input'}>
                                        <label>
                                        <input type={'checkbox'}
                                               name={'info_collect'}
                                               ref={register(
                                                   {
                                                       required: true
                                                   }
                                               )}
                                               readOnly
                                        />
                                        <p>개인정보수집동의</p>
                                        </label>
                                    </div>
                                    {_.get(errors, 'info_collect') &&
                                    errors.info_collect.type === 'required' && (
                                        <small className="text-danger description">필수 항목입니다.</small>
                                    )
                                    }
                                    <input type={'hidden'} ref={register()} name={'evaluate_type'} value={evalType}/>

                                    {_.get(errors, 'name') &&
                                    //errors.name.type === 'required' && (
                                    <small className="text-danger description">필수 항목입니다.</small>
                                        //)
                                    }
                                    {(id == 4) &&
                                    <div className={openQuestion ? 'eval_que_container_two' : 'eval_que_container_one'}>
                                        <div className={'eval_new_info'}>
                                            ※ 다음은 응답자의 <strong>「기본 정보」</strong>에 관한 내용입니다. 해당하는 곳에 체크해 주시기 바랍니다.
                                        </div>
                                        <div>
                                            <div className={'eval_new_que'}>
                                                <div className={'eval_q_title'}>
                                                    <div className={'eval_q_title sub'}>문항 1</div>
                                                    <div className={'eval_q_title qu'}>소속 대학교</div>
                                                </div>
                                                <div className={'eval_select_div'}>

                                                    <select name={'answer.yeokryangjindanpyeongga.info.univ[0]'}
                                                            ref={register}>
                                                        <option value={''}>- - - - 선택 - - - -</option>
                                                        {
                                                            (!_.isEmpty(organization_data)) &&
                                                            organization_data.map((item, idx) =>
                                                                <option key={idx} value={item}>{item}</option>
                                                            )
                                                        }
                                                    </select>


                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className={'eval_new_que'}>
                                                <div className={'eval_q_title'}>
                                                    <div className={'eval_q_title sub'}>문항 2</div>
                                                    <div className={'eval_q_title qu'}>성별</div>
                                                </div>
                                                <label htmlFor={'남자'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.sex[0]'} id={'남자'} value={'남자'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>남자</label>
                                                <label htmlFor={'여자'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.sex[0]'} id={'여자'} value={'여자'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>여자</label>
                                            </div>
                                        </div>

                                        <div>
                                            <div className={'eval_new_que'}>
                                                <div className={'eval_q_title'}>
                                                    <div className={'eval_q_title sub'}>문항 3</div>
                                                    <div className={'eval_q_title qu'}>직위</div>
                                                </div>
                                                <label htmlFor={'조교수'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.spot[0]'} id={'조교수'} value={'조교수'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>조교수</label>
                                                <label htmlFor={'부교수'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.spot[0]'} id={'부교수'} value={'부교수'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>부교수</label>
                                                <label htmlFor={'교수'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.spot[0]'} id={'교수'} value={'교수'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>교수</label>
                                                <label htmlFor={'강사'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.spot[0]'} id={'강사'} value={'강사'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>강사</label>
                                                <label htmlFor={'기타(연구교수, 겸임교수, 객원교수 등)'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.spot[0]'} id={'기타(연구교수, 겸임교수, 객원교수 등)'} value={'기타'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>기타(연구교수, 겸임교수, 객원교수 등)</label>
                                            </div>
                                        </div>

                                        <div>
                                            <div className={'eval_new_que'}>
                                                <div className={'eval_q_title'}>
                                                    <div className={'eval_q_title sub'}>문항 4</div>
                                                    <div className={'eval_q_title qu'}>현재 학교 근무 경력</div>
                                                </div>
                                                <input type={'text'} className={'eval_input_text'} name={'answer.yeokryangjindanpyeongga.info.career[0]'} ref={
                                                    register(
                                                        {
                                                            required: '근무 년수를 적어주세요'
                                                        }
                                                    )
                                                }/>년
                                                <input type={'text'} className={'eval_input_text'} name={'answer.yeokryangjindanpyeongga.info.career[1]'} ref={
                                                    register(
                                                        {
                                                            required: '근무 개월을 적어주세요'
                                                        }
                                                    )
                                                }/>개월
                                            </div>
                                        </div>

                                        <div>
                                            <div className={'eval_new_que'}>
                                                <div className={'eval_q_title'}>
                                                    <div className={'eval_q_title sub'}>문항 5</div>
                                                    <div className={'eval_q_title qu'}>전공계열(소속 단과대학 또는 학과)</div>
                                                </div>
                                                <select name={'answer.yeokryangjindanpyeongga.info.major[0]'} ref={register}>
                                                    <option value={''}>- - - -  선택  - - - -</option>
                                                    {
                                                        (!_.isEmpty(majorList)) &&
                                                        majorList.map((item, idx) =>
                                                            <option key={idx} value={item}>{item}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div>
                                            <div className={'eval_new_que'}>
                                                <div className={'eval_q_title'}>
                                                    <div className={'eval_q_title sub six'}><div>문항 6</div></div>
                                                    <div className={'eval_q_title qu'}>수업 시간에 주로 사용하는 수업 방법을 우선순위별로 2개를 고르시오.<br/>
                                                        {
                                                            (_.isEmpty(checkItem) &&
                                                                <div>
                                                                    <p>1순위 : {_.get(infoData, 'method[0]')}</p> <p>2순위 : {_.get(infoData, 'method[1]')}</p>
                                                                    <input type={'hidden'}
                                                                           name={'answer.yeokryangjindanpyeongga.info.method[0]'}
                                                                           value={_.get(infoData, 'method[0]')}
                                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>
                                                                    <input type={'hidden'}
                                                                           name={'answer.yeokryangjindanpyeongga.info.method[1]'}
                                                                           value={_.get(infoData, 'method[1]')}
                                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>
                                                                </div>
                                                                ||
                                                                <div>
                                                                    <p>1순위 : {checkItem[0]}</p> <p>2순위 : {checkItem[1]}</p>
                                                                    <input type={'hidden'}
                                                                           name={'answer.yeokryangjindanpyeongga.info.method[0]'}
                                                                           value={checkItem[0]}
                                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>
                                                                    <input type={'hidden'}
                                                                           name={'answer.yeokryangjindanpyeongga.info.method[1]'}
                                                                           value={checkItem[1]}
                                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <label htmlFor={'강의식'} className={'eval_new_label'}>
                                                    <input type={'checkbox'} ref={register} name={'study'} id={'강의식'} value={'강의식'} onChange={handleChange}/>강의식</label>
                                                <label htmlFor={'토의토론 수업'} className={'eval_new_label'}>
                                                    <input type={'checkbox'} ref={register} name={'study'} id={'토의토론 수업'} value={'토의토론 수업'} onChange={handleChange}/>토의토론 수업</label>
                                                <label htmlFor={'문제중심학습'} className={'eval_new_label'}>
                                                    <input type={'checkbox'} ref={register} name={'study'} id={'문제중심학습'} value={'문제중심학습'} onChange={handleChange}/>문제중심학습</label>
                                                <label htmlFor={'프로젝트기반학습'} className={'eval_new_label'}>
                                                    <input type={'checkbox'} ref={register} name={'study'} id={'프로젝트기반학습'} value={'프로젝트기반학습'} onChange={handleChange}/>프로젝트기반학습</label>
                                                <label htmlFor={'플립러닝(거꾸로 수업)'} className={'eval_new_label'}>
                                                    <input type={'checkbox'} ref={register} name={'study'} id={'플립러닝(거꾸로 수업)'} value={'플립러닝'} onChange={handleChange}/>플립러닝(거꾸로 수업)</label>
                                                <label htmlFor={'실헙실습수업'} className={'eval_new_label'}>
                                                    <input type={'checkbox'} ref={register} name={'study'} id={'실헙실습수업'} value={'실험실습수업'} onChange={handleChange}/>실헙실습수업</label>
                                            </div>
                                        </div>

                                        <div>
                                            <div className={'eval_new_que'}>
                                                <div className={'eval_q_title'}>
                                                    <div className={'eval_q_title sub'}>문항 7</div>
                                                    <div className={'eval_q_title qu'}>지난 해 교수법 워크숍, 특강, 수업연구회 등 교수역량 증진 프로그램에 참여한 횟수</div>
                                                </div>
                                                <label htmlFor={'0회'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.participation[0]'} id={'0회'} value={'0회'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>0회</label>
                                                <label htmlFor={'1~3회'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.participation[0]'} id={'1~3회'} value={'1~3회'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>1~3회</label>
                                                <label htmlFor={'4~6회'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.participation[0]'} id={'4~6회'} value={'4~6회'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>4~6회</label>
                                                <label htmlFor={'7~9회'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.participation[0]'} id={'7~9회'} value={'7~9회'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>7~9회</label>
                                                <label htmlFor={'10회 이상'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.participation[0]'} id={'10회 이상'} value={'10회 이상'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>10회 이상</label>
                                            </div>
                                        </div>

                                        <div>
                                            <div className={'eval_new_que'}>
                                                <div className={'eval_q_title'}>
                                                    <div className={'eval_q_title sub'}>문항 8</div>
                                                    <div className={'eval_q_title qu'}>지난 해 나의 강의에 대한 학생들의 강의 평가 수준</div>
                                                </div>
                                                <label htmlFor={'매우 낮은 수준'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.evaluation[0]'} id={'매우 낮은 수준'} value={'매우 낮은 수준'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>매우 낮은 수준</label>
                                                <label htmlFor={'낮은 수준'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.evaluation[0]'} id={'낮은 수준'} value={'낮은 수준'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>낮은 수준</label>
                                                <label htmlFor={'보통'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.evaluation[0]'} id={'보통'} value={'보통'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>보통</label>
                                                <label htmlFor={'높은 수준'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.evaluation[0]'} id={'높은 수준'} value={'높은 수준'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>높은 수준</label>
                                                <label htmlFor={'매우 높은 수준'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.evaluation[0]'} id={'매우 높은 수준'} value={'매우 높은 수준'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>매우 높은 수준</label>
                                            </div>
                                        </div>

                                        <div>
                                            <div className={'eval_new_que'}>
                                                <div className={'eval_q_title'}>
                                                    <div className={'eval_q_title sub'}>문항 9</div>
                                                    <div className={'eval_q_title qu'}>지난 해 내가 담당한 전체 강의의 평균 수강 학생 수</div>
                                                </div>
                                                <label htmlFor={'20명 이하'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.avg_student[0]'} id={'20명 이하'} value={'20명 이하'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>20명 이하</label>
                                                <label htmlFor={'21~40명'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.avg_student[0]'} id={'21~40명'} value={'21~40명'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>21~40명</label>
                                                <label htmlFor={'41~60명'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.avg_student[0]'} id={'41~60명'} value={'41~60명'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>41~60명</label>
                                                <label htmlFor={'61~80명'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.avg_student[0]'} id={'61~80명'} value={'61~80명'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>61~80명</label>
                                                <label htmlFor={'81~100명'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.avg_student[0]'} id={'81~100명'} value={'81~100명'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>81~100명</label>
                                                <label htmlFor={'101명 이상'} className={'eval_new_label'}>
                                                    <input type={'radio'} name={'answer.yeokryangjindanpyeongga.info.avg_student[0]'} id={'101명 이상'} value={'101명 이상'}
                                                           ref={register({required: '답변하지 않은 질문이 있습니다.'})}/>101명 이상</label>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {
                                        !_.isEmpty(surveyData) &&
                                        surveyData.map((item, index) => {
                                            const {title: depth_title = '', list = [],survey_type=''} = item
                                            if (evalType != 4) {
                                                return (
                                                    <>
                                                        <h3 className="title" id={`title${evalType}`}>
                                                            {depth_title}
                                                        </h3>
                                                        <input type={'hidden'} name={'survey_type'} value={survey_type} ref={register()} />
                                                        <div className="ta_div" id="edit-answer7">
                                                            {/*<div className="ta_start">*/}
                                                            {/*    /!*<div id="ta_low">번호</div>*!/*/}
                                                            {/*    /!*<div id="ta_low">문행</div>*!/*/}
                                                            {/*    <div id="ta_low">매우 그렇지 않다</div>*/}
                                                            {/*    <div id="ta_low">그렇지 않다</div>*/}
                                                            {/*    <div id="ta_low">보통</div>*/}
                                                            {/*    <div id="ta_low">그렇다</div>*/}
                                                            {/*    <div id="ta_low">매우 그렇다</div>*/}
                                                            {/*</div>*/}
                                                            <table className={'evaluate_table'}>
                                                                <thead>
                                                                <tr>
                                                                    <th className={'th_header th_number'}>번호</th>
                                                                    <th className={'th_header th_title'}>문항</th>
                                                                    <th className={'th_header'}>매우 그렇지 않다</th>
                                                                    <th className={'th_header'}>그렇지 않다</th>
                                                                    <th className={'th_header'}>보통</th>
                                                                    <th className={'th_header'}>그렇다</th>
                                                                    <th className={'th_header'}>매우 그렇다</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {list.map((list_item, list_index) => {
                                                                    const {
                                                                        no = 0,
                                                                        title: list_title,
                                                                        type = ''
                                                                    } = list_item
                                                                    return (
                                                                        <tr key={list_title}>
                                                                            <td className={`ta_question title${index}-${no}`}>
                                                                                {`${no}`}
                                                                            </td>
                                                                            <td className={'question_list_title'}>
                                                                                {`${list_title}`}
                                                                            </td>
                                                                            <td id={`edit-answer${index}--${no}`}
                                                                                className="form-radios">
                                                                    <span
                                                                        className={`form-item input-field form-type-radio form-item-answer${index}-0`}>
                                                                        <input type="radio"
                                                                               id={`edit-answer${index}-${no}-0`}
                                                                               name={`answer.${survey_type}.${type}[${no - 1}]`}
                                                                               value="1"
                                                                               className="form-radio"
                                                                               ref={register({
                                                                                   required: '답변하지 않은 질문이 있습니다.'
                                                                               })}
                                                                        />
                                                                        {/*       <label className="control-label"*/}
                                                                        {/*                                       htmlFor={`edit-answer${evalType}-${no}-0`}>매우*/}
                                                                        {/*그렇지 않다</label>*/}
                                                                    </span>
                                                                            </td>
                                                                            <td>
                                                                    <span
                                                                        className={`form-item input-field form-type-radio form-item-answer${index}-0`}>
                                                                        <input type="radio"
                                                                               id={`edit-answer${index}-${no}-1`}
                                                                               name={`answer.${survey_type}.${type}[${no - 1}]`}
                                                                               value="2"
                                                                               className="form-radio"
                                                                               ref={register({
                                                                                   required: '답변하지 않은 질문이 있습니다.'
                                                                               })}
                                                                        />
                                                                        {/*       <label className="control-label"*/}
                                                                        {/*                                       htmlFor={`edit-answer${evalType}-${no}-1`}>*/}
                                                                        {/*그렇지 않다</label>*/}
                                                                    </span>
                                                                            </td>
                                                                            <td>
                                                                    <span
                                                                        className={`form-item input-field form-type-radio form-item-answer${index}-0`}>
                                                                        <input type="radio"
                                                                               id={`edit-answer${index}-${no}-2`}
                                                                               name={`answer.${survey_type}.${type}[${no - 1}]`}
                                                                               value="3"
                                                                               className="form-radio"
                                                                               ref={register({
                                                                                   required: '답변하지 않은 질문이 있습니다.'
                                                                               })}
                                                                        />
                                                                        {/*<label className="control-label"*/}
                                                                        {/*                                htmlFor={`edit-answer${evalType}-${no}-2`}>보통이다</label>*/}
                                                                    </span>
                                                                            </td>
                                                                            <td>
                                                                    <span
                                                                        className={`form-item input-field form-type-radio form-item-answer${index}-0`}>
                                                                        <input type="radio"
                                                                               id={`edit-answer${index}-${no}-3`}
                                                                               name={`answer.${survey_type}.${type}[${no - 1}]`}
                                                                               value="4"
                                                                               className="form-radio"
                                                                               ref={register({
                                                                                   required: '답변하지 않은 질문이 있습니다.'
                                                                               })}
                                                                        />
                                                                        {/*<label className="control-label"*/}
                                                                        {/*                                htmlFor={`edit-answer${evalType}-${no}-3`}>그렇다</label>*/}
                                                                    </span>
                                                                            </td>
                                                                            <td>
                                                                    <span
                                                                        className={`form-item input-field form-type-radio form-item-answer${index}-0`}>
                                                                        <input type="radio"
                                                                               id={`edit-answer${index}-${no}-4`}
                                                                               name={`answer.${survey_type}.${type}[${no - 1}]`}
                                                                               value="5"
                                                                               className="form-radio"
                                                                               ref={register({
                                                                                   required: '답변하지 않은 질문이 있습니다.'
                                                                               })}
                                                                        />
                                                                        {/*       <label className="control-label"*/}
                                                                        {/*                                       htmlFor={`edit-answer${evalType}-${no}-4`}>매우*/}
                                                                        {/*그렇다</label>*/}
                                                                    </span>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </>
                                                );
                                            }  else {
                                                return (
                                                    <div className={openQuestion ? 'eval_que_container_one' : 'eval_que_container_two'}>
                                                        <h3 className="title" id={`title${evalType}`}>
                                                            {depth_title}
                                                        </h3>
                                                        <input type={'hidden'} name={'survey_type'} value={survey_type} ref={register()} />
                                                        <div className="ta_div" id="edit-answer7">
                                                            {/*<div className="ta_start">*/}
                                                            {/*    /!*<div id="ta_low">번호</div>*!/*/}
                                                            {/*    /!*<div id="ta_low">문행</div>*!/*/}
                                                            {/*    <div id="ta_low">매우 그렇지 않다</div>*/}
                                                            {/*    <div id="ta_low">그렇지 않다</div>*/}
                                                            {/*    <div id="ta_low">보통</div>*/}
                                                            {/*    <div id="ta_low">그렇다</div>*/}
                                                            {/*    <div id="ta_low">매우 그렇다</div>*/}
                                                            {/*</div>*/}
                                                            <table className={'evaluate_table'}>
                                                                <thead>
                                                                <tr>
                                                                    <th className={'th_header th_number'}>번호</th>
                                                                    <th className={'th_header th_title'}>문항</th>
                                                                    <th className={'th_header'}>매우 그렇지 않다</th>
                                                                    <th className={'th_header'}>그렇지 않다</th>
                                                                    <th className={'th_header'}>보통</th>
                                                                    <th className={'th_header'}>그렇다</th>
                                                                    <th className={'th_header'}>매우 그렇다</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {list.map((list_item, list_index) => {
                                                                    const {
                                                                        no = 0,
                                                                        title: list_title,
                                                                        type = '',
                                                                        subtype = ''
                                                                    } = list_item
                                                                    return (
                                                                        <tr key={list_title}>
                                                                            <td className={`ta_question title${index}-${no}`}>
                                                                                {`${no}`}
                                                                            </td>
                                                                            <td className={'question_list_title'}>
                                                                                {`${list_title}`}
                                                                            </td>
                                                                            <td id={`edit-answer${index}--${no}`}
                                                                                className="form-radios">
                                                                    <span
                                                                        className={`form-item input-field form-type-radio form-item-answer${index}-0`}>
                                                                        <input type="radio"
                                                                               id={`edit-answer${index}-${no}-0`}
                                                                               name={`answer.${survey_type}.${type}_${subtype}[${no - 1}]`}
                                                                               value="1"
                                                                               className="form-radio"
                                                                               ref={register({
                                                                                   required: '답변하지 않은 질문이 있습니다.'
                                                                               })}
                                                                        />
                                                                    </span>
                                                                            </td>
                                                                            <td>
                                                                    <span
                                                                        className={`form-item input-field form-type-radio form-item-answer${index}-0`}>
                                                                        <input type="radio"
                                                                               id={`edit-answer${index}-${no}-1`}
                                                                               name={`answer.${survey_type}.${type}_${subtype}[${no - 1}]`}
                                                                               value="2"
                                                                               className="form-radio"
                                                                               ref={register({
                                                                                   required: '답변하지 않은 질문이 있습니다.'
                                                                               })}
                                                                        />
                                                                        {/*       <label className="control-label"*/}
                                                                        {/*                                       htmlFor={`edit-answer${evalType}-${no}-1`}>*/}
                                                                        {/*그렇지 않다</label>*/}
                                                                    </span>
                                                                            </td>
                                                                            <td>
                                                                    <span
                                                                        className={`form-item input-field form-type-radio form-item-answer${index}-0`}>
                                                                        <input type="radio"
                                                                               id={`edit-answer${index}-${no}-2`}
                                                                               name={`answer.${survey_type}.${type}_${subtype}[${no - 1}]`}
                                                                               value="3"
                                                                               className="form-radio"
                                                                               ref={register({
                                                                                   required: '답변하지 않은 질문이 있습니다.'
                                                                               })}
                                                                        />
                                                                        {/*<label className="control-label"*/}
                                                                        {/*                                htmlFor={`edit-answer${evalType}-${no}-2`}>보통이다</label>*/}
                                                                    </span>
                                                                            </td>
                                                                            <td>
                                                                    <span
                                                                        className={`form-item input-field form-type-radio form-item-answer${index}-0`}>
                                                                        <input type="radio"
                                                                               id={`edit-answer${index}-${no}-3`}
                                                                               name={`answer.${survey_type}.${type}_${subtype}[${no - 1}]`}
                                                                               value="4"
                                                                               className="form-radio"
                                                                               ref={register({
                                                                                   required: '답변하지 않은 질문이 있습니다.'
                                                                               })}
                                                                        />
                                                                        {/*<label className="control-label"*/}
                                                                        {/*                                htmlFor={`edit-answer${evalType}-${no}-3`}>그렇다</label>*/}
                                                                    </span>
                                                                            </td>
                                                                            <td>
                                                                    <span
                                                                        className={`form-item input-field form-type-radio form-item-answer${index}-0`}>
                                                                        <input type="radio"
                                                                               id={`edit-answer${index}-${no}-4`}
                                                                               name={`answer.${survey_type}.${type}_${subtype}[${no - 1}]`}
                                                                               value="5"
                                                                               className="form-radio"
                                                                               ref={register({
                                                                                   required: '답변하지 않은 질문이 있습니다.'
                                                                               })}
                                                                        />
                                                                        {/*       <label className="control-label"*/}
                                                                        {/*                                       htmlFor={`edit-answer${evalType}-${no}-4`}>매우*/}
                                                                        {/*그렇다</label>*/}
                                                                    </span>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })
                                    }
                                    {_.get(errors, 'answer') &&
                                    <small className="text-danger description">응답하지 않은 문항이 있습니다.</small>
                                    }

                                </div>
                                <div className={'submit_container'}>
                                    {
                                        id == 4 ?
                                            (openQuestion == false ?
                                                <button type={'button'} className={'eval_next_btn'}
                                                        onClick={() => setOpenQuestion(true)}>다음</button>
                                                :
                                                <>
                                                    <button type={'button'} className={'eval_prev_btn'}
                                                            onClick={() => setOpenQuestion(false)}>이전</button>
                                                    {!_.isEmpty(formDatas) &&
                                                    <button className={'result_button'} type={'button'}
                                                            onClick={() => setOpenValue(true)}>결과보기</button>
                                                    }
                                                    <button>{mode == 'm' ? '재제출' : '제출'}</button>
                                                    <EvalPopup dataType={id} openValue={openValue} setOpenValue={setOpenValue}
                                                           eval_name={eval_name} formDatas={formDatas} surveyType={surveyType}/>
                                                </>
                                            )
                                            :
                                            <>
                                                {!_.isEmpty(formDatas) &&
                                                    <button className={'result_button'} type={'button'}
                                                            onClick={() => setOpenValue(true)}>결과보기</button>
                                                }
                                                <button>{mode == 'm' ? '재제출' : '제출'}</button>
                                                <EvalPopup dataType={id} openValue={openValue} setOpenValue={setOpenValue}
                                                           eval_name={eval_name} formDatas={formDatas} surveyType={surveyType}/>
                                            </>
                                    }
                                 </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Evaluate;
