import React from 'react';
import Slider from 'react-slick';
import SlideItem from './SlideItem';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Popup from "reactjs-popup";

const settings = {
  dots: true,
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const ContentsSlide = ({setEventClick,programData={}}) => {
  const {audit_title,content_files} = programData;
  return (

      <Popup open closeOnDocumentClick position="right center">
        {/*<Popup open={open} closeOnDocumentClick  onClose={setEventPop} position="right center">*/}
        <div className="popup_overlay">
          <div className="popup_content auditPop">
            <div className="program_popup">
              <div className="modal_header">
                <h5 className="modal_title">
                  {audit_title} 첨부동영상
                </h5>
                <button onClick={()=> {setEventClick(false)}} type="button" className="overlay_close_x close">
                  {/*<button onClick={close} type="button" className="overlay_close_x close">*/}
                  <span className="element-invisible" aria-hidden="true">X</span>
                </button>
              </div>

              <div className="modal_body">
                <div className="dataslide">
                  <Slider {...settings}>

                    {
                      !_.isEmpty(content_files) &&
                      content_files.map((slide,idx) => <SlideItem key={idx} data={slide} />)
                    }
                  </Slider>
                </div>
              </div>

              <div className="course_overlay_footer course_pop_bottom">
                {/*<button className="btn btn_info form-submit">인쇄하기</button>*/}
                {/*<Link to="/member/mystudyprogram"></Link>*/}
                <button onClick={()=> {setEventClick (false);}} type="button" className="overlay_close">확인완료
                </button>
              </div>

            </div>
          </div>
        </div>
      </Popup>


  );
};

export default ContentsSlide;
