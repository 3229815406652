/** 프로그램/지원사업 신청 관련 graphql */
import gql from "graphql-tag";


//유저 지원 상태 목록
export const APPLY_STATUS_CATEGORIES_QUERY = gql`
query ApplyStatusCategories(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
){ 
  applyStatusCategories(
      limit: $limit,
      sort: $sort,
      start: $start,
      where: $where
  ){
    id
    title
    apply_status_category_title
  }
}
`
// 프로그램 신청 확인 //
export const WORKSHOP_APPLIES_QUERY = gql`
query WorkshopApplies(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
){
  workshopprogramApplies(
      limit: $limit, 
      sort: $sort,
      start: $start,
      where: $where
  ){
    id
    createdAt
    body
    apply_files{
      id
      name
      url
      formats
      mime
    }
    appied_program{
      id
      title
      learning_startdate
      learning_enddate
      program_address
    }
    apply_status{
      apply_status_category_title
      title
    }
    apply_user{
      id
      username
      name
      email
    }
    apply_text_bigo
    apply_complete
  }
}
`

// 프로그램 신청 확인 //
export const STUDYPROGRAM_APPLIES_QUERY = gql`
query StudyprogramApplies(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
){
  studyprogramApplies(
      limit: $limit, 
      sort: $sort,
      start: $start,
      where: $where
  ){
	id
    createdAt
    body
    apply_files{
      id
      name
      url
      formats
      mime
    }
    appied_program{
      id
      title
      learning_startdate
      learning_enddate
      program_address
    }
    apply_status{
      apply_status_category_title
      title
    }
    apply_user{
      id
      username
      name
      email
    }
    apply_text_bigo
  }
}
`

// 지원사업 신청 확인 //
export const SUPPORT_PROGRAM_APPLIES_QUERY = gql`
query SupportprogramApplies(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
){
  supportprogramApplies(
      limit: $limit, 
      sort: $sort,
      start: $start,
      where: $where
  ){
		id
    createdAt
    body
    apply_files{
      id
      name
      url
      formats
      mime
    }
    appied_program{
      id
      title
      learning_startdate
      learning_enddate
      program_address
    }
    apply_status{
      apply_status_category_title
      title
    }
    apply_user{
      id
      username
      name
      email
    }
    apply_text_bigo
  }
}
`

// 워크숍 설문조사결과값 //
export const SURVEYRESULT_QUERY = gql`
query SurveyResult(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
){ 
  surveyResults(
      limit: $limit,
      sort: $sort,
      start: $start,
      where: $where
  ){
    id
    author{id}
    workshopprogram{id}
    survey
    survey_complete_num
  }
}
`