/** 인증 관련 API */
import axios from 'axios';
import qs from 'qs';
import {public_url, apiUrl} from 'config';


/****** 유저 인증 관련 ******/
/* Get jwt 인증 토큰
parmas:
{
    "identifier": "test@obenef.com",
    "password": "Chao0116"
}
 */
export const getAuth = (params) => {
    delete axios.defaults.headers.common['Authorization'];
    return axios.post(`${apiUrl}/auth/local`, {...params});
}

/* 나의 유저 정보 */
export const getMy = () => axios.get(`${apiUrl}/users/me`);

/* 유저 생성 api */
export const createUser = (params) => axios.post(`${apiUrl}/auth/local/register`, {...params});


/* 비밀번호 찾기 메일 전송 api */
export const findPassword = (params) => axios.post(`${apiUrl}/auth/forgot-password`, {...params});
 
/* 비밀번호 변경 api */
export const resetPassword = (params) => axios.post(`${apiUrl}/auth/reset-password`, {...params});

/* 유저정보 변경 api(?) */
export const updateUser = (id,params) => axios.put(`${apiUrl}/users/${id}`, {...params});