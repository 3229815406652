import React, {useState, useEffect} from 'react';
import {useStateIfMounted} from "use-state-if-mounted";

export default function usePromise(promiseCreator, deps, initialState = null) {
    const [data, setData] = useStateIfMounted(initialState);
    const [loading, setLoading] = useStateIfMounted(true);
    const [error, setError] = useStateIfMounted(null);

    const process = async () => {
        setLoading(true);
        try {
            const {data} = await promiseCreator();
            setData(data);
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        process();
    }, deps);

    return {loading, data, error};
}
