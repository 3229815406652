import React from 'react'
import {Route, Redirect} from "react-router-dom";
import {useSelector} from 'react-redux';
import {CustomLoading} from "components/Custom";
import {Forbidden} from "components/Errors";

/** 유저 인증 Route */
const AuthRoute = ({redirect, useAlert = true, ...rest}) => {
    const {user, isLoggedIn, loading} = useSelector(({auth: {user, isLoggedIn, loading}}) => ({
        user,
        isLoggedIn,
        loading
    }));

    if (loading) return (<CustomLoading loading={loading}/>);
    if (!loading && !isLoggedIn) {
        useAlert && swal({
            title: "로그인이 필요한 서비스 입니다.", text: ' ',
            icon: "warning", timer: 2000, buttons: false,
        });
        return (<Redirect to={redirect && redirect || `/member/login`}/>);
    }

    //접근권한 없음.
    if (!loading && !user) {
        return (<Forbidden/>);
    }

    //회원
    return (<Route {...rest}/>);
}

export default AuthRoute;
