import {handleActions} from 'redux-actions';

import {
    AUTHORIZATION,  //인증 요청
    LOAD_REQUEST, LOAD_SUCCESS, LOAD_FAILURE,   // 나의 유저 정보
    LOGOUT, //로그아웃
} from 'store/actions/auth'
import update from "react-addons-update";
import config from "config";

const initialState = {
    isLoggedIn: false,
    valid: false,
    loading: true,  //true - 로딩 중 / false - 로딩 완료
    user: null,
    // user: {
    //     "confirmed": true,
    //     "blocked": false,
    //     "_id": "5fe15e07a1770312abd07405",
    //     "username": "tester",
    //     "email": "test@obenef.com",
    //     "provider": "local",
    //     "createdAt": "2020-12-22T02:46:31.380Z",
    //     "updatedAt": "2020-12-22T02:46:31.391Z",
    //     "__v": 0,
    //     "role": {
    //         "_id": "5fdc204f3d0fd00909f4e838",
    //         "name": "Authenticated",
    //         "description": "Default role given to authenticated user.",
    //         "type": "authenticated",
    //         "__v": 0,
    //         "id": "5fdc204f3d0fd00909f4e838"
    //     },
    //     "id": "5fe15e07a1770312abd07405"
    // },

    // 회원가입
    register: {
        user: null, //현재 등록 진행중인 유저
        //이메일 인증하기
        email: {
            isValid: false, //email validate
            isCode: false, //email code Send 여부
            isAuth: false,  //email 인증 check
            errorMsg: null,
        },
        naver: {
            email: null,    //이메일 require
            name: null, //이름 require
        },
        kakao: {
            email: null,    //이메일 require
            name: null, //이름 require
        },
        google: {
            email: null,    //이메일 require
            name: null, //이름 require
        }
    },
};

export default handleActions({
    /** 유저 데이터 Load */
    [LOAD_REQUEST]: (state, action) => ({...state, loading: true}),
    [LOAD_SUCCESS]: (state, action) => update(state, {
        isLoggedIn: {$set: !_.isEmpty(action.payload.id)},
        valid: {$set: true},
        user: {$set: action.payload},
        loading: {$set: false}
    }),
    [LOAD_FAILURE]: (state, action) => update(state, {
        isLoggedIn: {$set: false},
        valid: {$set: false},
        user: {$set: null},
        loading: {$set: false}
    }),

    /** 로그아웃 */
    [LOGOUT]: (state, action) => update(state, {
        isLoggedIn: {$set: false},
        valid: {$set: true},
        user: {$set: null},
        loading: {$set: false}
    }),

}, initialState);
