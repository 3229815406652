import React, {useEffect, useMemo, useState} from 'react';
import {Helmet} from "react-helmet";
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import * as actions from 'store/actions/content';
import moment from 'moment/dist/moment';
import {useTranslation} from 'react-i18next'

//components
import {CustomPagination} from 'components/Custom'
import BreadCrumb from 'components/BreadCrumb';
import SubLeftMenu from 'components/SubLeftMenu';
import {BoardHeader, BoardList} from 'components/Board';
import BoardWrite from 'components/Write/BoardWrite';

import qs from 'qs';
import route from 'route';
const TYPE = `board-news`;
const News = () => {
  const dispatch = useDispatch();
  const {search} = useLocation();
  const {page: _page = 0, perPage: _perPage = 10} = qs.parse(search, {ignoreQueryPrefix: true});
  const {t, i18n} = useTranslation()
  const {list = [], totalCount = 0, loading} = useSelector(({content}) => ({...content}));
  const [perPage, setPerPage] = useState(_perPage && +_perPage || 10)
  const [page, setPage] = useState(_page && +_page || 0)
  const [filter, setFilter] = useState({});
  const {user,isLoggedIn} = useSelector(({auth}) => ({...auth}))
  const [params, setParams] = useState({_limit: perPage, _sort: `createdAt:DESC`, _start: page * perPage})
  const [reload, setReload] = useState(false);    //open 상태인가?
  const columns = useMemo(() => [
    {
      Header: "번호", accessor: 'id', className: `text-center`, minWidth: '50px',
      Cell: ({row, idx, totalCount}) => {
        const index = totalCount - (page * perPage) - idx;
        return index > 0 && (<div>{index}</div>) || '-'
      }
    },
    {
      Header: "제목",
      accessor: 'title',
      className: `text-center`,
      width: 'calc(100% - 190px)',
      padding: '0 15px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textAlign: 'left',
      textOverflow: 'ellipsis'
    },
    {
      Header: "작성일", accessor: 'createdAt', className: `text-center`, minWidth: '170px',
      Cell: ({row: {createdAt = new Date}, idx, totalCount}) => (<>{moment(createdAt).format(`YYYY-MM-DD`)}</>)
    },
    {Header: "조회수", accessor: 'view_count', className: `text-center`, minWidth: '80px'},
  ], [perPage, page])

  const pageCount = Math.ceil(totalCount / perPage);

  const updateParams = (data) => {
    setParams({...params, ...data});
  };

  const handleChange = ({keyword = "", type = ""}) => {

    if (!keyword || !type) {
      setFilter({})
      setParams ({_limit: 10, _sort: `createdAt:DESC`, _start: 0})
      setPage(0)
      return;
    }

    const filters = type.split(',') || [];
    const _filter = {};
    _.forEach(filters, (field, idx) => {
      _filter[`_where[_or][${idx}][${field}]`] = keyword
    })
    setFilter({..._filter})
  }

  const handleReset =() =>{
    setParams ({_limit: 10, _sort: `createdAt:DESC`, _start: 0})
    setPage(0)
    setFilter({})
  }

  //page 이동
  useEffect(() => {
    const {_start, _limit} = params;
    if (_limit !== perPage || _start !== page * perPage) {
      updateParams({...params, _start: page * perPage, _limit: perPage})
    }
  }, [page, perPage])

  useEffect(() => {
    dispatch(actions.listRequest({type: TYPE, ...params, ...filter}))
  }, [params, filter, reload])

  return (
      <div className="onair content introduction">
        <Helmet title="센터 소식"/>
        <BreadCrumb list={route.depth5}/>
        <div className="sub">
          <div className="wrap">
            <SubLeftMenu
                title={route.depth5.title}
                lnbList={route.depth5.list}/>
            <div className="sub__content introduction_content">
              <h1 className="sub__title introduction_title">
                <span className="">센터 소식</span>
              </h1>
              {/*<p className="sub__description">다양한 소식 및 안내사항을 확인해주세요</p>*/}
              <div className="board">
                <BoardHeader totalCount={totalCount}
                             title={'센터소식'}
                             onReset={handleReset}
                             onChange={handleChange}/>
                <BoardList
                    totalCount={totalCount}
                    loading={loading}
                    data={list}
                    user={user}
                    columns={columns}/>
                <CustomPagination
                    pageSize={perPage}
                    pageIndex={page}
                    pageCount={pageCount}
                    onPageChange={({selected}) => setPage(selected)}/>
                <div className={'submit_container'}>
                  {
                    !_.isEmpty(_.get(user,'role')) &&
                    _.get(user,'role.id') == '5fed5533258df16ef195ae04' &&
                    <BoardWrite
                        setReload={setReload}
                        reload={reload}
                        type={TYPE}
                        trigger={<button>글쓰기</button>}
                        title={"센터 소식"}
                        user={user}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default News;