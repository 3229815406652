import React from 'react';
import {Link, useHistory} from 'react-router-dom'

const NotFound = () => {
    const history = useHistory();
    return (
        <div className="container">
            <div className="nofind_error">
                <div className="description">
                    <div className="title_error">
                        <p className="space">
                            <img src="/assets/images/404.png" alt="404 error"/>
                        </p>
                        <p>404</p>
                    </div>
                    <div className="title">요청하신 페이지를 찾을 수 없습니다.</div>
                </div>
                <div className="sub_description">
                    <div className="sub_1">페이지의 주소가 변경되었거나 삭제되어 페이지를 찾을 수 없습니다.</div>
                    <div className="sub_2">입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다</div>
                    <div className="sub_3">서비스 이용에 불편을 드려 죄송합니다.</div>
                </div>
                <div className="link">
                    <button onClick={() => history.goBack()} className="before_btn">이전페이지</button>
                    <Link className="home_btn" to="/">홈으로</Link>
                </div>
            </div>
        </div>
    );
}

export default NotFound;
