//검수 신청서 작성
import React, {useEffect, useState, useCallback} from 'react';
import {useForm} from "react-hook-form/dist/index.ie11";
import moment from 'moment/dist/moment';
import * as api from 'lib/content';
import route from 'route';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';
import Quill from "../../Editor/Quill";

const defaultValues = {
    appied_program: {
        _id:'',
    },
    attached_files:[]
};
const WriteForm = ({
                       onSubmit,
                       data,
                       mode = '',
                       type,
                       handleCancel,
                       cancelSubmit,
                       apply_files,
                       reload,
                       bigo,
                       setReload,
                       user = ''
                   }) => {
    const { register, handleSubmit, watch, errors, setValue,  clearErrors } = useForm({defaultValues});
    const [contents,setContent] = useState([]);
    const [contents2,setContent2] = useState([]);
    const [contents3,setContent3] = useState([]);
    const [fileState,setFileState] = useState(false);
    let fileCourse = null;

    //1번 영상파일 용량체크
    useEffect(async ()=>{
        fileCourse = await Object.values(watch('content_files'))[0];
        try{
            // 2147483648 / 83648 임시 설정
            if(fileCourse.size < 2147483648) {
                setContent(Object.values(watch('content_files')));
            }else {
                swal({
                    title: `게시글 등록 실패`, text: "용량이 2기가 이상입니다.",
                    icon: "warning", timer: 3000, buttons: false,
                });
                setValue("content_files","")
            }
        }
        catch(e){
            console.log(e)
        }finally {
        }

    },[watch('content_files')]);

    //2번 영상파일 용량체크
    useEffect(async ()=>{
        fileCourse = await Object.values(watch('content_files2'))[0];
        try{
            // 2147483648 / 83648 임시 설정
            if(fileCourse.size < 2147483648) {
                setContent2(Object.values(watch('content_files2')));
            }else {
                swal({
                    title: `게시글 등록 실패`, text: "용량이 2기가 이상입니다.",
                    icon: "warning", timer: 3000, buttons: false,
                });
                setValue("content_files2","")
            }
        }
        catch(e){
            console.log(e)
        }finally {
        }

    },[watch('content_files2')]);

    //3번 영상파일 용량체크
    useEffect(async ()=>{
        fileCourse = await Object.values(watch('content_files3'))[0];
        try{
            // 2147483648 / 83648 임시 설정
            if(fileCourse.size < 2147483648) {
                setContent3(Object.values(watch('content_files3')));
            }else {
                swal({
                    title: `게시글 등록 실패`, text: "용량이 2기가 이상입니다.",
                    icon: "warning", timer: 3000, buttons: false,
                });
                setValue("content_files3","")
            }
        }
        catch(e){
            console.log(e)
        }finally {
        }

    },[watch('content_files3')]);


    //차시 음수값 안나오게
    useEffect(async ()=>{
        fileCourse = await Object.values(watch('num'))[0];
        console.log(fileCourse);
        try{
            if(!_.isEmpty(fileCourse)){
                if((fileCourse == "-") || (fileCourse == 0)) {
                    swal({
                        title: `차시 설정 오류`, text: "차시는 0보다 커야합니다.",
                        icon: "warning", timer: 3000, buttons: false,
                    });
                    setValue("num","")
                }
            }
        }
        catch(e){
            console.log(e)
        }finally {
        }

    },[watch('num')]);


    useEffect(() => {
        if(( (!_.isEmpty(watch('content_files3')) || !_.isEmpty(watch('content_files'))) ||  !_.isEmpty(watch('content_files2')))  == false){
            setFileState(false);
        }else{
            setFileState(true);
        }
    },[watch('content_files3'),watch('content_files2'),watch('content_files')])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className={'audit_top'}>
                    <h3 className={'write_title'}>검수 강좌 기본 정보 수정 / 등록</h3>
                    <span className={'write_info'}>검수할 강좌의 기본 정보를 등록합니다. (수업계획서가 여러개 일 경우 압축하여 올려주시길 바랍니다.)</span>
                </div>
                <div className={'audit_middle'}>
                    <div className={'insert_Form'}>
                        <span>강좌명</span>
                        <input type={'text'} ref={register({
                            required:'강좌명은 필수입니다.'
                        })} name={'audit_title'} />
                        {errors.audit_title && (
                            <small className="text-danger">{errors.audit_title.message}</small>
                        )}
                    </div>
                    <div className={'insert_Form'}>
                        <span>개발기관</span>
                        <input type={'text' }ref={register({
                            required:'개발기관은 필수입니다.'
                        })} name={'audit_orgs'} />
                        {errors.audit_orgs && (
                            <small className="text-danger">{errors.audit_orgs.message}</small>
                        )}
                    </div>
                    <div className={'insert_Form'}>
                        <span>개발연도</span>
                        <div className={'calander_select'}>
                            {/*<input type={'text'}/>
                            <input type={'text'} className={'select_date'}/>*/}
                            <select ref={register({
                                required:'년도는 필수값입니다.'
                            })} name={'year'}>
                                <option value={2020}>
                                    2020년도
                                </option>
                                <option value={2021}>
                                    2021년도
                                </option>
                                <option value={2022}>
                                    2022년도
                                </option>
                                <option value={2023}>
                                    2023년도
                                </option>
                                <option value={2024}>
                                    2024년도
                                </option>
                            </select>
                            {errors.year && (
                                <small className="text-danger">{errors.year.message}</small>
                            )}
                            {/*<select ref={register()} name={'month'} className={'select_date'}>
                                <option value={1}>
                                    01월
                                </option>
                                <option value={2}>
                                    02월
                                </option>
                                <option value={3}>
                                    03월
                                </option>
                                <option value={4}>
                                    04월
                                </option>
                                <option value={5}>
                                    05월
                                </option>
                                <option value={6}>
                                    06월
                                </option>
                                <option value={7}>
                                    07월
                                </option>
                                <option value={8}>
                                    08월
                                </option>
                                <option value={9}>
                                    09월
                                </option>
                                <option value={10}>
                                    10월
                                </option>
                                <option value={11}>
                                    11월
                                </option>
                                <option value={12}>
                                    12월
                                </option>
                            </select>*/}
                        </div>
                    </div>
                    <div className={'insert_Form'}>
                        <span>교수자</span>
                        <input type={'text'} ref={register({
                            required: '교수자는 필수입니다.'
                        })} name={'audit_props'} />
                        {errors.audit_props && (
                            <small className="text-danger">{errors.audit_props.message}</small>
                        )}
                    </div>
                    <div className={'insert_Form'}>
                        <span>총 차시</span>
                        <input type={'number'} ref={register({
                            required: '차시는 필수입니다.'
                        })} name={'num'} />
                        {errors.num && (
                            <small className="text-danger">{errors.num.message}</small>
                        )}
                    </div>
                </div>

                <div className={'audit_bottom'}>
                    <span>
                        수업계획서
                    </span>
                    {/*// 1. 사용자에게 파일 첨부를 받는다*/}
                    <div className="input_container">
                        {/*<input type="hidden" name="id" value={_.get(data,'id')}  ref={register({ required: true })} />*/}
                        <input type="file" multiple name="files" ref={
                            register(
                                {required:'수업계획서는 필수입니다.'}
                            )
                        }  />
                        {errors.files && (
                            <small className="text-danger">{errors.files.message}</small>
                        )}
                        {(!_.isEmpty(_.get(data,'attached_file',[])) && mode=='m') && <div className="files_container">
                            <span className="files_title">업로드 된 파일</span>
                            {_.get(data,'attached_file').map((item,index)=><span className="file_once" key={index}><span className="files_item">{item.name}</span> <span className="files_delete" onClick={file_del('upload/files',item.id,reload,setReload)}>삭제</span></span>)}

                        </div>}
                    </div>
                </div>

                <div className={'audit_bottom'}>
                    <div className="fileTitleNew">
                            콘텐츠업로드
                    </div>
                    {/*// 1. 사용자에게 파일 첨부를 받는다*/}
                    <div className="input_container_bottom">
                        <input className="input_file"
                            type="file"  name="content_files" accept={'.mp4,.avi,.mkv'}
                               ref={
                                    register({
                                    //      validate:(data)=>{
                                    //         console.log(data)
                                    //         if(data.length>3){
                                    //             console.log('데이터가 3개 이상')
                                    //             return '파일은 3개까지 업로드가 가능합니다.'
                                    //         }
                                    // }
                                        validate:()=>{
                                            console.log(fileState)
                                            if(fileState == false){
                                                return '파일을 하나 이상 올려주시기바랍니다.'
                                            }
                                        }
                                    // required:'콘텐츠 파일은 필수입니다.'
                            })
                        }  />

                         <input type="file"  className="input_file" name="content_files2" accept={'.mp4,.avi,.mkv'} ref={register(
                             {
                                 validate:()=>{
                                     if(fileState == false){
                                         return '파일을 하나 이상 올려주시기바랍니다.'
                                     }
                                 }
                             }


                         )}  />
                         <input type="file"  className="input_file" name="content_files3" accept={'.mp4,.avi,.mkv'} ref={register(
                             {
                                 validate:()=>{
                                     if(fileState == false){
                                         return '파일을 하나 이상 올려주시기바랍니다.'
                                     }
                                 }
                             }

                         )} />
                        {
                            fileState == false ?
                            <>
                            {errors.content_files && (
                                    <small className="text-danger">{errors.content_files.message}</small>
                                )}
                            </> : <></>
                        }


                        {(!_.isEmpty(_.get(data,'attached_file',[])) && mode=='m') && <div className="files_container">
                            <span className="files_title">업로드 된 파일</span>
                            {_.get(data,'attached_file').map((item,index)=><div className="file_once" key={index}><div className="files_item">{item.name}</div> <span className="files_delete" onClick={file_del('upload/files',item.id,reload,setReload)}>삭제</span></div>)}
                        </div>}
                        <small>파일은 최대 3개까지 업로드 가능합니다.</small>
                    </div>
                    {
                        ((!_.isEmpty(contents2)) ||  (!_.isEmpty(contents3)) || (!_.isEmpty(contents))) &&
                            <>
                            <div className="files_container">
                                <span className="files_title">선택한 파일</span>
                                { (!_.isEmpty(contents)) && <>
                                    {contents.map((item,index)=><div className="file_once" key={index}><div className="files_item">{item.name}</div>
                                    </div>)}
                                </>
                                }
                                { (!_.isEmpty(contents2)) && <>
                                    {contents2.map((item,index)=><div className="file_once" key={index}><div className="files_item">{item.name}</div>
                                    </div>)}
                                </>
                                }
                                { (!_.isEmpty(contents3)) && <>
                                    {contents3.map((item,index)=><div className="file_once" key={index}><div className="files_item">{item.name}</div>
                                    </div>)}
                                </>
                                }
                            </div>
                          </>
                    }
                </div>
            </div>
            <div className={'audit_footer'}>
                {/*<button className={'list_button'} type={'button'}>목록</button>*/}
                <button className={'submit_button'}>등록</button>
            </div>
        </form>
    )
}


export default WriteForm;
