import React from 'react';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

const Type = () => {
  return (
    <div>
    <Helmet title="회원가입"/>
        <Link className="close" title="홈페이지로 이동" to="/"><span className="invisible-btn">홈페이지로 이동</span></Link>
            <h2 className="page_title">회원가입</h2>
            <div className="join_wrap">
                <div className="join_info">간단한 정보 입력만으로 간편하게 회원 가입 하세요!</div>
                <div className="join_desc">이메일 가입</div>
                <div className="btn_join_go">
                    <Link title="이메일로 회원가입" to={{pathname: '/member/register/information'}}>
                        <i className="xi-mail-o"></i>이메일로 가입하기
                    </Link>
                </div>
                <br/>
                {/*<div className="join_desc">소셜미디어 계정 가입</div>
                <div className="btn_join_go">
                    <button title="새창열어 네이버아이디로 회원가입하기" className="naver" style={{cursor: 'pointer'}}>
                            <i className="xi-naver"/>네이버 가입하기</button>
                </div>
                <div className="btn_join_go"><button title="새창열어 카카오  아이디로 회원가입하기"  className="kakao" style={{cursor: 'pointer'}}>
                            <i className="xi-kakaotalk"/>카카오 가입하기</button>
  </div>*/}
                {/*<div className="btn_join_go">*/}
                {/*    <Link to={{*/}
                {/*        pathname: '/members/register/information.do',*/}
                {/*        type: 'facebook'*/}
                {/*    }} className="facebook">*/}
                {/*        <i className="xi-facebook"></i>페이스북 가입하기*/}
                {/*    </Link>*/}
                {/*</div>*/}
                {/*<div className="btn_join_go">
                <button title="새창열어 구글+ 아이디로 회원가입하기" className="google" style={{cursor: 'pointer'}}>
                        <i className="xi-google"/>구글 가입하기</button>
</div>*/}
            </div>
    </div>
  );
};

export default Type;
