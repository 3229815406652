// 마이콘텐츠
import React, {useEffect, useMemo, useState, useCallback} from 'react';
import BreadCrumb from 'components/BreadCrumb';
import SubLeftMenu from 'components/SubLeftMenu';
import * as actions from 'store/actions/content';
import {useSelector, useDispatch} from 'react-redux';
import {Helmet} from "react-helmet";
import {useTranslation} from 'react-i18next'
import moment from 'moment/dist/moment';
import * as api from 'lib/content';
import {apiUrl} from 'config';
import {useRouteMatch, generatePath, Link} from 'react-router-dom';
import {useLocation} from "react-router";
import route from 'route';
import {BoardHeader, BoardList} from 'components/Board';
import {CustomPagination} from 'components/Custom'
import ProgramItem from 'components/MyProgramList/ProgramItem';
import ProgramItemD from 'components/MyProgramList_D/ProgramItemd';
import MyContentPopup from 'components/Popup/MyContentPopup';
import useWindowSize from 'utils/useWindowSize';
import Popup from "reactjs-popup";
import MyEvaluationDetail from "./MyEvaluationDetail";

// customapi 관련
import axios from 'axios';
import usePromise from 'utils/usePromise'

const TYPE = 'evaluates';
const MyEvaluation = ({path, headerHidden, title = '', description = '', active=''}) => {

    const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}));
    const {id = '', username = ''} = user;
    //개별 목록 정보
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation()
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(0)
    const [filter, setFilter] = useState({});
    const [dataLoading, setDataLoading] = useState(true);
    const {list = [], totalCount = 0, loading} = useSelector(({content}) => ({...content}));
    const [params, setParams] = useState({
        type: 'evaluates',
        _limit: perPage,
        _start: page * perPage,
        _sort: `createdAt:DESC`,
        email:user.email
    });
    const pageCount = Math.ceil(totalCount / perPage);
    const columns = useMemo(() => [
        {
            Header: "순번",
            accessor: 'space_resource.title',
            className: `text-center`,
            width: '200px',
            padding: '0 15px',
            // whiteSpace: 'nowrap',
            // overflow: 'hidden',
            // textOverflow: 'ellipsis',
            Cell: ({row: {space_resource: {title = '-'} = {}}, idx, totalCount}) => (<>{totalCount - idx}</>)
        },
        {
            Header: "평가지",
            accessor: 'evaluate_type',
            className: `text-center`,
            width:'100%',
            minWidth: '200px',
            Cell: ({row: {evaluate_type = ''}, idx}) => {
                let value =''
                switch (evaluate_type){
                    case "sueopjagajindanchekeuriseuteu":
                        return "수업역량 진단평가";
                    case "grasha-Riechmanntichingseutailgeomsa":
                        return "티칭스타일 진단평가";
                    case "yeokryangjindanpyeongga":
                        return "교수역량 진단평가";
                    case "onrainsueopyeokryangjindandogu":
                        return "온라인수업 역량 진단도구";
                }
                return (<div className="small">{evaluate_type} </div>)
            },
        },{
            Header: "평가일",
            accessor: 'createdAt',
            className: `text-center`,
            width:'100%',
            maxWidth: '200px',
            Cell: ({row: {createdAt = ''}, idx}) => {
                return (<div className="small">{moment(createdAt).format('YYYY-MM-DD')}</div>)
            },
        },
    ], [perPage, page])

    const handleChange = ({keyword = "", type = ""}) => {
        const {id = '', username = ''} = user;
        if (!keyword || !type) {
            setFilter({})
            setParams({_limit: 10, _sort: `schedule_from:DESC`, _start: 0, schedule_author: id})
            setPage(0)
            return;
        }

        const filters = type.split(',') || [];
        const _filter = {};
        _.forEach(filters, (field, idx) => {
            _filter[`_where[_or][${idx}][${field}]`] = keyword
        })
        setFilter({..._filter})
    }
    const handleReset = () => {
        const {id = '', username = ''} = user;
        setParams({
            _limit: 10,
            _sort: `schedule_from:DESC`,
            _start: 0,
            schedule_author: id
        })
        setPage(0)
        setPerPage(10)
        setFilter({})
    }

    useEffect(()=>{
        list && console.log(list)
        user && console.log(user)
    },[list])

    //page 이동
    useEffect(() => {
        const {_start, _limit} = params;
        if ((_limit !== perPage || _start !== page * perPage)) {
            updateParams({...params, _start: page * perPage, _limit: perPage})
        }
    }, [page, perPage])

    useEffect(() => {
        dispatch(actions.listRequest({
            type: TYPE,
            ...params,
            ...filter
        }))
    }, [params, filter])
    useEffect(()=>{
        list && console.log(list)
    },[list]);

    console.log(user);
    return (
        <div className="myProgram">
            <div className="onair content introduction">
                <Helmet title="마이페이지 > 나의역량진단평가"/>
                <BreadCrumb list={route.depth6}/>
                <div className="sub">
                    <div className="wrap">
                        <SubLeftMenu
                            title={route.depth6.title}
                            lnbList={route.depth6.list}
                        />
                        <div className="sub__content">
                            <h1 className="sub__title">
                                <span className="">나의역량진단평가</span>
                            </h1>
                            <div className="board">
                                <div className="userNameBlock">
                                    <div className="userNameBox">
                                        <span className="userName"><span className="nameBar"></span>{_.get(user,"nickname")}</span>님의 역량진단평가 결과입니다.
                                    </div>
                                    <div className="goEval">
                                        <Link to={`/resource/evaluate`}>
                                            역량진단평가 바로가기
                                        </Link>
                                    </div>
                                </div>
                                <BoardList
                                    isLink={false}
                                    totalCount={totalCount}
                                    loading={loading}
                                    data={list}
                                    columns={columns}/>
                                <CustomPagination
                                    pageSize={perPage}
                                    pageIndex={page}
                                    pageCount={pageCount}
                                    onPageChange={({selected}) => setPage(selected)}
                                />


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
};

export default MyEvaluation;
