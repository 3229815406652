import React, {useEffect, useMemo, useState} from 'react';
import {Helmet} from "react-helmet";
import {useForm} from "react-hook-form/dist/index.ie11";
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import * as actions from 'store/actions/content';
import moment from 'moment/dist/moment';
import {useTranslation} from 'react-i18next'
import Popup from "reactjs-popup";
import {apiUrl} from "config";
import * as api from 'lib/content';
//components
import {CustomPagination} from 'components/Custom'
import {BoardHeader_e, BoardList_e} from 'components/Board_e';
import BoardWrite from 'components/Write/BoardWrite_c';

import route from 'route';

import WorkshopSurveyData from './WorkshopSurveyData'

const SurveyDataPopup = ({trigger,data}) => {
    const [open, setOpen] = useState(false);    //open 상태인가?
    useEffect(() =>{
        data && console.log(data,1111)
    },[data])

    return (
          <Popup trigger={trigger}
                 data={data}
                 position="right center"
                 onOpen={() => setOpen(true)}
                 onClose={() => {
                     //setLoading(true)
                     setOpen(false)
                 }}
                 modal
                 closeOnDocumentClick={false}>

              {popupClose => {
                    return (
                        <div className="popup_overlay">
                            <div className="popup_content worksurvey_height">
                                <div className="program_popup">
                                    <div className="modal_header">
                                        <h5 className="modal_title">
                                            설문조사 결과보기
                                        </h5>
                                        <button onClick={popupClose} type="button" className="overlay_close_x close">
                                            {/*<button onClick={close} type="button" className="overlay_close_x close">*/}
                                            <span className="element-invisible" aria-hidden="true">X</span>
                                        </button>
                                    </div>

                                    <div className="modal_body worksurvey">
                                        <WorkshopSurveyData data={data}/>
                                    </div>

                                    <div className="course_overlay_footer">
                                    </div>

                                </div>
                            </div>
                        </div>
                    )
                }}
          </Popup>
    )
}

export default SurveyDataPopup;
