import React from 'react';
import GalleryItem from './GalleryItem';

const CklDataList = ({ list = [] ,totalCount=0}) => {
  return (
        (totalCount != 0)&&list.map(item => <GalleryItem key={item.id} data={item} />)||(<div className={`text-center p-4`}>콘텐츠가 존재하지 않습니다.</div>)
  );
};
 
export default CklDataList;
