import React, {useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useSelector} from "react-redux";
import {useCookies} from 'react-cookie'

const quicklist = [
    // {
    //     icon: '/assets/images/quick_icon_1.png',
    //     title: '회원가입',
    //     url: '/member/register'
    // },
    {
        icon: '/assets/images/quick_register.png',
        title: '회원가입',
        url: '/member/register/information'
        /*url: 'https://eclass-lms.seoyeong.ac.kr/member/ext_login',*/
        /*type:'a'*/
    },
    {
        icon: '/assets/images/quick_gjudec.png',
        title: 'LMS',
        url: 'https://gnue-lms.gesturecloud.io/',
        type:'a'
        //url: '/program/supportprogram'
    },
    // {
    //   icon: '/assets/images/quick_icon_4.png',
    //   title: '공간/장비예약',
    //   url: ''
    // },
    /*{
        icon: '/assets/images/quick_studio.png',
        title: '스튜디오예약',
        url: '/resource/studio'
        //url: '/about/introduction'
    },*/
    {
        icon: '/assets/images/quick_content.png',
        title: '콘텐츠품질관리',
        //url: '#'
        url: '/content/auditcontent'

    },
    {
        icon: '/assets/images/quick_howto.png',
        title: 'Q&A',
        url: '/community/qna'
    }
];

const Quick = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['isQuickMenu']);
    const location = useLocation()
    const {user, isLoggedIn, loading:userLoading} = useSelector(({auth}) => ({...auth}));
    const [toggle, setToggle] = useState((
        _.isUndefined(cookies.isQuickMenu) || cookies.isQuickMenu == 'true'
    ) && true || false);

    useEffect(() => {
        _.isUndefined(cookies.isQuickMenu) && setCookie('isQuickMenu', false, {path: '/'});
    }, []);

    const handleToggle = (isToggle) => {
        setCookie('isQuickMenu', isToggle, {path: '/'});
        setToggle(isToggle);
    }

    return (
        <div className={`quick ${toggle && 'active'}`}>
            {/*<button className="quick__toggle" onClick={() => handleToggle(!toggle)}>
                <div>{toggle}</div>
                <img src={'/assets/images/quick-close.png'} alt="퀵메뉴 열고 닫기"/>
            </button>*/}

            <div className="quick__content">
                <div className="quick__header">
                    {/*전남콘텐츠코리아랩<br/>*/}
                    {/*오신 것 환영합니다*/}
                    {/*<img src={'/assets/images/main-quick-1.png'} alt="퀵메뉴 로고"/>*/}
                </div>
                <h2 className="quick__menu"></h2>
                <div className="quick__body">
                    {/** 회원가입 링크 */}
                    {/*{(!user || !isLoggedIn) && (*/}
                    {/*    <Link to={`/member/register/information`}>*/}
                    {/*        <span><img src={`/assets/images/quick_icon_1.png`} alt=""/></span> 회원가입*/}
                    {/*    </Link>*/}
                    {/*)}*/}

                    {quicklist.map(item => {

                            if(_.isEmpty(_.get(item,'type'))){
                                if (item.title == '콘텐츠품질관리'){
                                    if(_.get(user,'role.id') != '5fed5533258df16ef195ae04' && _.get(user,'role.id') != '602a265ab2b9050bdac24bcd'){
                                        return(
                                            <Link className='quick_hover' onClick={()=>{
                                                swal({
                                                    title: `권한이 없습니다.`, text: '해당 게시판을 이용할 수 있는 권한이 없습니다.',
                                                    icon: "warning", timer: 3000, buttons: false,
                                                })}}
                                                  key={item.title} >
                                                <div className='quick_img'><img src={item.icon} alt=""/>
                                                    <span className='quick_txt'>{item.title}</span>
                                                </div>
                                            </Link>
                                        )
                                    }
                                    else{
                                        return (
                                            <Link className='quick_hover' to={item.url} key={item.title} >
                                                <div className='quick_img'><img src={item.icon} alt=""/>
                                                    <span className='quick_txt'>{item.title}</span>
                                                </div>
                                            </Link>
                                        )
                                    }
                                }
                                else{
                                    return (
                                        <Link className='quick_hover' to={item.url} key={item.title} >
                                            <div className='quick_img'><img src={item.icon} alt=""/>
                                                <span className='quick_txt'>{item.title}</span>
                                            </div>
                                        </Link>
                                    )
                                }


                            }
                            else{
                                return(
                                    <a className='quick_hover' href={item.url} key={item.title} target="_blank">
                                        <div className='quick_img'><img src={item.icon} alt=""/>
                                        <span className='quick_txt'>{item.title}</span>
                                        </div>
                                    </a>
                                )
                            }
                    })}
                </div>
                <div className="quick__foot">
                    <a href='#'>
                    <img src='/assets/images/quick_allow.png' alt=""/>
                    <span>TOP</span>
                    </a>
                        {/*<h2>
                        <img src={'/assets/images/quick_icon_7.png'} alt="문의 연락처"/> 문의
                    </h2>
                    <h3>
                        062-530-5075 ,2595
                    </h3>
                    <br/>
                    <h4>문의시간</h4>
                    <p>
                        월~금 09시~18시<br/>
                        주말 및 공휴일 휴무
                    </p>*/}
                </div>
            </div>
        </div>
    );
};

export default Quick;
