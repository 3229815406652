/** 행사 후기 */
import React, {useEffect, useMemo, useState} from 'react';
import {Helmet} from "react-helmet";
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import * as actions from 'store/actions/content';
import moment from 'moment/dist/moment';
import {useTranslation} from 'react-i18next'
import {useForm} from "react-hook-form/dist/index.ie11";
import { Link } from 'react-router-dom';

//components
import {CustomPagination} from 'components/Custom'
import BreadCrumb from 'components/BreadCrumb';
import SubLeftMenu from 'components/SubLeftMenu';
import {BoardHeader, BoardList} from 'components/Board';
import BoardWrite from 'components/Write/BoardWrite';
import EvalPopup from 'components/Popup/EvalFinishPopup';

import qs from 'qs';
import route from 'route';
import {survey1,survey2,survey3} from 'surveyData';

const EvaluateIntro = () => {
    const [surveyData,setSurveyData] = useState(survey1);
    const [evalType,setEvalType] = useState(1);
    const { register, handleSubmit, watch, errors, setValue,  clearErrors } = useForm();
    useEffect(()=>{
        if(evalType==1){
            setSurveyData(survey1)
        }
        else if(evalType==2){
            setSurveyData(survey2)
        }
        else if(evalType==3){
            setSurveyData(survey3)
        }

    },[evalType])
    return (
        <div className="onair content introduction">
            <Helmet title="역량진단평가"/>
            <BreadCrumb list={route.depth4}/>
            <div className="sub">
                <div className="wrap">
                    <SubLeftMenu
                        title={route.depth4.title}
                        lnbList={route.depth4.list}/>
                    <div className="sub__content introduction_content">
                        <h1 className="sub__title introduction_title">
                            <span className="">역량 진단평가</span>
                        </h1>
                        <h1 className={'intro_sub_text'}>진단하고자 하는 <span>평가항목</span>을 클릭해주세요.</h1>
                        <div className={'intro__collage intro_evaluate_box'}>
                            <Link to={'/resource/evaluate/start/4'} className={'collage-item intro_collage_item'}>
                                <div className="thumb intro_thumb intro_box_size">
                                    <div className={'arrows4'}></div>
                                    <span className={'intro_bold'}>교수역량</span><br/>
                                    <span className={'intro_bold'}>진단평가</span><br/>
                                    <button>평가하기</button>
                                    {/*<img className={'intro_img'} src="/assets/images/off_arrow.png" alt=""/>*/}
                                </div>
                            </Link>
                            <Link to={'/resource/evaluate/start/2'} className={'collage-item intro_collage_item'}>
                                <div className="thumb intro_thumb intro_box_size">
                                    <div className={'arrows2'}></div>
                                    <span className={'intro_bold'}>티칭스타일</span><br/>
                                    <span className={'intro_bold'}>진단평가</span><br/>
                                    <button>검사하기</button>
                                    {/*<img className={'intro_img'} src="/assets/images/off_arrow.png" alt=""/>*/}
                                </div>
                            </Link>
                            <Link to={'/resource/evaluate/start/3'} className={'collage-item intro_collage_item'}>
                                <div className="thumb intro_thumb intro_box_size">
                                    <div className={'arrows3'}></div>
                                    <span className={'intro_bold'}>수업역량</span><br/>
                                    <span className={'intro_bold'}>진단평가</span><br/>
                                    <button>평가하기</button>
                                    {/*<img className={'intro_img'} src="/assets/images/off_arrow.png" alt=""/>*/}
                                </div>
                            </Link>

                            <Link to={'/resource/evaluate/start/1'} className={'collage-item intro_collage_item'}>
                                <div className="thumb intro_thumb intro_box_size">
                                    <div className={'arrows1'}></div>
                                    <span className={'intro_bold'}>온라인수업 역량</span><br/>
                                    <span className={'intro_bold'}>진단도구</span><br/>
                                    <button>진단하기</button>
                                    {/*<img className={'intro_img'} src="/assets/images/off_arrow.png" alt=""/>*/}
                                </div>
                            </Link>
                        </div>
                        {/*<div className={'intro__collage intro_evaluate_box'}>*/}

                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EvaluateIntro;
