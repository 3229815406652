import React from 'react';
import {Route, Switch} from 'react-router-dom';

import ResourceSpace from './Space';
import ResourceDevice from './Device';
import {NotFound} from 'components/Errors';
import WorkShop from './WorkShop';
import Consulting from './Consulting';
import ConsultingDetail from './ConsultingDetail';
import Space from './Space';
import Space2 from './Space2';
import Space3 from './Space3';
import YoutubeProgram from './YoutubeProgram';
import YoutubeProgramDetail from './YoutubeProgramDetail';
import Evaluate from './Evaluate';
import Result from './Result';
import EvaluateIntro from './EvaluateIntro';
import EvaluateStart from './EvaluateStart';
import WorkShopDetail from './WorkShopDetail';
import EvaluateNewSurvey from './EvaluateNewSurvey';

const Resource = ({match}) => {
    return (
        <Switch>
            <Route path={`${match.path}/space`} render={() => <ResourceSpace/>}/>
            <Route path={`${match.path}/device`} render={() => <ResourceDevice/>}/>
            <Route path={`${match.path}/competencyworkshop/:id`} render={() => <WorkShopDetail/>}/>
            <Route path={`${match.path}/competencyworkshop`} render={() => <WorkShop/>}/>
            <Route path={`${match.path}/consulting/:id`} render={() => <ConsultingDetail/>}/>
            <Route path={`${match.path}/consulting`} render={() => <Consulting/>}/>
            <Route path={`${match.path}/studio`} render={() => <Space/>}/>
            <Route path={`${match.path}/studio2`} render={() => <Space2/>}/>
            <Route path={`${match.path}/studio3`} render={() => <Space3/>}/>
            <Route path={`${match.path}/youtube/:id`} render={() => <YoutubeProgramDetail/>}/>
            <Route path={`${match.path}/youtube`} render={() => <YoutubeProgram/>}/>
            <Route path={`${match.path}/evaluate/start/:id`} render={() => <EvaluateStart/>} />
            <Route path={`${match.path}/evaluate/evaluatenewsurvey`} render={() => <EvaluateNewSurvey/>} />
            <Route path={`${match.path}/evaluate/:id`} render={() => <Evaluate/>} />
            <Route path={`${match.path}/evaluate`} render={() => <EvaluateIntro/>} />
            <Route path={`${match.path}/result`} render={() => <Result/>} />
            <Route path="*" component={NotFound} status={404}/>
        </Switch>
    );
};

export default Resource;

//<div className="content" style={{textAlign:'center',fontSize:'2.5rem',fontWeight:'400',margin:'15.26rem'}}>페이지 준비중입니다.</div>
