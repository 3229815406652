/** 공간 및 장비 관련 graphql */
import gql from "graphql-tag";

/**** 장비 관련 ****/
// 장비목록 카테고리
export const EQUIPMENT_RESOURCES_CATEGORIES_QUERY = gql`
query EquipmentResourceCategorys(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
){
  equipmentResourceCategories(
      limit: $limit, 
      sort: $sort,
      start: $start,
      where: $where
  ) {
        id
        title
    }
}
`

// 장비목록
export const EQUIPMENT_RESOURCES_QUERY = gql`
query EquipmentResources(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
){
  equipmentResources(
      limit: $limit, 
      sort: $sort,
      start: $start,
      where: $where
  ) {
    id
    title
    createdAt
    updatedAt
    operation
    quantity
    summary
    documentfiles {
      id
      name
      url
      formats
      mime
    }
    equipment_resource_category {
      id
      title
    }
    thumbnailimages {
      name
      url
      formats
      mime
    }
    available_dates {
      title
      available_day
      available_end_time
      available_start_time
    }
  }
}
`


/**** 공간 관련 ****/
//공간 카테고리
export const SPACE_RESOURCE_CATEGORY_QUERY = gql`
query SpaceResourceCategories(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
){
  spaceResourceCategories(
      limit: $limit, 
      sort: $sort,
      start: $start,
      where: $where
  ){
    id
    title
    weight
  }
}
`

//공간 위치 층
export const SPACE_RESOURCE_FLOOR_QUERY = gql`
query SpaceResourceFloors(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
){
  spaceResourceFloors(
      limit: $limit, 
      sort: $sort,
      start: $start,
      where: $where
  ){
    id
    title
    weight
    space_resources {
      id
      title
      space_resource_category {
        id
        title
      }
    }
  }
}
`

/*
공간 데이터
{"id": "5ff4169275d31d49b88dcb7a"}
 */
export const SPACE_RESOURCE_QUERY = gql`
query SpaceResource($id: ID!){
  spaceResource(id: $id){
    id
    title
    noti_date
    noti_time
    noti_run
    quantity
    capacity
    thumbnailimages {
      name
      url
      formats
      mime
    }
    documentfiles {
      id
      name
      url
      formats
      mime
    }
    space_resource_category {
      id
      title
      weight
    }
    space_resource_floor{
      id
      title
      weight
    }
    available_dates {
      title
      available_day
      available_start_time
      available_end_time
    }
  }
}
`

/*
공간 목록
 */
export const SPACE_RESOURCES_QUERY = gql`
query SpaceResources(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
){
  spaceResources(
      limit: $limit, 
      sort: $sort,
      start: $start,
      where: $where
  ){
    id
    title
    noti_date
    noti_time
    noti_run
    quantity
    capacity
    thumbnailimages {
      name
      url
      formats
      mime
    }
    documentfiles {
      id
      name
      url
      formats
      mime
    }
    space_resource_category {
      id
      title
      weight
    }
    space_resource_floor{
      id
      title
      weight
    }
    available_dates {
      title
      available_day
      available_start_time
      available_end_time
    }
  }
}
`

/**** 장비-공간 예약 관련 ****/
//공간 예약 스케쥴
export const SCHEDULE_SPACES_QUERY = gql`
query ScheduleSpaces(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
){
  scheduleSpaces(
      limit: $limit, 
      sort: $sort,
      start: $start,
      where: $where
  ){
    id
    createdAt
    schedule_title
    schedule_from
    schedule_to
    schedule_description
    schedule_author {
      id
      email
      username
    }
    space_resource {
      id
      title
      quantity
      space_resource_category {
        title
      }
    }
    schedule_equipments {
      id
      schedule_title
      schedule_from
      schedule_to
      schedule_author {
        id
        email
        username
      }
      equipment_resource{
        id
        title
        quantity
      }
    }
    documentfiles {
      id
      name
      url
      formats
      mime
    }
    confirmed
  }
}
`

/** 가용 장비/공간 목록 */
export const AVAILABLE_DATES = gql`
query AvailableDates(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
){
  availableDates(
      limit: $limit, 
      sort: $sort,
      start: $start,
      where: $where
  ){
    id
    title
    available_day
    available_end_time
    available_start_time
    space_resources {
      id
      title
      noti_date
      noti_time
      noti_run
      quantity
      capacity
      thumbnailimages {
        name
        url
        formats
        mime
      }
      documentfiles {
        id
        name
        url
        formats
        mime
      }
      space_resource_category {
        id
        title
        weight
      }
      space_resource_floor{
        id
        title
        weight
      }
    }
    equipment_resources{
      id
      title
      createdAt
      updatedAt
      operation
      quantity
      summary
      equipment_resource_category {
        id
        title
      }
      thumbnailimages {
        name
        url
        formats
        mime
      }
    }
  }
}
`

/** 대학 목록 */
export const UNIV_LIST = gql`
query PartnersUnivs(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
){
  partnersUnivs(
      limit: $limit, 
      sort: $sort,
      start: $start,
      where: $where
  ) {
        id
        univ_name
        univ_logo{
          name
          url
          formats
          mime
        }
        univ_type
        univ_phone
        univ_code
    }
}
`