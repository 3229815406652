import React, {useState, useEffect, useRef} from 'react';
import {withRouter, Link, NavLink, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import * as actions from 'store/actions/auth';
import {useTranslation} from 'react-i18next'
import {Motion, spring} from 'react-motion';

import route from 'route';

import {GiHamburgerMenu} from 'react-icons/gi';
import {AiOutlineUser} from 'react-icons/ai';
import useWindowPosition from 'utils/useWindowPosition';

const GnbItem = ({data}) => {
    const [customStyle, setCustomStyle] = useState('')
    const path = location.pathname.split('/')[1]
    const {user, isLoggedIn, loading:userLoading} = useSelector(({auth}) => ({...auth}));

    return (
        <div className="_col">
            {
                data.type == 'a' ?
                <a href={_.get(data,'url','#')}
                    className={'link active2 ' + customStyle + ' ' + (path == data.code && ' active1')}
                    onMouseOver={() => {
                        setCustomStyle('active1')
                    }}
                    onMouseOut={() => {
                        setCustomStyle('')
                    }}
                >{data.title}</a>
                    :
                <NavLink to={data.list[0]?.url}
                        className={'link active2 ' + customStyle + ' ' + (path == data.code && ' active1')}
                        onMouseOver={() => {
                            setCustomStyle('active1')
                        }} onMouseOut={() => {
                    setCustomStyle('')
                }}>{data.title}</NavLink>

            }

            <div className="depth">
                {data.list?.map(link => {
                    if (_.isEmpty(link.type)) {
                        if(link.title == '품질관리 시스템'){
                            if(_.get(user,'role.id') != '5fed5533258df16ef195ae04' && _.get(user,'role.id') != '602a265ab2b9050bdac24bcd'){
                                return(
                                    <span className={'top_menu'} onClick={()=>{
                                        swal({
                                            title: `권한이 없습니다.`, text: '해당 게시판을 이용할 수 있는 권한이 없습니다.',
                                            icon: "warning", timer: 3000, buttons: false,
                                        })}}>{link.title}</span>
                                )
                            }
                        }
                        return <NavLink activeClassName={'bold'} onMouseOver={() => {
                            setCustomStyle('active1')
                        }} onMouseOut={() => {
                            setCustomStyle('')
                        }} key={link.title} to={link.url}>{link.title}</NavLink>
                    } else {
                        return <a activeClassName={'bold'} target={'_blank'} onMouseOver={() => {
                            setCustomStyle('active1')
                        }} onMouseOut={() => {
                            setCustomStyle('')
                        }} key={link.title} href={link.url}>{link.title}</a>
                    }
                })}
            </div>
        </div>
    );
};



const Header = ({onChangeOpenUserMenu, onChangeOpenMobileNav, pageClass}) => {

    const [mouseOver, setMouseOver] = useState(false);
    const [top_class,setTopClass] = useState('gnb_button_bar_top');
    const [bottom_class,setBottomClass] = useState('gnb_button_bar_bottom');
    const dispatch = useDispatch()
    const history = useHistory()

    const {user, isLoggedIn} = useSelector(({auth: {user, isLoggedIn}}) => ({
        user, isLoggedIn
    }));




    const handleLogout = () => {
        dispatch(actions.logout())
        document.getElementById('iframe-lms').contentWindow.postMessage('log_out',"https://gnue-lms.gesturecloud.io")
        history.push('/')
    }

    const Headerdom = document.getElementsByClassName('header');

    function Header_hover(){
        setMouseOver(true)
    }

    function Header_hover_leave(){
        setMouseOver(false)
    }

    useEffect(() => {
        if(!_.isEmpty(document.getElementById('iframe-lms'))){
            if(!_.isEmpty(user)){
                document.getElementById('iframe-lms').addEventListener('load',()=>{
                    document.getElementById('iframe-lms').contentWindow.postMessage({'jwt':_.get(localStorage,'jwt')},"https://gnue-lms.gesturecloud.io")
                })
                document.getElementById('iframe-lms').contentWindow.postMessage({'jwt':_.get(localStorage,'jwt')},"https://gnue-lms.gesturecloud.io")
            }
        }
    }, [document.getElementById('iframe-lms'),user]);
    


    return (
        <header className="header" onMouseOver={() => Header_hover()} onMouseOut={() => Header_hover_leave()}>
            <div>
                <iframe src="https://gnue-lms.gesturecloud.io/member/ext_login" id={'iframe-lms'} style={{display:'none'}}/>
            </div>
            <div className="header__top">
                <div className="wrap">
                    {(!user || !isLoggedIn) && (<>
                        <a href="https://gnue-lms.gesturecloud.io/member/login">로그인</a>
                        <Link to="/member/register/information">회원가입</Link>
                    </>)}
                    {user && isLoggedIn && (<>
                        <span className={`mr-3 text-white`}>
                            {user.name && `${user.name} 님` || `${user.email} 님`}
                        </span>
                        <button onClick={handleLogout}>로그아웃</button>

                        <a href={'https://gnue-lms.gesturecloud.io/home/message'}>
                            메세지 수신함
                        </a>
                        {
                            (_.get(user,'role.type') == "instructor") || (_.get(user,'role.type') == "staff") ?  <Link to="/member/InstructorCourse">마이페이지</Link> :
                                <Link to="/member/mycontent">마이페이지</Link>
                        }

                        {
                            (_.get(user, 'role.name') == 'Admin') &&
                                <a href={'https://gnue-lms.gesturecloud.io/backend/dashboard'} target='_blank'>관리자페이지</a>
                        }
                    </>)}
                </div>
            </div>

            <div className={`${useWindowPosition() > 10 ? "header__bottom border_g_bottom" : (mouseOver ? "header__bottom border_g_bottom" : _.isEmpty(pageClass) ? "header__bottom" : "header__bottom border_g_bottom")}`}>
                <div className="wrap">
                    <button className="mobile-button mobile-button--gnb" onClick={() => onChangeOpenMobileNav(true)}>
                        <GiHamburgerMenu/>
                    </button>

                    <Link to="/" className="header__logo">
                        <img
                            src={`${useWindowPosition() > 10 ? "/assets/images/gnue-white.png" : (mouseOver ? "/assets/images/gnue-color.png" : _.isEmpty(pageClass) ? "/assets/images/gnue-white.png" : "/assets/images/gnue-color.png")}`}
                            alt="광주교육대학교"/>
                    </Link>
                    <div className="header__gnb">
                        <GnbItem data={route.depth1}/>
                        <GnbItem data={route.depth2}/>
                        <GnbItem data={route.depth3}/>
                        <GnbItem data={route.depth4}/>
                        <GnbItem data={route.depth5}/>
                        <div className="gnb_button_new">
                            <div className={`${useWindowPosition() > 10 ? "gnb_button_bar_top top_color_ac" : (mouseOver ? "gnb_button_bar_top top_color_ac" : _.isEmpty(pageClass) ? "gnb_button_bar_top" : "gnb_button_bar_top top_color_ac")}`} ></div>
                            <div className={`${useWindowPosition() > 10 ? "gnb_button_bar_bottom bottom_color_ac" : (mouseOver ? "gnb_button_bar_bottom bottom_color_ac" : _.isEmpty(pageClass) ? "gnb_button_bar_bottom" : "gnb_button_bar_bottom bottom_color_ac")}`} ></div>
                        </div>
                    </div>

                    <button
                        className="mobile-button mobile-button--user"
                        onClick={() => onChangeOpenUserMenu(true)}
                    >
                        <AiOutlineUser/>
                    </button>
                </div>
                <div className={`${useWindowPosition() > 10 ? "head_bar_border" : (mouseOver ? "head_bar_border" : _.isEmpty(pageClass) ? "" : "head_bar_border")}`}></div>
            </div>
        </header>
    );
};

export default Header;
