// 마이콘텐츠
import React, {useEffect, useMemo, useState, useCallback} from 'react';
import BreadCrumb from 'components/BreadCrumb';
import SubLeftMenu from 'components/SubLeftMenu';
import * as actions from 'store/actions/content';
import {useSelector, useDispatch} from 'react-redux';
import {Helmet} from "react-helmet";
import {useTranslation} from 'react-i18next'
import moment from 'moment/dist/moment';
import * as api from 'lib/content';
import {apiUrl} from 'config';
import {useRouteMatch, generatePath, Link} from 'react-router-dom';
import route from 'route';
import {BoardHeader, BoardList} from 'components/Board';
import {CustomPagination} from 'components/Custom'
import ProgramItem from 'components/MyProgramList/ProgramItem';
import ProgramItemD from 'components/MyProgramList_D/ProgramItemd';
import useWindowSize from 'utils/useWindowSize';

const TYPE = 'study-program-applies';
const MyContent = ({path, headerHidden,title = '', description = '' }) => {

    const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}));
    const {id = '', username = ''} = user;
    //개별 목록 정보
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation()
    const {list = [], totalCount = 0, loading} = useSelector(({content}) => ({...content}));
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(0)
    const [filter, setFilter] = useState({});
    const [params, setParams] = useState({
        _limit: perPage,
        _start: page * perPage,
        _sort: `createdAt:DESC`,
        apply_user: id
    });

    const pageCount = Math.ceil(totalCount / perPage)
    const [admUser, catchAdmUser] = useState(false);
    const [isActive, setActive] =useState(1);
    
    const updateParams = useCallback(() => {
        const {username = '', id = ''} = user
        // setParams({...params, ...data, "apply_user.id": id});
        setParams({...params, ...data, apply_user: id});
    }, [user]);


    const handleChange = ({keyword = "", type = ""}) => {
        const {id = '', username = ''} = user;
        if (!keyword || !type) {
            setFilter({})
            setParams({
                _limit: 10,
                _sort: `createdAt:DESC`,
                _start: 0,
                apply_user: id
            })
            setPage(0)
            return;
        }

        const filters = type.split(',') || [];
        const _filter = {};
        _.forEach(filters, (field, idx) => {
            _filter[`_where[_or][${idx}][${field}]`] = keyword
        })
        setFilter({..._filter})
    };

    const handleReset = () => {
        const {id = '', username = ''} = user;
        setParams({
            _limit: 10,
            _sort: `createdAt:DESC`,
            _start: 0,
            apply_user: id
        })
        setPage(0)
        setPerPage(10)
        setFilter({})
    }

    //page 이동
    useEffect(() => {
        const {_start, _limit} = params;
        if ((_limit !== perPage || _start !== page * perPage)) {
            updateParams({...params, _start: page * perPage, _limit: perPage})
        }
    }, [page, perPage])

    useEffect(() => {
        dispatch(actions.listRequest({
            type: TYPE,
            ...params,
            ...filter
        }))
    }, [params, filter]);


    //관리자인지 접속 확인
    useEffect(() => {
        if(user.id){
            if(user.id == "6080cbefb629f15efd7de790"){
                catchAdmUser(true)
            }
            else { return }
        }
    },[user]);

    return (
            <div className="myProgram">
                <div className="onair content introduction">
                    <Helmet title="마이페이지 > 나의강좌:콘텐츠"/>
                    <BreadCrumb list={route.depth6}/>
                    <div className="sub">
                        <div className="wrap">
                            {/*<SubLeftMenu
                                title={route.depth6.title}
                                lnbList={route.depth6.list}
                            />*/}
                            <div className="sub__content subcontent_wide ">
                                <h1 className="sub__title">
                                    <span className="">나의 강좌: 콘텐츠 > 학습진도현황</span>
                                </h1>
                                {/*관리자 계정인지 확인합니다.*/}
                                {/*<div>{admUser}</div>*/}

                                {/*{*/}
                                {/*    !_.isEmpty(_.get(user, 'role')) && (*/}
                                {/*        ((_.get(data, 'author.id') == _.get(user, 'id')) ||*/}
                                {/*            (_.get(user, 'role.id') == '5fed5533258df16ef195ae04')) &&*/}
                                {/*                */}
                                {/*        */}
                                {/*}*/}
                                    {/*<BoardHeader totalCount={totalCount}*/}
                                    {/*             title={'프로그램 신청 확인'}*/}
                                    {/*             onChange={handleChange}*/}
                                    {/*             onReset={handleReset}*/}
                                    {/*             fields={[*/}
                                    {/*                 {title: '과정명', value: 'appied_program.title_contains'},*/}
                                    {/*             ]}*/}
                                    {/*/>*/}
                                    <div className="Myclass_list">

                                        {/*<div className="myContent_button">*/}
                                        {/*          <div className={ isActive == 1 ? 'left_button is_active' : 'left_button'} onClick={()=> setActive(1)}>*/}
                                        {/*                수강중*/}
                                        {/*         </div>*/}
                                        {/*        <div className={ isActive == 2 ? 'right_button is_active' : 'right_button'} onClick={()=> setActive(2)}>*/}
                                        {/*                수강완료*/}
                                        {/*        </div>*/}
                                        {/*</div>*/}
                                        {/*<BoardList*/}
                                        {/*    isLink={false}*/}
                                        {/*    totalCount={totalCount}*/}
                                        {/*    loading={loading}*/}
                                        {/*    data={list}*/}
                                        {/*    columns={columns}/>*/}
                                        <div className="progress_table_box">
                                            <table className="Myclass_progressrate_table">
                                                <thead>
                                                <tr>
                                                    <th rowSpan="2" width="70px">번호</th>
                                                    <th rowSpan="2" width="110px">이름</th>
                                                    <th rowSpan="2" width="130px">학번</th>
                                                    <th rowSpan="2" width="200px">대학</th>
                                                    <th rowSpan="2">신분</th>
                                                    <th colSpan="5">기획의 세계 (총 5강 )</th>
                                                </tr>
                                                <tr>
                                                    <th><img src="/assets/images/play_icon_ta.png" alt="강의아이콘"/><p className="play_time"> 21:00</p></th>
                                                    <th><img src="/assets/images/play_icon_ta.png" alt="강의아이콘"/><p className="play_time"> 21:00</p></th>
                                                    <th><img src="/assets/images/play_icon_ta.png" alt="강의아이콘"/><p className="play_time"> 21:00</p></th>
                                                    <th><img src="/assets/images/play_icon_ta.png" alt="강의아이콘"/><p className="play_time"> 21:00</p></th>
                                                    <th><img src="/assets/images/play_icon_ta.png" alt="강의아이콘"/><p className="play_time"> 21:00</p></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>김세연</td>
                                                        <td>217955</td>
                                                        <td>광주전남대학</td>
                                                        <td>학생</td>
                                                        <td>1</td>
                                                        <td>2</td>
                                                        <td>3</td>
                                                        <td>4</td>
                                                        <td>5</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>김세연</td>
                                                        <td>217955</td>
                                                        <td>광주전남대학</td>
                                                        <td>학생</td>
                                                        <td>1</td>
                                                        <td>2</td>
                                                        <td>3</td>
                                                        <td>4</td>
                                                        <td>5</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>김세연</td>
                                                        <td>217955</td>
                                                        <td>광주전남대학</td>
                                                        <td>학생</td>
                                                        <td>1</td>
                                                        <td>2</td>
                                                        <td>3</td>
                                                        <td>4</td>
                                                        <td>5</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>김세연</td>
                                                        <td>217955</td>
                                                        <td>광주전남대학</td>
                                                        <td>학생</td>
                                                        <td>1</td>
                                                        <td>2</td>
                                                        <td>3</td>
                                                        <td>4</td>
                                                        <td>5</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>김세연</td>
                                                        <td>217955</td>
                                                        <td>광주전남대학</td>
                                                        <td>학생</td>
                                                        <td>1</td>
                                                        <td>2</td>
                                                        <td>3</td>
                                                        <td>4</td>
                                                        <td>5</td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>김세연</td>
                                                        <td>217955</td>
                                                        <td>광주전남대학</td>
                                                        <td>학생</td>
                                                        <td>1</td>
                                                        <td>2</td>
                                                        <td>3</td>
                                                        <td>4</td>
                                                        <td>5</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <CustomPagination
                                                pageSize={perPage}
                                                pageIndex={page}
                                                pageCount={pageCount}
                                                onPageChange={({selected}) => setPage(selected)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
    );
};

export default MyContent;
