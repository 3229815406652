
import React, {useEffect} from 'react';
import useWindowSize from 'utils/useWindowSize';
import {useRouteMatch, generatePath, Link} from 'react-router-dom';
import LocalDataItem from './LocalDataItem';

const Index = ({ list = [], title = '', headerHidden, description = '' ,path='',loading=false} ) => {

    return (
        <div className="localdata__list">

                {
                    loading && (<div className={`text-center p-4`}>로딩중...</div>) || list.length > 0
                        ? list.map(item => <LocalDataItem key={item.id} data={item} path={path}/>)
                        : <p className="null__list">등록된 프로그램이 없습니다.</p>
                }

        </div>
    );
};
export default Index;