/** 로그인 폼 */
import React, {useEffect,useRef,useState} from 'react';
import {useForm, Controller} from "react-hook-form/dist/index.ie11";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Postcode from 'components/Postcode';


const defaultValues = {
    code: "",
  };

const ResetForm = ({onSubmit, handleCancel, code}) => {
    const {register, handleSubmit, setValue, errors, watch, getValues,setError,control} = useForm({defaultValues});
    const password = useRef({});
    password.current = watch("password", "");
    //registerLocale("ko", ko);
    return (
        
            <form onSubmit={handleSubmit(onSubmit)}>
                <input type="hidden" id="code" name="code" value={code}                    
                className={`form-control ${errors.code && `border-danger` || ``}`}
                    ref={register({required: "비밀번호 변경 코드는 필수입니다."})}/>
                {errors.code && (
                    <small className="text-danger">{errors.code.message}</small>
                )}
                
                <div className="form-group">
                    <label htmlFor="password" className="col-form-label font-weight-bold">비밀번호</label>
                    <input type="password" id="password" name="password"
                        className={`form-control ${errors.password && `border-danger` || ``}`}
                        ref={register({required: "비밀번호는 필수입니다.",
                            minLength: {
                            value: 8,
                            message: "영문, 숫자, 특수기호 포함 8자리 이상 입력해주세요"
                        }})}
                        placeholder="비밀번호 입력 (영문, 숫자, 특수기호 포함 8자리 이상)"/>
                    {errors.password && (
                        <small className="text-danger">{errors.password.message}</small>
                    )}
                </div>


                
                <div className="form-group">
                    <label htmlFor="passwordConfirmation" className="col-form-label font-weight-bold">비밀번호 확인</label>
                    <input type="password" id="passwordConfirmation" name="passwordConfirmation"
                        className={`form-control ${errors.password_confirm && `border-danger` || ``}`}
                        ref={register({required: true,
                            validate: value =>
                            value === password.current || "비밀번호가 일치하지 않습니다."})}
                        placeholder="비밀번호 확인"
                        />
                    {(errors.passwordConfirmation)&&(
                        <small className="text-danger">{errors.passwordConfirmation.message}</small>
                    )}
                </div>

                <div className="btn_submit_wrap">
                    <button className="btn_submit">비밀번호 변경</button>
                </div>
            </form>)
}


export default ResetForm;

