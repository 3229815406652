import React from 'react';
import { Link } from 'react-router-dom';

const RightBoard = () => {

    const winurl = window.location.href;
    const url = winurl.indexOf('sublms');
    const newurl = winurl.substring(0,url);
    const url1 = newurl+'sublms';
    
    return (
        <div className="col col--right">
            <div className="top">
                <h1 className="">
                    바로가기
                </h1>
            </div>
            <div className="contbox">

                <Link to={'/about/introduction'}>
                    <div>
                        <img src="/assets/images/main-quick-1.png" alt=""/>
                    </div>
                    <p>광주교육대학교소개</p>
                </Link>
                <Link to={'/content/repository'}>
                    <div>
                        <img src="/assets/images/main-quick-3.png" alt=""/>
                    </div>
                    <p>비교과/공개강좌<br/>/임직원교육</p>
                </Link>
                {/*<Link to={'/content/repository'}>
                    <div>
                        <img src="/assets/images/main-quick-2.png" alt=""/>
                    </div>
                    <p>공개강좌</p>
                </Link>*/}

                <Link to={'/community/qna'}>
                    <div>
                        <img src="/assets/images/main-quick-4.png" alt=""/>
                    </div>
                    <p>헬프 센터</p>
                </Link>
                <a target={'_blank'} href={'https://eclass-lms.seoyeong.ac.kr/member/ext_login'}>
                    <div>
                        <img src="/assets/images/main-quick-5.png" alt=""/>
                    </div>
                    <p>LMS</p>
                </a>
            </div>
        </div>
    // {window.location.href === url1 && (
    //     <div className="col col--right">
    //         <div className="top">
    //             <button className="">
    //                 메롱
    //             </button>
    //         </div>
    //         <div className="contbox">
    //             <Link to={'/about/introduction'}>
    //                 <div>
    //                     <img src="/assets/images/main-quick-1.png" alt=""/>
    //                 </div>
    //                 <p>GJUDEC 소개</p>
    //             </Link>
    //             <Link to={'/content/repository'}>
    //                 <div>
    //                     <img src="/assets/images/main-quick-2.png" alt=""/>
    //                 </div>
    //                 <p>공개강좌</p>
    //             </Link>
    //             <Link to={'/content/repository'}>
    //                 <div>
    //                     <img src="/assets/images/main-quick-3.png" alt=""/>
    //                 </div>
    //                 <p>통합 콘텐츠 저장소</p>
    //             </Link>
    //             <Link to={'/'}>
    //                 <div>
    //                     <img src="/assets/images/main-quick-4.png" alt=""/>
    //                 </div>
    //                 <p>헬프 센터</p>
    //             </Link>
    //             <Link to={'/'}>
    //                 <div>
    //                     <img src="/assets/images/main-quick-5.png" alt=""/>
    //                 </div>
    //                 <p>홈페이지 들어가기</p>
    //             </Link>
    //         </div>
    //     </div>
    // )}
    );
};

export default RightBoard;
