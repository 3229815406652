import React, {useEffect, useMemo, useState, useCallback} from 'react';

import {courseSurveyData2} from 'surveyData';


const SubjectiveSurvey = ({register,courseSurveyData2,workshopSurveyData2}) => {

    // 강좌만족도인지 워크숍만족도인지 체크
    const [surveyData, setsurveyData] = useState([]);

    useEffect(() => {
        if (!_.isEmpty(courseSurveyData2)) {
            setsurveyData(courseSurveyData2);
        }
        else if (!_.isEmpty(workshopSurveyData2)) {
            setsurveyData(workshopSurveyData2);
        }
    }, [surveyData]);

    return (
                <>
                    {
                        surveyData.map((item,index)=>
                            <div key={item.id}>
                                <input type={'hidden'} name={`survey[${_.get(item,'no') - 1}].name`} ref={register()} value={_.get(item,'title')}/>
                                <div className={'survey_question'}>
                                    <h1>{_.get(item,'no')}. {item.title}</h1>
                                    <input type={'text'} name={`survey[${_.get(item,'no') - 1}].answer`} ref={register()}/>
                                </div>
                             </div>
                        )
                    }
                </>

    );
};

export default SubjectiveSurvey;