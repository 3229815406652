import React, {useEffect, useMemo, useState, useCallback} from 'react';
import * as actions from 'store/actions/content';
import {Helmet} from "react-helmet";
import {useSelector, useDispatch} from 'react-redux';
import * as api from 'lib/content';
import {apiUrl} from 'config';
import route from 'route';
import moment from 'moment/dist/moment';


const WorkshopCertificate = ({user={},programData={}}) => {
    const {author,workshopprogram,createdAt,survey_complete_num} = _.get(programData,'0')

    return (
        <div className={'certificate_back'}>
            {/*<img className={'certi_badge'} src={'/assets/images/certificate_badge.png'}/>*/}
            <div className={'certificate_inner'}>
                <div>
                    <div className={'print_one'}>
                        <p className={'print_num'}>발급번호 : {`${moment().format("YYYY")} - ${_.get(workshopprogram,'id')} - ${survey_complete_num}`}</p>
                        {/*<p>출력일시 : {moment().format("YYYY.MM.DD")}</p>*/}
                    </div>
                    <h1 className={'print_title'}>이 수 증</h1>
                </div>

                <div>
                    <div className={'print_two'}>
                        <p>
                            <div className={'print_box'}>
                                <div>성 명</div>
                                <div className={'print_center'}>:</div>
                                <div className={'print_data'}>{_.get(author,'nickname')}</div><br/>
                            </div>
                            <div className={'print_box'}>
                                <div>소 속</div>
                                <div className={'print_center'}>:</div>
                                <div className={'print_data'}>{_.get(author,'organization')}</div>
                                <br/>
                            </div>
                            <div className={'print_box'}>
                                <div>워 크 숍 제 목</div>
                                <div className={'print_center'}>:</div>
                                <div className={'print_data'}>{_.get(workshopprogram,'title')}</div>
                                <br/>
                            </div>
                            <div className={'print_box'}>
                                <div>워 크 숍 날 짜</div>
                                <div className={'print_center'}>:</div>
                                <div className={'print_data'}>{moment(_.get(workshopprogram,'learning_startdate')).format('YYYY - MM - DD')} ~ {moment(_.get(workshopprogram,'learning_enddate')).format('YYYY - MM - DD')}</div>
                                <br/>
                            </div>
                            <div className={'print_box'}>
                                <div>워 크 숍 시 간</div>
                                <div className={'print_center'}>:</div>
                                <div className={'print_data'}>{_.get(workshopprogram,'apply_time')}시간</div>
                            </div>
                            </p>
                    </div>
                    <div className={'print_three'}>
                        위 과정을 성실히 이수하여<br/>
                        이 증서를 수여합니다.
                    </div>
                    <div className={'print_day'}>
                        <p>{moment().format("YYYY년　MM월　DD일")}</p>
                    </div>
                </div>

                <div className={'print_footer'}>
                    <h1>광주전남권역&nbsp;대학원격교육지원센터장</h1>
                    <img src={'/assets/images/certi_stamp.png'}/>
                </div>
            </div>
        </div>
    );
};

export default WorkshopCertificate;
