import React, {useEffect,useState} from 'react';

const ProgressBar = ({progress,setProgress}) => {

    let styles = {
        width: progress + "%",
    }

    return (<>

        <div className="progressBox">
            <div className="progressBar">
                <span className="progressNum">{progress}%</span>
                <div className="progressLoading" style={styles}>
                </div>
            </div>
        </div>
    </>)

}
export default ProgressBar;