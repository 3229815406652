import React from 'react';
import {Link, useLocation} from 'react-router-dom';

const BreadCrumb = ({list}) => {
    const {pathname} = useLocation();
    return (
        <div className="breadcrumb">
            <div className="wrap">
                <Link to="/">HOME</Link>
                <Link to={list.list[0].url}>{list.title}</Link>
                {
                    list.list.map((item, idx) => {
                        return pathname.indexOf(item.url) !== -1 && (
                            <Link key={idx} to={item.url}>{item.title}</Link>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default BreadCrumb;
