
import React, {useEffect, useState, useCallback} from 'react';
import {useForm} from "react-hook-form/dist/index.ie11";
import moment from 'moment/dist/moment';
import * as api from 'lib/content';
import route from 'route';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';


const defaultValues = {
    appied_program: {
        _id:'',
    },
    attached_files:[]
};
const file_del = (type,id,reload,setReload) => async() =>{
    try {
        const {status} = await api.del({type:type,id:id})
        if(status !== 200){
            throw new Error('에러 발생');
        }
        else{
            setReload(!reload)
            swal({
                title: `파일 삭제 완료`,
                icon: "success", timer: 10000, buttons: false,
            });
        }
    } catch (e) {
        swal({
            title: `파일 삭제 실패`, text: e.message,
            icon: "warning", timer: 3000, buttons: false,
        });
    } finally {
    }
}
const file_type_check = (value) => {
    //const {files: files} = data
    var type_check = true
    Object.entries(value).map((item, idx)=>{
        var file_kind = item[1].name.lastIndexOf('.')
        var file_name = item[1].name.substring(file_kind+1,item[1].length)
        var file_type = file_name.toLowerCase()
        var check_file_type = ['pdf','hwp','doc','docx','pptx','ppt']
        if(check_file_type.indexOf(file_type)==-1) {
            type_check = false;
            /*swal({
                title: `파일 유형이 맞지 않습니다.`, text: 'pdf,hwp,dox,docs,pptx,ppt 형식의 파일만 업로드할 수 있습니다.',
                icon: "warning", timer: 3000, buttons: false,
            });*/
        }
    })
    if(type_check == false){
        return '파일형식이 맞지 않습니다.';
    }
}
const ProgramApplyForm = ({onSubmit,data,mode='',type,handleCancel,cancelSubmit,apply_files=[],reload,bigo,setReload,user,applied_data,defaultId}) => {
    const [validation,setValidation] = useState({});
    const [status,setStatus] =useState('');
    const [nameChange,setNameChange] =useState('');
    const [filesApplys, setFilesApplys]=useState();
    useEffect(() =>{
        if(mode == '')setStatus('신청하기')
        else if(mode == 'm')setStatus('정정하기')
        else if(mode == 'd')setStatus('신청하기')
        else if(mode == 'can')setStatus('신청취소하기')
    },[mode])
    useEffect(() =>{
        if(mode == 'can')setNameChange('프로그램')
    })
    const
        cancel_check = () => {
        swal({
            title: `수강 취소`, text: '정말로 취소하시겠습니까?',
            icon: "warning",  buttons:  {
                yesDelete: {
                    text: "예",
                    value: "yes"
                },
                notDelete: {
                    text: "아니오",
                    value: "no"
                }
            },
        }).then((value)=>{
            if(value=='yes'){
                cancelSubmit()
            }
            else{
            }
        });
    }
/////////////////workshop 신청취소/////////////////////
    const workshop_cancle = (user,type,defaultId) => {
        const params = {apply_status:{_id:'5ff6b7a6bf55b944295d63ed'}};
        console.log(defaultId)
            swal({
                title: `프로그램 신청 취소`, text: '정말로 취소하시겠습니까?',
                icon: "warning",  buttons:  {
                    yesDelete: {
                        text: "예",
                        value: "yes"
                    },
                    notDelete: {
                        text: "아니오",
                        value: "no"
                    }
                },
            }).then(async (value)=>{
                if(value=='yes'){
                        try {
                            const {data, status} = await api.update({type: type, id: defaultId,...params});
                            /*api.del({type: type, apply_user: user.email, id: applied_data[0].id});*/

                            swal({
                                title: `취소신청 완료`, text: '취소신청이 완료되었습니다.',
                                icon: "success", timer: 3000, buttons: false,
                            })
                        } catch (e) {
                            swal({
                                title: `취소신청 실패`, text: e.message,
                                icon: "warning", timer: 3000, buttons: false,
                            });
                        } finally {
                            //return newlist;
                            setReload(!reload)
                            //삭제 후
                        }

                }
                else{
                }
            });
        }

    useEffect(() =>{
        //setFilesApplys(apply_files)
        if(data.enroll_attache_force){
            if(apply_files && apply_files.length>0){
                setValidation({validate: (value) => file_type_check(value)})
            }
            else{
                setValidation({required: '파일을 등록해주세요',
                    validate: (value) => file_type_check(value)})
            }
        }
        else{
            //setValidation({validate: (value) => file_type_check(value)})
        }
    },[apply_files,data])

    const { register, handleSubmit, watch, errors } = useForm({defaultValues});

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {(mode == 'can') ?
                <>
                <div className="modal_sub_title">
                    {nameChange}정보
                </div>
                <div className="table_responsive">
                    <table className="sticky_header">
                        <thead>
                        <tr>
                            <th rowSpan="2">{nameChange}명</th>
                            <th rowSpan="2">{nameChange}일정</th>
                            <th rowSpan="2">장소</th>
                            {/*<th rowSpan="2">작성</th>*/}
                        </tr>
                        </thead>
                    </table>

                    <table className="sticky_table table_modal">
                        <thead>
                        <tr>
                            <th rowSpan="2">{nameChange}명</th>
                            <th rowSpan="2">{nameChange}일정</th>
                            <th rowSpan="2">장소</th>
                            {/*<th rowSpan="2">작성자</th>*/}
                        </tr>
                        </thead>

                        <tbody>
                        <tr>
                            <td>{data.title}</td>
                            <td>
                                {moment(data.learning_startdate).format(`YYYY-MM-DD`) } ~<br/>
                                {moment(data.learning_enddate).format(`YYYY-MM-DD`) }
                            </td>
                            <td>{data.program_address}</td>
                            {/*<td>작성자</td>*/}
                        </tr>
                        </tbody>
                    </table>

                </div>
                <div className="course_overlay_description_work">
                    {nameChange} 정보를 확인 하신 후 '{`취소하기`}' 버튼을 클릭해주세요
                </div>
</>
                :
                <>
            <div className="modal_sub_title">
                과정정보
            </div>
            <div className="table_responsive">
                <table className="sticky_header">
                    <thead>
                    <tr>
                        <th rowSpan="2">교육 과정명</th>
                        <th rowSpan="2">교육일정</th>
                        <th rowSpan="2">장소</th>
                        {/*<th rowSpan="2">작성</th>*/}
                    </tr>
                    </thead>
                </table>

                <table className="sticky_table table_modal">
                    <thead>
                    <tr>
                        <th rowSpan="2">교육 과정명</th>
                        <th rowSpan="2">교육일정</th>
                        <th rowSpan="2">장소</th>
                        {/*<th rowSpan="2">작성자</th>*/}
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <td>{data.title}</td>
                        <td>
                            {moment(data.learning_startdate).format(`YYYY-MM-DD`) } ~<br/>
                            {moment(data.learning_enddate).format(`YYYY-MM-DD`) }
                        </td>
                        <td>{data.program_address}</td>
                        {/*<td>작성자</td>*/}
                    </tr>
                    </tbody>
                </table>

            </div>
                <div className="course_overlay_description_work">
                    {
                        status =='정정하기' && <>교육과정 정보를 확인 하신 후 '{`취소하기`}' 버튼을 클릭해주세요.</>||
                        <>교육과정 정보를 확인 하신 후 '{status}' 버튼을 클릭해주세요.</>
                    }

                </div>
            </>
            }
            {/*<div className="modal_sub_title">*/}
            {/*    파일 서식*/}
            {/*</div>*/}

            {/*// 1. 사용자에게 파일 첨부를 받는다*/}

            {/*<div className="input_container">*/}
            {/*    <input type="hidden" name="id" value={data.id}  ref={register({ required: true })} />*/}
            {/*    <input accept={".pdf,.hwp,.dox,.docs,.pptx,.ppt"} type="file" multiple name="files" ref={*/}
            {/*        register(*/}
            {/*            validation*/}
            {/*        )*/}
            {/*    }  />*/}
            {/*    {errors.files && (*/}
            {/*            <small className="text-danger">{errors.files.message}</small>*/}
            {/*        )}*/}
            {/*    {(filesApplys && mode=='m') && <div className="files_container"> */}
            {/*        <span className="files_title">제출한 파일</span>*/}
            {/*        {filesApplys.map((item,index)=><span className="file_once" key={index}><span className="files_item">{item.name}</span> <span className="files_delete" onClick={file_del('upload/files',item.id,reload,setReload)}>삭제</span></span>)}*/}
            {/*        */}
            {/*        </div>}*/}
            {/*</div>*/}


            {/*<div className="modal_sub_title">*/}
            {/*    비고*/}
            {/*</div>*/}

            {/*// 2. 비고작성 부분*/}

            {/*<div className="input_container">*/}
            {/*    <textarea name="bigo" ref={*/}
            {/*        register(data.enroll_textbox_force &&*/}
            {/*            {*/}
            {/*                required: '비고란을 작성해주세요'*/}
            {/*            }*/}
            {/*            ||{})*/}
            {/*        }*/}
            {/*        defaultValue={*/}
            {/*            bigo?bigo:''*/}
            {/*        }*/}
            {/*    />*/}
            {/*    {errors.bigo && (*/}
            {/*        <small className="text-danger">{errors.bigo.message}</small>*/}
            {/*    )}*/}
            {/*</div>*/}


            <div className="course_overlay_footer">
                {
                    (mode != 'can' && (mode != 'm' && <button className="btn btn_info form-submit">{status}</button>))
                }

                {
                    (mode == 'm' &&
                    <><button onClick={cancel_check} type="button"
                         className="cancel_submit">수강 취소하기</button>
                    {/*<button onClick={handleCancel} type="button" className="overlay_close">취소하기</button>*/}</>) ||
                    (mode == 'can' &&
                        <><button onClick={()=>{workshop_cancle(user,type,defaultId)}} type="button"
                                className="cancel_submit">신청 취소하기</button>
                    {/*<button onClick={handleCancel} type="button" className="overlay_close">돌아가기</button>*/}</>)
                }

            </div>
        </form>
    )
}


export default ProgramApplyForm;
