/** 아이디/비밀번호 찾기 */
import React, {useEffect,useRef,useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory,useParams,useLocation} from 'react-router-dom'
import {Helmet} from "react-helmet";
import swal from 'sweetalert'
import * as api from 'lib/auth'
import ResetForm from 'components/Forms/ResetForm';
import qs from 'qs';

// 아이디/비밀번호 찾기

//비밀번호 찾기
const handleSubmit = ({dispatch,history}) => async (formData) => {
    try {
        console.log(formData)
        const {data, status} = await api.resetPassword({...formData});
        console.log(status)
        if(status == 400 || status == 500){
            throw satus;
        }
        else{
            swal("비밀번호가 변경되었습니다. 메인페이지로 이동합니다.",'','success')
            .then((value)=>{
                value&& window.location.replace('/')
            })
        }
    } catch (e){
        console.log(e)
        swal('코드가 일치하지 않습니다.','','warning')
    } finally{

    }
}
const defaultValues = {
    code:''
} 
const ResetPassword = ({match}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const {code} = qs.parse(location.search, {
        ignoreQueryPrefix: true
      });
    return (
        <div className="conts_body">
            <Link className="close" title="홈페이지로 이동" to="/"><span className="invisible-btn">홈페이지로 이동</span></Link>
            <Helmet title="비밀번호 변경"/>
            <h2 className="page_title">비밀번호 변경</h2>
            
            <ResetForm onSubmit={handleSubmit(dispatch,history)} code={code}/>
        </div>
    )
}

export default ResetPassword;
