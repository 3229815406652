/** 행사 후기 */
import React, {useEffect, useMemo, useState} from 'react';
import {Helmet} from "react-helmet";
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import * as actions from 'store/actions/content';
import moment from 'moment/dist/moment';
import {useTranslation} from 'react-i18next'
import {useForm} from "react-hook-form/dist/index.ie11";
import {Link} from 'react-router-dom';
import {useParams, useHistory} from 'react-router-dom';

//components
import {CustomPagination} from 'components/Custom'
import BreadCrumb from 'components/BreadCrumb';
import SubLeftMenu from 'components/SubLeftMenu';
import {BoardHeader, BoardList} from 'components/Board';
import BoardWrite from 'components/Write/BoardWrite';
import EvalPopup from 'components/Popup/EvalFinishPopup';

import qs from 'qs';
import route from 'route';
import {survey1, survey2, survey3} from 'surveyData';

const EvaluateStart = () => {
    const [title, setTitle] = useState('');
    const [evalType, setEvalType] = useState(1);
    const {id = ''} = useParams();
    const [labels, setLabels] = useState([])
    const history = useHistory();

    const {user, isLoggedIn, loading: loginLoading} = useSelector(({auth}) => ({...auth}))


    useEffect(() => {
        if (!loginLoading) {
            if (_.isEmpty(user)) {
                swal({
                    title: '로그인이 필요한 서비스입니다.', text: '로그인을 해주세요.',
                    icon: 'warning', timer: 3000, buttons: false,
                }).then(() => {
                    history.goBack();
                })
            }
        }
    }, [user])

    useEffect(() => {
        setEvalType(id)
    }, [id]);

    /*console.log(id);*/

    useEffect(() => {
        if (evalType == 1) {
            setLabels([
                '수업구성 및 진행',
                '수업방법',
                '비언어적 표현',
                '학생들과 관계 및 태도',
                '영상콘텐츠 활용'
            ])
            setTitle(`본 진단도구는 자기이해를 위해 실시해 보는 것입니다. 
온라인 수업 준비 및 운영에 참고자료로 활용해보시기 바랍니다.`)
        } else if (evalType == 2) {
            setLabels([
                '전문가',
                '엄격한 교수자',
                '위임자',
                '역할모델',
                '촉진자'
            ])
            setTitle(`티칭 스타일은 교수자의 일반적 특성을 나타내주고 어떠한 수업방식이 적합한지에 관한 유용한 정보를 제공합니다.
이는 자가진단의 결과이며 절대적인 유형으로 해석할 필요는 없으며, 일반적인 성향정도로만 파악하면 됩니다.`)
        } else if (evalType == 3) {
            setLabels([
                '기본소양',
                '온라인 교육에 대한 이해',
                '수업설계',
                '수업개발',
                '수업운영'
            ])
            setTitle(`설문조사의 내용은 수업 구성 및 진행, 수업 방법, 비언어적 표현, 학생들과의 관계 및 태도, 영상 콘텐츠 활용 등의 5가지 항목에 따라 진행되며
각 항목에 따른 평가 총점은 차트로 구성되어 항목별 과부족을 한눈에 확인할 수 있습니다.`)
        } else if (evalType == 4) {
            setLabels([
                '기본역량',
                '수업역량',
                '학생지원역량'
            ])
            setTitle(`안녕하십니까?  
광주전남권역 대학원격교육지원센터와 광주전남공동교육혁신센터에서는 5개 참여 대학 교원을 대상으로 교수역량진단을 실시하고
있습니다. 응답한 결과는 광주전남 지역 대학교원의 교수역량 증진 프로그램을 개발하는데 기초자료로 활용됩니다.
성실한 답변에 미리 감사드립니다.`)
        }
    }, [evalType])
    {
        if (!loginLoading) {
            if (_.isEmpty(user)) return (<div className="onair content introduction">
                    <Helmet title="역량진단평가"/>
                    <BreadCrumb list={route.depth4}/>
                    <div className="sub">
                        <div className="wrap">
                            <SubLeftMenu
                                title={route.depth4.title}
                                lnbList={route.depth4.list}/>
                        </div>
                    </div>
                </div>
            )
        }
    }
    return (
        <div className="onair content introduction">
            <Helmet title="역량진단평가"/>
            <BreadCrumb list={route.depth4}/>
            <div className="sub">
                <div className="wrap">
                    <SubLeftMenu
                        title={route.depth4.title}
                        lnbList={route.depth4.list}/>
                    <div className="sub__content introduction_content">
                        <h1 className="sub__title introduction_title">
                            <span className="">역량 진단평가</span>
                        </h1>

                        <div className="introduction">

                            <div className="introduction__info">
                                {evalType==1 && <h3 className={'info_3'}>온라인수업 역량 진단도구</h3>}
                                {evalType==2 && <h3 className={'info_3'}>티칭스타일 진단평가</h3>}
                                {evalType==3 && <h3 className={'info_3'}>수업역량 진단평가</h3>}
                                {evalType==4 && <h3 className={'info_3'}>교수역량 진단평가</h3>}
                                <h3>01 | 진단목적</h3>
                            </div>
                            <div className="start_p">
                            <span>
                                {title}
                            </span>
                            </div>
                        </div>

                        <div className="introduction">
                            <div className="introduction__info">
                                <h3>02 | 진단방법</h3>
                            </div>
                            <div className="start_p">
                                <span>아래 내용은 설문조사 예시입니다.</span>
                                <br/>
                                <span>각 문항을 잘 읽고 자신과 가장 가깝다고 생각되는 정도를 판단하여 체크합니다.</span>
                            </div>
                        </div>
                        <div className="dr_wrapper dr table_answer1 question7">
                            <h3 className="title">
                                수업 구성 및 진행
                            </h3>
                            <div className="ta_div" id="edit-answer7">
                                <table className={'evaluate_table'}>
                                    <thead>
                                    <tr>
                                        <th className={'th_header th_number'}>번호</th>
                                        <th className={'th_header th_title'}>문항</th>
                                        <th className={'th_header'}>매우 그렇지 않다</th>
                                        <th className={'th_header'}>그렇지 않다</th>
                                        <th className={'th_header'}>보통</th>
                                        <th className={'th_header'}>그렇다</th>
                                        <th className={'th_header'}>매우 그렇다</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={'ta_question title1-1'}>
                                            1
                                        </td>
                                        <td className={'question_list_title'}>
                                            수업 시작과 마치는 시간을 정확히 지키는가?
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className={'ta_question title1-1'}>
                                            2
                                        </td>
                                        <td className={'question_list_title'}>
                                            수업을 시작할 때 수업내용의 전체 개요를 알려주는가?
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className={'ta_question title1-1'}>
                                            3
                                        </td>
                                        <td className={'question_list_title'}>
                                            학생들에게 학습목표를 정확히 제시하는가?
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className={'ta_question title1-1'}>
                                            4
                                        </td>
                                        <td className={'question_list_title'}>
                                            수업내용을 학생들의 수준에 적합하게 조절하는가?
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className={'ta_question title1-1'}>
                                            5
                                        </td>
                                        <td className={'question_list_title'}>
                                            평가방법과 기준에 대한 명확한 설명을 하는가?
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                        <td id={'edit-answer1--1'} className="form-radios">
                                                                    <span
                                                                        className={'form-item input-field form-type-radio form-item-answer1-0'}>
                                                                        <input type="radio" value="0"
                                                                               className="form-radio"/>
                                                                    </span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="introduction">
                            <div className="introduction__info">
                                <h3>03 | 진단결과 확인방법</h3>
                            </div>
                        </div>
                        <p>※3.0 미만이면 "낮음", 3.0~3.5 미만이면 "보통", 3.5 이상이면 "높음"</p>
                        <div className="ta_div" id="edit-answer7">
                            <table className={'evaluate_table'}>
                                <thead>
                                {!_.isEmpty(labels) &&
                                labels.map((value, index) => {
                                    return <th className={'dc_way'} key={value}>{value}</th>
                                })}
                                </thead>
                                <tbody>
                                <tr>
                                    {
                                        evalType != 4 ?
                                            <>
                                                <td>4.37</td>
                                                <td>2.9</td>
                                                <td>3</td>
                                                <td>3.87</td>
                                                <td>2.9</td>
                                            </>
                                            :
                                            <>
                                                <td>4.37</td>
                                                <td>2.9</td>
                                                <td>3</td>
                                            </>
                                    }
                                </tr>
                                <tr>
                                    {
                                        evalType != 4 ?
                                            <>
                                                <td>높음</td>
                                                <td>낮음</td>
                                                <td>보통</td>
                                                <td>높음</td>
                                                <td>낮음</td>
                                            </>
                                            :
                                            <>
                                                <td>높음</td>
                                                <td>낮음</td>
                                                <td>보통</td>
                                            </>
                                    }
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={'start_button_container'}>
                            <Link to={`/resource/evaluate/${id}`}>시작하기</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EvaluateStart;
