import React, {useEffect, useState, useCallback, useRef} from 'react';
import RentalHeader from 'components/RentalHeader';
import {apiUrl} from 'config';
import * as api from 'lib/content';

//graphql 관련
import {useLazyQuery} from "@apollo/react-hooks";
import * as query from "queries/resource";

const Index = ({hiddenHeader = false, onSubmit}) => {
    const [space, setSpace] = useState({})  //선택 된 공간
    const [cateList, setCateList] = useState([]);   //카테고리 목록
    const [floors, setFloors] = useState([]);   //층수
    const [tab, setTab] = useState(''); //선택 된 탭
    const {
        title: spaceTitle = '',
        documentfiles = [], thumbnailimages = [],
        noti_time = null, noti_date = null, noti_run = null,
        id = '607fff679ad8cb29300f4454'
    } = space;
    //1. 카테고리 목록
    const [getCategory] = useLazyQuery(query.SPACE_RESOURCE_CATEGORY_QUERY, {
        fetchPolicy: 'no-cache', variables: {sort: "weight:ASC"},
        onCompleted: ({spaceResourceCategories = []}) => {
            setCateList([/*{id: '', title: '전체'},*/ ...spaceResourceCategories]);
            setTab(_.get(spaceResourceCategories, '0.id'));
        }
    });

    //2. 층 목록
    const [getFloors] = useLazyQuery(query.SPACE_RESOURCE_FLOOR_QUERY, {
        fetchPolicy: 'no-cache', variables: {sort: "weight:ASC"},
        onCompleted: ({spaceResourceFloors = []}) => {
            setFloors([...spaceResourceFloors])
        }
    });

    //Get 공간 데이터
    const [getData] = useLazyQuery(query.SPACE_RESOURCE_QUERY, {
        fetchPolicy: 'no-cache',
        onCompleted: ({spaceResource = {}}) => setSpace({...spaceResource})
    });

    //카테고리 및 층
    useEffect(() => {
        getCategory({variables: {sort: "weight:ASC"}})
        getFloors({variables: {sort: "weight:ASC"}})
    }, []);

    //데이터 initialize
    useEffect(() => {
        if (!_.isEmpty(cateList) && !_.isEmpty(floors) && _.isEmpty(space)) {
            const lastData = _.chain(floors)
                .map('space_resources').flatten().last().value() || null;
            lastData && getData({variables: {id: '607ffa659ad8cb29300f4445'}});
        }
    }, [floors, cateList, space])

    useEffect(() => {
        onSubmit && space && onSubmit(space)
    }, [space])

    return (
        <div className="section info">

            <div className="part_select">
                <div className={'part'}>
                    <p className={'part_title'}>
                        스튜디오
                    </p>
                    <RentalHeader
                        title={'공간 안내'}
                        tabList={cateList}
                        tab={tab}
                        onChangeTag={setTab}
                        hiddenHeader={hiddenHeader}/>

                    <div className="col">
                        <ul className="spaceInfo__floor">
                            {floors && floors.map((floor, idx) => {
                                const {title = '', id = '', space_resources = []} = floor;
                                if (!space_resources.length) return;
                                return (
                                    <li className="item" key={id}>
                                        <h3>{title}</h3>
                                        {space_resources.length && (
                                            <div className="roomlist">
                                                {space_resources.map((v, index) => {
                                                    const {
                                                        title = '', id = '',
                                                        space_resource_category: {id: cateId = ''} = {}
                                                    } = v;
                                                    if (tab !== '' && tab !== cateId) return;
                                                    return (<button
                                                        className={`room-btn ${id === space.id && 'active' || ''}`}
                                                        onClick={() => getData({variables: {id}})}
                                                        key={id}>{title}</button>)
                                                })}
                                            </div>
                                        ) || null}
                                    </li>
                                )
                            }) || (
                                <li className="item">
                                    <div className="roomlist">
                                        <p>가능한 공간이 없습니다.</p>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                {/*<div className={'part_footer'}>
                    <p className={'footer_title'}>대관시간</p>
                    <p className={'footer_info'}>월~금(토요일 및 공휴일 제외)</p>
                    {
                        !_.isEmpty(space) &&
                        (noti_time && (<p className={'footer_info'}>{noti_time}</p>))
                    }
                </div>*/}
            </div>

            <div className="part_info">
                <div className="spaceInfo__content part">
                    <p className={'part_title'}>
                        시설
                    </p>
                    <div className="col col--download">

                        <div className="spaceInfo__operation">
                            {
                                _.isEmpty(thumbnailimages) ?
                                    <img src={'/assets/images/noimage.png'}/>
                                    :
                                    <img src={`${apiUrl}${_.get(thumbnailimages, '0.url')}`}/>
                            }
                            {
                                !_.isEmpty(space) &&
                                (<>
                                    {spaceTitle && (
                                        <div className="space_info"><p className={'space_info_title'}>시설명칭:</p>&nbsp;
                                            <p>{spaceTitle}</p></div>)}
                                    {_.get(space, 'phone', '') && (
                                        <div className="space_info"><p className={'space_info_title'}>전화번호:</p>
                                            <p>{_.get(space, 'phone', '')}</p></div>)}
                                    {noti_run && (
                                        <div className="space_info"><p className={'space_info_title'}>시설위치:</p>&nbsp;
                                            <p>{noti_run}</p></div>)}
                                </>) ||
                                (<p className="empty">선택 된 공간이 없습니다.</p>)
                            }
                        </div>
                    </div>
                </div>
                {/*<div className={'part_footer'}>
                    <p className={'footer_title'}>이용수칙</p>
                    <p className={'footer_info'}>시간준수</p>
                    <p className={'footer_info'}>목적 외 사용 금지</p>
                    <p className={'footer_info'}>외부인 출입금지</p>
                </div>*/}
            </div>
            <div className={'part_footer'}>
                <ul className={'part_footer_list'}>
                <li className={'footer_title'}><strong>대관시간</strong> : 월~금(토요일 및 공휴일 제외) {noti_time}</li>
                <li><strong>이용수칙</strong> : 시간준수, 목적 외 사용 금지, 외부인 출입금지</li>
                </ul>
            </div>
        </div>
)
    ;
};

export default Index;
