/** 로그인 폼 */
import React, {useEffect,useRef,useState} from 'react';
import {useForm, Controller} from "react-hook-form/dist/index.ie11";
import ReactDatePicker, { registerLocale,setDefaultLocale  } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Postcode from 'components/Postcode';
import * as authActions from 'store/actions/auth';
import Policy from 'components/Content/Policy';
import Terms from 'components/Content/Terms';
import MemberPopup from 'components/Popup/MemberPopup';
import ko from 'date-fns/locale/ko';
registerLocale('ko', ko);
// import Popup from "reactjs-popup";
import {organization_data} from 'data';

const defaultValues = {
    useName: "",
    birth: "",
  };

const RegisterForm = ({onSubmit, handleCancel, email_check,emailcheckdata}) => {
    const {register, handleSubmit, setValue, errors, watch, getValues,setError,control} = useForm({defaultValues});
    useEffect(()=>{
        setValue('email_check_value', emailcheckdata)
    },[emailcheckdata])
    const password = useRef({});
    password.current = watch("password", "");
    const [isPostcode,setPostCode] = useState(false);
    const [usernamedata, setUsernamedata] = useState(null);
    const [date, setDate] = useState(null);
    const allvalue = getValues('service_agree','information_agree','emailSend','smsSend','password');
    const [popup, setPopup] = useState(null);   //팝업 ref
    const [open, setOpen] = useState(false);    //open 상태인가?
    const [loading, setLoading] = useState(true);   //데이터 로딩중인가?
    const handleTogglePostcode = () => {
        setPostCode(!isPostcode)
    };

    const range = (start, end, step=1) => {
        let array = [];
        for (let i = start; i < end; ++i) {
            if (!(i % step)) {
                array.push(i);
            }
        }
        return array;
    }

    const [startDate, setStartDate] = useState(new Date());
    const years = range(1900, moment().year() + 1, 1);
    const months = [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월"
    ];

    const check_email = () =>{
        email_check(getValues('userName'))
    }

    useEffect(()=>{
        if(allvalue == true){
            setValue('all_agree',true)
        }
        else{
            setValue('all_agree',false)
        }
    },[allvalue])
    //주소 선택
    const handlePostcode = ({address, postcode, extraAddress}) => {
        setValue('zip_code', postcode)
        setValue('addr1', address)
        setValue('extra_address', extraAddress)
        setPostCode(false)
    }

    const popupClose = () => {
        if (!_.isEmpty(popup)) {
            const {ContentEl = {}, setContentRef, closePopup} = popup;
            if (ContentEl.className && ContentEl.className.indexOf('popup-close-content') === -1) {
                ContentEl.className += ' popup-close-content ';
                setContentRef(ContentEl);
            }
            setTimeout(closePopup, 450);
        }
    }
    //registerLocale("ko", ko);
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
                <label htmlFor="userName" className="col-form-label font-weight-bold">아이디(이메일)</label>
                <input type="text" id="userName" name="userName"
                       className={`form-control jd_input ${errors.userName && `border-danger` || ``}`}
                       ref={register({required: "이메일은 필수입니다",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "이메일 형식이 아닙니다."
                          }})}
                       placeholder="아이디"
                       readOnly={emailcheckdata}/>
                <button type="button"
                        className="btn_inline btn_id_ch" onClick={check_email}>중복 확인</button>
                {(errors.userName) && (
                    <small className="text-danger">{errors.userName.message}</small>
                )}
                <input type="hidden" id="email_check_value" name="email_check_value"
                       className={`form-control ${errors.email_check_value && `border-danger` || ``}`}
                       ref={register({
                        pattern: {
                            value: true,
                            message: "이메일 중복체크를 해주세요"
                          }})}
                       placeholder="아이디"/>

            </div>

            <div className="form-group">
                <label htmlFor="password" className="col-form-label font-weight-bold">비밀번호</label>
                <input type="password" id="password" name="password"
                       className={`form-control ${errors.password && `border-danger` || ``}`}
                       ref={register({required: "비밀번호는 필수입니다",
                        minLength: {
                        value: 8,
                        pattern: {
                            value:[/^[0-9A-Za-z\w!~@#$%\^&\*\(\)?+=/]{8,29}$/],
                            message: "영문, 숫자, 특수기호 포함 8자리 이상 입력해주세요"
                        },
                        message: "영문, 숫자, 특수기호 포함 8자리 이상 입력해주세요"
                      }})}
                       placeholder="비밀번호 입력 (영문, 숫자, 특수기호 포함 8자리 이상)"
                />
                {errors.password && (
                    <small className="text-danger">{errors.password.message}</small>
                )}
            </div>



            <div className="form-group">
                <label htmlFor="password_confirm" className="col-form-label font-weight-bold">비밀번호 확인</label>
                <input type="password" id="password_confirm" name="password_confirm"
                       className={`form-control ${errors.password_confirm && `border-danger` || ``}`}
                       ref={register({required: true,
                        validate: value =>
                        value === password.current || "비밀번호가 일치하지 않습니다"})}
                       placeholder="비밀번호 확인"
                       />
                {(errors.password_confirm)&&(
                    <small className="text-danger">{errors.password_confirm.message}</small>
                )}
            </div>

            <div className="form-group">
                <label htmlFor="name" className="col-form-label font-weight-bold">이름</label>
                <input type="name" id="name" name="name"
                       className={`form-control ${errors.name && `border-danger` || ``}`}
                       ref={register({required: '이름은 필수입니다.'})}
                       placeholder="이름"
                       />
                {(errors.name)&&(
                    <small className="text-danger">{errors.name.message}</small>
                )}
            </div>

            {/*<div className="form-group">
                <label htmlFor="gender" className="col-form-label font-weight-bold">성별</label>
                    <label className="radiolabel"> <input
                        className="ipradio"
                       name="gender"
                       type="radio"
                       value="man"
                       ref={register({ required: '성별을 선택해주세요' })}/>남성</label>
                    <label className="radiolabel"> <input
                        className="ipradio"
                        name="gender"
                        type="radio"
                        value="woman"
                        ref={register({ required: '성별을 선택해주세요' })}/>여성</label>
                {(errors.gender)&&(
                    <small className="text-danger">{errors.gender.message}</small>
                )}
            </div>*/}

            {/*<div className="form-group">
                <label htmlFor="birth" className="col-form-label font-weight-bold">생년월일</label>
                <Controller
                    control={control}
                    name="birth"
                    render={(props) => (
                    <ReactDatePicker
                        className="input"
                        placeholderText="생년월일을 입력하세요"
                        onChange={(e) => props.onChange(e)}
                        selected={props.value}
                        className={`form-control input ${errors.name && `border-danger` || ``}`}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        scrollableYearDropdown={true}
                        dateFormat={"yyyy-MM-dd"}
                        popperPlacement="auto"
                        locale="ko"
                        renderCustomHeader={({
                                                 date,
                                                 changeYear,
                                                 changeMonth,
                                                 decreaseMonth,
                                                 increaseMonth,
                                                 prevMonthButtonDisabled,
                                                 nextMonthButtonDisabled
                                             }) => (
                            <div
                                style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center"
                                }}
                            >
                                <button className={'left_button'} type={'button'} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                    {"<"}
                                </button>
                                <select
                                    defaultValue={moment().year()}
                                    onChange={({ target: { value } }) => changeYear(value)}
                                >
                                    {years.map(option => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <select
                                    defaultValue={moment().month()}
                                    onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                    }
                                >
                                    {months.map(option => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <button className={'right_button'} type={'button'} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                    {">"}
                                </button>
                            </div>
                        )}
                    />
                    )}
                    rules={{ required: "생년월일은 필수값입니다" }}
                />
                {(errors.birth)&&(
                    <><small className="text-danger">{errors.birth.message}</small>
                    <div></div></>
                )}
            </div>*/}

            <div className="form-group">
                <label htmlFor="hp" className="col-form-label font-weight-bold">핸드폰 번호</label>
                <input type="hp" id="hp" name="hp"
                       className={`form-control ${errors.hp && `border-danger` || ``}`}
                       ref={register({required: '핸드폰번호는 필수입니다.',
                       pattern: {value: /^\d{3}-\d{3,4}-\d{4}$/, message:"형식이 맞지 않습니다"}})}
                       placeholder="010-1234-5678"
                       />
                {(errors.hp)&&(
                    <small className="text-danger">{errors.hp.message}</small>
                )}
            </div>




            {/*<div className="form-group">
                <label htmlFor="phone" className="col-form-label font-weight-bold">전화번호</label>
                <input type="phone" id="phone" name="phone"
                       className={`form-control`}
                       ref={register({
                       pattern: {value: /^\d{2,3}-\d{3,4}-\d{4}$/, message:"형식이 맞지 않습니다"}})}
                       placeholder="02-1234-5678"
                       />
                {(errors.phone)&&(
                    <small className="text-danger">{errors.phone.message}</small>
                )}
            </div>*/}

            {/*<div className="form-group zip">*/}
            {/*    <label htmlFor="phone" className="col-form-label font-weight-bold">주소입력</label>*/}
            {/*        <label htmlFor="maddress">*/}
            {/*        <input name="zip_code" type="text"*/}
            {/*               readOnly={true}*/}
            {/*               id="zip_code"*/}
            {/*               title="주소입력, 우편번호찾기"*/}
            {/*               className="frm_ipt addr1 mb5"*/}
            {/*               placeholder="우편번호"*/}
            {/*               ref={register}*/}
            {/*               />*/}
            {/*            <button type="button"*/}
            {/*                    className="btn_inline"*/}
            {/*                    onClick={handleTogglePostcode}*/}
            {/*                    disabled={isPostcode}>우편번호 찾기*/}
            {/*            </button>*/}
            {/*        </label>*/}
            {/*        {isPostcode && (*/}
            {/*            <div>*/}
            {/*                <button onClick={handleTogglePostcode}>*/}
            {/*                    <img className="close_btn" src="/assets/images/daumpostcode_close.png" alt="우편번호 찾기 닫기버튼"*/}
            {/*                         id="btnFoldWrap"/>*/}
            {/*                </button>*/}
            {/*                <Postcode onSubmit={handlePostcode} animation={true} autoResize={true}/>*/}
            {/*            </div>*/}
            {/*        )}*/}
            {/*        <label htmlFor="bmadd"></label>*/}
            {/*        <input name="addr1" type="text"*/}
            {/*               readOnly={true}*/}
            {/*               id="addr1"*/}
            {/*               title="기본주소"*/}
            {/*               className="frm_ipt frm_100 mb5"*/}
            {/*               placeholder="기본주소"*/}
            {/*               ref={register}/>*/}
            {/*        <input name="addr2" type="text"*/}
            {/*               title="상세주소"*/}
            {/*               className="frm_ipt frm_100 mb5"*/}
            {/*               id="addr2"*/}
            {/*               placeholder="상세주소"*/}
            {/*               ref={register}/>*/}
            {/*        <input name="extra_address" type="hidden" placeholder="참고항목" ref={register}/>*/}
            {/*    </div>*/}

            <div className="form-group">
                <label htmlFor="organization" className="col-form-label font-weight-bold">소속</label>
                <select
                    type="organization" id="organization" name="organization"
                    ref={register({required:'소속을 선택해주세요.'})}
                    className={`form-control`}
                >
                    <option>선택</option>
                    {organization_data.map((item,index)=>
                    <option value={item} key={index}>{item}</option>)}
                </select>
            </div>


            <div className="form-group">
                <label htmlFor="identity" className="col-form-label font-weight-bold">신분</label>
                <select
                    type="identity" id="identity" name="identity"
                    ref={register({required:'신분을 선택해주세요.'})}
                    className={`form-control`}
                >
                    <option>선택</option>
                    <option value={'professor'}>교수</option>
                    <option value={'teacher'}>강사</option>
                    <option value={'employee'}>직원</option>
                    <option value={'student'}>학생</option>
                    <option value={'other'}>기타</option>
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="major" className="col-form-label font-weight-bold">학과</label>
                <input type="regiNum" id="major" name="major"
                       // className={`form-control ${errors.regiNum && `border-danger` || ``}`}
                       className={`form-control`}
                       ref={register()}
                       placeholder="학과정보"
                />
                {/*{(errors.regiNum)&&(*/}
                {/*    <small className="text-danger">{errors.regiNum.message}</small>*/}
                {/*)}*/}
            </div>

            <div className="form-group">
                <label htmlFor="regiNum" className="col-form-label font-weight-bold">사번/학번</label>
                <input type="regiNum" id="regiNum" name="regiNum"
                       className={`form-control ${errors.regiNum && `border-danger` || ``}`}
                       ref={register({required: '사번ㆍ학번은 필수입니다.'})}
                       placeholder="학번ㆍ사번"
                />
                {(errors.regiNum)&&(
                    <small className="text-danger">{errors.regiNum.message}</small>
                )}
            </div>



            <div className="form-group">
                <label className="col-form-label font-weight-bold">약관동의</label>
                {/*<label><input type="checkbox" name="email-send" ref={register} />이메일 수신 알림</label>*/}
                {/*<label><input type="checkbox" name="sms-send" ref={register} />SMS 수신 알림</label>*/}
            </div>
            <div className="form-group">
                <label className='label_chk all_agree'><input name={'all_agree'} type="checkbox" ref={register} onChange={e=>{
                    setValue('service_agree',getValues('all_agree'))
                    setValue('information_agree',getValues('all_agree'))
                    setValue('emailSend',getValues('all_agree'))
                    setValue('smsSend',getValues('all_agree'))
                }} />모두 동의합니다.</label>

            </div>
            <div className="form-group">
                <label className='label_chk'><input name={'service_agree'} type="checkbox" ref={register({ required: '필수값입니다.' })} />서비스 이용약관에 동의합니다.<span className="required">(필수)</span>
                </label><MemberPopup trigger={<p className="contentsopen">[내용보기]</p>}><Terms/></MemberPopup>
                {(errors.service_agree)&&(
                    <small className="text-danger">{errors.service_agree.message}</small>
                )}
            </div>

            <div className="form-group">
                <label className='label_chk'><input name={'information_agree'} type="checkbox" ref={register({ required: '필수값입니다.' })} />개인정보 수집 및 이용에 동의합니다.<span className="required">(필수)</span>
                </label><MemberPopup trigger={<p className="contentsopen">[내용보기]</p>} modal><Policy/></MemberPopup>
                {(errors.information_agree)&&(
                    <small className="text-danger">{errors.information_agree.message}</small>
                )}
            </div>
            <div className="form-group">
                <label className='label_chk'><input type="checkbox" name="emailSend" ref={register} />이메일 수신 알림</label>
            </div>
            <div className="form-group">
                <label className='label_chk'><input type="checkbox" name="smsSend" ref={register} />SMS 수신 알림</label>
            </div>

            <div className="btn_submit_wrap">
                <button className="btn_submit">회원가입</button>
            </div>
        </form>)
}


export default RegisterForm;
