import React from 'react';

import BreadCrumb from '../../components/BreadCrumb';
import SubLeftMenu from '../../components/SubLeftMenu';
import route from '../../route';
import { BsArrowRightShort } from 'react-icons/all';
import {Helmet} from "react-helmet";

const Organization = () => {
    return (
        <div className="introduction content">
            <Helmet title="조직도"/>
            <BreadCrumb list={route.depth1} />
            <div className="sub">
                <div className="wrap">
                    <SubLeftMenu
                        title={route.depth1.title}
                        lnbList={route.depth1.list}
                    />
                    <div className="sub__content introduction_content">
                        <h1 className="sub__title introduction_title">
                            <span className="">조직도</span>
                        </h1>
                        {/*<p className="introduction__title">*/}
                        {/*  빛나는 작은아이디어가 <span>글로벌 콘텐츠</span>가 되는 그날까지*/}
                        {/*</p>*/}

                        <div className="introduction__desc">
                            <p>내용 준비중입니다.</p>
                            {/*<img src="/assets/images/org5.png" alt=""/>*/}
                            {/*<p>*/}
                            {/*  시작의 목소리에 귀 기울이고, 성장하는 전남형 인재육성 및 지역의 문제를 해결하는*/}
                            {/*  4차 산업혁명시대 선도 콘텐츠 비즈니스 육성*/}
                            {/*</p>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Organization;
