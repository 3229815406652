// 마이콘텐츠
import React, {useEffect, useMemo, useState, useCallback} from 'react';
import BreadCrumb from 'components/BreadCrumb';
import SubLeftMenu from 'components/SubLeftMenu';
import * as actions from 'store/actions/content';
import {useSelector, useDispatch} from 'react-redux';
import {Helmet} from "react-helmet";
import {useTranslation} from 'react-i18next'
import moment from 'moment/dist/moment';
import * as api from 'lib/content';
import {apiUrl} from 'config';
import {useRouteMatch, generatePath, Link} from 'react-router-dom';
import {useLocation} from "react-router";
import route from 'route';
import {BoardHeader, BoardList} from 'components/Board';
import {CustomPagination} from 'components/Custom'
import ProgramItem from 'components/MyProgramInstructor/ProgramItem';
import ProgramItemD from 'components/MyProgramList_D/ProgramItemd';
import MyContentPopup from 'components/Popup/MyContentPopup';
import useWindowSize from 'utils/useWindowSize';
import Popup from "reactjs-popup";

// customapi 관련
import axios from 'axios';
import usePromise from 'utils/usePromise'

const TYPE = 'studyprogram-applies';
const MyContentInstructor = ({path, headerHidden, title = '', description = '', active=''}) => {

    const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}));
    const {id = '', name,username = '',role={}} = user;
    //개별 목록 정보
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation()
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(0)
    const [filter, setFilter] = useState({});
    const [dataLoading, setDataLoading] = useState(true);
    const [params, setParams] = useState({});
    const [pageCount, setPageCount] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [admUser, catchAdmUser] = useState(false);
    const [isActive, setActive] = useState(1);
    const [eventclick, setEventClick] = useState(false);
    const [clickedproid, setClickedproid] = useState('');
    const [programName, setProgram] = useState("");
    const [programData, setProgramData] = useState("");
    const [studyNumber, setProgramNumber] = useState({});
    const [paramsGrade, setParamsGrade] = useState({});
    const [videodetailList, setVideoDetailList] = useState([]);
    const [percent, setPercent] = useState(0);
    const [allStudy, setAllStudy] = useState([]);
    const [blockList, setBlockList] = useState([]);
    const [proBlockNum, setProBlockNum] = useState([]);
    const [appiedClass, setAppiedClass] = useState([]);
    const [uuid,setUuid] = useState("")
    const [list, setList] = useState([]);
    const [arritem,setArrItem] = useState([]);
    const [comList, setComList] = useState([]);

    useEffect(() => {
        async function getStuey({id}) {
            try {
                const {data, status} = await api.gets({type: 'gjudec-2021-1-hakgi-middle-instructors', ins_name:name});
                console.log(data)
                data.map(async (item) => {
                    let uuid = _.get(item,'cw_uuid');
                    const {data:datas, status:status} = await api.gets({type: 'studyprograms', cw_uuid:uuid});
                    setList(arr => [datas,...arr]);
                })
                if (status != 200) {
                    throw new Error()
                }
            } catch (e) {
                console.log(e)
            } finally {

            }
        }

        if (!_.isEmpty(user)) {
            getStuey({id: user.id})
        }
    }, [user]);

    console.log(list);


    // useEffect(() => {
    //     if(!_.isEmpty(uuid)){
    //         console.log(uuid)
    //         async function getPrograms() {
    //             try{
    //                 const {data, status} = await api.gets({type: 'studyprograms', cw_uuid:uuid});
    //                 setList(data);
    //                 if (status != 200) {
    //                     throw new Error()
    //                 }
    //             }
    //             catch(e) {console.log(e)}
    //             finally { }
    //         }
    //         getPrograms();
    //     }
    //
    // },[uuid])

    //grade-by-blocks 가져오기
    useEffect(() => {
        async function getProgram() {
            try {
                const {data, state} = await api.gets({type: 'grade-by-blocks', ...paramsGrade});
                setProgramData(data);
                let dataList = data.filter(item => (username == (_.get(item, 'grade_user_id'))));
                setBlockList(dataList.filter(item => (_.get(item, 'achieve') == "true")));
            } catch (e) {
                console.log(e)
            } finally {
            }
        }

        getProgram();
    }, []);

    const updateParams = useCallback(() => {
        const {username = '', id = ''} = user
        // setParams({...params, ...data, "apply_user.id": id});
        setParams({...params, _start: page * perPage, _limit: page * perPage, apply_user: id});
    }, [page, perPage]);

    useEffect(() => {
        setPageCount(Math.ceil(totalCount / perPage));
    }, [totalCount])


    //page 이동
    useEffect(() => {
        const {_start, _limit} = params;
        if ((_limit !== perPage || _start !== page * perPage)) {
            setParams({...params, _start: page * perPage, _limit: (page + 1) * perPage})
        }
    }, [page, perPage]);


    //관리자인지 접속 확인
    useEffect(() => {
        if (user.id) {
            if (user.id == "6080cbefb629f15efd7de790") {
                /*console.log(user.id);*/
                catchAdmUser(true)
            } else {
                return
            }
        }
    }, [user]);

    // 진도율 체크 상세
    useEffect(() => {
        if (!_.isEmpty(list) && eventclick == true) {
            videodetail()
        }
    }, [eventclick, list]);


    const videodetail = async () => {
        const {
            data: videodetail,
            status
        } = await axios.get(`/custom-api/course/check/videodetail?id=${clickedproid}&username=${user.username}`);
        setVideoDetailList(videodetail)
    }
    return (
        <div className="myProgram">
            <div className="onair content introduction">
                <Helmet title="마이페이지 > 나의강좌:콘텐츠"/>
                <BreadCrumb list={route.depth6}/>
                <div className="sub">
                    <div className="wrap">
                        <SubLeftMenu
                            title={route.depth6.title}
                            lnbList={route.depth6.list}
                        />
                        <div className="sub__content">
                            <h1 className="sub__title">
                                <span className="">운영중인 강좌</span>
                            </h1>
                            <div className="Myclass_list">

                                <div className="myContent_button">

                                </div>

                                {
                                    (!_.isEmpty(list) ?
                                        <div className="programList">
                                            {!headerHidden && (
                                                <div className="programList__title">
                                                    <h2>{title}</h2>
                                                    {description && <p>{description}</p>}
                                                </div>
                                            )}
                                            <div className="programList__list">
                                                { list.map(item => {
                                                        return(
                                                            <>
                                                            {
                                                                item.map(items =>
                                                                    <ProgramItem key={_.get(item, 'id')}
                                                                                 activeCount = {_.get(item,'active_count')}
                                                                                 item={items}
                                                                                 data={items} path={path}
                                                                                 user={user} admUser={admUser}
                                                                                 setEventClick={setEventClick}
                                                                                 setProgram={setProgram}
                                                                                 eventclick={eventclick}
                                                                                 programName={programName}
                                                                                 setClickedproid={setClickedproid}
                                                                                 setProgramData={setProgramData}
                                                                                 programData={programData}
                                                                                 studyNumber={studyNumber}
                                                                                 setProgramNumber={setProgramNumber}
                                                                                 setPercent={setPercent}
                                                                                 percent={percent}
                                                                                 totalCount={totalCount}
                                                                    />
                                                                )
                                                            }</>

                                                            )
                                                         }

                                                    )

                                                }

                                            </div>
                                        </div>
                                        :
                                        <div className="programList">
                                            <div className="programList__list">
                                                <p className="null__list">등록된 게시물이 없습니다.</p>
                                            </div>
                                        </div>)
                                }

                                {/*<CustomPagination
                                    pageSize={perPage}
                                    pageIndex={page}
                                    pageCount={pageCount}
                                    onPageChange={({selected}) => setPage(selected)}
                                />*/}
                                {eventclick == true ?
                                    <MyContentPopup setEventClick={setEventClick} setAppiedClass={setAppiedClass}
                                                    appiedClass={appiedClass} programName={programName}
                                                    videodetailList={videodetailList} user={user}/>
                                    : <></>
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
};

export default MyContentInstructor;
