import React from 'react';
import { Link } from 'react-router-dom';
import {apiUrl} from "config";
 
const GalleryItem = ({ data }) => {
  return (
    <div className="programList__item">
      <Link to={`/dataset/ckldata/${data.id}`}>
        <div className="thumb">
          <img src={apiUrl+_.get(data,'thumbnailimages[0].url')} alt=""/>
        </div>
        <div className="info text-center">
          <h2>{data.title}</h2>
          <p>
            {/*data.author*/}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default GalleryItem;
