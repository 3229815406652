import React,{useEffect,useState} from 'react';
import {NavLink} from 'react-router-dom';
import useWindowPosition from 'utils/useWindowPosition';
import {useSelector, useDispatch} from 'react-redux';

const SubLeftMenu = ({title, lnbList = []}) => {
    const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}));
    const [lnbs,setLnbs] = useState([]);

    useEffect(()=>{
        if( (_.get(user,'role.type') =="instructor") || (_.get(user,'role.type') =="staff") ){
            let lnbLists = lnbList.filter(item =>  _.get(item,'title') !== "나의 강좌: 콘텐츠")
            setLnbs(lnbLists);
        }else{
            let lnbLists = lnbList.filter(item =>  _.get(item,'title') !== "운영중인 강좌")
            setLnbs(lnbLists);
        }
    },[user])
    

    return (
        <div className={`lnb ${useWindowPosition() > 56 && 'fixed' || ''}`}>
            <div className="lnb__layout">
                <h2 className="lnb__title">
                    {title}
                </h2>
                <div className="lnb__scroll">
                    <ul className="lnb__list">
                        {lnbs.map(item => {
                            if(_.isEmpty(item.type)){
                                return(
                                <li className="lnb__item" key={item.title}>
                                    <NavLink to={item.url} activeClassName="active">{item.title}</NavLink>
                                </li>)
                            }
                            else{
                                return(
                                <li className="lnb__item" key={item.title}>
                                    <a href={item.url} activeClassName="active" target={'_blank'}>{item.title}</a>
                                </li>)
                            }
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};



export default SubLeftMenu;
