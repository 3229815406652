import React from 'react';
import { Link } from 'react-router-dom';
import {apiUrl} from 'config'
import Slider from 'react-slick';


const IntroCollages = ({univList=[]}) => {

  const settings = {
    // slide: 'Slider',
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    appendDots: dots => (
        <div
            style={{
              borderRadius: "10px",
              padding: "10px"
            }}
        >
          <ul style={{ margin: "0px" }}> {dots} </ul>
        </div>
    ),
    customPaging: i => (
        <div
            style={{
              width: "30px",
              color: "#5f616e",
              fontWeight: "700",
            }}
        >
          {i + 1}
        </div>
    )
  };

  return (
    <div className={'intro__collage'}>
      <Slider {...settings}>
        {
          univList.map(collage => {
            return (
                <Link to={'sublms/'+collage.univ_code} className={'collage-item'} key={collage.id}>
                  <div className="thumb">
                    <img src={apiUrl + _.get(collage,'univ_logo.0.url')} alt=""/>
                  </div>
                  {/*<div className="info">*/}
                  {/*  <ul>*/}
                  {/*    /!*<li className={'name'}>{collage.name}</li>*!/*/}
                  {/*    <li className={'number'}>*/}
                  {/*      <a href={collage.number}>{collage.number}</a>*/}
                  {/*    </li>*/}
                  {/*  </ul>*/}
                  {/*</div>*/}
               </Link>
             );
          })
        }
      </Slider>
    </div>
  );
};

export default IntroCollages;
