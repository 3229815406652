import React, {useEffect, useState} from 'react';
import config from 'config';
import axios from 'axios';
import usePromise from 'utils/usePromise';

const editorConfig = {
    /* 런타임 시 리소스들을 로딩할 때 필요한 부분으로, 경로가 변경되면 이 부분 수정이 필요. ex) http://xxx.xxx.com */
    txHost: config.portal_domain,
    /* 런타임 시 리소스들을 로딩할 때 필요한 부분으로, 경로가 변경되면 이 부분 수정이 필요. ex) /xxx/xxx/ */
    txPath: config.daumEditorPath,
    /* 수정필요없음. */
    txService: 'daumEditor',
    /* 수정필요없음. 프로젝트가 여러개일 경우만 수정한다. */
    txProject: 'daumEditor',
    /* 대부분의 경우에 빈문자열 */
    initializedId: "",
    /* 에디터를 둘러싸고 있는 레이어 이름(에디터 컨테이너) */
    wrapper: "tx_trex_container",
    /* 등록하기 위한 Form 이름 */
    form: '',
    /*에디터에 사용되는 이미지 디렉터리, 필요에 따라 수정한다. */
    txIconPath: config.daumEditorPath + '/images/icon/editor/',
    /*본문에 사용되는 이미지 디렉터리, 서비스에서 사용할 때는 완성된 컨텐츠로 배포되기 위해 절대경로로 수정한다. */
    txDecoPath: config.daumEditorPath + '/images/deco/contents/',
    canvas: {
        styles: {
            /* 기본 글자색 */
            color: "#123456",
            /* 기본 글자체 */
            fontFamily: "굴림",
            /* 기본 글자크기 */
            fontSize: "10pt",
            /*기본 배경색 */
            backgroundColor: "#fff",
            /*기본 줄간격 */
            lineHeight: "1.5",
            /* 위지윅 영역의 여백 */
            padding: "8px"
        },
        showGuideArea: false
    },
    events: {
        preventUnload: false
    },
    sidebar: {
        attachbox: {
            show: true,
            confirmForDeleteAll: true
        }
    },
    size: {
        /* 지정된 본문영역의 넓이가 있을 경우에 설정 */
        contentWidth: 700
    }
};
const getEditorFrame = () => axios.get('/libraries/daumEditor/editor_frame.html');

/**
 * DaumEditor 컴포넌트
 * @param form : 폼이름
 * @param name : textarea 이름
 * @param value : 초기 값
 * @param onChange : 반환 할 함수
 * @returns {*}
 * @constructor
 */
const DaumEditor = ({form, name, value = '', onChange}) => {
    const {loading: editorFrameLoading = false, data: editorFrame} = usePromise(getEditorFrame, [], null);
    const [editorHtml, setEditorHtml] = useState(null);
    const [content, setContent] = useState(value);
    const [DaumEditor, setDaumEditor] = useState(null);

    //EditorFrame 설정,
    useEffect(() => {
        if (!editorFrameLoading && editorFrame) {
            setEditorHtml({__html: editorFrame})
        }
    }, [editorFrameLoading])

    //EditorFrame html 로드 완료 시,
    useEffect(() => {
        if (editorHtml) {
            //daum Editor 로더 ready
            if (window.EditorJSLoader && EditorJSLoader) {
                editorConfig.form = form;
                EditorJSLoader.ready(function (Editor) {
                    var editor = new Editor(editorConfig);
                    setDaumEditor(() => Editor)
                });
            }
        }
    }, [editorHtml])

    //DaumEditor 설정 완료 시,
    useEffect(() => {
        if (DaumEditor) {
            //content setting
            DaumEditor.modify({content});

            //event setting
            _.each(Trex.Ev, (val) => {
                if (val.indexOf('canvas.panel.') !== -1) {
                    DaumEditor.getCanvas().observeJob(val, () => {
                        setContent(DaumEditor.getContent())
                        onChange && onChange(DaumEditor.getContent());
                    });
                }
            });
        }
    }, [DaumEditor])


    if (editorFrameLoading) return (<>로딩중...</>)
    return (<>
        <div dangerouslySetInnerHTML={editorHtml}/>
        <textarea
            name={name}
            id={name}
            style={{display: 'none'}}
            value={content}
            onChange={(e) => {
                const content = e.target.value;
                DaumEditor.modify({content});
                setContent(content)
            }}/>
    </>)
}

export default DaumEditor;
