/** 공지사항 관련 graphql */
import gql from "graphql-tag";

//유저데이터 목록
export const AUTH_EMIAL_CHECK_QUERY = gql`
query users(
    $sort: String
    $limit: Int
    $start: Int
  	$where: JSON
) {
  users(
      limit: $limit, 
      sort: $sort,
      start: $start,
      where: $where
  ) {
    	email
  }
}
`;