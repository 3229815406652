// import React, {useEffect, useMemo, useState} from 'react';
// import BreadCrumb from '../../components/BreadCrumb';
// import route from '../../route';
// import SubLeftMenu from '../../components/SubLeftMenu';
// import Pagination from '../../components/Pagination';
// import MyProgramBoard from '../../components/MyProgramBoard';
// import {useSelector, useDispatch} from 'react-redux';
// import {useRouteMatch, generatePath, Link} from 'react-router-dom';
// import {useTranslation} from 'react-i18next'
// import * as actions from 'store/actions/content';
// import {CustomPagination} from 'components/Custom';
import React, {useEffect, useMemo, useState, useCallback} from 'react';
import BreadCrumb from 'components/BreadCrumb';
import SubLeftMenu from 'components/SubLeftMenu';
import * as actions from 'store/actions/content';
import {useSelector, useDispatch} from 'react-redux';
import {Helmet} from "react-helmet";
import {useTranslation} from 'react-i18next'
import moment from 'moment/dist/moment';
import * as api from 'lib/content';
import {apiUrl} from 'config';
import {useRouteMatch, generatePath, Link} from 'react-router-dom';
import route from 'route';
import {BoardHeader, BoardList} from 'components/Board';
import {CustomPagination} from 'components/Custom'

const TYPE = 'support-program-applies';
const MySupportProgram = () => {
    // const dispatch = useDispatch();
    // const {list = [], totalCount = 0, loading} = useSelector(({content}) => ({...content}));
    // const {user, isLoggedIn} = useSelector(({auth: {user, isLoggedIn}}) => ({
    //     user, isLoggedIn
    // }));
    // const [filter, setFilter] = useState({});
    // const [category,setCategory] =useState('')
    // const [perPage, setPerPage] = useState(12)
    // const [page, setPage] = useState(0)
    // //const [filter, setFilter] = useState({});
    // const [params, setParams] = useState({_limit: perPage, _start: page * perPage,"_where[0][apply_user.username]":user.username})
    //
    // const match = useRouteMatch()
    // const {t} = useTranslation()
    // const pageCount = Math.ceil(totalCount / perPage);


    const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}));
    const {id = '', username = ''} = user;
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation()
    const {list = [], totalCount = 0, loading} = useSelector(({content}) => ({...content}));
    const [perPage, setPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [filter, setFilter] = useState({});
    const [params, setParams] = useState({
        _limit: perPage,
        _start: page * perPage,
        _sort: `createdAt:DESC`,
        apply_user: id
    })
    // console.log(list)
    const pageCount = Math.ceil(totalCount / perPage);

    const columns = useMemo(() => [
        {
            Header: "지원 사업명",
            accessor: 'appied_program.title',
            className: `text-center`,
            // width: 'calc(56% - 190px)',
            minWidth: 'calc(40% - 110px)',
            padding: '0 15px',
            // whiteSpace: 'nowrap',
            // overflow: 'hidden',
            // textOverflow: 'ellipsis',
            Cell: ({row: {appied_program: {title = '-',id='-'} = {}}, idx, totalCount}) => {
                return (<Link to={`/program/supportprogram/${id}`}>{title}</Link>)
            },
        },
        {
            Header: "교육일정", accessor: ['appied_program.learning_startdate', 'appied_program.learning_enddate'],
            accessor: 'appied_program.learning_enddate',
            className: `text-center`,
            minWidth: '150px',
            Cell: ({row: {appied_program:{learning_startdate = null,learning_enddate = null} = {}}, idx, totalCount}) => {
                // console.log(learning_startdate)
                if (!learning_startdate && !learning_enddate) return (<>-</>)
                // if (!learning_startdata  && !learning_enddate) return (<>-</>)
                //  else{
                    const start = moment(learning_startdate).format(`YYYY-MM-DD hh:mm:ss`)
                    const end = moment(learning_enddate).format(`YYYY-MM-DD hh:mm:ss`)
                    return(<div className="small">{start} ~<br/>{end}</div>)
                 // }
            },
        },
        {
            Header: "장소",
            accessor: 'appied_program.program_address',
            className: `text-center`,
            minWidth: 'calc(40% - 190px)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            Cell: ({row: {appied_program: {program_address = '-'} = {}}, idx, totalCount}) => (<>{program_address}</>)
        },
        {
            Header: "신청상태",
            accessor: 'apply_status.title',
            className: `text-center`,
            minWidth: 'calc(35% - 190px)',
            Cell: ({row: {apply_status: {title = '-'} = {}}, idx, totalCount}) => (<>{title}</>)
        },
        // {
        //     Header: "신청인",
        //     accessor: 'apply_user.name',
        //     className: `text-center`,
        //     minWidth: '150px',
        //     // width: 'calc(35% - 190px)',
        //     Cell: ({row: {apply_user: {username = '', name = ''} = {}}, idx, totalCount}) => (<>{name || username}</>)
        // },
        {
            Header: "신청서식",
            accessor: 'apply_files',
            className: `text-center`,
            minWidth: '200px',
            Cell: ({row: {apply_files = []}, idx, totalCount}) => {
                if (!apply_files.length) return (<>-</>)
                return (<>{
                    apply_files.map(({id = '', name = '', url = ''}) => (
                        <button key={id}
                                onClick={() => api.download(`${apiUrl}${url}`, name)}
                                className="small btn btn-dark">{name}</button>
                    ))}
                </>)
            }
        },
    ], [perPage, page])

    const updateParams = useCallback((data) => {
        const {username = '', id = ''} = user
        setParams({...params, ...data, "apply_user.id": id});
    }, [user]);

    const handleChange = ({keyword = "", type = ""}) => {
        const {id = '', username = ''} = user;
        if (!keyword || !type) {
            setFilter({})
            setParams({_limit: 10, _sort: `appied_program.learning_startdate:DESC`, _start: 0, "apply_user.id": id})
            setPage(0)
            return;
        }

        const filters = type.split(',') || [];
        const _filter = {};
        _.forEach(filters, (field, idx) => {
            _filter[`_where[_or][${idx}][${field}]`] = keyword
        })
        setFilter({..._filter})
    }

    const handleReset = () => {
        const {id = '', username = ''} = user;
        setParams({
            _limit: 10,
            _sort: `appied_program.learning_startdate:DESC`,
            _start: 0,
            "apply_user.id": id
        })
        setPage(0)
        setPerPage(10)
        setFilter({})
    }

    //page 이동
    useEffect(() => {
        const {_start, _limit} = params;
        if ((_limit !== perPage || _start !== page * perPage)) {
            updateParams({...params, _start: page * perPage, _limit: perPage})
        }
    }, [page, perPage])

    useEffect(() => {
        dispatch(actions.listRequest({
            type: TYPE,
            ...params,
            ...filter
        }))
    }, [params, filter])


    // //page 이동
    // useEffect(() => {
    //     const {_start, _limit, classification_eq} = params;
    //     if (_limit !== perPage || _start !== page * perPage) {
    //         updateParams({...params, _start: page * perPage, _limit: perPage})
    //     }
    //
    // }, [page, perPage])
    //
    // useEffect(() => {
    //     //const filter = category && {program_category_eq:category} || {}
    //     dispatch(actions.listRequest({type: TYPE, ...params,...filter}))
    //
    // }, [params,category,user,filter])
    //
    // useEffect(() => {
    //     const _filter = {};
    //
    //     _filter[`_where[0][apply_user.username]`] = user.username
    //     setFilter({..._filter})
    //     // user && console.log(user);
    // }, [user])


    return (
        <div className="myProgram">
            <Helmet title="마이페이지 > 지원사업 신청 확인"/>
            <BreadCrumb list={route.mypage}/>
            <div className="sub">
                <div className="wrap">
                    <SubLeftMenu
                        title={route.mypage.title}
                        lnbList={route.mypage.list}
                    />
                    <div className="sub__content">
                        <h1 className="sub__title">
                            <span className="">지원사업 신청 확인</span>
                        </h1>
                        <div className="board">
                            <BoardHeader totalCount={totalCount}
                                         title={'지원사업 신청 확인'}
                                         onChange={handleChange}
                                         onReset={handleReset}
                                         fields={[
                                             {title: '사업명', value: 'appied_program.title_contains'}
                                         ]}
                            />
                            <BoardList
                                isLink={false}
                                totalCount={totalCount}
                                loading={loading}
                                data={list}
                                columns={columns}/>
                            <CustomPagination
                                pageSize={perPage}
                                pageIndex={page}
                                pageCount={pageCount}
                                onPageChange={({selected}) => setPage(selected)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MySupportProgram;
