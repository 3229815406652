import React, {useEffect, useMemo, useState} from 'react';
import {Helmet} from "react-helmet";
import {useForm} from "react-hook-form/dist/index.ie11";
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import * as actions from 'store/actions/content';
import moment from 'moment/dist/moment';
import {useTranslation} from 'react-i18next'
import Popup from "reactjs-popup";
import {apiUrl} from "config";
import * as api from 'lib/content';
//components
import {CustomPagination} from 'components/Custom'
import {BoardHeader_e, BoardList_e} from 'components/Board_e';
import BoardWrite from 'components/Write/BoardWrite_c';


import route from 'route';
const TYPE = `board-consultings`;

const DeletePop =({onSubmit, columns2,test,handleSubmit,admUser,users,totalCount,handleReset,handleChange,loading,list,user,selectCheck,setOpenDele}) => {

        return (
            <Popup open closeOnDocumentClick  position="right center">
                <div className="popup_overlay">
                    <div className="popup_content delete_height">
                        <div className="program_popup">
                            <div className="modal_header">
                                <h5 className="modal_title">
                                    신청 취소하기
                                </h5>
                                <button  type="button" onClick={()=>{setOpenDele(false)}}
                                         className="overlay_close_x close">
                                    {/*<button onClick={close} type="button" className="overlay_close_x close">*/}
                                    <div className="element-invisible"
                                         aria-hidden="true">X</div>
                                </button>
                            </div>

                            <div className="modal_body">
                                <form onSubmit={handleSubmit(test())}>

                                    <BoardList_e
                                        totalCount={totalCount}
                                        loading={loading}
                                        data={list}
                                        user={user}
                                        columns={columns2}
                                        selectCheck = {selectCheck}
                                        admUser={admUser}
                                        users={users}
                                    />

                                    <div className="erasebutton_wrap">
                                        <div className="course_overlay_footer">
                                            {/*<button className="btn btn_info form-submit">인쇄하기</button>*/}
                                            {/*<Link to="/member/mystudyprogram"></Link>*/}
                                            <button type="submit"  className="overlay_close consulting"> 선택 취소하기 </button>
                                        </div>
                                    </div>
                                </form>

                            </div>

                            {/*<div className="course_overlay_footer">*/}
                            {/*    /!*<button className="btn btn_info form-submit">인쇄하기</button>*!/*/}
                            {/*    /!*<Link to="/member/mystudyprogram"></Link>*!/*/}
                            {/*    <button type="button" onClick={()=>{setOpenDele(false)}} className="overlay_close"> 닫기 </button>*/}
                            {/*</div>*/}

                        </div>
                    </div>
                </div>
            </Popup>
        )
    }


export default DeletePop;
