import React, {useEffect, useMemo, useState} from 'react';
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import {useParams, useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';

import BreadCrumb from 'components/BreadCrumb';
import route from 'route';
import SubLeftMenu from 'components/SubLeftMenu';
import {BoardDetail} from 'components/Board';

const TYPE = `board-cleans`;
/*import BoardWrite from 'components/Write/BoardWrite';*/
import CleanWrite from 'components/Write/CleanWrite';

const Clean = () => {
    const [reload, setReload] = useState(false);
    const {user,isLoggedIn} = useSelector(({auth}) => ({...auth}));
    return (
        <div className="onair content introduction">
            <Helmet title="클린신고"/>
            <BreadCrumb list={route.depth5}/>
            <div className="sub">
                <div className="wrap">
                    <SubLeftMenu
                        title={route.depth5.title}
                        lnbList={route.depth5.list}/>
                    <div className="sub__content">
                        <h1 className="sub__title introduction_title">
                            <span className="">클린신고</span>
                        </h1>
                        <div className={'sub__ment'}>
                            <div className={'ment_box'}>
                                {/*<div className={'clean_left'}><img className={'cleanTest_img'}
                                                                   src={'/assets/images/ban1.png'}/></div>*/}
                                <div className={'clean_right'}>
                                    <h3>클린신고란?</h3>
                                    <p className={'cleanTest_ment'}>
                                        광주전남권역 대학원격교육지원센터와 관련하여 발생한, 법령위반 및 반윤리적 행위에 대한 신고를 받고 있습니다.<br/>
                                        신고내용은 육하원칙에 따라 반드시 사실에 근거한 내용으로 명확하게 작성하여 주시고, 가급적 증빙자료나 확인방법 등 참고자료를 첨부하여 주시기 바랍니다.
                                    </p>
                                </div>
                            </div>

                                <div className={'submit_container'}>
                                    <div className={'sub__clean_btn'}>
                                    {

                                            (_.get(user, 'role._id') == '5fed5533258df16ef195ae04') ?
                                                <div>
                                            <Link to={`/community/clean/intro`}><button className={'btn_write'}> 답변등록</button></Link>
                                        </div>
                                    :
                                    _.get(user,'role.name') &&
                                                <div>
                                        <CleanWrite
                                            setReload={setReload}
                                            reload={reload}
                                            type={TYPE}
                                            trigger={<button className={'btn_write'}>클린신고서 작성</button>}
                                            title={"클린신고"}
                                            user={user}
                                        />
                                        <Link to={`/community/clean/intro`}><button className={'btn_check'}> 답변확인</button></Link>
                                       </div>

                                    }
                                    
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Clean;