/** 언론보도 상세 */
import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {useParams, useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';

import BreadCrumb from 'components/BreadCrumb';
import route from 'route';
import SubLeftMenu from 'components/SubLeftMenu';
import {BoardDetail} from 'components/Board';

const TYPE = `board-presses`;
const NewsDetail = () => {
    const dispatch = useDispatch();
    const {view = {}, loading, errorMsg = ''} = useSelector(({content}) => ({...content}));
    const {id} = useParams();
    const history = useHistory();

    useEffect(() => {
        dispatch(actions.viewRequest({type: TYPE, id}))
    }, [id])

    //컨텐츠 데이터 로딩 실패시
    useEffect(() => {
        if (!loading && _.isEmpty(view) && errorMsg) {
            history.push('/onair/news');
        }
    }, [view, loading, errorMsg])

    return (
        <div className="onair">
            <Helmet title="언론보도"/>
            <BreadCrumb list={route.onair}/>
            <div className="sub">
                <div className="wrap">
                    <SubLeftMenu
                        title={route.onair.title}
                        lnbList={route.onair.list}/>
                    <div className="sub__content">
                        <h1 className="sub__title">
                            <span className="">언론보도</span>
                        </h1>
                        <p className="sub__description">다양한 소식 및 안내사항을 확인해주세요</p>
                        <BoardDetail type={TYPE} data={view} id={id}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsDetail;
