import React, { useState, useCallback,useEffect } from 'react';
import 'react-modal-video/scss/modal-video.scss';

import LeftBoard from './LeftBoard';
import RightBoard from './RightBoard';

/*const leftBoardList = [
    // {
    //     id: '1',
    //     url: 'https://www.jnilbo.com/m/view/media/view?code=2021031511002881154',
    //     title: '동강대, 대학 원격교육 지원센터 구축 속도낸다',
    //     type: 'a',
    //     board:'news'
    // },
    {
        id: '2',
        url: 'https://n.news.naver.com/article/001/0012002349',
        title: '대학 원격교육 지원센터 10곳 선정',
        type: 'a',
        board:'news'
    },
    {
        id: '3',
        url: 'https://m.blog.naver.com/moeblog/222139745971',
        title: '권역 대학원격교육지원센터 선정 결과 발표',
        type: 'a',
        board:'notice'
    }
];*/

const Index = ({notices={}, news={}}) => {
    const [type, setType] = useState('notice');
    const [list, setList] = useState([]);

    const handleChangeType = useCallback((name) => {
        setType(name);
    }, [type]);

    useEffect(()=>{
        if(type == 'notice'){
            setList(notices)
        }
        else if(type == 'news'){
            setList(news)
        }
    },[type,notices])

    return (
        <div className="mainBoard">
            <div className="wrap">
                <LeftBoard type={type} onChangeType={handleChangeType} list={list} />
                <RightBoard />
            </div>
        </div>
    );
};

export default Index;
