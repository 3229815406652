import React, {useEffect, useMemo, useState} from 'react';
import * as api from 'lib/content';
import AuditWrite from 'components/Write/AuditWrite';


const AuditButton = ({data,user,setReload,reload}) =>{
    const {audit_title, year, month, audit_num, createdAt, audit_prop,status,audit_result} = data
    const [auditStatus,setAuditStatus] = useState('검수 전');
    const [auditLog,setAuditLog] = useState();


    const emailSend = async () =>{
        try{
            let callStatus = ''
            if(auditStatus == '미승인'){
                callStatus = '검수를'
            }
            else{
                callStatus = '재검을'
            }
            if(_.get(user,'id') != _.get(data,'audit_author.id')){
                swal({
                    title: `담당 대학교만 검수요청할 수 있습니다.`, text: '해당 학교의 담당자가 아닙니다.',
                    icon: "warning", timer: 3000, buttons: false,
                });
                return
            }
            console.log(`${_.get(user,'organization')}에서 ${_.get(data,'audit_title')} 강좌의 ${callStatus} 신청합니다.`)
            //return
            const parames = {
                to:[`hey@jnu.ac.kr`,`ysb619@jnu.ac.kr`],
                //to:'zz123a@naver.com',
                from: _.get(user,'email'),
                subject: `품질관리 요청 알림`,
                text: `${_.get(user,'organization')}에서 ${_.get(data,'audit_title')} 강좌의 ${callStatus} 신청합니다.`,
                //html: `<div style="color:yellow">테스트</div>`,
            }

            const {status} = await api.emailSend({...parames });
            if(status != 200){
                throw new Error()
            }

            swal({
                title: `이메일 발송 성공`, text: '성공',
                icon: "success", timer: 3000, buttons: false,
            });
        }
        catch(e){
            swal({
                title: `이메일 발송에 실패했습니다.`, text: e.message,
                icon: "warning", timer: 3000, buttons: false,
            });
        }
        finally {

        }
    }
    useEffect(()=>{
        async function getLogs() {
            try{
                const {data:log_data,
                    status:log_status} = await api.gets({type: 'audit-logs',
                    audit_content:_.get(data,'id'),
                    _sort:"createdAt:desc"
                });
                setAuditLog(log_data[0])

                if(_.isEmpty(_.get(log_data,'0.audit_status'))){
                    setAuditStatus('미승인')
                }
                else if(_.get(log_data,'0.audit_status') == 'audit_start'){
                    setAuditStatus('재검')
                }
                else if(_.get(log_data,'0.audit_status') == 'audit_end'){
                    setAuditStatus('검수 완료')
                }

            }
            catch (e) {
                swal({
                    title: `로그데이터를 받아오지 못했습니다.`, text: e.message,
                    icon: "warning", timer: 3000, buttons: false,
                });
            } finally {

            }
        }
        getLogs()

    },[data])


    return (
        <tr>
            <td className={'course_year'}>{year}</td>
            <td className={'course_title'}>{audit_title}
                {/*<span className={'prop_name'}>{audit_prop}</span>*/}
            </td>
            <td>{audit_prop}</td>
            <td>{audit_num}
            </td>
            <td>{moment(createdAt).format('YYYY.MM.DD')}</td>
            {_.get(user, 'role.id') == '5fed5533258df16ef195ae04' ?
            <>
                <td>
                    <AuditWrite title={auditStatus == '미승인' ? '검수' : '수정'}
                                setReload={setReload}
                                data={data}
                                reload={reload}
                                mode={auditStatus == '미승인' ? '' : 'd'}
                                trigger={<button
                                    className={auditStatus == '미승인' ? 'check_butt' : 'modify_butt'}>{auditStatus == '미승인' ? '검수' : '수정'}</button>}
                                type={auditStatus == '미승인' ? 'audit' : 'edit'}/>
                </td>
                <td><span>
                    <AuditWrite title={'처리'}
                                data={data}
                                setReload={setReload}
                                reload={reload}
                                trigger={<button
                                    className={`result_check`}>처리</button>}
                                type={'audit_register'}/>
                    </span>
                </td>
            </>
                :
                <td>
                    {_.get(auditLog,'final_score',0)}
                </td>
            }
            {/*검수 상태*/}
            <td>
                {
                    _.get(user,'role.id') == '602a265ab2b9050bdac24bcd' ?
                        (auditStatus == '미승인' ? <button className="examineRequest">검수요청</button> :


                                 <button className={`resultConfirm`}>결과확인</button>
                        )

                        : <span className={auditStatus == "검수 완료" ? "result_state" : "" }>{auditStatus}</span>
                }
            </td>
            {/*상태*/}
            <td>
                {
                    _.get(user,'role.id') == '602a265ab2b9050bdac24bcd' ?
                        (auditStatus == '미승인' ?

                                <button onClick={()=>emailSend()} className="resultSend">요청</button> :
                                <AuditWrite title={'검수 결과'}
                                            data={data}
                                            setReload={setReload}
                                            reload={reload}
                                            trigger={<button
                                                disabled={auditStatus == '미승인' ? true : false}
                                                className={`result_check ${auditStatus == '미승인' ? 'deactive' : 'active' }`}>확인</button>}
                                            type={'result'}/>
                        ) :

                        <AuditWrite title={'검수 결과'}
                                    data={data}
                                    setReload={setReload}
                                    reload={reload}
                                    trigger={<button
                                        disabled={auditStatus == '미승인' ? true : false}
                                        className={`result_check ${auditStatus == '미승인' ? 'deactive' : 'active' }`}>결과확인</button>}
                                    type={'result'}/>
                }
            </td>
        </tr>
    )
}

export default AuditButton;