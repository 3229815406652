import React, {useEffect, useMemo, useState} from 'react';
import {useRouteMatch, generatePath, Link} from 'react-router-dom';
import {apiUrl} from 'config';
import moment from 'moment/dist/moment';

const ProgramItem = ({data = {}, path}) => {
    const [parmas, setParmas] = useState({});

    const {
        id = '',
        title = '',
        allowednumber = null,
        apply_startdate = new Date(),
        apply_allowednumber = 0,
        apply_maxnumber = 0,
        apply_enddate = new Date(),
        learning_enddate = new Date(),
        learning_startdate = new Date(),
        maxnumber = null,
        thumbnailimages = {},
        study_program_applies = [],
        program_address = "",
        props = "",
        dev_org = ""
    } = data;
    const today = moment().format(`YYYY-MM-DD`);
    const d_day = moment(apply_enddate).diff(today, 'days');
    const applied = applied_num + applied_over;
    const apply_started = moment(apply_startdate).format(`YYYY-MM-DD`);
    const apply_ended = moment(apply_enddate).format(`YYYY-MM-DD`);
    const learning_ended = moment(learning_enddate).format(`YYYY-MM-DD`);
    const learning_started = moment(learning_startdate).format(`YYYY-MM-DD`);
    const applied_num = study_program_applies.filter(item => item.apply_status == "5ff6b769bf55b944295d63e8").length  // 수강 신청 완료 상태 개수
    const applied_over = study_program_applies.filter(item => item.apply_status == "5ff6b774bf55b944295d63e9").length // 수강 신청 완료(정원 초과) 개수
    // console.log(data);
    const InnerData = ({data = {}}) => {
        return (
            <>
                {/*{(d_day > 0 && d_day < 7) && <span className="d_day">D-{d_day}</span>}*/}
                <div className="thumb">
                    {/*<img
                        src={!_.isEmpty(_.get(data, 'thumbnail')) ? _.get(data, 'thumbnail') : `${apiUrl}${_.get(data, 'thumbnailimages.0.url')}`}
                        alt=""/>*/}
                    <img
                        src={!_.isEmpty(_.get(data, 'thumbnailimages.0.url')) ? `${apiUrl}${_.get(data, 'thumbnailimages.0.url')}` : '/assets/images/thumb_none.png'}
                        alt=""/>
                </div>
                <div className="info">
                    <div className='info_div_one'>
                        <div>
                            <span className='div_one_p'>
                                {
                                    (today < apply_ended && '신청가능') ||
                                    (today > apply_started && '신청마강') ||
                                        '신청가능'
                                }
                            </span>

                            {/*<span className={`status ${1 === 2 && 'disabled'}`}>*/}





                            <h2>{title}</h2>
                        </div>
                    </div>
                    <div>
                        {
                            (!_.isEmpty(dev_org) && (!_.isEmpty(props)) ?
                                <p className="date">
                                    {/*<img src='/assets/images/info_date.png'/>*/}
                                    {dev_org} | {props}
                                </p>
                                : (!_.isEmpty(dev_org)) ?
                                <p className="date">
                                        {/*<img src='/assets/images/info_date.png'/>*/}
                                        {dev_org}
                                    </p>
                                : (!_.isEmpty(props)) &&
                                <p className="date">
                                    {/*<img src='/assets/images/info_date.png'/>*/}
                                    {props}
                                </p>
                            )
                         }



                        {/*{dev_org && props && (
                            <p className="date">
                                <img src='/assets/images/info_date.png'/>
                                {apply_started} ~{apply_ended}
                                {dev_org} | {props}
                            </p>) || <>
                        <p><p className="date"><img src='/assets/images/info_date.png'/></p></p>
                        </> }*/}

                        {/*<span className={`status ${1 === 2 && 'disabled'}`}>
                        {(today < apply_ended && today > apply_started) ? '신청가능' : '신청마감'}
                        <span className={`status ${1 === 2 && 'disabled'}`}>
                            진행 중
                        {(today < apply_ended && today > apply_started) ? '신청가능' : '신청마감'}
                    </span>*/}
                    </div>
                </div>


                {/* 호버 레이아웃 */
                }
                <div className="hoverLayout">
                    <ul>
                        <li>
                            신청기간 : 상시
                        </li>
                        <li>
                            진행기간 : 상시
                        </li>
                    </ul>
                    {/*<p>View more</p>*/}
                    {/*<h3>{title}</h3>
                        <ul>
                        <li>
                        신청기간 : 상시
                        </li>
                        <li>
                        진행기간 : 상시
                        </li>
                    {/*<li>
                            장소 : {program_address}
                        </li>*/}
                    {/*<li>
                            신청 / 정원 : {apply_allowednumber} / {apply_maxnumber}
                        </li>
                        </ul>*/}
                </div>
            </>
        )
    }
    return (
        <div className="programList__item">
            {/*<Link to={`/program/studyprogram/${id}`}>*/}
            {/*<a href={data.url}>*/}
            <Link to={`/content/${id}`}>
                <InnerData data={data}/>
            </Link>
            {/*{!_.isEmpty(_.get(data, 'url')) ? <a href={_.get(data, 'url')} target={'_blank'}>
                <InnerData data={data}/>
            </a> : <Link to={`/program/studyprogram/${id}`}>
                <InnerData data={data}/>
            </Link>}*/}
            {/*<a href={data.url.replace('http://34.84.15.163:6061','https://gjudec-lms.obenef.com')}>*/}

        </div>
    );
};

export default ProgramItem;