export default {
    depth1: {
        title: '센터 소개',
        code:'about',
        list: [
            {
                title: '인사말',
                // url: '#'
                url: '/about/introduction'
            }, {
                title: '조직도',
                url: '/about/organization'
                // url: '/about/membership'
            }, {
                title: '규정 및 지침',
                url: '/about/guideline'
                // url: '/about/membership'
            }, {
                title: '오시는 길',
                // url: '#'
                url: '/about/map'
            }
        ]
    },
    depth2: {
        title: '학습관리시스템',
        code:'lms',
        url: 'https://gnue-lms.gesturecloud.io/member/login',
        type:'a',
        list: [
            {
                title: 'LMS',
                // url: '#'
                //type:'a',
                //taget:'_blank',
                url: 'https://gnue-lms.gesturecloud.io/member/ext_login',
                type:'a'
                //href: 'https://eclass-lms.seoyeong.ac.kr/member/ext_login'
            },
            /*{
                title: '참여대학',
                // url: '#'
                url: '/university/college'
            }*/
        ]
    },
    depth3: {
        title: '콘텐츠',
        code:'content',
        list: [
            {
                title: '비교과/공개강좌/임직원교육',
                url: '/content/repository'
                // url: '/resource/space'
            },
            {
                title: '품질관리 시스템',
                url: '/content/auditcontent'
                // url: '/resource/device'
            },
            /*{
                title: '우수 이러닝 강좌',
                url: '/content/exellentcontent'
                // url: '/resource/device'
            },*/
        ]
    },
    depth4: {
        title: '원격수업 지원',
        code:'resource',
        list: [
            /*{
                title: '스튜디오 예약',
                url: '/resource/studio'
                // url: '/help/remote'
            },*/
            /*{
                title: '역량 진단평가',
                url: '/resource/evaluate'
            },*/
            {
                title: '역량강화 워크숍',
                url: '/resource/competencyworkshop'
                // url: '/help/qna'
            },
            {
                title: '원격수업 컨설팅',
                url: '/resource/consulting',
                //type:'a'
                // url: '/help/remote'
            },
            {
                title: '원격수업 우수사례',
                url: '/resource/youtube'
                // url: '/resource/device'
            }
        ]
    },
    depth5: {
        title: '커뮤니티',
        code:'community',
        list: [
            {
                title: '공지사항',
                // url: '#'
                url: '/community/notice'
            },
            {
                title: '센터 소식',
                url: '/community/news'
                // url: '/board/faq'
            },
            {
                title: 'FAQ',
                // url: '#'
                url: '/community/faq'
            },
            {
                title: 'Q&A',
                url: '/community/qna'
                // url: '/board/notice'
            },
            /*{
                title: '클린신고',
                url: '/community/clean'
                // url: '/board/faq'
            }*/
        ]
    },
    depth6: {
        title: '마이페이지',
        code:'member',
        list: [

            {
                title: '운영중인 강좌',
                url: '/member/InstructorCourse'
                //url: '/member/mystudyprogram'
            },
            {
                title: '나의 강좌: 콘텐츠',
                url: '/member/mycontent'
                //url: '/member/mystudyprogram'
            },
            {
                title: '프로그램 신청 확인',
                // url: '#'
                url: '/member/mystudyprogram'
            },
            // {
            //     title: '컨설팅 신청 확인',
            //     url: '/member/myconsulting'
            //     // url: '/board/faq'
            // },
           /* {
                title: '공간·장비 예약 현황',
                url: '/member/myresource'
                // url: '/board/faq'
            },*/
            /*{
                title: '나의역량진단평가',
                url: '/member/MyEvaluation'
                // url: '/board/faq'
            },*/
            {
                title: '개인정보 수정',
                url: '/member/changeinfo'
                // url: '/board/faq'
            }

        ]
    }
};
