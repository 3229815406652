import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {BsArrowRight, RiArrowDropRightLine} from 'react-icons/all';
import {useDispatch, useSelector} from "react-redux";
import * as actions from 'store/actions/auth';
import route from 'route';

import MobileNavItem from '../MobileNav/MobileNavItem';

const {
    mypage
} = route;
const MobileUserMenu = ({openUserMenu, onChangeOpenUserMenu}) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const {user, isLoggedIn} = useSelector(({auth: {user, isLoggedIn}}) => ({
        user, isLoggedIn
    }));

    const handleLogout = () => {
        dispatch(actions.logout())
        onChangeOpenUserMenu(false)
        history.push('/')
    }

    return (
        <>
            <div
                className={`dim ${openUserMenu ? 'active' : ''}`}
                onClick={() => onChangeOpenUserMenu(false)}/>
            <nav className={`mobileUserMenu ${openUserMenu ? 'active' : ''}`}>
                <header className="mobileUserMenu__header">
                    <div className="wrap">
                        사용자 메뉴
                        <button onClick={() => onChangeOpenUserMenu(false)}>
                            <BsArrowRight/>
                        </button>
                    </div>
                </header>
                <div className="mobileUserMenu__links">
                    {(!user || !isLoggedIn) && (<>
                        <a href="https://gnue-lms.gesturecloud.io/member/ext_login" onClick={() => onChangeOpenUserMenu(false)}>
                            로그인
                            <RiArrowDropRightLine/>
                        </a>
                        <Link to="/member/register/information" onClick={() => onChangeOpenUserMenu(false)}>
                            회원가입
                            <RiArrowDropRightLine/>
                        </Link>

                    </>)}

                    {user && isLoggedIn && (<>
                            <div className={`mobileUserMenu__usercontainer`}>
                                <span className={`username`}>
                                    {`${user.name} 님` || `email: ${user.email}` || '-'}
                                </span>
                                <button className={`logout`} onClick={handleLogout}>로그아웃</button>
                            </div>
                            <div className={`mobileNav__link-item`}>
                                <Link to={'/member/mycontent'}>
                                    마이페이지
                                </Link>
                            </div>
                            {/*<div className="mobileNav__links">
                    <MobileNavItem data={mypage}/>
                    </div>*/}</>
                    )}
                </div>
            </nav>
        </>
    );
};

export default MobileUserMenu;
