import React from 'react';
import ReactPaginate from 'react-paginate';
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md';
import {AiOutlineDoubleLeft, AiOutlineDoubleRight} from 'react-icons/ai';

const CustomPagination = ({pageCount, onPageSizeChange, onPageChange, pageSize = 10, pageIndex = 0, pageSizeList = [5, 10, 20]}) => {
    if (pageCount <= 1) return null;
    return (
        <div className="paginationLayout">
            <ReactPaginate
                containerClassName={`pagination`}
                pageClassName={`page-item`}
                pageLinkClassName={`page-link`}
                activeLinkClassName={`active`}
                previousClassName={`page-item page-item--arrow`}
                nextClassName={`page-item page-item--arrow`}
                previousLabel={<MdKeyboardArrowLeft/>}
                nextLabel={<MdKeyboardArrowRight/>}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={onPageChange}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                ariaLabelBuilder={(pageNumber) => (pageIndex + 1 === pageNumber) && '선택' || `페이지 ${pageIndex + 1}`}
                forcePage={pageIndex}/>
        </div>
    )
}

export default CustomPagination;
