import React, {useEffect, useCallback, useState} from 'react';
import BreadCrumb from 'components/BreadCrumb';
import route from 'route';
import SubLeftMenu from 'components/SubLeftMenu';
import SpaceInfo from 'components/SpaceInfo';
import {useSelector} from 'react-redux';
import {Helmet} from "react-helmet";
import {CustomLoading} from "components/Custom";

//예약캘린더
import ScheduleCalendar from 'components/ScheduleCalendar';
import ScheduleCalendar2 from 'components/ScheduleCalendar2';

//graphql 관련
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import * as query from "queries/resource";

const TYPE = 'schedule-spaces';
const Space3 = () => {
    const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}));
    const [space, setSpace] = useState({}); //선택된 공간
    const [schedules, setSchedules] = useState([]); //스케쥴 목록
    const lazyQueryOpt = {
        fetchPolicy: 'no-cache',
        onCompleted: ({scheduleSpaces = []}) => setSchedules([...scheduleSpaces])
    }
    const variables = _.isEmpty(space) && {} || {where: {"space_resource.id": space.id}};
    const [getSchedules, {loading}] = useLazyQuery(query.SCHEDULE_SPACES_QUERY, {...lazyQueryOpt, variables});

    return (
        <div className="content introduction">
            <div className="resource">
                <Helmet title="스튜디오 예약"/>
                <BreadCrumb list={route.depth4}/>
                <div className="sub">
                    <div className="wrap">
                        {/*<SubLeftMenu
                        title={route.depth4.title}
                        lnbList={route.depth4.list}
                    />*/}
                        <div className="sub__content introduction_content">
                            <h1 className="sub__title introduction_title">
                                <span className="">스튜디오 예약</span>
                            </h1>

                            {/* 공간 안내 */}
                            <SpaceInfo hiddenHeader={true} onSubmit={setSpace}/>

                            {/* 공간장비 예약캘린더 */}
                            <div className="section calander">

                                <div className="calendar mb-3" style={{height: '50px'}}>
                                    <h2 className="part_title">공간 예약캘린더</h2>
                                    <div className={`calendar-status`}>
                                        <div className={`calendar-status-confirmed`}>승인</div>
                                        <div className={`calendar-status-disprove`}>미승인</div>
                                    </div>
                                    {/*{loading && (<span className="loading-text">로딩중...</span>)}*/}
                                    <button onClick={getSchedules} className="refresh"/>
                                </div>
                                {(loading || _.isEmpty(space)) && (<CustomLoading loading={true}/>)}
                                <div className={(loading || _.isEmpty(space)) && `d-none` || ``}>{
                                    (<ScheduleCalendar2 space={space} data={schedules} getData={getSchedules}/>)
                                }</div>

                                {/*<ScheduleCalendar data={schedules} getData={getSchedules}/>*/}
                                {/*<ReservationCalendar hiddenHeader={true} events={reservationlist}/>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Space3;
