//검수 신청서 작성
import React, {useEffect, useState, useCallback} from 'react';
import {useForm} from "react-hook-form/dist/index.ie11";
import moment from 'moment/dist/moment';
import * as api from 'lib/content';
import route from 'route';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';
import Quill from "../../Editor/Quill";

const defaultValues = {
    appied_program: {
        _id:'',
    },
    attached_files:[]
};


const AuditContentUpForm = ({
                       onSubmit,
                       data,
                       mode = '',
                       type,
                       handleCancel,
                       cancelSubmit,
                       apply_files,
                       reload,
                       bigo,
                       setReload,
                       user = ''
                   }) => {

    const { register, handleSubmit, watch, errors, setValue,  clearErrors } = useForm({defaultValues});



/*

    const AuditContent = () => {
        for (let i=0; i < 13; i++) {
            return(<>
                <span>
                     콘텐츠
                 </span>
                {/!*!// 1. 사용자에게 파일 첨부를 받는다*!/}
                <div className="input_container">
                    {/!*<input type="hidden" name="id" value={_.get(data,'id')}  ref={register({ required: true })} />*!/}
                    <input type="file" multiple name="files" ref={
                        register()
                    }  />
                    {errors.files && (
                        <small className="text-danger">{errors.files.message}</small>
                    )}
                    {(!_.isEmpty(_.get(data,'attached_file',[])) && mode=='m') && <div className="files_container">
                        <span className="files_title">업로드 된 파일</span>
                        {_.get(data,'attached_file').map((item,index)=><span className="file_once" key={index}><span className="files_item">{item.name}</span> <span className="files_delete" onClick={file_del('upload/files',item.id,reload,setReload)}>삭제</span></span>)}

                    </div>}
                </div>
            </>)
        }
    }
*/


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className={'audit_top'}>
                    <h3 className={'write_title'}>강좌 기본 정보</h3>
                    <span className={'write_info'}>콘텐츠를 업로드할 강좌의 기본 정보를 조회합니다.</span>
                </div>
                <div className={'audit_middle'}>
                    <div className={'Audit_Form'}>
                        <span>강좌명</span>
                        <div className={'audit_text'}>{_.get(data,'audit_title')}</div>
                    </div>
                    <div className={'Audit_Form'}>
                        <span>개발기관</span>
                        <div className={'audit_text'}>{_.get(data,'audit_org')}</div>
                    </div>
                    <div className={'Audit_Form'}>
                        <div className={'calander_select'}>
                            <div className="Audit_form_box_left">
                                <span>개발연도</span>
                                <div className={'audit_subtext'}>{_.get(data,'year')}</div>
                            </div>
                            <div className="Audit_form_box_right">
                                <span className={'subspan'}>총 차시</span>
                                <div className={'audit_subtext'}>{_.get(data,'audit_num')}</div>
                            </div>
                        </div>
                    </div>
                    <div className={'Audit_Form'}>
                        <div className={'calander_select'}>
                            <div className="Audit_form_box_left">
                                <span>강좌분류</span>
                                <div className={'audit_subtext'}>공동 활용 콘텐츠</div>
                            </div>
                            <div className="Audit_form_box_right">
                                <span className={'subspan'}>제출일자</span>
                                <div className={'audit_subtext'}>{moment(_.get(data,'createdAt')).format('YYYY.MM.DD')}</div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={'audit_bottom'}>
                    {/*<span>
                        콘텐츠
                    </span>
                    // 1. 사용자에게 파일 첨부를 받는다
                    <div className="input_container">
                        <input type="hidden" name="id" value={_.get(data,'id')}  ref={register({ required: true })} />
                        <input type="file" multiple name="files" ref={
                            register()
                        }  />
                        {errors.files && (
                            <small className="text-danger">{errors.files.message}</small>
                        )}
                        {(!_.isEmpty(_.get(data,'attached_file',[])) && mode=='m') && <div className="files_container">
                            <span className="files_title">업로드 된 파일</span>
                            {_.get(data,'attached_file').map((item,index)=><span className="file_once" key={index}><span className="files_item">{item.name}</span> <span className="files_delete" onClick={file_del('upload/files',item.id,reload,setReload)}>삭제</span></span>)}

                        </div>}
                    </div>*/}

                    <div className="num_container">
                    {
                        [...Array(_.get(data,'audit_num'))].map((item, idx) => {
                        return (
                           <div className="audit_num_title" key={idx}>
                               <span>
                                   {idx+1}차시 콘텐츠
                               </span>
                           </div>
                            )
                        })
                    }
                    </div>
                    <div>
                    {
                        [...Array(_.get(data,'audit_num'))].map((item, idx) => {
                            return (
                                <div className="input_container">
                                    {/*<input type="hidden" name="id" value={_.get(data,'id')}  ref={register({ required: true })} />*/}
                                    <input type="file" multiple name="files" ref={
                                        register()
                                    }  />
                                    {errors.files && (
                                        <small className="text-danger">{errors.files.message}</small>
                                    )}
                                    {(!_.isEmpty(_.get(data,'attached_file',[])) && mode=='m') &&
                                    <div className="files_container">
                                        <span className="files_title">업로드 된 파일</span>
                                        {_.get(data,'attached_file').map((item,index)=><span className="file_once" key={index}><span className="files_item">{item.name}</span> <span className="files_delete" onClick={file_del('upload/files',item.id,reload,setReload)}>삭제</span></span>)}

                                    </div>}
                                </div>
                            )
                        })
                    }
                    </div>

                </div>
            </div>
            <div className={'audit_footer'}>
                <button className={'list_button'} type={'button'}>목록</button>
                <button className={'submit_button'}>등록</button>
            </div>
        </form>
    )
}


export default AuditContentUpForm;
