import React, {useState, useEffect} from 'react';
import {Helmet} from "react-helmet";
import SubLeftMenu from 'components/SubLeftMenu';
import ChangeinfoForm from 'components/Forms/ChangeinfoForm';
import {useDispatch, useSelector} from "react-redux";
import * as authActions from 'store/actions/auth';
import * as api from 'lib/auth'
import axios from 'axios';
import {Link, Redirect} from 'react-router-dom'
import moment from 'moment/dist/moment';
import BreadCrumb from '../../components/BreadCrumb';
import route from 'route';

//graphql 관련
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import * as auth from "queries/auth";
//import * as cQuery from "queries/content";

const handleSubmit = ({dispatch}) => async (formData) => {
    const {id='',userName = '', email_check_value,regiNum = '',identity = '', after_password = '',birth_type='', phone="", birth = '',emailSend='',smsSend='',gender='',hp='',name='',organization='',zip_code='',addr1='',addr2='',major='' } = formData;
    const params = {
        hp:hp,
        organization:organization,
        user_num:regiNum,
        identity:identity,
        password:after_password,
        major:major
    }
    //return
    try {
        const {data, status} = await api.updateUser(id,params);
        if(status !== 200){
            throw new Error('에러 발생');
        }
        else{
            swal('회원수정이 완료되었습니다.','','success')
        }
    } catch (e){
        swal(e.message,'','warning')
    } finally{

    }
}





const Changeinfo = () => {
    const [userData,setUserData] = useState(null);
    const [emailcheckdata,setEmailCheckData] = useState(false);
    const [email_id,setEmailId] = useState()
    const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}));

    const dispatch = useDispatch();
    return (
        <div className="content">
            <Helmet title="회원정보수정"/>
            <BreadCrumb list={route.depth6}/>
            <div className="sub">
                <div className="wrap">
                    <SubLeftMenu
                        title={route.depth6.title}
                        lnbList={route.depth6.list}
                    />

                    <div className="sub__content">
                        <h1 className="sub__title">
                            <span className="">개인정보 수정</span>
                        </h1>
                    <ChangeinfoForm onSubmit={handleSubmit(dispatch)} userData={user}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Changeinfo;
