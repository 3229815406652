/** 전남CKL 온에어 */
import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';

import Workshop from './Workshop';

import {NotFound} from 'components/Errors';

const Community = ({match}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => dispatch(actions.init())
    }, []);

    return (
        <Switch>
            <Route path={`${match.path}/workshop/:id`} render={() => <Workshop/>}/>
            <Route path={`${match.path}/workshop`} render={() => <Workshop/>}/>

            <Route path="*" component={NotFound} status={404}/>
        </Switch>
    );
};

export default Community;
