import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';

import {NotFound} from 'components/Errors';
import PanelLayout from './PanelLayout'

const Lms = ({match}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => dispatch(actions.init())
    }, []);

    return (
        <Switch>
            <Route path={`${match.path}/module/course/:course_id/:id`} render={() => <PanelLayout/>}/>

            <Route path="*" component={NotFound} status={404}/>
        </Switch>
    );
};

export default Lms;
