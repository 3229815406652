import React, {useEffect, useMemo, useState} from 'react';
import {useRouteMatch, generatePath, Link} from 'react-router-dom';
import {apiUrl} from 'config';
import moment from 'moment/dist/moment';

const LocalDataItem = ({ data={}}) => {

    const [parmas, setParmas] = useState({});
    const defaultUrl = 'http://35.243.114.29:1347';
    const {
        title='',
        thumbnailimages_path='',
        summary='',
        attached_file_path='',
    } =data
    const thumbnailimages_list =thumbnailimages_path.split('^=^')

    return (
        <Link to={`/dataset/localdata/${data.id}`} className='localdata__item'>
            <div className="thumb">


                    {
                        (thumbnailimages_list.length > 1) && thumbnailimages_list.map((item,idx) =>{
                        const [thumbName = '',detailName='' , thumbFileName = '', filePath = ''] = item.split('|')
                        if(idx>=1) return
                        return (<div key={idx}>
                            <img  src={`${defaultUrl}${filePath}${thumbFileName}`}></img>
                        </div>)

                    }) ||
                    <img src='/assets/images/image-not-found.png' alt=""/>
                }



                {/*{*/}
                {/*    data.thumbnailimages_path &&*/}
                {/*    <img src={`${data.thumbnailimages_path}`} alt=""/> ||*/}
                {/*    <img src='/assets/images/image-not-found.png' alt=""/>*/}
                {/*}*/}

            </div>
            <div className="info">
                <div className="info__top">
                    <h2>{data.title}</h2>
                    <p className="created">
                        생성일  {moment(data.createdAt).format(`YYYY-MM-DD`)}
                    </p>
                </div>
                <div className="description">
                    {data && data.summary&& data.summary}
                </div>
                <div className="info__foot">
                    <span>자세히보기</span>
                </div>
            </div>
        </Link>
    );
};

export default LocalDataItem;
