/*
 takeEvery: 여러번 실행했을때, 여러번 계속해서 실행되도 되는것.
 takeLatest: 여러번 실행했을때, 한번만 실행되야 되는것. ex)로그인 버튼
*/
import {put, spawn, takeEvery, takeLatest, call} from 'redux-saga/effects'
import * as actions from 'store/actions/auth';
import axios from 'axios';
import qs from 'qs';
import {showLoading, hideLoading} from 'react-redux-loading-bar'
import config from 'config'
import * as api from 'lib/auth'

/*
인증 요청
payload = {
    "identifier": "test@obenef.com",
    "password": "Chao0116"
}
 */
function* authorizationSaga({payload}) {
    const authToken = localStorage.getItem('jwt');
    try {
        if (authToken) {  //토큰이 있는 경우
            const {data, status} = yield call(api.getMy);
            yield put(actions.loadSuccess({...data}));
        } else {    //없는경우 (로그인 페이지에서 인증요청)
            const {data, status} = yield api.getAuth({...payload});
            const {jwt = '', user = {}} = data;

            localStorage.setItem('jwt', jwt);
            yield put(actions.loadSuccess({...user}));
        }
    } catch (e) {
        if (payload) {  //파라미터로 받은경우 메세지 alert(로그인페이지)
            swal({title: `인증 실패`, text: '유저 인증에 실패하였습니다.', icon: "warning", timer: 2000, buttons: false});
        }
        localStorage.removeItem('jwt');
        yield put(actions.loadFailure(e));
    } finally {
        yield put(hideLoading())
    }
}

/** 로그아웃 */
function* logoutSaga() {
    try {
        yield put(showLoading())
        localStorage.removeItem('jwt');
    } catch (e) {
        console.log(e, `logoutSaga Err`)
    } finally {
        yield put(hideLoading())
    }
}


function* watchAuth() {
    yield takeLatest(actions.AUTHORIZATION, authorizationSaga)  //토큰 및 유저정보 요청
    yield takeLatest(actions.LOGOUT, logoutSaga)  //로그아웃
}

export default function* root() {
    yield spawn(watchAuth);
}
