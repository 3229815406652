import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment/dist/moment';

//게시글 안내용 가져오기
import Quill from "../Editor/Quill";
import {useSelector, useDispatch} from 'react-redux';


const Item = ({ data, type='' }) => {

    //게시글 안내용 가져오기
    const {title = '', view_count = 0, body = null, attached_file = [], createdAt = new Date, author = {}} = data;
    const body2 = useSelector((state) => state.content.view.body)
    const list_data = moment(data.createdAt).format(`YYYY-MM-DD`);
    const type_name = type;
    let new_name;

    //카테고리 명 추출
    function type_name_sub() {
      if(type_name=="notice"){
          new_name = "공지사항";
      }
      else if(type_name=="news"){
          new_name= " 센터 소식"
      }
    }
    type_name_sub();

    //글자수자르기
    const new_title = data.title;
    const new_title_substr = new_title.substr(0,40)+"...";

    return (
        <Link to={`community/${type}/${data.id}`} className="link">
            <p className="main_list_cate">{new_name}</p>
            <h1 className="main_list_title">{data.title}</h1>
            <Quill isView={true} initialValue={body}/>
            <p className="main_list_date" >{list_data}</p>
        </Link>
    );
};

export default Item;
