
import React, {useEffect, useState, useCallback} from 'react';
import {useForm, Controller} from "react-hook-form/dist/index.ie11";
import moment from 'moment/dist/moment';
import * as api from 'lib/content';
import route from 'route';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';
import Quill from "../../Editor/Quill";
import ko from 'date-fns/locale/ko';


import ReactDatePicker, { registerLocale,setDefaultLocale  } from "react-datepicker";
registerLocale('ko', ko);


const defaultValues = {
    appied_program: {
        _id:'',
    },
    attached_files:[]
};
const file_del = (type,id,reload,setReload) => async() =>{
    try {
        const {status} = await api.del({type:type,id:id})
        if(status !== 200){
            throw new Error('에러 발생');
        }
        else{
            setReload(!reload)
            swal({
                title: `파일 삭제 완료`,
                icon: "success", timer: 10000, buttons: false,
            });
        }
    } catch (e) {
        swal({
            title: `파일 삭제 실패`, text: e.message,
            icon: "warning", timer: 3000, buttons: false,
        });
    } finally {

    }
}
const file_type_check = (value) => {
    //const {files: files} = data
    var type_check = true
    Object.entries(value).map((item, idx)=>{
        var file_kind = item[1].name.lastIndexOf('.')
        var file_name = item[1].name.substring(file_kind+1,item[1].length)
        var file_type = file_name.toLowerCase()
        var check_file_type = ['pdf','hwp','doc','docx','pptx','ppt']
        if(check_file_type.indexOf(file_type)==-1) {
            type_check = false;
            /*swal({
                title: `파일 유형이 맞지 않습니다.`, text: 'pdf,hwp,dox,docs,pptx,ppt 형식의 파일만 업로드할 수 있습니다.',
                icon: "warning", timer: 3000, buttons: false,
            });*/
        }
    })
    if(type_check == false){
        return '파일형식이 맞지 않습니다.';
    }
}
const WriteForm = ({onSubmit,data,mode='',type,handleCancel,cancelSubmit,apply_files,reload,bigo,setReload,user=''}) => {
    const [validation,setValidation] = useState({});
    const [status,setStatus] =useState('');
    const [secret,setSecret] = useState(false);
    const [filesApplys, setFilesApplys]=useState();


    const [max,setMax] = useState(false);

    const range = (start, end, step=1) => {
        let array = [];
        for (let i = start; i < end; ++i) {
            if (!(i % step)) {
                array.push(i);
            }
        }
        return array;
    }

    useEffect(()=>{
        if(!_.isEmpty(data)){
            if(!_.isEmpty(_.get(data,'apply_maxnumber'))){
                setValue('apply_maxnumber',_.get(data,'apply_maxnumber'))
            }
            else{
                setMax(true)
                setValue('noLimit',true)
                setValue('apply_startdate',new Date(_.get(data,'apply_startdate')))
                setValue('learning_startdate',new Date(_.get(data,'learning_startdate')))
                setValue('apply_enddate',new Date(_.get(data,'apply_enddate')))
                setValue('learning_enddate',new Date(_.get(data,'learning_enddate')))
            }
        }
    },[data])

    const [startDate, setStartDate] = useState(new Date());
    const years = range(2021, moment().year() + 3, 1);
    const months = [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월"
    ];


    // const [description, setDescription] = useState('');

    /*useEffect(() =>{
        setFilesApplys(apply_files)
        if(data.enroll_attache_force){
            if(apply_files && apply_files.length>0){
                setValidation({validate: (value) => file_type_check(value)})
            }
            else{
                setValidation({required: '파일을 등록해주세요',
                    validate: (value) => file_type_check(value)})
            }
        }
        else{
            setValidation({validate: (value) => file_type_check(value)})
        }
    },[apply_files,data])*/

    const { register, handleSubmit, watch, errors, setValue,  clearErrors ,control} = useForm({defaultValues});
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="workshop_add_form_wrap">
                <div className="reg_form">
                    <div className="input_box">
                        <div className="modal_sub_title">
                            제목
                        </div>
                        <div className="input_container">
                            <input type={"text"} name="title"
                                   placeholder={'제목을 적어주세요'}
                                   ref={
                                register(
                                    {
                                        required: '제목을 적어주세요'
                                    }
                                )
                            }
                                   defaultValue={_.get(data,'title')}
                            />
                            {errors.title && (
                                <small className="text-danger">{errors.title.message}</small>
                            )}
                        </div>
                    </div>

                    <div className="input_box">
                        <div className="modal_sub_title">
                            담당자
                        </div>
                        <div className="input_container">
                            <input type={"text"} name="props"
                                   placeholder={'담당자를 적어주세요'} ref={
                                register(
                                    {
                                        required: '담당자 이름을 적어주세요'
                                    }
                                )
                            }
                                   defaultValue={_.get(data,'props')}
                            />
                            {errors.props && (
                                <small className="text-danger">{errors.props.message}</small>
                            )}
                        </div>
                    </div>

                    <div className="input_box">
                        <div className="modal_sub_title">
                            문의 전화
                        </div>
                        <div className="input_container">
                            <input type={"text"} name="inquiry"
                                   placeholder={'전화번호를 적어주세요'} ref={
                                register(
                                    {
                                        required: '전화번호를 적어주세요'
                                    }
                                )
                            }
                                   defaultValue={_.get(data,'inquiry')}
                            />
                            {errors.Inquiry && (
                                <small className="text-danger">{errors.Inquiry.message}</small>
                            )}
                        </div>
                    </div>

                    <div className="input_box">
                        <div className="modal_sub_title">
                            담당자 소속
                        </div>
                        <div className="input_container">
                            <input type={"text"} name="dev_org"
                                   placeholder={'담당자의 소속을 적어주세요'}ref={
                                register(
                                    {
                                        required: '담당자의 소속을 적어주세요'
                                    }
                                )
                            }
                                   defaultValue={_.get(data,'dev_org')}
                            />
                            {errors.dev_org && (
                                <small className="text-danger">{errors.dev_org.message}</small>
                            )}
                        </div>
                    </div>

                    <div className="input_box">
                        <div className="modal_sub_title">
                            수강대상
                        </div>
                        <div className="input_container">
                            <input type={"text"} name="body_apply"
                                   placeholder={'수강 대상을 적어주세요'} ref={
                                register(
                                    {
                                        required: '수강 대상을 적어주세요'
                                    }
                                )
                            }
                                   defaultValue={_.get(data,'body_apply')}
                            />
                            {errors.body_apply && (
                                <small className="text-danger">{errors.body_apply.message}</small>
                            )}
                        </div>
                    </div>


                    <div className="input_box">
                        <div className="modal_sub_title">
                            진행 장소
                        </div>
                        <div className="input_container">
                            <input type={"text"} name="program_address"
                                   placeholder={'진행 장소를 적어주세요'}ref={
                                register(
                                    {
                                        required: '진행 장소를 적어주세요'
                                    }
                                )
                            }
                                   defaultValue={_.get(data,'program_address')}
                            />
                            {errors.program_address && (
                                <small className="text-danger">{errors.program_address.message}</small>
                            )}
                        </div>
                    </div>

                    <div className="input_box">
                        <div className="modal_sub_title">
                            수강 인원
                        </div>
                        <div className="input_container">
                            <div className="check_box_wrap">
                             <label><input type={'checkbox'} name={'noLimit'} style={{width:"auto"}}  className="input_check" onChange={(e)=>{setMax(e.target.checked)}} ref={register()}/>무제한</label>
                            </div>
                            {
                                !max&&
                                    <>
                                        <input type={"number"} name="apply_maxnumber"
                                               placeholder={'최대 수강인원을 적어주세요'}
                                        ref={
                                            register(
                                                {
                                                    required: '제한 인원을 적어주세요'
                                                }
                                            )
                                        }
                                                    defaultValue={_.get(data,'apply_maxnumber')}
                                        />
                                        {errors.apply_maxnumber && (
                                            <small className="text-danger">{errors.apply_maxnumber.message}</small>
                                        )}
                                    </>
                            }

                        </div>
                    </div>

                    <div className="input_box">
                        <div className="modal_sub_title">
                            신청 기간
                        </div>
                        <div className="input_container">
                            <div className="cal_box">
                                <Controller
                                    control={control}
                                    name="apply_startdate"
                                    defaultValue={''}
                                    render={(props) => (
                                        <ReactDatePicker
                                            className="input"
                                            placeholderText="프로그램 신청 시작일을 입력하세요"
                                            onChange={(e) => props.onChange(e)}
                                            selected={props.value}
                                            className={`form-control input ${errors.apply_startdate && `border-danger` || ``}`}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown={true}
                                            dateFormat={"yyyy-MM-dd HH:mm"}
                                            popperPlacement="auto"
                                            locale="ko"
                                            showTimeSelect={true}
                                            renderCustomHeader={({
                                                                     date,
                                                                     changeYear,
                                                                     changeMonth,
                                                                     decreaseMonth,
                                                                     increaseMonth,
                                                                     prevMonthButtonDisabled,
                                                                     nextMonthButtonDisabled
                                                                 }) => (
                                                <div
                                                    style={{
                                                        margin: 10,
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    <button className={'left_button'} type={'button'} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                        {"<"}
                                                    </button>
                                                    <select
                                                        defaultValue={moment().year()}
                                                        onChange={({ target: { value } }) => changeYear(value)}
                                                    >
                                                        {years.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    <select
                                                        defaultValue={months[moment().month()]}
                                                        onChange={({ target: { value } }) =>
                                                            changeMonth(months.indexOf(value))
                                                        }
                                                    >
                                                        {months.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    <button className={'right_button'} type={'button'} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                        {">"}
                                                    </button>
                                                </div>
                                            )}
                                        />
                                    )}
                                    rules={{ required: "신청 시작 날짜는 필수값입니다" }}
                                />
                                <p className="cal_ir"> ~ </p>

                                <Controller
                                    control={control}
                                    name="apply_enddate"
                                    //defaultValue={moment(_.get(data,'apply_enddate')).format('yyyy-MM-dd HH:mm')}
                                    defaultValue={''}
                                    render={(props) => (
                                        <ReactDatePicker
                                            className="input"
                                            placeholderText="신청 마감 날짜는 입력하세요"
                                            onChange={(e) => props.onChange(e)}
                                            selected={props.value}
                                            className={`form-control input ${errors.apply_enddate && `border-danger` || ``}`}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown={true}
                                            dateFormat={"yyyy-MM-dd HH:mm"}
                                            popperPlacement="auto"
                                            locale="ko"
                                            showTimeSelect={true}
                                            renderCustomHeader={({
                                                                     date,
                                                                     changeYear,
                                                                     changeMonth,
                                                                     decreaseMonth,
                                                                     increaseMonth,
                                                                     prevMonthButtonDisabled,
                                                                     nextMonthButtonDisabled
                                                                 }) => (
                                                <div
                                                    style={{
                                                        margin: 10,
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    <button className={'left_button'} type={'button'} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                        {"<"}
                                                    </button>
                                                    <select
                                                        defaultValue={moment().year()}
                                                        onChange={({ target: { value } }) => changeYear(value)}
                                                    >
                                                        {years.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    <select
                                                        defaultValue={months[moment().month()]}
                                                        onChange={({ target: { value } }) =>
                                                            changeMonth(months.indexOf(value))
                                                        }
                                                    >
                                                        {months.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    <button className={'right_button'} type={'button'} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                        {">"}
                                                    </button>
                                                </div>
                                            )}
                                        />
                                    )}
                                    rules={{ required: "프로그램 마감 날짜는 필수값입니다" ,
                                        validate: value =>
                                        {
                                            if(_.isEmpty(value)){
                                                if(!moment(value).isAfter(watch('apply_startdate'))){
                                                    return '마감 날짜는 시작하는 날짜보다 작을 수 없습니다.'
                                                }
                                            }
                                        }

                                    }}
                                />
                                {(_.get(errors,'apply_startdate.type') == 'required' || _.get(errors,'apply_enddate.type') == 'required') && (
                                    <><small className="text-danger">신청 기간은 필수입니다.</small></>
                                )}

                                {(_.get(errors,'apply_enddate.type') == 'validate') && (
                                    <><small className="text-danger">{errors.apply_enddate.message}</small></>
                                )}
                            </div>

                        </div>

                    </div>

                    <div className="input_box">
                        <div className="modal_sub_title">
                            진행 기간
                        </div>
                        <div className="input_container">
                            <div className="cal_box">
                                <Controller
                                    control={control}
                                    name="learning_startdate"
                                    defaultValue={''}
                                    render={(props) => (
                                        <ReactDatePicker
                                            className="input"
                                            placeholderText="프로그램 시작일을 입력하세요"
                                            onChange={(e) => props.onChange(e)}
                                            selected={props.value}
                                            className={`form-control input ${errors.learning_startdate && `border-danger` || ``}`}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown={true}
                                            dateFormat={"yyyy-MM-dd HH:mm"}
                                            popperPlacement="auto"
                                            locale="ko"
                                            showTimeSelect={true}
                                            renderCustomHeader={({
                                                                     date,
                                                                     changeYear,
                                                                     changeMonth,
                                                                     decreaseMonth,
                                                                     increaseMonth,
                                                                     prevMonthButtonDisabled,
                                                                     nextMonthButtonDisabled
                                                                 }) => (
                                                <div
                                                    style={{
                                                        margin: 10,
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    <button className={'left_button'} type={'button'} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                        {"<"}
                                                    </button>
                                                    <select
                                                        defaultValue={moment().year()}
                                                        onChange={({ target: { value } }) => changeYear(value)}
                                                    >
                                                        {years.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    <select
                                                        defaultValue={months[moment().month()]}
                                                        onChange={({ target: { value } }) =>
                                                            changeMonth(months.indexOf(value))
                                                        }
                                                    >
                                                        {months.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    <button className={'right_button'} type={'button'} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                        {">"}
                                                    </button>
                                                </div>
                                            )}
                                        />
                                    )}
                                    rules={{ required: "프로그램 시작 날짜는 필수값입니다" }}
                                />

                                <p className="cal_ir"> ~ </p>

                                <Controller
                                    control={control}
                                    name="learning_enddate"
                                    defaultValue={''}
                                    render={(props) => (
                                        <ReactDatePicker
                                            className="input"
                                            placeholderText="프로그램 마감 날짜는 입력하세요"
                                            onChange={(e) => props.onChange(e)}
                                            selected={props.value}
                                            className={`form-control input ${errors.learning_enddate && `border-danger` || ``}`}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown={true}
                                            dateFormat={"yyyy-MM-dd HH:mm"}
                                            popperPlacement="auto"
                                            locale="ko"
                                            showTimeSelect={true}
                                            renderCustomHeader={({
                                                                     date,
                                                                     changeYear,
                                                                     changeMonth,
                                                                     decreaseMonth,
                                                                     increaseMonth,
                                                                     prevMonthButtonDisabled,
                                                                     nextMonthButtonDisabled
                                                                 }) => (
                                                <div
                                                    style={{
                                                        margin: 10,
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    <button className={'left_button'} type={'button'} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                        {"<"}
                                                    </button>
                                                    <select
                                                        defaultValue={moment().year()}
                                                        onChange={({ target: { value } }) => changeYear(value)}
                                                    >
                                                        {years.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    <select
                                                        defaultValue={months[moment().month()]}
                                                        onChange={({ target: { value } }) =>
                                                            changeMonth(months.indexOf(value))
                                                        }
                                                    >
                                                        {months.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    <button className={'right_button'} type={'button'} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                        {">"}
                                                    </button>
                                                </div>
                                            )}
                                        />
                                    )}
                                    rules={{ required: "프로그램 마감 날짜는 필수값입니다" ,
                                        validate: value => {
                                            if(_.isEmpty(value)){
                                                if(!moment(value).isAfter(watch('learning_startdate'))){
                                                    return '마감 날짜는 시작하는 날짜보다 작을 수 없습니다.'
                                                }
                                            }
                                        }
                                         }}
                                />
                                {(_.get(errors,'learning_startdate.type') == 'required' || _.get(errors,'learning_enddate.type') == 'required') && (
                                    <><small className="text-danger">진행 기간은 필수입니다.</small></>
                                )}
                                {(_.get(errors,'learning_enddate.type') == 'validate') && (
                                    <><small className="text-danger">{errors.learning_enddate.message}</small></>
                                )}
                            </div>

                        </div>
                    </div>



                    <div className="input_box">
                        <div className="modal_sub_title">
                            진행 시간
                        </div>
                        <div className="input_container">
                              <input type={"number"} name="apply_time"
                                     placeholder={'진행 시간을 적어주세요 ex) 2'}
                                     className={'time_input'}
                                     ref={
                                         register(
                                             {
                                                 required: '진행 시간을 적어주세요'
                                             }
                                         )
                                     }
                                     defaultValue={_.get(data,'apply_time')}
                              />
                              {errors.apply_time && (
                                  <small className="text-danger">{errors.apply_time.message}</small>
                              )}
                            시간
                        </div>
                    </div>

                    <div className="modal_sub_title">
                        프로그램 구성
                    </div>
                    {/*// 2. 비고작성 부분*/}
                    <div className="input_container">
                        <Quill initialValue={_.get(data, 'body_config')}
                               onChange={(content, delta, source, editor) => {
                                   if (errors.bigo && errors.bigo.type === 'required') {
                                       clearErrors('bigo');
                                   }
                                   if (editor.getLength() === 1) {
                                       setValue('bigo', '');
                                   } else {
                                       setValue('bigo', content)
                                   }}}/>
                        {/*<div>*/}
                        <div style={{display: 'none'}}>
                            <textarea defaultValue={_.get(data, 'body_config')} name={'bigo'} ref={register({required:true})} />
                        </div>
                        {/*<Quill onChange={setDescription} initialValue={_.get(data, 'body')}/>*/}
                        {/*<div style={{display: 'none'}}>*/}
                        {/*    <textarea name="bigo" ref={register({required: '내용을 작성해주세요'})}*/}
                        {/*              value={description}*/}
                        {/*              readOnly*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {errors.bigo &&
                        errors.bigo.type === 'required' && (
                            <small className="text-danger description">필수 항목입니다.</small>)
                        }
                    </div>
                    {/*<div className="modal_sub_title">
                        첨부파일
                    </div>*/}
                    {/*// 1. 사용자에게 파일 첨부를 받는다*/}
                    {/*<div className="input_container">
                        <input type="file" multiple name="files" ref={
                            register()
                        }  />
                        {errors.files && (
                            <small className="text-danger">{errors.files.message}</small>
                        )}
                        {(!_.isEmpty(_.get(data,'attached_file',[])) && mode=='m') && <div className="files_container">
                            <span className="files_title">업로드 된 파일</span>
                            {_.get(data,'attached_file').map((item,index)=><span className="file_once" key={index}><span className="files_item">{item.name}</span> <span className="files_delete" onClick={file_del('upload/files',item.id,reload,setReload)}>삭제</span></span>)}

                        </div>}
                    </div>*/}
                    <div className="course_overlay_footer">
                        <button className="btn btn_info form-submit">{mode=='m' ? '수정하기' : '작성하기'}</button>
                        <button onClick={handleCancel} type="button" className="overlay_close">취소하기</button>
                    </div>
                </div>
            </div>
        </form>
    )
}


export default WriteForm;
