/** 유저 로그인 */
import React, {useEffect} from 'react';
import {Helmet} from "react-helmet";
import {Link, Redirect} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import * as authActions from 'store/actions/auth';
import LoginForm from 'components/Forms/LoginForm';
import MainBoard from 'components/MainBoard';
//graphql 관련
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import * as cQuery from "queries/content";
import * as rQuery from "queries/resource";
const handleSubmit = ({dispatch}) => async (formData) => {
    const {identifier = '', password = ''} = formData;
    dispatch(authActions.authorization({...formData}))
}
const quicklist = [
    // {
    //     icon: '/assets/images/quick_icon_1.png',
    //     title: '회원가입',
    //     url: '/member/register'
    // },
    {
        icon: '/assets/images/quick_icon_2.png',
        title: '전남대학교',
        url: '/program/studyprogram',
        type: 'site'
    },
    {
        icon: '/assets/images/quick_icon_7.png',
        title: 'Q&A',
        url: '/onair/eventstory',
        type: 'board'
    },
    {
        icon: '/assets/images/quick_icon_3.png',
        title: '전남대포털',
        url: '/program/supportprogram',
        type: 'site'
    },
    {
        icon: '/assets/images/quick_icon_7.png',
        title: '공지사항',
        url: '/onair/eventstory',
        type: 'board'
    },
    {
        icon: '/assets/images/quick_icon_5.png',
        title: '도서관',
        url: '/about/introduction',
        type: 'site'
    },
    {
        icon: '/assets/images/quick_icon_3.png',
        title: '매뉴얼',
        url: '/program/supportprogram',
        type: 'board'
    },
    {
        icon: '/assets/images/quick_icon_6.png',
        title: '교수학습지원센터',
        url: '/onair/faq',
        type: 'site'
    },
    {
        icon: '/assets/images/quick_icon_7.png',
        title: 'FAQ',
        url: '/onair/eventstory',
        type: 'board'
    }
];
const Login = () => {
    const dispatch = useDispatch();
    const {user, isLoggedIn} = useSelector(({auth: {user, isLoggedIn}}) => ({
        user, isLoggedIn
    }));

    if (user && isLoggedIn) return (<Redirect to={`/`}/>);
    return (
        <div className="content">
        <div className="conts_body">
            <Helmet title="로그인"/>
            {/*<a href="/" className={"logo_link"}>*/}
            {/*    <img src="/assets/images/logo_green.png" alt="kmbig"/>*/}
            {/*</a>*/}
            <div className="login-main">
                <Link className="close" title="홈페이지로 이동" to="/"><span className="invisible-btn">홈페이지로 이동</span></Link>
                <div className="form-wrapper">
                    {/*<h2 className="page_title">사용자 로그인</h2>*/}
                    <h2 className="page_title">로그인</h2>
                    <div className="userlogtitle">광주교육대학교<br/>방문을 환영합니다.</div>
                    <div className="sub_title_userlog">일반 로그인</div>
                    <div className="login_wrap">
                        <LoginForm onSubmit={handleSubmit({dispatch})}/>
                        <div className="members">
                            <Link to="/member/register/information">회원가입</Link>
                            <span> | </span>
                            <Link to="/member/find-password">비밀번호찾기</Link>
                        </div>
                    </div>
                    {/*<div className="sns_sub_title_userlog">소셜계정 로그인</div>
                    <div className="sns_log_wrap">
                        <div className="sns_sub_wrap">
                            <button className="sns_btn">
                                <img className="login-img naver" src="/assets/images/naver.png"/>
                                <div className="login-sns-title">네이버</div>
                            </button>
                        </div>
                        <div className="sns_sub_wrap">
                            <button className="sns_btn">
                                <img className="login-img facebook" src="/assets/images/facebook.png"/>
                                <div className="login-sns-title">페이스북</div>
                            </button>
                        </div>
                        <div className="sns_sub_wrap">
                            <button className="sns_btn">
                                <img className="login-img kakao" src="/assets/images/kakao.png"/>
                                <div className="login-sns-title">카카오</div>
                            </button>
                        </div>
                        <div className="sns_sub_wrap">
                            <button className="sns_btn">
                                <img className="login-img google" src="/assets/images/google.png"/>
                                <div className="login-sns-title">구글</div>
                            </button>
                        </div>
    </div>*/}
                </div>
            </div>

            {/*<div className="sub_title">*/}
            {/*    /!* 게시판 *!/*/}
            {/*    <div className={"left_container"}>*/}
            {/*        <MainBoard/>*/}
            {/*        <div className={"link_container"}>*/}
            {/*            {quicklist.map(item => (*/}
            {/*                <Link to={item.url} key={item.title} className={item.type=='site' && "left" || "right"}>*/}
            {/*                    <span><img src={item.icon} alt=""/></span> {item.title}*/}
            {/*                </Link>*/}
            {/*            ))}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
        </div>
    )
}

export default Login;
