import React, {useEffect, useMemo, useState, useCallback} from 'react';
import * as actions from 'store/actions/content';
import {Helmet} from "react-helmet";
import {useSelector, useDispatch} from 'react-redux';
import * as api from 'lib/content';
import {apiUrl} from 'config';
import route from 'route';
import {Link,useParams,useHistory} from 'react-router-dom';
import {WorkshopCertificate,CourseCertificate} from 'components/Certificate'


const MyCertificate = ({data}) => {
    const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}));
    const {id = '', username = '', nickname='',organization=''} = user;

    const [programData,setProgramData] = useState({})
    const [certificateLoading,setCertificateLoading] = useState(true)
    const [certType,setCertType] = useState('')
    const history = useHistory();
    const {program_id=''} = useParams();
    //개별 목록 정보
    const dispatch = useDispatch();
    const {list = [], totalCount = 0, loading} = useSelector(({content}) => ({...content}));
    //const type = 'workshop';
    // 이수증 출력
    /* const handlePrint = () => {
         const initBody = document.body.innerHTML;
         window.onbeforeprint = () => {
             document.body.innerHTML = document.getElementById('print_area').innerHTML;
         }
         window.onafterprint = () => {
             document.body.innerHTML = initBody;
         }
         window.print();
     }*/

    useEffect(()=>{
        async function getSurveyResult(program_id,id) {
            const params = {}
            const {data:workshop_data,workshop_loading} = await api.gets({type:'survey-results', workshopprogram:program_id,author:id})
            const {data:study_data,study_loading} = await api.gets({type:'survey-results', studyprogram:program_id,author:id})

            if(_.isEmpty(study_data) && _.isEmpty(workshop_data)){
                swal({
                    title: ('설문조사를 진행하지 않았습니다.'), text: ('설문조사 미진행'),
                    icon: "warning", timer: 3000, buttons: false,
                }).then(()=>{
                    history.push(`/`);
                })
            }
            else{
                if(!_.isEmpty(workshop_data)){
                    setCertType('workShop')
                    setProgramData(workshop_data)
                    setCertificateLoading(workshop_loading)
                }
                if(!_.isEmpty(study_data)){
                    setCertType('program')
                    setProgramData(study_data)
                    setCertificateLoading(study_loading)
                }
            }
        }
        if(!_.isEmpty(program_id) && !_.isEmpty(id)){
            getSurveyResult(program_id,id)
        }
    },[program_id,id])

    useEffect(()=>{
        /*console.log(programData)*/
    },[programData])


    const updateParams = useCallback(() => {
        const {username = '', id = ''} = user
        // setParams({...params, ...data, "apply_user.id": id});
        setParams({...params, ...data, apply_user: id});
    }, [user]);

    const [active, setActive] = useState(2);


    const goBack = () => {
        if (history.location.state == "resource"){
            history.goBack();
        }
        else {
            history.push({pathname:`/member/mycontent`, state:{active:active}});
        }
    }

    return (
        <div className="myProgram">
            <div id={'notPrint'} className={'certi_head_div'}>
                <img className={'certi_head'} src={'/assets/images/certificate_logo.png'}/>
            </div>
            <div className="onair content introduction">
                <Helmet title="마이페이지 > 나의강좌:콘텐츠"/>
                <div className="sub">

                    <div className="wrap">
                        <div className={'notPrint'}>
                            <div className="certi_sub_wrap">
                                <div className={'certi_sub_one'}>
                                    {/*<h1 className={'sub_h1'}>광주전남권역대학은 다음과 같이 학습자의 강좌 이수 결과를 제공합니다.</h1>
                                    <h1 className={'sub_h2'}>축하합니다! 이 페이지는 당신이 달성한 것들을 나타냅니다. 가족, 친구, 동료 등에게 자랑하세요.</h1>*/}
                                    <div className={'button_area'}>
                                        <div className={'btn_div'}>
                                            {/*<button className={'check_btn'}>본인인증하기</button>*/}
                                            <button className={'print_btn'} type={'button'} onClick={window.print}><img
                                                src={'/assets/images/certi_print.png'}/><span>이수증
                                                인쇄하기</span></button>
                                            <button className={'print_btn'} onClick={goBack}><span>뒤로가기</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'certi_sub_two_wrap'}>
                                <div className={'certi_sub_two'}>
                                    광주전남권역 대학원격교육지원센터는 다음과 같이 학습자의 이수 결과를 제공합니다.
                                </div>
                            </div>
                            
                        </div>
                        {!certificateLoading &&
                            <div>
                                <div className={'certi_sub_three'}>
                                    <div id={'print_area'} className={'certificate'}>

                                        {
                                            certType == 'workShop' ?
                                                <WorkshopCertificate user={user} programData={programData}/>
                                                :
                                                <CourseCertificate user={user} programData={programData}/>
                                        }

                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyCertificate;
