import React, {useEffect, useMemo, useState} from 'react';
import {useRouteMatch, generatePath, Link} from 'react-router-dom';
import {apiUrl} from 'config';
import moment from 'moment/dist/moment';
import Popup from "reactjs-popup";
import * as api from 'lib/content';
// import {CustomPagination} from 'components/Custom';
//graphql 관련
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import * as cQuery from "queries/content";
import * as aQuery from "queries/program";


const ProgramItemD = ({data = {},
                          item = {},
                          path,
                          setProgramData,
                          admUser,
                          studyNumbers,
                          setProgramNumber,
                          idx,
                          programData,
                          setStudyProgramNumber,
                          user,
                          setProgram,
                          setEventClick,
                          studyNumber,
                          eventclick,
                          setPercent,
                          percent,
                          programName,
                          activeCount,
                          setClickedproid}) => {

    const [params, setParams] = useState({
        type: 'studyprogram-applies',
        _sort: `createdAt:DESC`,
        apply_user: id,
        apply_status: '5ff6b769bf55b944295d63e8',
        appied_program_null: false
    });
    const {
        id = '',
        title = '',
        allowednumber = null,
        apply_startdate = new Date(),
        apply_allowednumber = 0,
        apply_maxnumber = 0,
        apply_enddate = new Date(),
        learning_enddate = new Date(),
        learning_startdate = new Date(),
        maxnumber = null,
        thumbnailimages = {},
        study_program_applies = [],
        program_address = "",
        props = "",
        dev_org = "",
        chapter = "",
        cw_uuid = ""
    } = data;

    
    const today = moment().format(`YYYY-MM-DD`);
    const d_day = moment(apply_enddate).diff(today, 'days');
    const applied = applied_num + applied_over;
    const apply_started = moment(apply_startdate).format(`YYYY-MM-DD`);
    const apply_ended = moment(apply_enddate).format(`YYYY-MM-DD`);
    const learning_ended = moment(learning_enddate).format(`YYYY-MM-DD`);
    const learning_started = moment(learning_startdate).format(`YYYY-MM-DD`);
    const applied_num = study_program_applies.filter(item => item.apply_status == "5ff6b769bf55b944295d63e8").length  // 수강 신청 완료 상태 개수
    const applied_over = study_program_applies.filter(item => item.apply_status == "5ff6b774bf55b944295d63e9").length // 수강 신청 완료(정원 초과) 개수
    const [paramsGrade, setParamsGrade] = useState({ })
    const [percentNum, setPercentNum] = useState(0);
    const [allStudy,setAllStudy] = useState([]);

    const [surverResult, setSurverResult] = useState({});
    const [reload, setReload] = useState(false);    //open 상태인가?
    const [loading, setLoading] = useState(true);   //데이터 로딩중인가?

    const [getSurvey_result, {loading: survey_result_loading}] = useLazyQuery(cQuery.SURVEY_RESULT_QUERY, {
        fetchPolicy: 'no-cache',
        variables: {where: {studyprogram:_.get(data,'id'),author:_.get(user,'id')}},
        onCompleted: ({surveyResults = []}) => {
            if(!_.isEmpty(surveyResults)){
                setSurverResult(_.get(surveyResults,'0'))
            }
        }
    });

    //프로그램 신청내역
    useEffect(()=>{
        getSurvey_result()
    },[]);


    const InnerData = ({data = {}}) => {
        return (
            <>
                {
                    activeCount >= 90 ?
            <div className="programList__item">
                {/*{(d_day > 0 && d_day < 7) && <span className="d_day">D-{d_day}</span>}*/}
                <div className="thumb" >
                    <Link to={`/content/${id}`}>
                        <img src={!_.isEmpty(_.get(data, 'thumbnail')) ? _.get(data, 'thumbnail') : `${apiUrl}${_.get(data, 'thumbnailimages.0.url')}`}  alt=""/>
                    </Link>
                </div>
                <div className="info">
                        <div className="playTime">
                            <span className="mini_title">진행률</span> <span className="playTime_value">{_.get(item,'check_true',0)}/{_.get(item,'all_block_count',0)}</span>
                        </div>

                        <div className="12321">

                        </div>
                    <div className='info_div_one'>
                        <div>

                            {/*<p className='div_one_p'> 신청가능 </p>*/}
                            <h2>
                                <Link to={`/content/${id}`}>
                                    {title}
                                </Link>
                            </h2>
                            {
                                (!_.isEmpty(dev_org) && (!_.isEmpty(props)) ?
                                        <>
                                            {/*<img src='/assets/images/info_date.png'/>*/}
                                            {props} | {dev_org}
                                        </>
                                        : (!_.isEmpty(dev_org)) ?
                                            <>
                                                {/*<img src='/assets/images/info_date.png'/>*/}
                                                {dev_org}
                                            </>
                                            : (!_.isEmpty(props)) &&
                                            <>
                                                {/*<img src='/assets/images/info_date.png'/>*/}
                                                {props}
                                            </>
                                )
                            }
                            <div className="chapter_prop">
                                {chapter}
                            </div>
                        </div>

                        <div className="bottom_button_box">
                            <button className="left_button_confirm left_active" onClick={() => {setEventClick(true),setClickedproid(_.get(data, 'cw_uuid')), setProgram(title)}}>
                                {/*<Link to="/member/mycontent/progressRate">*/}
                                학습진도현황 상세확인
                                {/*</Link>*/}
                            </button>

                            <button className="left_button mg12" >
                                <Link to={`/member/mycontent/studysurvey/${id}`}>
                                    강의만족도 설문
                                </Link>
                            </button>
                            <button className={`right_button ${!_.isEmpty(surverResult) ? 'active' : '' }`} >
                                <Link to={`/member/mycontent/mycertificate/${id}`}>
                                    이수증 출력
                                </Link>
                            </button>
                        </div>
                        <div className="bottom_button_box">

                        </div>
                    </div>

               {/*{eventclick == true ?*/}
               {/*     <Popup open  closeOnDocumentClick position="right center">*/}
               {/*         /!*<Popup open={open} closeOnDocumentClick  onClose={setEventPop} position="right center">*!/*/}
               {/*         <div className="popup_overlay">*/}
               {/*             <div className="popup_content">*/}
               {/*                 <div className="program_popup">*/}
               {/*                     <div className="modal_header">*/}
               {/*                         <h5 className="modal_title">*/}
               {/*                             {title} 진행률*/}
               {/*                         </h5>*/}
               {/*                         <button onClick={()=> {setEventClick(false)}} type="button" className="overlay_close_x close">*/}
               {/*                             /!*<button onClick={close} type="button" className="overlay_close_x close">*!/*/}
               {/*                             <span className="element-invisible" aria-hidden="true">X</span>*/}
               {/*                         </button>*/}
               {/*                     </div>*/}

               {/*                     <div className="modal_body">*/}
               {/*                         <div className="information_complate">*/}
               {/*                             /!*접수번호 : {apply}*/}
               {/*                             신청일시 : {applyData}*!/*/}
               {/*                         </div>*/}
               {/*                         <div className="table_responsive">*/}
               {/*                             <div className="progress_table_box_mycontent">*/}
               {/*                                 <table className="Myclass_progressrate_table">*/}
               {/*                                     <thead>*/}
               {/*                                     <tr>*/}
               {/*                                         <th colSpan="5">  {user.name}님 차시별 진행도</th>*/}
               {/*                                     </tr>*/}
               {/*                                     <tr>*/}
               {/*                                         <th><img src="/assets/images/play_icon_ta.png" alt="강의아이콘"/><p className="play_time"> 21:00</p></th>*/}
               {/*                                         <th><img src="/assets/images/play_icon_ta.png" alt="강의아이콘"/><p className="play_time"> 21:00</p></th>*/}
               {/*                                         <th><img src="/assets/images/play_icon_ta.png" alt="강의아이콘"/><p className="play_time"> 21:00</p></th>*/}
               {/*                                         <th><img src="/assets/images/play_icon_ta.png" alt="강의아이콘"/><p className="play_time"> 21:00</p></th>*/}
               {/*                                         <th><img src="/assets/images/play_icon_ta.png" alt="강의아이콘"/><p className="play_time"> 21:00</p></th>*/}
               {/*                                     </tr>*/}
               {/*                                     </thead>*/}
               {/*                                     <tbody>*/}
               {/*                                     <tr>*/}
               {/*                                         <td>100%</td>*/}
               {/*                                         <td>95%</td>*/}
               {/*                                         <td>-</td>*/}
               {/*                                         <td>-</td>*/}
               {/*                                         <td>-</td>*/}
               {/*                                     </tr>*/}
               {/*                                     </tbody>*/}
               {/*                                 </table>*/}
               {/*                                 */}
               {/*                             </div>*/}
               {/*                         </div>*/}
               {/*                     </div>*/}

               {/*                     <div className="course_overlay_footer">*/}
               {/*                         /!*<button className="btn btn_info form-submit">인쇄하기</button>*!/*/}
               {/*                         /!*<Link to="/member/mystudyprogram"></Link>*!/*/}
               {/*                         <button onClick={()=> {setEventClick (false);}} type="button" className="overlay_close">확인완료*/}
               {/*                         </button>*/}
               {/*                     </div>*/}

               {/*                 </div>*/}
               {/*             </div>*/}
               {/*         </div>*/}
               {/*     </Popup>*/}
               {/*     : <></>*/}
               {/* }*/}



                    {/*<div>*/}

                    {/*    /!*{dev_org && props && (*/}
                    {/*        <p className="date">*/}
                    {/*            <img src='/assets/images/info_date.png'/>*/}
                    {/*            {apply_started} ~{apply_ended}*/}
                    {/*            {dev_org} | {props}*/}
                    {/*        </p>) || <>*/}
                    {/*    <p><p className="date"><img src='/assets/images/info_date.png'/></p></p>*/}
                    {/*    </> }*!/*/}

                    {/*    /!*<span className={`status ${1 === 2 && 'disabled'}`}>*/}
                    {/*    {(today < apply_ended && today > apply_started) ? '신청가능' : '신청마감'}*/}
                    {/*    <span className={`status ${1 === 2 && 'disabled'}`}>*/}
                    {/*        진행 중*/}
                    {/*    {(today < apply_ended && today > apply_started) ? '신청가능' : '신청마감'}*/}
                    {/*</span>*!/*/}
                    {/*</div>*/}
                </div>


                {/* 호버 레이아웃 */
                }
                <div className="hoverLayout">
                    <ul>
                        <li>
                            신청기간 : 상시
                        </li>
                        <li>
                            진행기간 : 상시
                        </li>
                    </ul>
                    {/*<p>View more</p>*/}
                    {/*<h3>{title}</h3>
                        <ul>
                        <li>
                        신청기간 : 상시
                        </li>
                        <li>
                        진행기간 : 상시
                        </li>
                    {/*<li>
                            장소 : {program_address}
                        </li>*/}
                    {/*<li>
                            신청 / 정원 : {apply_allowednumber} / {apply_maxnumber}
                        </li>
                        </ul>*/}
                </div>

                {/*/!* 팝업 *!/*/}
                {/*<div className = {eventclick == true ? "Mycontent_pop" : "Mycontent_pop disnone" }>*/}
                {/*    <div className ="inner_box">*/}
                {/*        {title} <br/>*/}
                {/*        { id }<br/><br/>*/}
                {/*        <div className="popClose" onClick={() => {setEventClick (false)}}>닫기</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*/!* 팝업 *!/*/}

            </div>
                        : <></>}
           </>
        )
    }

    return (
                <InnerData data={data}/>

    );
};

export default ProgramItemD;