import React from 'react';
import {Link} from 'react-router-dom';

const IntroFooter = () => {
    return (
        <footer className={'intro__footer'}>
            <div className='top_btn'>
                <a href={'#'}>
                    <img src='/assets/images/top_btn.png'/>
                </a>
            </div>
            <div className="wrap">
                <Link to={'/'}>
                    <img src="/assets/images/bottom_logo.png" alt=""/>
                </Link>
                <p>
            <span>
              {/*61186*/}
                <em className='bottom_em'>(우) 61204 광주광역시 북구 필문대로 55(풍향동) 대표전화 : 062-520-4114</em>
            </span>
            <span className='bottom_span'>
                Copyright 2021 GWANGJU NATIONAL UNIVERSITY OF EDUCATION. All rights reserved.
            </span>
                </p>
            </div>
        </footer>
    );
};

export default IntroFooter;
