import React, {useEffect, useMemo, useState, useCallback} from 'react';
import BreadCrumb from 'components/BreadCrumb';
import SubLeftMenu from 'components/SubLeftMenu';
import * as actions from 'store/actions/content';
import {useSelector, useDispatch} from 'react-redux';
import {Helmet} from "react-helmet";
import * as api from 'lib/content';
import {apiUrl} from 'config';
import {Link,useParams} from 'react-router-dom';
import route from 'route';
import {useForm} from "react-hook-form/dist/index.ie11";

// 강좌 만족도 설문
import {workshopSurveyData1} from 'surveyData';
import {workshopSurveyData2} from 'surveyData';
import MultipleSurvey from 'components/CourseSurvey/MultipleSurvey';
import SubjectiveSurvey from 'components/CourseSurvey/SubjectiveSurvey';


const MyWorkshopSurvey = ({onSubmit, data, handleCancel, cancelSubmit}) => {
    const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}));
    const {program_id=''} = useParams();
    const {id = '', username = '', nickname='',organization=''} = user;
    const [courseData,setCourseData] = useState();
    const [reload,setReload] = useState(true);
    const [loading,setLoading] = useState(true);
    const [surveyCheck,setSurveyCheck] = useState(false);
    const [surveyDefault,setSurveyDefault] = useState({});

    useEffect(()=>{
        async function getSurveyData(program_id) {
            const {data,loading} = await api.gets({type:'survey-results',workshopprogram:program_id,author:id})
            if(!_.isEmpty(data)){
                setSurveyCheck(true)
                if(!_.isEmpty(_.get(data,'0.survey'))){
                    _.get(data,'0.survey').map((item,index)=>{
                        /*console.log(item)*/
                        if(!_.isEmpty(item)){
                            setTimeout(setValue(`survey[${index}].answer`,_.get(item,'answer')),1000)
                        }
                    })
                }
                //setValue(_.get(data,'0.survey'),'survey')
            }
        }
        if(!loading){
            getSurveyData(program_id)
        }
    },[reload,loading])

    const updateParams = useCallback(() => {
        const {username = '', id = ''} = user
        // setParams({...params, ...data, "apply_user.id": id});
        setParams({...params, ...data, apply_user: id});
    }, [user]);

    //관리자인지 접속 확인
    useEffect(() => {
        if (user.id) {
            if (user.id == "6080cbefb629f15efd7de790") {
                //console.log(user.id);
            } else {
                return
            }
        }
    }, [user]);

    useEffect(()=>{
        async function getCourseData(program_id) {
            const {data,loading} = await api.get({type:'workshopprograms',id:program_id})
            setCourseData(data)
            setLoading(loading)
        }
        if(!_.isEmpty(program_id)){
            getCourseData(program_id)
        }
    },[program_id])

    const { register, handleSubmit, watch, errors, setValue,  clearErrors } = useForm({});

    const test = () => async (formData) =>{
        const {name,survey,program_type,workshopId} = formData;
        let params = {author:name,survey,workshopprogram:workshopId,program_type}

        try{
            let {data:count_data} = await api.count({type:'survey-results',workshopprogram:workshopId});
            //return
            let result_data = await api.create({type:'survey-results',...params,survey_complete_num:(count_data+1)});
            const {data, status} = result_data;
            swal({
                title: ('설문조사 완료'), text: ('설문조사 작성이 완료되었습니다.'),
                icon: "success", timer: 3000, buttons: false,
            }).then(
                setSurveyCheck(true)
            )

        }catch(e){
            swal({
                title: ('설문조사 작성 실패'), text: (e.message),
                icon: "warning", timer: 3000, buttons: false,
            });
        }finally {
            setReload(!reload)
        }
    }

    return (
        <div className="myProgram">
            <div className="onair content introduction">
                <Helmet title="마이페이지 > 나의강좌:콘텐츠"/>
                <BreadCrumb list={route.depth6}/>
                <div className="sub">
                    <div className="wrap">
                        <SubLeftMenu
                            title={route.depth6.title}
                            lnbList={route.depth6.list}
                        />
                        {!loading &&
                            <div className="sub__content">

                            <div className="board">
                                <h1 className="sub__title2">
                                    <span className="">광주전남권역 대학원격교육지원센터 워크숍 만족도 조사</span>
                                </h1>



                                <div className={'sub_survey_title'}>
                                    <h1>{_.get(courseData,'title','')}</h1>
                                    <span className="">{nickname} | {organization}</span>
                                </div>
                                <div className={'sub_survey_explain'}>
                                    워크숍이 전반적으로 어떠했는지 묻는 질문입니다. 본인의 의견과 가까운 곳에 표시해주시기 바랍니다.
                                </div>


                                <form onSubmit={handleSubmit(test())}>
                                    <input type={'hidden'} value={id} ref={register()} name={'name'}/>
                                    <input type={'hidden'} value={_.get(courseData,'id')} ref={register()} name={'workshopId'}/>
                                    <input type={'hidden'} value={'workshop'} ref={register()} name={'program_type'}/>

                                    <MultipleSurvey register={register} workshopSurveyData1={workshopSurveyData1} errors={errors} defaultData={surveyDefault}></MultipleSurvey>
                                    <SubjectiveSurvey register={register} workshopSurveyData2={workshopSurveyData2} errors={errors} defaultData={surveyDefault}></SubjectiveSurvey>

                                    <div>
                                        {
                                            surveyCheck ?
                                                <Link className={'survey_btn'} to={`/member/mycontent/mycertificate/${program_id}`}>이수증 확인하기</Link>
                                                :
                                                <button className={'survey_btn'} type={"submit"}>설문 제출하기</button>

                                        }
                                    </div>
                                </form>

                            </div>
                        </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyWorkshopSurvey;
