import React from 'react';
import { Link } from 'react-router-dom';

import BreadCrumb from '../../components/BreadCrumb';
import SubLeftMenu from '../../components/SubLeftMenu';
import route from '../../route';

const Membership = () => {
  return (
    <div className="membership">
      <BreadCrumb list={route.about} />
      <div className="sub">
        <div className="wrap">
          <SubLeftMenu
            title={route.about.title}
            lnbList={route.about.list}
          />
          <div className="sub__content">
            <h1 className="sub__title">
              <span className="">멤버십</span>
            </h1>
            <div className="membership__content">
              <div className="membership__list">
                <div className="membership__item">
                  <div className="thumb">
                    <img src="/assets/images/membership_img1.png" alt=""/>
                  </div>
                  <div className="info">
                    <h3>멤버십 부여 조건</h3>
                    <p>
                      전남콘텐츠코리아랩 교육 프로그램 이수자<br/>
                      전남콘텐츠코리아랩 서포터즈 활동 수료자<br/>
                      전남콘텐츠코리아랩 장비 교육 이수자
                    </p>
                  </div>
                </div>
                <div className="membership__item">
                  <div className="thumb">
                    <img src="/assets/images/membership_img2.png" alt=""/>
                  </div>
                  <div className="info">
                    <h3>멤버십 부여 절차</h3>
                    <p>
                      멤버십 부여 조건 충족<br/>
                      회원가입<br/>
                      멤버십 신청 및 승인<br/>
                      멤버십 부여 완료
                    </p>
                  </div>
                </div>
                <div className="membership__item">
                  <div className="thumb">
                    <img src="/assets/images/membership_img3.png" alt=""/>
                  </div>
                  <div className="info">
                    <h3>전남콘텐츠코리아랩 멤버십 혜택</h3>
                    <p>
                      전남콘텐츠코리아랩 시설 및 장비 지원<br/>
                      <span>장비 및 시설 예약 사용은 별도 교육프로그램 이수 필요</span><br/>
                      전남콘텐츠코리아랩 행사 우선 참여권<br/>
                      행사 운영시 별도 우선 안내
                    </p>
                  </div>
                </div>
                <div className="membership__item">
                  <div className="thumb">
                    <img src="/assets/images/membership_img4.png" alt=""/>
                  </div>
                  <div className="info">
                    <h3>멤버십 관련 기타 문의</h3>
                    <p>
                      주소, 전남 순천시 장평로 60 111동 2층<br/>
                      전화, 061-755-9522~6
                    </p>
                  </div>
                </div>
              </div>

              <div className="membership__register">
                <Link to={''}>멤버십 회원가입</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Membership;
