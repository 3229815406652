import React, {useEffect, useCallback, useState} from 'react';
import BreadCrumb from 'components/BreadCrumb';
import route from 'route';
import SubLeftMenu from 'components/SubLeftMenu';
import SpaceInfo from 'components/SpaceInfo';
import {useSelector} from 'react-redux';
import {Helmet} from "react-helmet";
import {CustomLoading} from "components/Custom";

//예약캘린더
import ScheduleCalendar from 'components/ScheduleCalendar';

import {
    AvailabilityCalendar,
    AvailabilityEvent,
    MsSinceMidnightRange,
    Booking,
    Range,
    CalendarThemeProp,
} from 'react-availability-calendar';


//graphql 관련
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import * as query from "queries/resource";

const msInHour = 60 * 60 * 1000;
const TYPE = 'schedule-spaces';
const Space = () => {

    const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}));
    const [space, setSpace] = useState({}); //선택된 공간
    const [schedules, setSchedules] = useState([]); //스케쥴 목록
    const lazyQueryOpt = {
        fetchPolicy: 'no-cache',
        onCompleted: ({scheduleSpaces = []}) => setSchedules([...scheduleSpaces])
    }
    const variables = _.isEmpty(space) && {} || {where: {"space_resource.id": space.id}};
    const [getSchedules, {loading}] = useLazyQuery(query.SCHEDULE_SPACES_QUERY, {...lazyQueryOpt, variables});


    useEffect(()=>{
        /*console.log(schedules)*/
    },[schedules])


    const now = new Date();

    const onAvailabilitySelected = (a: AvailabilityEvent) =>
        console.log('Availability slot selected: ', a);

    const onChangedCalRange = (r: Range) =>
        console.log('Calendar range selected (fetch bookings here): ', r);

    const blockOutPeriods: MsSinceMidnightRange[] = [
        [0 * msInHour, 9 * msInHour],
        [19 * msInHour, 24 * msInHour],
    ];

    const bookings: Booking[] = [
        {
            startDate: new Date(2020, 2, 1, 19),
            endDate: new Date(2020, 2, 1, 20),
        },
        {
            startDate: new Date(2020, 2, 1, 16, 30),
            endDate: new Date(2020, 2, 1, 17),
        },
    ];

    const providerTimeZone = 'Asia/Seoul';

    return (
        <div className="content introduction">
            <div className="resource">
                <Helmet title="스튜디오 예약"/>
                <BreadCrumb list={route.depth4}/>
                <div className="sub">
                    <div className="wrap">
                        {/*<SubLeftMenu
                        title={route.depth4.title}
                        lnbList={route.depth4.list}
                    />*/}
                        <div className="sub__content introduction_content">
                            <h1 className="sub__title introduction_title">
                                <span className="">스튜디오 예약222</span>
                            </h1>

                            {/* 공간 안내 */}
                            <SpaceInfo hiddenHeader={true} onSubmit={setSpace}/>

                            {/* 공간장비 예약캘린더 */}
                            <div className="section calander">

                                <div className="calendar mb-3" style={{height: '50px'}}>
                                    <h2 className="part_title">공간 예약캘린더</h2>
                                    <div className={`calendar-status`}>
                                        <div className={`calendar-status-confirmed`}>승인</div>
                                        <div className={`calendar-status-disprove`}>미승인</div>
                                    </div>
                                    {/*{loading && (<span className="loading-text">로딩중...</span>)}*/}
                                    <button onClick={getSchedules} className="refresh"/>
                                </div>
                                {(loading || _.isEmpty(space)) && (<CustomLoading loading={true}/>)}
                                {/*<div className={(loading || _.isEmpty(space)) && `d-none` || ``}>{
                                    (<ScheduleCalendar space={space} data={schedules} getData={getSchedules}/>)
                                }</div>*/}
                                <div className={(loading || _.isEmpty(space)) && `d-none` || ``}>
                                    <AvailabilityCalendar
                                        theme={{requestAppointmentLabel: "예약하고자 하는 시간을 선택하세요."}}
                                        bookings={bookings}
                                        providerTimeZone={providerTimeZone}
                                        moment={moment}
                                        initialDate={now}
                                        onAvailabilitySelected={onAvailabilitySelected}
                                        onCalRangeChange={onChangedCalRange}
                                        blockOutPeriods={blockOutPeriods}
                                    />
                                </div>
                                {/*<ScheduleCalendar data={schedules} getData={getSchedules}/>*/}
                                {/*<ReservationCalendar hiddenHeader={true} events={reservationlist}/>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Space;
