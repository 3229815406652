import React from 'react';
import { FaAngleDoubleLeft } from 'react-icons/all';
import * as actions from 'store/actions/content';
import _ from 'lodash';
import {useSelector, useDispatch} from 'react-redux';

import {Helmet} from "react-helmet";
import * as api from 'lib/content';
import usePromise from "utils/usePromise";
import { useForm, Controller } from "react-hook-form";



export default class CommentBox extends React.Component {
    constructor() {
        super();

        this.state = {
            showComments: false,
            comments: []
        };
    }

    render() {
        const {like,view_counts,comments:comments_data=[],onSubmit,user,likeHandle,course_id='',onDlete,setModify,modify}=this.props

        let commentNodes;


        const comments = this._getComments({comments_data,onDlete,course_id,onSubmit,setModify,modify});

        let buttonText = '댓글 보기';

        if (this.state.showComments) {
            buttonText = '댓글 감추기';
            commentNodes = <div className="comment-list">{comments}</div>;
        }
        return (
            <div className="comment-box">
                <div className={'title_container'}>
                    <div className={'add_container'}>
                        <button  className={"see"}><strong>조회수</strong>{_.get(view_counts,'0.count',0)}</button>
                        <button onClick={likeHandle()} className={"thumb"}><strong>좋아요</strong>{_.get(like,'0.like_count',0)}</button>
                    </div>
                    <h2>강의에 대한 의견을 달아주세요</h2>
                </div>
                <CommentForm user={user} onSubmit={onSubmit} addComment={this._addComment.bind(this)} onDlete={onDlete}/>
                <div className={'comment_info'}>
                    {/*<h3 className={'comment_title'}>댓글</h3>*/}
                    <h4 className="comment-count">
                        {this._getCommentsTitle(comments_data.length)}
                    </h4>
                </div>
                <div className={'comment-reveal_container'}>
                    <button id="comment-reveal" className={'comment-reveal'} onClick={this._handleClick.bind(this)}>
                        {buttonText}
                    </button>

                </div>
                {commentNodes}
            </div>
        );
    } // end render

    _addComment(author, body) {
        const comment = {
            id: this.state.comments.length + 1,
            author,
            body
        };

        this.setState({comments: this.state.comments.concat([comment])}); // *new array references help React stay fast, so concat works better than push here.
    }



    _handleClick() {
        this.setState({
            showComments: !this.state.showComments
        });
    }

    _getComments({comments_data,onDlete,course_id,onSubmit,setModify,modify}) {

        if(!_.isEmpty(comments_data)){
            return comments_data.map((comment) => {
                return (
                    <Comment
                        author={comment.name}
                        body={comment.body}
                        key={comment.id}
                        onDlete ={onDlete}
                        course_id={course_id}
                        comment={comment}
                        onSubmit={onSubmit}
                        setModify={setModify}
                        modify={modify}
                    />

                );
            });
        }
        else{
            return '댓글이 없습니다.'
        }

    }

    _getCommentsTitle(commentCount) {

        if (commentCount === 0) {
            return '아직 댓글이 없습니다';
        }else {
            return `댓글 ${commentCount}개`;
        }
    }
} // end CommentBox component

const CommentForm = ({onSubmit=()=>{},user}) => {
    const { register, handleSubmit, formState: { errors } ,control  } = useForm();
    return (
        <form className="comment-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="comment-form-fields">
                <p className={'writer'}>{_.get(user,'name','')}</p>
                {/*<p className={'timer'}>1시간 전</p>*/}
                {/*<input placeholder="이름" required ref={(input) => this._author = input}></input><br/>*/}
                {/*<textarea placeholder="댓글" rows="4" required ref={(textarea) => this._body = textarea}></textarea>*/}

                <Controller
                    control={control}
                    name="body"
                    defaultValue={''}
                    render={({ field }) => {
                        return <textarea control={control} name="body" defaultValue={''} ref={register({required: '댓글을 입력해주세요'})} {...field}/>
                    }}
                    rules={{ required: '댓글을 입력해주세요' }}
                />
                {errors.body && (
                    <small className="text-danger">{errors.body.message}</small>
                )}
                <div className="comment-form-actions">
                    <button type="submit">글 쓰기</button>
                </div>
            </div>
        </form>
    );

    /*_handleSubmit(event) {
        event.preventDefault();   // prevents page from reloading on submit
        let author = this._author;
        let body = this._body;
        // this.props.addComment(author.value, body.value);
        this.props.addComment({author, body});
    }*/
} // end CommentForm component

class Comment extends React.Component {
    constructor() {
        super();

        this.state = {
            showModifyFrom: false,
            comments: []
        };
    }

    render() {
        const {author='', body ='' ,course_id='',comment={} ,onDlete,onSubmit,setModify,modify}=this.props

        const modifyForm =() =>{
            this.setState({
                showModifyFrom: true,
            })
        }

        return (
            <div className="comment">
                {
                    !modify && <p className="comment-header writer">{author}</p>
                }
                {
                    modify ?
                        <ModifyForm onSubmit={onSubmit} author={author} body={body} comment={comment} />
                        : <p className="comment-body">- {body}</p>
                }
                {/*<p className="comment-body">- {body}</p>*/}
                <div className="comment-footer">
                    {
                        !modify && <button onClick={()=>setModify(true)} className={'edit'}>수정</button>
                    }
                    {
                        !modify && <button onClick={()=>onDlete({author, body, course_id, comment})} className={'delete'}>삭제</button>
                    }

                    {/*<a href="#" className="comment-footer-delete" onClick={this._deleteComment}>댓글 삭제</a>*/}
                </div>
            </div>
        );
    }
}

const ModifyForm = ({body,author,comment,onSubmit=()=>{}}) => {
    const { register, handleSubmit, formState: { errors } ,control  } = useForm();
    return (
        <form className="comment-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="comment-form-fields">
                <p className={'writer'}>{author}</p>
                <p className={'timer'}>1시간 전</p>
                {/*<input placeholder="이름" required ref={(input) => this._author = input}></input><br/>*/}
                {/*<textarea placeholder="댓글" rows="4" required ref={(textarea) => this._body = textarea}></textarea>*/}
                {/*<input name={'mode'} defaultValue={'modify'} />*/}
                <Controller
                    control={control}
                    name="body"
                    defaultValue={body}
                    render={({ field }) =>
                    {
                        return  (<textarea {...field}/>)
                    }

                    }
                    rules={{ required: '댓글을 입력해주세요' }}
                />
                <Controller
                    className ="disnone"
                    control={control}
                    name="mode"
                    type="hidden"
                    defaultValue='m'
                    render={({ field }) =>
                        <input {...field} type={'hidden'}/>
                    }
                />
                <Controller
                    className ="disnone"
                    control={control}
                    name="id"
                    type="hidden"
                    defaultValue={comment.id}
                    render={({ field }) =>
                        <input {...field} type={'hidden'}/>
                    }
                />
                {/*<input type="hidden"*/}
                {/*       name="mode"*/}
                {/*       // ref={(input) =>() =input}*/}
                {/*       defaultValue="m"/>*/}
                {/*<input type="hidden"*/}
                {/*       name="id"*/}
                {/*       ref={register()}*/}
                {/*       defaultValue={comment.id}/>*/}
                {errors.body && (
                    <small className="text-danger">{errors.body.message}</small>
                )}
                <div className="comment-form-actions">
                    <button type="submit">수정</button>
                </div>
            </div>
        </form>
    );

    /*_handleSubmit(event) {
        event.preventDefault();   // prevents page from reloading on submit
        let author = this._author;
        let body = this._body;
        // this.props.addComment(author.value, body.value);
        this.props.addComment({author, body});
    }*/
} // end CommentForm component


//ReactDOM.render(<CommentBox />, document.getElementById('main'));
