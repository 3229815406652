import React, {useEffect, useMemo, useState} from 'react';
import * as api from 'lib/content';

const TYPE2 = 'workshopprogram-applies';
const BoardCustomColum = ({data}) => {
    const {apply_maxnumber=0,id} = data

    const [applyCount, setApplyCount] = useState([]);
    async function fetchMyAPI(id) {
        if(id){
            try {
                const {status:apply_status,data:apply_data} = await api.count({type:TYPE2,appied_program:id,apply_status:'5ff6b769bf55b944295d63e8'})
                if(apply_status !== 200){
                    throw new Error('에러 발생');
                }
                else{
                    setApplyCount(apply_data);
                }
            } catch (e) {
                console.log('데이터 로딩 실패',e.message);
            } finally {
            }
        }
    }


    useEffect(()=>{
        if(!_.isEmpty(data)){
            const {id} = data
            fetchMyAPI(id)
        }
    },[applyCount,data])

    if(!_.isEmpty(apply_maxnumber)){
        if ((apply_maxnumber == applyCount) || (apply_maxnumber < applyCount)){
            return(<>정원초과</>)
        }
        else{
            return (<>{applyCount}/<br/>{apply_maxnumber}</>)
        }
    }
    else{
        return(<>{applyCount}명/<br/>무제한</>)
    }
    /*return (

    )*/
}

export default BoardCustomColum;