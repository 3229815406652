import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AboutIntroduction from './Introduction';
import AboutMembership from './Membership';
import GuideLine from './GuideLine';
import GuideLineDetail from './GuideLineDetail';
import Organization from './Organization';
import AboutMap from './Map';

const About = ({ match }) => {
  return (
    <Switch>
      {/** 아카데미 소개 - 소개 */}
      <Route path={`${match.path}/introduction`} render={() => <AboutIntroduction />} />
      <Route path={`${match.path}/membership`} render={() => <AboutMembership />} />
      <Route path={`${match.path}/map`} render={() => <AboutMap />} />
        <Route path={`${match.path}/map`} render={() => <div className="content" style={{textAlign:'center',fontSize:'2.5rem',fontWeight:'400',margin:'15.26rem'}}>페이지 준비중입니다.</div>} />
        <Route path={`${match.path}/organization`} render={() => <Organization/>} />
        <Route path={`${match.path}/guideline/:id`} render={() => <GuideLineDetail/>} />
        <Route path={`${match.path}/guideline`} render={() => <GuideLine/>} />
    </Switch>
  );
};

export default About;
