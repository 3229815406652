import React, {useEffect} from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';

import MemberLogin from './Login';
import MemberRegister from './Register';
import MemberRegisterComplete from './RegisterComplete';
import Membertype from './Type';
import MemberMyStudyProgram from './MyStudyProgram';
import MemberMyWorkShopProgram from './MyWorkShopProgram';
import MemberMySupportProgram from './MySupportProgram';
import MemberMyConsulting from './MyConsulting';
import MemberMyResource from './MyResource';
import PasswordFind from "./PasswordFind"
import {NotFound} from "components/Errors";
import AuthRoute from "components/AuthRoute";
import PasswordReset from "./PasswordReset";
import Changeinfo from "./ChangeInfomation";
import EmailTest from "./Emailtest";
import EmailSend from "./EmailSend";
import MySurvey from "./MySurvey";
import MyWorkshopSurvey from "./MyWorkshopSurvey";
import MyCertificate from "./MyCertificate";
import MyContent from "./MyContent";
import MyContentInstructor from "./MyContentInstructor";
import MyEvaluation from "./MyEvaluation";
import MyEvaluationDetail from "./MyEvaluationDetail";
import ProgressRate from "./ProgressRate";

const Member = ({match}) => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        return () => dispatch(actions.init())
    }, [location.pathname]);

    return (
        <Switch>
            {/** 로그인 페이지 */}
            <Route path={`${match.path}/login`} render={() => <MemberLogin/>}/>

            {/** 회원가입 타입 페이지 */}
            <Route path={`${match.path}/register/type`} render={() => <Membertype/>}/>

            {/** 회원가입 페이지 */}
            <Route path={`${match.path}/register/information`} render={() => <MemberRegister/>}/>
            <Route path={`${match.path}/register/complete`} render={() => <MemberRegisterComplete/>}/>

            {/** 비밀번호 찾기 페이지 */}
            <Route path={`${match.path}/find-password`} render={() => <PasswordFind/>}/>
            <Route path={`${match.path}/reset-password`} render={() => <PasswordReset/>}/>

            <AuthRoute path={`${match.path}/mystudyprogram`} render={() => <MemberMyStudyProgram/>}/>
            <AuthRoute path={`${match.path}/myworkshoprogram`} render={() => <MemberMyWorkShopProgram/>}/>
            <AuthRoute path={`${match.path}/myconsulting`} render={() => <MemberMyConsulting/>}/>
            <AuthRoute path={`${match.path}/mysupportprogram`} render={() => <MemberMySupportProgram/>}/>
            <AuthRoute path={`${match.path}/myresource`} render={() => <MemberMyResource/>}/>


            <AuthRoute path={`${match.path}/mycontent/workshopsurvey/:program_id`} render={() => <MyWorkshopSurvey/>}/>
            <AuthRoute path={`${match.path}/mycontent/studysurvey/:program_id`} render={() => <MySurvey/>}/>
            {/** 마이페이지 */}
            <AuthRoute path={`${match.path}/mycontent/mycertificate/:program_id`} render={() => <MyCertificate/>}/>
            <AuthRoute path={`${match.path}/mycontent/progressRate`} render={() => <ProgressRate/>}/>
            <AuthRoute path={`${match.path}/InstructorCourse`} render={() => <MyContentInstructor/>}/>
            <AuthRoute path={`${match.path}/mycontent`} render={() => <MyContent/>}/>
            <AuthRoute path={`${match.path}/changeinfo`} render={() => <Changeinfo/>}/>
            <AuthRoute path={`${match.path}/Emailtest`} render={() => <EmailTest/>}/>
            <AuthRoute path={`${match.path}/EmailSend`} render={() => <EmailSend/>}/>
            <AuthRoute path={`${match.path}/MyEvaluation/:id`} render={() => <MyEvaluationDetail/>}/>
            <AuthRoute path={`${match.path}/MyEvaluation`} render={() => <MyEvaluation/>}/>

            <Route path="*" component={NotFound} status={404}/>
        </Switch>
    );
};

export default Member;
