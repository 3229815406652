import React, {useEffect, useMemo, useState} from 'react';
import { Link ,useParams, useHistory } from 'react-router-dom';
import { FaFacebookF, FaTwitter, AiOutlineGooglePlus } from 'react-icons/all';
import { FaDownload } from 'react-icons/fa';
import {useSelector, useDispatch} from 'react-redux';
import BreadCrumb from '../../components/BreadCrumb';
import route from '../../route';
import SubLeftMenu from '../../components/SubLeftMenu';
import * as actions from 'store/actions/content';
import {apiUrl} from 'config';
import * as api from 'lib/content';
import ReactMarkdown from "react-markdown";
import {Helmet} from "react-helmet";

const TYPE = 'localstories';
const LocalDataDetail = () => {
  const dispatch = useDispatch();
  const {view = {}, loading, errorMsg = ''} = useSelector(({content}) => ({...content}));
  const history = useHistory();
  const {id} = useParams();
  const [tab, setTab] = useState('detail');
  const updateParams = (data) => {
    setParams({...params, ...data});
  };
    const defaultUrl = 'http://35.243.114.29:1347';
    const {
        thumbnailimages_path='',
        attached_file_path =''
    }=view


    const attached_file_list = attached_file_path.split('^=^')
    const thumbnailimages_list =thumbnailimages_path.split('^=^')


  const newurl = window.location.href
  useEffect(() => {
    dispatch(actions.viewRequest({type: TYPE, id}));
  }, [id])


  //컨텐츠 데이터 로딩 실패시
  useEffect(() => {
    if (!loading && _.isEmpty(view) && errorMsg) {
      history.push('/dataset/localdata');
    }
  }, [view, loading, errorMsg])






  return (
    <div className="localdata">
      <Helmet title='지역 스토리'/>
      <BreadCrumb list={route.dataset} />
      <div className="sub">
        <SubLeftMenu
          title={route.dataset.title}
          lnbList={route.dataset.list}
        />
        <div className="localdataDetail__top">
          <div className="wrap">
            <div className="left">
              <h2>{view.title}</h2>
             {
                view.location_categories && view.location_categories[0] &&
                  <p>지역:&nbsp;&nbsp;
                    {view.location_categories[0].title}
                  </p>

              }
              {/*<div className="popup_icon">*/}
              {/*  <a target="_blank" href={`http://www.facebook.com/sharer/sharer.php?u=${newurl}`}>*/}
              {/*    <img src="/assets/images/icon_snsshare-facebook.png" alt="Facebook"/>*/}
              {/*  </a>*/}
              {/*  <a target="_blank" href={`https://twitter.com/intent/tweet?text=${newurl}`}>*/}
              {/*    <img src="/assets/images/icon_snsshare-twitter.png" alt="Twitter"/>*/}
              {/*  </a>*/}
              {/*  /!*<a target="_blank" href="https://accounts.google.com/">*!/*/}
              {/*  /!*  <img src="/assets/images/icon_snsshare-googleplus.png" alt="Google+"/>*!/*/}
              {/*  /!*</a>*!/*/}
              {/*</div>*/}
              <div className="snsbox">
                <a href={`http://www.facebook.com/sharer/sharer.php?u=${newurl}`} className="facebook"><FaFacebookF/></a>
                <a href={`https://twitter.com/intent/tweet?text=${newurl}`} className="twitter"><FaTwitter/></a>
                <a href="https://accounts.google.com/" className="google"><AiOutlineGooglePlus/></a>
              </div>
            </div>
            <div className="right">
              <div className="right__row">
                {/*<button>추천</button>*/}
                {/*<button>북마크</button>*/}
                {/*<button>인쇄</button>*/}
              </div>
              {/*<div className="right__row">*/}
              {/*  <div className="_col">*/}
              {/*    <strong>0</strong> 인기도*/}
              {/*  </div>*/}
              {/*  <div className="_col">*/}
              {/*    <strong>0</strong> 조회수*/}
              {/*  </div>*/}
              {/*  <div className="_col">*/}
              {/*    <strong>0</strong> 댓글수*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
          {/*<img src={`http://seolhwa.jcia.or.kr${view.thumbnailimages_path}`}></img>*/}
        <div className="localdataDetail__body">
          <div className="wrap">
              { thumbnailimages_list.length >1 &&
            <div className="thumb">
              {
                  thumbnailimages_list && thumbnailimages_list.map((item,idx) =>{
                      const [thumbName = '',detailName='' , thumbFileName = '', filePath = ''] = item.split('|')
                      if(idx>=1) return
                      return (<div key={idx}>
                          <img  src={`${defaultUrl}${filePath}${thumbFileName}`}></img>
                      <a href={`${defaultUrl}${filePath}${detailName}`} target="_blank" rel="noreferrer">크게보기</a>
                      </div>)

                  }) ||<>
                  <img src={`${defaultUrl}${filePath}${thumbFileName}`}></img>
                   <a href="/assets/images/image-not-found.png" target="_blank" rel="noreferrer">크게보기</a>
                  </>
              }

            </div>


            }
            <div className="detail">
              {
                view.data_code &&
                <div className="_row hori">
                  <strong>자료코드</strong>
                  <p>
                    {view.data_code}<br/>
                  </p>
                </div>
              }
              {
                view.investigation_place &&
                <div className="_row hori">
                  <strong>조사장소</strong>
                  <p>{view.investigation_place}</p>
                </div>
              }
              {
                view.investigation_date &&
                <div className="_row hori">
                  <strong>조사일</strong>
                  <p>{view.investigation_date}</p>
                </div>
              }
              {
                view.informant &&
                <div className="_row hori">
                  <strong>제보자</strong>
                  <p>{view.informant}</p>
                </div>
              }
              {
                view.investigator &&
                <div className="_row hori">
                  <strong>조사자</strong>
                  <p>{view.investigator}</p>
                </div>
              }

              {
                view.summary &&
                <div className="_row">
                  <strong>줄거리</strong>
                  <p>
                    {view.summary}
                  </p>
                </div>
              }
              {
                view.body &&
                <div className="_row">
                  <strong>내용</strong>
                  <ReactMarkdown
                      transformImageUri={(uri) => uri.match(/^\/uploads/) && `${apiUrl}${uri}` || uri}
                      escapeHtml={false}
                      source={view.body.replaceAll('src=\"data','src=\"http://seolhwa.jcia.or.kr/data')}/>
                </div>
              }


            </div>
          </div>
        </div>
        <div className="localdataDetail__foot">

          <div className="wrap">
              { !(attached_file_list=='') &&
              <div className="files">
                  <div className="_row">
                      { attached_file_list &&  attached_file_list.map((item,idx) =>{
                          const [attachName = '', attachFileName = '', , filePath = ''] = item.split('|')
                          return (
                          <button key ={idx} className="download file"
                                  onClick={(e) => {
                                      api.download(`${defaultUrl}${filePath}${attachFileName}`, attachName)
                                  }}>
                                  <span className="icon">
                                  <FaDownload />
                                </span>
                                <p>{attachName}</p>
                          </button>
                          )
                          {/*<a href={`http://seolhwa.jcia.or.kr/${view.attached_file_path}`} download className="file">*/}
                          {/*    <span className="icon">*/}
                          {/*    <FaDownload />*/}
                          {/*  </span>*/}
                          {/*  <p>{view.attached_file_name}</p>*/}
                          {/*</a>*/}
                      })
                      }
                  </div>
              </div>
              }
            <div className="actions">
              <Link to="/dataset/localdata">목록으로 가기</Link>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default LocalDataDetail;
