import React, {useState, useEffect} from 'react';
import {Link, useRouteMatch, generatePath} from 'react-router-dom';
import {MainSlide, PartnersSlide} from 'components/Slide';
import MainBoard from 'components/MainBoard';
import ProgramList from 'components/ProgramList';
import ReservationCalendar from 'components/ReservationCalendar';
import SpaceInfo from 'components/SpaceInfo';
import EquipmentInfo from 'components/EquipmentInfo';
import IntroCollages from '../../components/Intro/IntroCollages';
import IntroPagination from '../../components/Intro/IntroPagination';
import {CustomPagination} from 'components/Custom'

// 센터소식 팝업
import {CustomHomePopup} from 'components/Custom'
import { CookiesProvider } from 'react-cookie';
import {reservationlist, equipmentlist, programlist} from 'data';


//예약캘린더
import ScheduleCalendar from 'components/ScheduleCalendar';

//하단 대학 슬라이더
import Slider from 'react-slick';

//graphql 관련
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import * as cQuery from "queries/content";
import * as rQuery from "queries/resource";

const Index = () => {
    const [paging, setPaging] = React.useState(0);
    const [totalCount, setTotalPaging] = React.useState(0);
    useEffect(() => {
        /*console.log(paging)*/
    }, [paging])
    const handleClickPaging = React.useCallback((value) => {
        if (typeof value === 'number') {
            setPaging(value);
            return;
        }
        if (value === 'next') {
            setPaging(prev => {
                if (prev == 3) {
                    return 0
                } else {
                    return prev + 1;
                }
            });
            return
        }
        if (value === 'prev') {
            setPaging(prev => {
                if (prev == 0) {
                    return 3
                } else {
                    return prev - 1
                }
            });
            return
        }
    }, [paging]);


    //메인 페이지 배너 관련
    const [mainbannerList, setMainbannerList] = useState([]);
    useQuery(cQuery.MAIN_BANNER_QUERY, {
        fetchPolicy: 'network-only',
        variables: {sort: "banner_weight:ASC"},
        onCompleted: ({mainBanners = []}) => setMainbannerList([...mainBanners])
    });

    //공지사항 관련
    const [noticeList, setNoticeList] = useState([]);
    useQuery(cQuery.NOTICES_QUERY, {
        fetchPolicy: 'network-only',
        variables: {sort: "createdAt:ASC"},
        onCompleted: ({boardNotices = []}) => setNoticeList([...boardNotices])
    });


    //센터소식 관련
    const [newsList, setNewsList] = useState([]);
    useQuery(cQuery.NEWS_QUERY, {
        fetchPolicy: 'network-only',
        variables: {sort: "createdAt:ASC"},
        onCompleted: ({boardNews = []}) => setNewsList([...boardNews])
    });


    //프로그램 목록 관련
    const [studyList, setStudyList] = useState([]);
    useQuery(cQuery.STUDY_PROGRAM_QUERY, {
        fetchPolicy: 'network-only',
        variables: {limit: 13, sort: "weight:asc"},
        onCompleted: ({studyprograms = []}) => setStudyList([...studyprograms])
    });

    //지원사업 목록 관련
    const [supportList, setSupportList] = useState([]);
    useQuery(cQuery.SUPPORT_PROGRAM_QUERY, {
        fetchPolicy: 'network-only',
        variables: {limit: 3, sort: "createdAt:DESC"},
        onCompleted: ({supportprograms = []}) => setSupportList([...supportprograms])
    });

    //파트너쉽 사이트 관련
    const [relationSites, setRelationSites] = useState([]);
    useQuery(cQuery.RELATION_SITES_QUERY, {
        fetchPolicy: 'network-only',
        variables: {sort: "pinned_order:ASC,createdAt:DESC"},
        onCompleted: ({relationsitelists = []}) => setRelationSites([...relationsitelists])
    });

    //공간/장비 예약 관련
    const [schedules, setSchedules] = useState([]); //스케쥴 목록
    const [getSchedules] = useLazyQuery(rQuery.SCHEDULE_SPACES_QUERY, {
        fetchPolicy: 'no-cache',
        onCompleted: ({scheduleSpaces = []}) => setSchedules([...scheduleSpaces])
    });

    //대학교 관련
    const [univ, setUniv] = useState([]); //대학 목록
    useQuery(rQuery.UNIV_LIST, {
        fetchPolicy: 'no-cache',
        variables: {sort: "univ_name:asc", start: paging * 10, limit: 10},
        onCompleted: ({partnersUnivs = []}) => {
            setUniv([...partnersUnivs])
        }
    });
    useQuery(rQuery.UNIV_LIST, {
        fetchPolicy: 'no-cache',
        variables: {sort: "univ_name:asc"},
        onCompleted: ({partnersUnivs = []}) => {
            setTotalPaging(partnersUnivs.length)
        }
    });

    //슬라이더 세팅
    const settings = {
        // slide: 'Slider',
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false
    };

    // 다중 팝업 시 위치변경
    let idxNum = -1;

    return (
        <div className={'main-wrap'}>
            <div className="home">

                {/* 공지사항 팝업창 */}
                <CookiesProvider>
                {
                    !_.isEmpty(noticeList) && noticeList.map((item, idx) => {
                        if (_.get(item, 'notices')) {
                            const {notice_startdate='', notice_enddate='',size_x='auto',size_y='auto',position_x='0',position_y='0'} = item
                            if (!_.isEmpty(notice_startdate, notice_enddate)){
                                const today = moment();
                                if (moment(today).isBefore(notice_enddate) && moment(today).isAfter(notice_startdate)) {
                                    idxNum++;
                                    return (<CustomHomePopup data={item} key={_.get(item, 'id')} num={idxNum}/>)
                                }else{
                                    return(
                                        <></>
                                    )
                                }
                            }

                        }
                        /**/
                    })
                }
                </CookiesProvider>

                {/* 센터소식 팝업창 */}
                <CookiesProvider>
                {
                    !_.isEmpty(newsList) && newsList.map((item, idx) => {
                        if (_.get(item, 'notices')) {
                            const {notice_startdate='', notice_enddate='',size_x='auto',size_y='auto',position_x='0',position_y='0'} = item
                            if (!_.isEmpty(notice_startdate, notice_enddate)){
                                const today = moment();
                                if (moment(today).isBefore(notice_enddate) && moment(today).isAfter(notice_startdate)) {
                                    idxNum++;
                                    return (<CustomHomePopup data={item} key={_.get(item, 'id')} num={idxNum}/>)
                                }else{
                                    return(
                                        <></>
                                    )
                                }
                            }
                        }
                        /**/
                    })
                }
                </CookiesProvider>

                {/* 비쥬얼 */}
                <MainSlide bannerlist={mainbannerList}/>


                {/* 게시판 */}
                <MainBoard notices={noticeList} news={newsList}/>


                {/*<div className={'intro__section intro__section-2'}>
                    <div className="wrap">
                        <h3 className={'section-title'}>광주전남권역 대학안내</h3>
                        <IntroCollages univList={univ}/>

                        <IntroPagination
                            paging={paging}
                            handleClickPaging={handleClickPaging}
                            totalCountPaging={Math.ceil(totalCount/10)}
                          />

                        <CustomPagination
                        pageSize={10}
                        pageIndex={paging}
                        pageCount={(totalCount/10)}
                        onPageChange={({selected}) => setPaging(selected)}/>
                    </div>
                </div>*/}

                {/* 전남콘텐츠코리아랩 프로그램은 ~ing : */}
                <div className="programBlock">
                    <div className="wrap">
                        <ProgramList
                            path={'http://34.84.15.163:6061/module/course/'}
                            list={studyList}
                            title={'공개강좌'}
                            description={'광주교육대학교에서 제공하는 공개강좌를 만나보세요.'}
                        />

                        <div className="studyViewDiv">
                            <Link to="/content/repository" className="studyViewBtn"> + 전체 보기</Link>
                        </div>
                    </div>


                </div>

                <div className={'intro__section intro__section-3'}>
                    <div className="wrap">
                        <div>
                            {/*<img className='univ_arrow' src='/assets/images/link_left.png'/>*/}
                            <Slider {...settings}>
                                <div>
                                    {/*<a href="https://www.moe.go.kr/main.do?s=moe" target="_blank">
                                        <img src='/assets/images/bottom_univ.png'/>
                                    </a>*/}
                                </div>
                                <div>
                                    {/*<a href=" https://www.jnu.ac.kr/jnumain.aspx " target="_blank">
                                        <img src='/assets/images/bottom_univ2.png'/>
                                    </a>*/}
                                </div>
                                <div>
                                   {/* <a href=" https://www.dkc.ac.kr/ " target="_blank">
                                        <img src='/assets/images/bottom_univ3.png'/>
                                    </a>*/}
                                </div>
                                <div>
                                    {/*<a href="https://www.keris.or.kr/" target="_blank">
                                        <img src='/assets/images/bottom_univ7.png'/>
                                    </a>*/}
                                </div>
                                <div>
                                    {/*<a href="http://www.koer.kr/" target="_blank">
                                        <img src='/assets/images/bottom_univ6.png'/>
                                    </a>*/}
                                </div>
                                <div>
                                    {/*<a href="http://www.kocw.net/" target="_blank">
                                        <img src='/assets/images/bottom_univ5.png'/>
                                    </a>*/}
                                </div>
                            </Slider>
                            {/*<img className='univ_arrow' src='/assets/images/link_right.png'/>*/}
                        </div>
                    </div>
                </div>

                {/* 전남콘텐츠랩 지원사업 */}
                {/*<div className="section section--business">
                <div className="wrap">
                    <ProgramList
                        path={'/program/supportprogram'}
                        list={supportList}
                        title={'전남콘텐츠랩 지원사업'}
                    />
                </div>
            </div>*/}

                {/* 공간장비 예약캘린더 */}
                {/*<div className="section">
                <div className="wrap">
                    <ReservationCalendar events={reservationlist}/>

                    <div className="calendar mb-3" style={{height: '50px'}}>
                        <h2 className="calendar__title">공간/장비 예약캘린더</h2>
                        <div className={`calendar-status`} style={{right: 0}}>
                            <div className={`calendar-status-confirmed`}>승인</div>
                            <div className={`calendar-status-disprove`}>미승인</div>
                        </div>
                    </div>
                    <ScheduleCalendar data={schedules} getData={getSchedules}/>
                </div>
            </div>*/}

                {/* 공간 안내 */}
                {
                    false && (
                        <div className="section">
                            <div className="wrap">
                                <SpaceInfo/>
                            </div>
                        </div>
                    )
                }

                {/* 장비 안내 */}
                {
                    false && (
                        <div className="section">
                            <div className="wrap">
                                <EquipmentInfo/>
                            </div>
                        </div>
                    )
                }

                {/* 관련 기관 */}
                <div className="section section--relation">
                    {/*<div className="wrap">
                    <PartnersSlide />
                </div>*/}
                </div>
            </div>
        </div>
    );
};

export default Index;
