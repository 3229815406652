// 강좌만족도 설문 객관식/주관식
export const courseSurveyData1 = [
    {
        no: 1,
        id: 'survey_q1',
        title: '본 강좌를 수강한 목적은 무엇입니까?',
        type: 'multiple2'
    },
    {
        no: 2,
        id: 'survey_q2',
        title: '강좌내용은 교육목적에 적합하게 구성되었습니까?',
        type: 'multiple'
    },
    {
        no: 3,
        id: 'survey_q3',
        title: '교수자는 강좌에 필요한 충분한 전문지식을 가지고 있고, 이해하기 쉽게 강의 내용을 전달하였습니까?',
        type: 'multiple'
    },
    {
        no: 4,
        id: 'survey_q4',
        title: '강좌에 대해 전반적으로 만족하셨습니까?',
        type: 'multiple'
    },
    {
        no: 5,
        id: 'survey_q5',
        title: '본 강좌를 친구나 동료, 지인 등에게 추천할 의사가 있습니까?',
        type: 'multiple'
    }

];

export const courseSurveyData2 = [
    {
        no: 6,
        id : 'answer_1',
        title: '강좌에 대해 좋았던 점이나 개선이 필요한 사항이 있다면 작성해 주세요.',
        type: 'subjective'
    }
];




// 워크숍 만족도 설문 객관식/주관식
export const workshopSurveyData1 = [
    {
        no: 1,
        id: 'survey_q1',
        title: '워크숍에 대해 전반적으로 만족하셨습니까?',
        type: 'multiple'
    },
    {
        no: 2,
        id: 'survey_q2',
        title: '워크숍 내용은 명확하고 이해하기 쉬웠습니까?',
        type: 'multiple'
    },
    {
        no: 3,
        id: 'survey_q3',
        title: '이번과 같은 형식의 온라인 워크숍을 다시 개최한다면 신청할 의사가 있습니까?',
        type: 'multiple'
    },
    {
        no: 4,
        id: 'survey_q4',
        title: '본 워크숍을 동료, 지인 등에게 추천할 의사가 있습니까?',
        type: 'multiple'
    }

];

export const workshopSurveyData2 = [
    {
        no: 5,
        id : 'answer_1',
        title: '향후 워크숍에서 다루기를 희망하는 주제가 있다면 작성해 주세요.',
        type: 'subjective'
    },
    {
        no: 6,
        id : 'answer_2',
        title: '워크숍과 관련하여 좋았던 점이나 개선이 필요한 사항이 있다면 작성해 주세요.',
        type: 'subjective'
    }
];







export const survey3 = [
    {
        title: '수업 자가진단 체크리스트',
        survey_type: 'sueopjagajindanchekeuriseuteu',
        list: [
            {
                no: 1,
                title: '수업 시작과 마치는 시간을 정확히 지키는가?',
                type: 'first'
            },
            {
                no: 2,
                title: '멀티미디어 자료를 활용하는가?',
                type: 'second'
            },
            {
                no: 3,
                title: '학생들에게 학습목표를 정확히 제시하는가?',
                type: 'first'
            },
            {
                no: 4,
                title: '발음이 명확한가?',
                type: 'third'
            },
            {
                no: 5,
                title: '평가방법과 기준에 대한 명확한 설명을 하는가?',
                type: 'first'
            },
            {
                no: 6,
                title: '다양한 수업 방법을 적용하는가?',
                type: 'second'
            },
            {
                no: 7,
                title: '수업을 시작할 때 수업내용의 전체 개요를 알려주는가?',
                type: 'first'
            },
            {
                no: 8,
                title: '학생들의 동기와 흥미를 유발하고 있는가?',
                type: 'second'
            },
            {
                no: 9,
                title: '교재와 함께 학습하기에 편리하게 제작되었는가?',
                type: 'fifth'
            },
            {
                no: 10,
                title: '학생들의 반응 및 과제물과 시험에 대한 적극적인 피드백을 제공하는가?',
                type: 'second'
            },
            {
                no: 11,
                title: '동영상에 교수자의 모습이 포함되어 있는가?',
                type: 'fifth'
            },
            {
                no: 12,
                title: '말의 속도가 적절한가?',
                type: 'third'
            },
            {
                no: 13,
                title: '수업내용을 학생들의 수준에 적합하게 조절하는가?',
                type: 'first'
            },
            {
                no: 14,
                title: '목소리에 강약의 변화가 있는가?',
                type: 'third'
            },
            {
                no: 15,
                title: '학생들을 보면서 수업을 진행하는가? (비대면시 카메라를 응시하는가?)',
                type: 'third'
            },
            {
                no: 16,
                title: '학생들에게 수업에 참여할 기회를 제공하는가?',
                type: 'fourth'
            },
            {
                no: 17,
                title: '학생들의 의견을 존중해 주는가?',
                type: 'fourth'
            },
            {
                no: 18,
                title: '학생들과 원활한 상호작용이 이루어지고 있는가?',
                type: 'fourth'
            },
            {
                no: 19,
                title: '추가 자료 및 퀴즈를 업로드하여 활성화 하는가?',
                type: 'fifth'
            },
            {
                no: 20,
                title: '학생들의 이름을 불러주는가?',
                type: 'fourth'
            },
            {
                no: 21,
                title: '동영상의 내용이 수업내 활동과 긴밀히 연계되는가?',
                type: 'fifth'
            },
            {
                no: 22,
                title: '학생들이 졸거나 산만하지 않고 수업에 적극적으로 참여하는가?',
                type: 'fourth'
            },
            {
                no: 23,
                title: '수업에 학생들의 참여를 유도하는가?',
                type: 'second'
            },
            {
                no: 24,
                title: '목소리의 크기가 적절한가?',
                type: 'third'
            },
            {
                no: 25,
                title: '저작권 침해를 방지하며, 참고문헌을 제시하였는가?',
                type: 'fifth'
            }
        ]
    }
]
/*
export const survey1 = [
    {
        title: '수업 구성 및 진행',
        list: [
            {
                no: 1,
                title: '수업 시작과 마치는 시간을 정확히 지키는가?'
            },
            {
                no: 2,
                title: '수업을 시작할 때 수업내용의 전체 개요를 알려주는가?'
            },
            {
                no: 3,
                title: '학생들에게 학습목표를 정확히 제시하는가?'
            },
            {
                no: 4,
                title: '수업내용을 학생들의 수준에 적합하게 조절하는가?'
            },
            {
                no: 5,
                title: '평가방법과 기준에 대한 명확한 설명을 하는가?'
            }
        ]
    },
    {
        title: '수업방법',
        list: [
            {
                no: 1,
                title: '다양한 수업 방법을 적용하는가?'
            },
            {
                no: 2,
                title: '멀티미디어 자료를 활용하는가?'
            },
            {
                no: 3,
                title: '학생들의 동기와 흥미를 유발하고 있는가?'
            },
            {
                no: 4,
                title: '수업에 학생들의 참여를 유도하는가?'
            },
            {
                no: 5,
                title: '학생들의 반응 및 과제물과 시험에 대한 적극적인 피드백을 제공하는가?'
            }
        ]
    },
    {
        title: '비언어적 표현',
        list: [
            {
                no: 1,
                title: '목소리의 크기가 적절한가?'
            },
            {
                no: 2,
                title: '말의 속도가 적절한가?'
            },
            {
                no: 3,
                title: '발음이 명확한가?'
            },
            {
                no: 4,
                title: '목소리에 강약의 변화가 있는가?'
            },
            {
                no: 5,
                title: '학생들을 보면서 수업을 진행하는가? (비대면시 카메라를 응시하는가?)'
            }
        ]
    },
    {
        title: '학생들과 관계 및 태도',
        list: [
            {
                no: 1,
                title: '학생들에게 수업에 참여할 기회를 제공하는가?'
            },
            {
                no: 2,
                title: '학생들의 의견을 존중해 주는가?'
            },
            {
                no: 3,
                title: '학생들과 원활한 상호작용이 이루어지고 있는가?'
            },
            {
                no: 4,
                title: '학생들이 졸거나 산만하지 않고 수업에 적극적으로 참여하는가?'
            },
            {
                no: 5,
                title: '학생들의 이름을 불러주는가?'
            }
        ]
    },
    {
        title: '영상 콘텐츠 활용',
        list: [
            {
                no: 1,
                title: '동영상의 내용이 수업내 활동과 긴밀히 연계되는가?'
            },
            {
                no: 2,
                title: '추가 자료 및 퀴즈를 업로드하여 활성화 하는가?'
            },
            {
                no: 3,
                title: '교재와 함께 학습하기에 편리하게 제작되었는가?'
            },
            {
                no: 4,
                title: '동영상에 교수자의 모습이 포함되어 있는가?'
            },
            {
                no: 5,
                title: '저작권 침해를 방지하며, 참고문헌을 제시하였는가?'
            }
        ]
    }
];
 */

/*
export const survey2 = [
    {
        title: '전문가',
        list: [
            {
                no: 1,
                title: '사실, 개념, 원리는 학생들이 알아야 할 가장 중요한 것이다.'
            },
            {
                no: 2,
                title: '나는 이 수업에서 강의식 교수법을 가장 빈번하게 사용한다.'
            },
            {
                no: 3,
                title: '학습 주제와 관련된 논쟁은 나의 전문적 지식으로 거의 해결된다.'
            },
            {
                no: 4,
                title: '학생들은 나를 사실, 원리, 개념을 알려주는 "지식저장소"로 생각할 것이다.'
            },
            {
                no: 5,
                title: '나는 이 수업 시간 내에 소화할 수 있는 것보다 더 많은 수업자료를 가지고 있다.'
            }
        ]
    },
    {
        title: '엄격한 교수자',
        list: [
            {
                no: 1,
                title: '나는 이 수업을 수강하는 학생들이게 높은 수준의 기준을 적용한다.'
            },
            {
                no: 2,
                title: '나는 학생들의 수행결과가 만족스럽지 않을 때는 부정적 피드백을 제공한다.'
            },
            {
                no: 3,
                title: '학생들은 나의 기준과 기대치가 다소 엄격하다고 말할 것이다.'
            },
            {
                no: 4,
                title: '학생들이 무엇을 배워야 하는지, 어떻게 학습해야 하는지를 알려주는 것은 나의 책임이다.'
            },
            {
                no: 5,
                title: '이 수업에서는 성취되어야 할 구체적인 목적과 목표들이 있다.'
            },
            {
                no: 6,
                title: '수업에서 학생들이 무엇을 할 것인지는 강의계획서에 명확하게 정의되어 있다.'
            },
            {
                no: 7,
                title: '나의 기준과 기대치는 학생들이 자신의 학습능력을 개발시키도록 해준다.'
            }
        ]
    },
    {
        title: '위임자',
        list: [
            {
                no: 1,
                title: '학생들은 대체로 나의 관리나 통제 없이 스스로 프로젝트를 수행한다.'
            },
            {
                no: 2,
                title: '이 수업에서의 활동은 학생 스스로 학습 주제를 이해할 수 있도록 도와준다.'
            },
            {
                no: 3,
                title: '이 수업에서 학생들은 하나 이상의 자기주도적인 학습경험을 스스로 설계한다.'
            },
            {
                no: 4,
                title: '나에게는 학생들이 독립적으로 사고하고 수행할 수 있는 능력 개발이 가장 중요하다.'
            },
            {
                no: 5,
                title: '학생들은 개인 또는 그룹과제를 완료하기 위한 일정을 스스로 계획한다.'
            },
            {
                no: 6,
                title: '나는 학생들이 필요로 하는 도움을 중 수 있는 사람이다.'
            }
        ]
    },
    {
        title: '역할모델',
        list: [
            {
                no: 1,
                title: '나의 행동이나 말은 학생들이 학습 내용을 이해하는데 있어 좋은 모델이 된다.'
            },
            {
                no: 2,
                title: '나는 학생들에게 내가 제시한 예시를 따라 해 볼 수 있는 기회를 제공한다.'
            },
            {
                no: 3,
                title: '나는 학생들이 학습주제를 마스터하기 위해서 무엇을 어떻게 해야 할지를 제시해준다.'
            },
            {
                no: 4,
                title: '학습자료의 요점을 설명하기 위해 자주 내 개인적인 경험에 따른 사례들을 사용한다.'
            },
            {
                no: 5,
                title: '나는 자주 다양한 원리와 개념들을 어떻게 활용할 수 있는지 보여준다.'
            },
            {
                no: 6,
                title: '나는 학생들의 수행결과에 대해서 자주 나의 코멘트를 제공한다.'
            },
            {
                no: 7,
                title: '결과적으로 많은 학생들은 학습내용에 대해 나와 같은 방식으로 사고한다.'
            },
            {
                no: 8,
                title: '학생들은 나를 "코치"(문제 해결을 위해 옆에서 조언해주는 사람)라고 생각할 것이다.'
            }
        ]
    },
    {
        title: '촉진자',
        list: [
            {
                no: 1,
                title: '나의 교수목적과 교수방법은 학습자의 다양한 학습스타일을 고려한 것이다.'
            },
            {
                no: 2,
                title: '나는 학생들이 개별 또는 그룹 프로젝트를 수행하는데 있어 도움을 주기 위한 컨설팅 시간을 갖는다.'
            },
            {
                no: 3,
                title: '학생들의 비판적인 사고 능력을 개발해주기 위해 소규모 토론을 적용하고 있다.'
            },
            {
                no: 4,
                title: '나는 학생들의 프로젝트 수행을 지도할 때 주로 학생들에게 질문을 하거나, 다양한 방법 및 대안들을 제시한다.'
            },
            {
                no: 5,
                title: '이 수업에서는 다양한 활동을 통해 학생들이 학습 주도권과 학습에 대한 책임감을 갖는다.'
            },
            {
                no: 6,
                title: '나는 학생들에게 이 수업에서 무엇을 어떻게 가르쳤으면 좋은가에 대한 조언을 자주 구한다.'
            },
            {
                no: 7,
                title: '학생들은 수업에서 요구되는 것들을 성취하기 위한 활동들을 직접 선택할 수 있다.'
            }
        ]
    }
];
*/

export const survey2 = [
    {
        title: '티칭스타일 검사',
        survey_type: 'grasha-Riechmanntichingseutailgeomsa',
        list: [
            {
                no: 1,
                title: '사실, 개념, 원리는 학생들이 알아야 할 가장 중요한 것이다.',
                type: 'first'
            },
            {
                no: 2,
                title: '나는 이 수업을 수강하는 학생들이게 높은 수준의 기준을 적용한다.',
                type: 'second'
            },
            {
                no: 3,
                title: '나의 행동이나 말은 학생들이 학습 내용을 이해하는데 있어 좋은 모델이 된다.',
                type: 'fourth'
            },
            {
                no: 4,
                title: '나의 교수목적과 교수방법은 학습자의 다양한 학습스타일을 고려한 것이다.',
                type: 'fifth'
            },
            {
                no: 5,
                title: '학생들은 대체로 나의 관리나 통제 없이 스스로 프로젝트를 수행한다.',
                type: 'third'
            },
            {
                no: 6,
                title: '지식과 전문성을 학생들과 나누는 것은 나에게 매우 중요하다.',
                type: 'none'
            },
            {
                no: 7,
                title: '나는 학생들의 수행결과가 만족스럽지 않을 때는 부정적 피드백을 제공한다.',
                type: 'second'
            },
            {
                no: 8,
                title: '나는 학생들에게 내가 제시한 예시를 따라 해 볼 수 있는 기회를 제공한다.',
                type: 'fourth'
            },
            {
                no: 9,
                title: '나는 학생들이 개별 또는 그룹 프로젝트를 수행하는데 있어 도움을 주기 위한 컨설팅 시간을 갖는다.',
                type: 'fifth'
            },
            {
                no: 10,
                title: '이 수업에서의 활동은 학생 스스로 학습 주제를 이해할 수 있도록 도와준다.',
                type: 'third'
            },
            {
                no: 11,
                title: '수업 주제에 대해 설명할 때 학생들이 관련된 분야에 대해 보다 넓은 관점을 갖도록 하는 것이 중요하다.',
                type: 'none'
            },
            {
                no: 12,
                title: '학생들은 나의 기준과 기대치가 다소 엄격하다고 말할 것이다.',
                type: 'second'
            },
            {
                no: 13,
                title: '나는 학생들이 학습주제를 마스터하기 위해서 무엇을 어떻게 해야 할지를 제시해준다.',
                type: 'fourth'
            },
            {
                no: 14,
                title: '학생들의 비판적인 사고 능력을 개발해주기 위해 소규모 토론을 적용하고 있다.',
                type: 'fifth'
            },
            {
                no: 15,
                title: '이 수업에서 학생들은 하나 이상의 자기주도적인 학습경험을 스스로 설계한다.',
                type: 'third'
            },
            {
                no: 16,
                title: '나는 학생들이 전공 분야로 진출할 때 충분히 준비시켜서 내보내고 싶다.',
                type: 'none'
            },
            {
                no: 17,
                title: '학생들이 무엇을 배워야 하는지, 어떻게 학습해야 하는지를 알려주는 것은 나의 책임이다.',
                type: 'second'
            },
            {
                no: 18,
                title: '학습자료의 요점을 설명하기 위해 자주 내 개인적인 경험에 따른 사례들을 사용한다.',
                type: 'fourth'
            },
            {
                no: 19,
                title: '나는 학생들의 프로젝트 수행을 지도할 때 주로 학생들에게 질문을 하거나, 다양한 방법 및 대안들을 제시한다.',
                type: 'fifth'
            },
            {
                no: 20,
                title: '나에게는 학생들이 독립적으로 사고하고 수행할 수 있는 능력 개발이 가장 중요하다.',
                type: 'third'
            },
            {
                no: 21,
                title: '나는 이 수업에서 강의식 교수법을 가장 빈번하게 사용한다.',
                type: 'first'
            },
            {
                no: 22,
                title: '나는 어떻게 수업 과제를 완료하길 원하는지 매우 명확하게 가이드라인을 제시한다.',
                type: 'none'
            },
            {
                no: 23,
                title: '나는 자주 다양한 원리와 개념들을 어떻게 활용할 수 있는지 보여준다.',
                type: 'fourth'
            },
            {
                no: 24,
                title: '이 수업에서는 다양한 활동을 통해 학생들이 학습 주도권과 학습에 대한 책임감을 갖는다.',
                type: 'fifth'
            },
            {
                no: 25,
                title: '이 수업에서는 학생들이 강의를 맡고 있다.',
                type: 'none'
            },
            {
                no: 26,
                title: '학습 주제와 관련된 논쟁은 나의 전문적 지식으로 거의 해결된다.',
                type: 'first'
            },
            {
                no: 27,
                title: '이 수업에서는 성취되어야 할 구체적인 목적과 목표들이 있다.',
                type: 'second'
            },
            {
                no: 28,
                title: '나는 학생들의 수행결과에 대해서 자주 나의 코멘트를 제공한다.',
                type: 'fourth'
            },
            {
                no: 29,
                title: '나는 학생들에게 이 수업에서 무엇을 어떻게 가르쳤으면 좋은가에 대한 조언을 자주 구한다.',
                type: 'fifth'
            },
            {
                no: 30,
                title: '학생들은 개인 또는 그룹과제를 완료하기 위한 일정을 스스로 계획한다.',
                type: 'third'
            },
            {
                no: 31,
                title: '학생들은 나를 사실, 원리, 개념을 알려주는 "지식저장소"로 생각할 것이다.',
                type: 'first'
            },
            {
                no: 32,
                title: '수업에서 학생들이 무엇을 할 것인지는 강의계획서에 명확하게 정의되어 있다.',
                type: 'second'
            },
            {
                no: 33,
                title: '결과적으로 많은 학생들은 학습내용에 대해 나와 같은 방식으로 사고한다.',
                type: 'fourth'
            },
            {
                no: 34,
                title: '학생들은 수업에서 요구되는 것들을 성취하기 위한 활동들을 직접 선택할 수 있다.',
                type: 'fifth'
            },
            {
                no: 35,
                title: '교수(teaching)에 대한 내 생각은 타 교수님들에게 모델이 될 수 있다고 생각한다.',
                type: 'none'
            },
            {
                no: 36,
                title: '나는 이 수업 시간 내에 소화할 수 있는 것보다 더 많은 수업자료를 가지고 있다.',
                type: 'first'
            },
            {
                no: 37,
                title: '나의 기준과 기대치는 학생들이 자신의 학습능력을 개발시키도록 해준다.',
                type: 'second'
            },
            {
                no: 38,
                title: '학생들은 나를 "코치"(문제 해결을 위해 옆에서 조언해주는 사람)라고 생각할 것이다.',
                type: 'fourth'
            },
            {
                no: 39,
                title: '나는 학생들이게 수업에서 잘할 수 있도록 개별적인 지원과 격려를 많이 한다.',
                type: 'none'
            },
            {
                no: 40,
                title: '나는 학생들이 필요로 하는 도움을 줄 수 있는 사람이다.',
                type: 'third'
            }
        ]
    }
];

export const survey1 = [
    {
        title: '온라인수업 역량 진단도구',
        survey_type: 'onrainsueopyeokryangjindandogu',
        list: [
            {
                no: 1,
                title: '수업운영에서 야기되는 업무 과부하를 조정하고 부담을 관리한다.',
                type: 'second'
            },
            {
                no: 2,
                title: '스스로 부족한 부분에 대해 성찰하여 변화하고자 한다.',
                type: 'first'
            },
            {
                no: 3,
                title: '학생 중심의 수업을 이끌고 학습의 조력자로 자신의 역할을 전환하고자 한다.',
                type: 'second'
            },
            {
                no: 4,
                title: '현재 상황과 바람직한 상황 간의 차이(gap)를 해소하기 위해 타당한 해결책을 도출한다.',
                type: 'first'
            },
            {
                no: 5,
                title: '수업을 방해하거나 혹은 전혀 관심을 보이지 않는 학생들의 행동을 모니터링하고, 이들의 학습 의욕을 촉진하고 관리한다.',
                type: 'fifth'
            },
            {
                no: 6,
                title: '혁신을 수용하고 채택하며 선도적으로 적용하고자 하는 태도를 가진다.',
                type: 'first'
            },
            {
                no: 7,
                title: '교수-학습과 관련된 전반적인 절차를 기획, 운영, 평가한다.',
                type: 'first'
            },
            {
                no: 8,
                title: '학습자의 선수 지식 및 특성을 분석하고 학습 환경을 파악하여 효과적인 수업을 계획한다.',
                type: 'third'
            },
            {
                no: 9,
                title: '가르치는 주제와 내용에 대해 자신을 가진다.',
                type: 'first'
            },
            {
                no: 10,
                title: '자신의 교육철학에 대하여 고민하고 실천한다.',
                type: 'first'
            },
            {
                no: 11,
                title: '학습자의 다양한 특성을 이해한다.',
                type: 'second'
            },
            {
                no: 12,
                title: '온라인 교육 상황에서 효과적/효율적인 교수-학습을 촉진하기 위해 필요한 제반 이론을 이해한다.',
                type: 'second'
            },
            {
                no: 13,
                title: '온라인 교육의 교육적 가치에 대한 긍정적인 인식과 태도를 갖는다.',
                type: 'second'
            },
            {
                no: 14,
                title: '효과적으로 커뮤니케이션 할 수 있는 기술과 태도를 갖추고 있다.',
                type: 'first'
            },
            {
                no: 15,
                title: '온라인 상에서 준수해야 할 예의, 도덕, 행동규범을 실천한다.',
                type: 'second'
            },
            {
                no: 16,
                title: '최종 강의콘텐츠 개발 결과물을 예측한다.',
                type: 'fourth'
            },
            {
                no: 17,
                title: 'LMS의 기능 및 다양한 활용 사례를 이해하고 이를 실제 수업에 적용한다.',
                type: 'second'
            },
            {
                no: 18,
                title: '다양한 테크놀로지에 대해 지속적인 관심을 가지고 그 기능과 효과에 대해 평가한다.',
                type: 'second'
            },
            {
                no: 19,
                title: '스스로가 평생 학습자라는 생각으로 신기술을 연마하고 새로운 지식을 습듭하여 더 나은 교수능력을 갖춘다.',
                type: 'first'
            },
            {
                no: 20,
                title: '온라인 교육 특성을 반영하여 효과적인 수업을 설계한다.',
                type: 'third'
            },
            {
                no: 21,
                title: '교수설계에 대한 지식을 바탕으로 콘텐츠를 기획한다.',
                type: 'third'
            },
            {
                no: 22,
                title: '내용전문가로서 주제와 내용에 대해 충분하고 정확한 지식을 갖추고있다.',
                type: 'first'
            },
            {
                no: 23,
                title: '내용을 효과적으로 계열화하고 조직한다.',
                type: 'third'
            },
            {
                no: 24,
                title: '수업 목표에 따라 차시별로 강의 분량을 적절히 분배한다.',
                type: 'third'
            },
            {
                no: 25,
                title: '상호작용적 의사소통이 가능한 온라인 매체에 적합한 방식으로 프리젠테이션한다.',
                type: 'fourth'
            },
            {
                no: 26,
                title: '학습자가 사이버강의에 몰입하고 집중력을 유지할 수 있는 전략을 모색하고 적용한다.',
                type: 'third'
            },
            {
                no: 27,
                title: '교수 자신의(혹은 외부 전문가 도입을 통해) 풍부한 실무/현장 경험을 수업 내용에 적용한다.',
                type: 'third'
            },
            {
                no: 28,
                title: '실제적(authentic) 사례와 자료를 기반으로 수업을 구성한다.',
                type: 'third'
            },
            {
                no: 29,
                title: '학습자의 적극적 참여를 촉진한다.',
                type: 'fifth'
            },
            {
                no: 30,
                title: '온라인 교육 맥락을 반영하여 효과적인 성취도 평가를 설계한다.',
                type: 'third'
            },
            {
                no: 31,
                title: '출석, 과제 등 성적 관련 기준을 정의하고 공유한다.',
                type: 'third'
            },
            {
                no: 32,
                title: '교수에 필요한 자료 및 자원을 각종 매체를 활용하여 검색한다.',
                type: 'fourth'
            },
            {
                no: 33,
                title: '각종 매체에서 찾은 적절한 자료를 온라인 교수 환경에 맞게 변환한다.',
                type: 'fourth'
            },
            {
                no: 34,
                title: '지적재산권의 공정한 사용을 이해하고 저작권 규제를 준수한다.',
                type: 'fourth'
            },
            {
                no: 35,
                title: '학습자의 과제 수행과 평가결과에 대한 피드백을 제공한다.',
                type: 'fifth'
            },
            {
                no: 36,
                title: '학습내용의 제시 및 학습자와의 상호 작용 과정에 있어서 상황 맥락에 부합하는 어휘를 사용하여 학습자의 이해를 돕는다.',
                type: 'fourth'
            },
            {
                no: 37,
                title: '학습자의 고차적 사고를 유도하는 수업 기법을 활용한다.',
                type: 'third'
            },
            {
                no: 38,
                title: '실제 강의실에서 학생들과 수업을 하는 것과 같이 자연스러운 음성, 톤, 제스츄어로 강의한다.',
                type: 'fourth'
            },
            {
                no: 39,
                title: '수업을설계·개발하는 데 있어 설계·개발 팀과 아이디어를 원활하게 공유한다.',
                type: 'fourth'
            },
            {
                no: 40,
                title: '텍스트 기반의 속성이 강한 온라인 교수환경에서 효과적인 학습을 촉진하기 위해 적절한 글쓰기 기술을 갖춘다.',
                type: 'second'
            },
            {
                no: 41,
                title: '수업 준비, 강의 및 운영에 있어서 계획에 된 시간에 맞추어 수업을 진행한다.',
                type: 'fifth'
            },
            {
                no: 42,
                title: '강의와 관련된 주요 공지 사항을 자주, 구체적으로 전달한다.',
                type: 'fifth'
            },
            {
                no: 43,
                title: '학생과읠 의사소통에 성의와 열의를 갖고 임한다.',
                type: 'fifth'
            },
            {
                no: 44,
                title: '학생의 질의에 친절하게 응대한다.',
                type: 'fifth'
            },
            {
                no: 45,
                title: '학습자가 온라인 학습 상화에 적응할 수 있도록 기술적, 인지적, 심리적인 도움을 준다.',
                type: 'fifth'
            },
            {
                no: 46,
                title: '일방적인 교수 진행이 아닌 학생과의 상호작용을 통해 수업을 이끌어간다.',
                type: 'fifth'
            },
            {
                no: 47,
                title: '자신의 교직 경험이나 동료의 교수활동 모니터링을 통해 얻은 지식을 수업 설계에 적용한다.',
                type: 'third'
            },
            {
                no: 48,
                title: '학습자들이 교수-학습 방향 및 자신의 학습 스타일에 부합하는 학습 전략을 사용하도록 돕는다.',
                type: 'fifth'
            },
            {
                no: 49,
                title: '온라인 공간의 사회/심리적 환경, 물리적 환경, 학습 분위기를 조성하여 학습자들의 공동체 의식을 함양한다.',
                type: 'fifth'
            },
            {
                no: 50,
                title: '학습자들이 학습공동체를 구성하거나 팀 프로젝트를 수행하는 등 학습자 간의 다양한 형태의 협력을 촉진한다.',
                type: 'fifth'
            },
            {
                no: 51,
                title: '학습자가 과제를 수행하는 데 있어 컨설팅을 제공한다.',
                type: 'fifth'
            },
            {
                no: 52,
                title: '예측하지 못했던 돌발적 상황이 일어났을 경우, 당황하지 않고 효과적으로 대처한다.',
                type: 'first'
            },
            {
                no: 53,
                title: '시의 적절한 질문을 통해 학습자의 이해도를 확인한다.',
                type: 'fifth'
            },
            {
                no: 54,
                title: '가르치는 내용을 학생의 수준에 맞도록 이해하기 쉽게 설명한다.',
                type: 'fourth'
            },
            {
                no: 55,
                title: '학습자가 배운 것을 자신의 상황과 업무에 적용하도록 유도한다.',
                type: 'fifth'
            }
        ]
    }
];
/*
export const survey3 = [
    {
        title: '기본 소양',
        list: [
            {
                no: 1,
                title: '스스로가 평생 학습자라는 생각으로 신기술을 연마하고 새로운 지식을 습듭하여 더 나은 교수능력을 갖춘다.'
            },
            {
                no: 2,
                title: '스스로 부족한 부분에 대해 성찰하여 변화하고자 한다.'
            },
            {
                no: 3,
                title: '효과적으로 커뮤니케이션 할 수 있는 기술과 태도를 갖추고 있다.'
            },
            {
                no: 4,
                title: '현재 상황과 바람직한 상황 간의 차이(gap)를 해소하기 위해 타당한 해결책을 도출한다.'
            },
            {
                no: 5,
                title: '예측하지 못했던 돌발적 상황이 일어났을 경우, 당황하지 않고 효과적으로 대처한다.'
            },
            {
                no: 6,
                title: '혁신을 수용하고 채택하며 선도적으로 적용하고자 하는 태도를 가진다.'
            },
            {
                no: 7,
                title: '교수-학습과 관련된 전반적인 절차를 기획, 운영, 평가한다.'
            },
            {
                no: 8,
                title: '내용전문가로서 주제와 내용에 대해 충분하고 정확한 지식을 갖추고있다.'
            },
            {
                no: 9,
                title: '가르치는 주제와 내용에 대해 자신을 가진다.'
            },
            {
                no: 10,
                title: '자신의 교육철학에 대하여 고민하고 실천한다.'
            }
        ]
    },
    {
        title: '온라인 교육에 대한 이해',
        list: [
            {
                no: 1,
                title: '학습자의 다양한 특성을 이해한다.'
            },
            {
                no: 2,
                title: '온라인 교육 상황에서 효과적/효율적인 교수-학습을 촉진하기 위해 필요한 제반 이론을 이해한다.'
            },
            {
                no: 3,
                title: '온라인 교육의 교육적 가치에 대한 긍정적인 인식과 태도를 갖는다.'
            },
            {
                no: 4,
                title: '학생 중심의 수업을 이끌고 학습의 조력자로 자신의 역할을 전환하고자 한다.'
            },
            {
                no: 5,
                title: '온라인 상에서 준수해야 할 예의, 도덕, 행동규범을 실천한다.'
            },
            {
                no: 6,
                title: '텍스트 기반의 속성이 강한 온라인 교수환경에서 효과적인 학습을 촉진하기 위해 적절한 글쓰기 기술을 갖춘다.'
            },
            {
                no: 7,
                title: 'LMS의 기능 및 다양한 활용 사례를 이해하고 이를 실제 수업에 적용한다.'
            },
            {
                no: 8,
                title: '다양한 테크놀로지에 대해 지속적인 관심을 가지고 그 기능과 효과에 대해 평가한다.'
            },
            {
                no: 9,
                title: '수업운영에서 야기되는 업무 과부하를 조정하고 부담을 관리한다.'
            }
        ]
    },
    {
        title: '수업 설계',
        list: [
            {
                no: 1,
                title: '온라인 교육 특성을 반영하여 효과적인 수업을 설계한다.'
            },
            {
                no: 2,
                title: '교수설계에 대한 지식을 바탕으로 콘텐츠를 기획한다.'
            },
            {
                no: 3,
                title: '학습자의 선수 지식 및 특성을 분석하고 학습 환경을 파악하여 효과적인 수업을 계획한다.'
            },
            {
                no: 4,
                title: '내용을 효과적으로 계열화하고 조직한다.'
            },
            {
                no: 5,
                title: '수업 목표에 따라 차시별로 강의 분량을 적절히 분배한다.'
            },
            {
                no: 6,
                title: '학습자의 고차적 사고를 유도하는 수업 기법을 활용한다.'
            },
            {
                no: 7,
                title: '학습자가 사이버강의에 몰입하고 집중력을 유지할 수 있는 전략을 모색하고 적용한다.'
            },
            {
                no: 8,
                title: '교수 자신의(혹은 외부 전문가 도입을 통해) 풍부한 실무/현장 경험을 수업 내용에 적용한다.'
            },
            {
                no: 9,
                title: '실제적(authentic) 사례와 자료를 기반으로 수업을 구성한다.'
            },
            {
                no: 10,
                title: '자신의 교직 경험이나 동료의 교수활동 모니터링을 통해 얻은 지식을 수업 설계에 적용한다.'
            },
            {
                no: 11,
                title: '온라인 교육 맥락을 반영하여 효과적인 성취도 평가를 설계한다.'
            },
            {
                no: 12,
                title: '출석, 과제 등 성적 관련 기준을 정의하고 공유한다.'
            }
        ]
    },
    {
        title: '수업 개발',
        list: [
            {
                no: 1,
                title: '교수에 필요한 자료 및 자원을 각종 매체를 활용하여 검색한다.'
            },
            {
                no: 2,
                title: '각종 매체에서 찾은 적절한 자료를 온라인 교수 환경에 맞게 변환한다.'
            },
            {
                no: 3,
                title: '지적재산권의 공정한 사용을 이해하고 저작권 규제를 준수한다.'
            },
            {
                no: 4,
                title: '가르치는 내용을 학생의 수준에 맞도록 이해하기 쉽게 설명한다.'
            },
            {
                no: 5,
                title: '학습내용의 제시 및 학습자와의 상호 작용 과정에 있어서 상황 맥락에 부합하는 어휘를 사용하여 학습자의 이해를 돕는다.'
            },
            {
                no: 6,
                title: '상호작용적 의사소통이 가능한 온라인 매체에 적합한 방식으로 프리젠테이션한다.'
            },
            {
                no: 7,
                title: '실제 강의실에서 학생들과 수업을 하는 것과 같이 자연스러운 음성, 톤, 제스츄어로 강의한다.'
            },
            {
                no: 8,
                title: '수업을설계·개발하는 데 있어 설계·개발 팀과 아이디어를 원활하게 공유한다.'
            },
            {
                no: 9,
                title: '최종 강의콘텐츠 개발 결과물을 예측한다.'
            }
        ]
    },
    {
        title: '수업 운영',
        list: [
            {
                no: 1,
                title: '수업 준비, 강의 및 운영에 있어서 계획에 된 시간에 맞추어 수업을 진행한다.'
            },
            {
                no: 2,
                title: '강의와 관련된 주요 공지 사항을 자주, 구체적으로 전달한다.'
            },
            {
                no: 3,
                title: '학생과읠 의사소통에 성의와 열의를 갖고 임한다.'
            },
            {
                no: 4,
                title: '학생의 질의에 친절하게 응대한다.'
            },
            {
                no: 5,
                title: '학습자가 온라인 학습 상화에 적응할 수 있도록 기술적, 인지적, 심리적인 도움을 준다.'
            },
            {
                no: 6,
                title: '일방적인 교수 진행이 아닌 학생과의 상호작용을 통해 수업을 이끌어간다.'
            },
            {
                no: 7,
                title: '학습자의 적극적 참여를 촉진한다.'
            },
            {
                no: 8,
                title: '학습자들이 교수-학습 방향 및 자신의 학습 스타일에 부합하는 학습 전략을 사용하도록 돕는다.'
            },
            {
                no: 9,
                title: '온라인 공간의 사회/심리적 환경, 물리적 환경, 학습 분위기를 조성하여 학습자들의 공동체 의식을 함양한다.'
            },
            {
                no: 10,
                title: '학습자들이 학습공동체를 구성하거나 팀 프로젝트를 수행하는 등 학습자 간의 다양한 형태의 협력을 촉진한다.'
            },
            {
                no: 11,
                title: '학습자가 과제를 수행하는 데 있어 컨설팅을 제공한다.'
            },
            {
                no: 12,
                title: '수업을 방해하거나 혹은 전혀 관심을 보이지 않는 학생들의 행동을 모니터링하고, 이들의 학습 의욕을 촉진하고 관리한다.'
            },
            {
                no: 13,
                title: '시의 적절한 질문을 통해 학습자의 이해도를 확인한다.'
            },
            {
                no: 14,
                title: '학습자의 과제 수행과 평가결과에 대한 피드백을 제공한다.'
            },
            {
                no: 15,
                title: '학습자가 배운 것을 자신의 상황과 업무에 적용하도록 유도한다.'
            }
        ]
    }
];
*/

/*
export const survey4 = [
    {
        title: '교수역량진단',
        list: [
            {
                no: 1,
                title: '우리대학의 발전계획 및 추진전략을 이해한다.',
                type: 'first'
            },
            {
                no: 2,
                title: '우리 대학의 교육 목표를 달성하기 위해 노력한다.',
                type: 'first'
            },
            {
                no: 3,
                title: '우리 대학에서 추구하는 학생 인재상과 핵심역량을 이해하고 있다.',
                type: 'first'
            },
            {
                no: 4,
                title: '우리대학의 학생 핵심역량 증진을 위해 목표를 설정하고 이를 구현하기 위해 수업을 구성하고 운영한다.',
                type: 'first'
            },
            {
                no: 5,
                title: '미래사회가 요구하는 인재상 및 핵심역량에 관심을 갖고, 교육에 적용시키고자 노력한다.',
                type: 'first'
            },
            {
                no: 6,
                title: '교육에 필요한 정보를 수집, 평가하고 새로운 정보를 창출한다.',
                type: 'first'
            },
            {
                no: 7,
                title: '전공분야 관련한 새로운 지식과 기술을 탐구하여 교육내용에 반영한다.',
                type: 'first'
            },
            {
                no: 8,
                title: '학생지도 및 교육을 위해 동료교수나 교직원과 적극적으로 학생에 대한 정보를 공유한다.',
                type: 'first'
            },
            {
                no: 9,
                title: '동료 교수나 교직원과의 협력을 통해 습득한 학생 정보를 학생 지도에 종합적으로 반영한다.',
                type: 'first'
            },
            {
                no: 10,
                title: '교수법 향상을 위해 교수지원프로그램에 적극적으로 참여한다.',
                type: 'first'
            },
            {
                no: 11,
                title: '교육자로서 지켜야 할 윤리의식(공정성, 신의, 인권 존중 등)을 가지고 있다.',
                type: 'first'
            },
            {
                no: 12,
                title: '학생의 성별, 국적, 출신, 장애유무, 개성 등의 다양성을 존중하고, 학생들을 차별하지 않으면서 인격적으로 대우한다.',
                type: 'first'
            },
            {
                no: 13,
                title: '교육자로서의 태도와 자질을 향상시키기 위해 노력한다.',
                type: 'first'
            },
            {
                no: 14,
                title: '나눔, 봉사, 헌신, 공공복리 증진 등 교육자로서 사회적 책무를 실천한다.',
                type: 'first'
            },
            {
                no: 15,
                title: '교육자로서 학생들에게 성정체성, 성주체성, 성지향성에 대한 혐오성 발언과 행동을 하지 않는다.',
                type: 'first'
            },
            {
                no: 16,
                title: '수업의 명확한 목표를 수립하고 이를 달성하기 위해 수업을 설계한다.',
                type: 'second'
            },
            {
                no: 17,
                title: '학생들의 수준과 특성에 맞는 적합한 수업내용과 방법을 계획한다.',
                type: 'second'
            },
            {
                no: 18,
                title: '수업계획서에 수업의 목표를 명확히 제시하고 수업방법을 구체적으로 제시한다.',
                type: 'second'
            },
            {
                no: 19,
                title: '수업계획서에 학생참여 및 평가 방식 등을 구체적으로 제시한다.',
                type: 'second'
            },
            {
                no: 20,
                title: '수업 내용과 관련된 최근 동향을 수업 계획에 반영한다.',
                type: 'second'
            },
            {
                no: 21,
                title: '학습내용의 이해를 돕기 위한 다양한 자료를 준비한다.',
                type: 'second'
            },
            {
                no: 22,
                title: '공정한 평가를 위해 신뢰할만하고 타당한 평가방법을 계획한다.',
                type: 'second'
            },
            {
                no: 23,
                title: '학생들의 창의력과 사고력을 향상시킬 수 있는 다양한 수업방식(토론, 발표 등)을 제공한다.',
                type: 'second'
            },
            {
                no: 24,
                title: '학생들의 수업참여 기회 확대를 위해 다양한 상호작용 방식을 적용한다.',
                type: 'second'
            },
            {
                no: 25,
                title: '다양한 방식으로 학생들의 수업참여 동기를 유발시킨다.',
                type: 'second'
            },
            {
                no: 26,
                title: '학습자 중심의 교수법을 개발하고, 이를 수업에 적용한다.',
                type: 'second'
            },
            {
                no: 27,
                title: '학습내용의 현장 적용 사례를 공유하기 위해 노력한다.',
                type: 'second'
            },
            {
                no: 28,
                title: '학생들의 협력활동을 장려하는 평가방법을 활용한다.',
                type: 'second'
            },
            {
                no: 29,
                title: '평가결과에 대한 학생들의 의견을 신중하게 검토한 후 이에 대한 의견을 적시에 제공한다.',
                type: 'second'
            },
            {
                no: 30,
                title: '평가결과를 통해 수업방식의 적절성을 점검한다.',
                type: 'second'
            },
            {
                no: 31,
                title: '학생들의 강의평가 및 피드백을 모니터링한다.',
                type: 'second'
            },
            {
                no: 32,
                title: '수업에 대한 학생들의 피드백을 다음 수업계획에 반영한다.',
                type: 'second'
            },
            {
                no: 33,
                title: '교육목표를 효과적으로 달성할 수 있는 교수 전략을 탐구한다.',
                type: 'second'
            },
            {
                no: 34,
                title: '학생 입장에서 학생들의 생각, 상황, 행동을 이해하려고 노력한다.',
                type: 'third'
            },
            {
                no: 35,
                title: '학생을 존중하는 말과 행동으로 학생들과 소통한다.',
                type: 'third'
            },
            {
                no: 36,
                title: '학생에 대한 선입견 없이 개방적인 자세로 학생들을 대한다.',
                type: 'third'
            },
            {
                no: 37,
                title: '대학생이 처한 환경과 심리적 특성에 대해 이해하려고 노력한다.',
                type: 'third'
            },
            {
                no: 38,
                title: '학생의 고민에 적극적으로 관심을 기울인다.',
                type: 'third'
            },
            {
                no: 39,
                title: '학생 면담 시간을 별도로 마련하여 정기적으로 상담한다.',
                type: 'third'
            },
            {
                no: 40,
                title: '효과적인 학생 상담을 위해 상담기법 등 상담역량을 증진하기 위해 노력한다.',
                type: 'third'
            },
            {
                no: 41,
                title: '학생이 필요할 때 언제든지 상담을 요청할 수 있는 분위기를 조성한다.',
                type: 'third'
            },
            {
                no: 42,
                title: '상담을 통해 학생에게 어떠한 도움이 필요한지 파악한다.',
                type: 'third'
            },
            {
                no: 43,
                title: '상담 내용의 비밀유지 등 상담윤리를 준수한다.',
                type: 'third'
            },
            {
                no: 44,
                title: '학생에게 필요한 정보와 자원이 무엇인지 알기 위해 노력한다.',
                type: 'third'
            },
            {
                no: 45,
                title: '학생에게 장학금, 현장실습 등의 정보를 적극적으로 안내한다.',
                type: 'third'
            },
            {
                no: 46,
                title: '학생에게 학습동기를 부여하고, 필요한 학습전략을 안내한다.',
                type: 'third'
            },
            {
                no: 47,
                title: '학생에게 전공 교육과정과 졸업 후 진로에 대해 적극적으로 안내한다.',
                type: 'third'
            },
            {
                no: 48,
                title: '대학의 학사 일정에 따라 학생을 지도한다.',
                type: 'third'
            },
            {
                no: 49,
                title: '학생과 함께 다양한 비교과 활동에 참여하려고 노력한다.',
                type: 'third'
            }
        ]
    },
];
*/
export const survey4 = [
    {
        title: '기본역량',
        survey_type: 'yeokryangjindanpyeongga',
        list: [
            {
                no: 1,
                title: '우리대학의 발전계획 및 추진전략을 이해한다.',
                type: 'first',
                subtype: 'a'

            },
            {
                no: 2,
                title: '우리 대학의 교육 목표를 달성하기 위해 노력한다.',
                type: 'first',
                subtype: 'a'
            },
            {
                no: 3,
                title: '우리 대학에서 추구하는 학생 인재상과 핵심역량을 이해하고 있다.',
                type: 'first',
                subtype: 'a'
            },
            {
                no: 4,
                title: '우리대학의 학생 핵심역량 증진을 위해 목표를 설정하고 이를 구현하기 위해 수업을 구성하고 운영한다.',
                type: 'first',
                subtype: 'a'
            },
            {
                no: 5,
                title: '미래사회가 요구하는 인재상 및 핵심역량에 관심을 갖고, 교육에 적용시키고자 노력한다.',
                type: 'first',
                subtype: 'a'
            },
            {
                no: 6,
                title: '교육에 필요한 정보를 수집, 평가하고 새로운 정보를 창출한다.',
                type: 'first',
                subtype: 'b'
            },
            {
                no: 7,
                title: '전공분야 관련한 새로운 지식과 기술을 탐구하여 교육내용에 반영한다.',
                type: 'first',
                subtype: 'b'
            },
            {
                no: 8,
                title: '학생지도 및 교육을 위해 동료교수나 교직원과 적극적으로 학생에 대한 정보를 공유한다.',
                type: 'first',
                subtype: 'b'
            },
            {
                no: 9,
                title: '동료 교수나 교직원과의 협력을 통해 습득한 학생 정보를 학생 지도에 종합적으로 반영한다.',
                type: 'first',
                subtype: 'b'
            },
            {
                no: 10,
                title: '교수법 향상을 위해 교수지원프로그램에 적극적으로 참여한다.',
                type: 'first',
                subtype: 'b'
            },
            {
                no: 11,
                title: '교육자로서 지켜야 할 윤리의식(공정성, 신의, 인권 존중 등)을 가지고 있다.',
                type: 'first',
                subtype: 'c'
            },
            {
                no: 12,
                title: '학생의 성별, 국적, 출신, 장애유무, 개성 등의 다양성을 존중하고, 학생들을 차별하지 않으면서 인격적으로 대우한다.',
                type: 'first',
                subtype: 'c'
            },
            {
                no: 13,
                title: '교육자로서의 태도와 자질을 향상시키기 위해 노력한다.',
                type: 'first',
                subtype: 'c'
            },
            {
                no: 14,
                title: '나눔, 봉사, 헌신, 공공복리 증진 등 교육자로서 사회적 책무를 실천한다.',
                type: 'first',
                subtype: 'c'
            },
            {
                no: 15,
                title: '교육자로서 학생들에게 성정체성, 성주체성, 성지향성에 대한 혐오성 발언과 행동을 하지 않는다.',
                type: 'first',
                subtype: 'c'
            }
        ]
    },
    {
        title: '수업역량',
        survey_type: 'yeokryangjindanpyeongga',
        list: [
            {
                no: 1,
                title: '수업의 명확한 목표를 수립하고 이를 달성하기 위해 수업을 설계한다.',
                type: 'second',
                subtype: 'd'
            },
            {
                no: 2,
                title: '학생들의 수준과 특성에 맞는 적합한 수업내용과 방법을 계획한다.',
                type: 'second',
                subtype: 'd'
            },
            {
                no: 3,
                title: '수업계획서에 수업의 목표를 명확히 제시하고 수업방법을 구체적으로 제시한다.',
                type: 'second',
                subtype: 'd'
            },
            {
                no: 4,
                title: '수업계획서에 학생참여 및 평가 방식 등을 구체적으로 제시한다.',
                type: 'second',
                subtype: 'd'
            },
            {
                no: 5,
                title: '수업 내용과 관련된 최근 동향을 수업 계획에 반영한다.',
                type: 'second',
                subtype: 'd'
            },
            {
                no: 6,
                title: '학습내용의 이해를 돕기 위한 다양한 자료를 준비한다.',
                type: 'second',
                subtype: 'd'
            },
            {
                no: 7,
                title: '공정한 평가를 위해 신뢰할만하고 타당한 평가방법을 계획한다.',
                type: 'second',
                subtype: 'd'
            },
            {
                no: 8,
                title: '학생들의 창의력과 사고력을 향상시킬 수 있는 다양한 수업방식(토론, 발표 등)을 제공한다.',
                type: 'second',
                subtype: 'e'
            },
            {
                no: 9,
                title: '학생들의 수업참여 기회 확대를 위해 다양한 상호작용 방식을 적용한다.',
                type: 'second',
                subtype: 'e'
            },
            {
                no: 10,
                title: '다양한 방식으로 학생들의 수업참여 동기를 유발시킨다.',
                type: 'second',
                subtype: 'e'
            },
            {
                no: 11,
                title: '학습자 중심의 교수법을 개발하고, 이를 수업에 적용한다.',
                type: 'second',
                subtype: 'e'
            },
            {
                no: 12,
                title: '학습내용의 현장 적용 사례를 공유하기 위해 노력한다.',
                type: 'second',
                subtype: 'e'
            },
            {
                no: 13,
                title: '학생들의 협력활동을 장려하는 평가방법을 활용한다.',
                type: 'second',
                subtype: 'e'
            },
            {
                no: 14,
                title: '평가결과에 대한 학생들의 의견을 신중하게 검토한 후 이에 대한 의견을 적시에 제공한다.',
                type: 'second',
                subtype: 'f'
            },
            {
                no: 15,
                title: '평가결과를 통해 수업방식의 적절성을 점검한다.',
                type: 'second',
                subtype: 'f'
            },
            {
                no: 16,
                title: '학생들의 강의평가 및 피드백을 모니터링한다.',
                type: 'second',
                subtype: 'f'
            },
            {
                no: 17,
                title: '수업에 대한 학생들의 피드백을 다음 수업계획에 반영한다.',
                type: 'second',
                subtype: 'f'
            },
            {
                no: 18,
                title: '교육목표를 효과적으로 달성할 수 있는 교수 전략을 탐구한다.',
                type: 'second',
                subtype: 'f'
            }
        ]
    },
    {
        title: '학생지원역량',
        survey_type: 'yeokryangjindanpyeongga',
        list: [
            {
                no: 1,
                title: '학생 입장에서 학생들의 생각, 상황, 행동을 이해하려고 노력한다.',
                type: 'third',
                subtype: 'g'
            },
            {
                no: 2,
                title: '학생을 존중하는 말과 행동으로 학생들과 소통한다.',
                type: 'third',
                subtype: 'g'
            },
            {
                no: 3,
                title: '학생에 대한 선입견 없이 개방적인 자세로 학생들을 대한다.',
                type: 'third',
                subtype: 'g'
            },
            {
                no: 4,
                title: '대학생이 처한 환경과 심리적 특성에 대해 이해하려고 노력한다.',
                type: 'third',
                subtype: 'g'
            },
            {
                no: 5,
                title: '학생의 고민에 적극적으로 관심을 기울인다.',
                type: 'third',
                subtype: 'g'
            },
            {
                no: 6,
                title: '학생 면담 시간을 별도로 마련하여 정기적으로 상담한다.',
                type: 'third',
                subtype: 'h'
            },
            {
                no: 7,
                title: '효과적인 학생 상담을 위해 상담기법 등 상담역량을 증진하기 위해 노력한다.',
                type: 'third',
                subtype: 'h'
            },
            {
                no: 8,
                title: '학생이 필요할 때 언제든지 상담을 요청할 수 있는 분위기를 조성한다.',
                type: 'third',
                subtype: 'h'
            },
            {
                no: 9,
                title: '상담을 통해 학생에게 어떠한 도움이 필요한지 파악한다.',
                type: 'third',
                subtype: 'h'
            },
            {
                no: 10,
                title: '상담 내용의 비밀유지 등 상담윤리를 준수한다.',
                type: 'third',
                subtype: 'h'
            },
            {
                no: 11,
                title: '학생에게 필요한 정보와 자원이 무엇인지 알기 위해 노력한다.',
                type: 'third',
                subtype: 'i'
            },
            {
                no: 12,
                title: '학생에게 장학금, 현장실습 등의 정보를 적극적으로 안내한다.',
                type: 'third',
                subtype: 'i'
            },
            {
                no: 13,
                title: '학생에게 학습동기를 부여하고, 필요한 학습전략을 안내한다.',
                type: 'third',
                subtype: 'i'
            },
            {
                no: 14,
                title: '학생에게 전공 교육과정과 졸업 후 진로에 대해 적극적으로 안내한다.',
                type: 'third',
                subtype: 'i'
            },
            {
                no: 15,
                title: '대학의 학사 일정에 따라 학생을 지도한다.',
                type: 'third',
                subtype: 'i'
            },
            {
                no: 16,
                title: '학생과 함께 다양한 비교과 활동에 참여하려고 노력한다.',
                type: 'third',
                subtype: 'i'
            }
        ]
    },
];


export const question1 = [

    {
        type: 'design',
        contents: [
            {
                type: '학습내용',
                titles: [
                    '학습내용이 강좌의 목적에 맞게 타당하게 선정·조직되었는가?',
                    '학습내용이 오타나 오류 없이 정확한 정보를 담고 있는가?',
                    '학습내용이 이해하기 쉽게 구체적으로 표현되었는가?',
                    '학습내용의 난이도가 대상 학습자의 수준에 적합한가?',
                    '학습자가 성공적인 학습 경험을 하며 학습을 완료할 수 있도록 적절한 학습 분량으로 구성하였는가?',
                    '학습내용이 종교, 지역, 이념, 성, 계층, 다문화가정 등과 관련하여 윤리적으로 문제가 없는가?'
                ],
                answer:[
                    4,
                    4,
                    4,
                    4,
                    4,
                    4
                ]
            },
            {
                type: '교수설계',
                titles: [

                    '강좌 수강을 통해 이루고자 하는 성과 및 학습목표가 구체적으로 제시되어 있는가?',
                    '학습목표를 고려하여 적절한 교수학습 전략을 설계하였는가?',
                    '학습목표를 고려하여 다양한 학습 요소를 포함하여 설계하였는가?',
                    '학습자의 학습동기를 유발하고 지속적으로 유지시켜, 학습에 집중할 수 있도록 유도하기 위한 전략을 수립하였는가?'
                ],
                answer:[
                    3,
                    4,
                    4,
                    4
                ]
            }
        ]
    },
    {
        type: 'development',
        contents: [
            {
                type: '동영상',
                titles: [
                    '학습자의 주의집중 시간을 고려하여 적절하게 분할하여 제시하고 있는가?',
                    '일관되고 적절한 수준의 동영상과 오디오 품질을 유지하는가?',
                    '학습자의 이해를 돕기 위한 텍스트 오버레이 형식이 제시되는가?',
                    '동영상 내 텍스트는 학습자의 인지부하를 고려하여 제시되는가?',
                    '동영상 내에 학습자가 학습내용을 확인할 수 있도록 학습목표 및 주제 등이 제시되는가?'
                ],
                answer: [
                    5,
                    5,
                    5,
                    5,
                    4,
                ]
            },
            {
                type: '기타자료',
                titles:
                    [
                        '모든 텍스트 자료는 오류 없이 적절한 품질을 갖추고 있는가?',
                        '모든 이미지 자료는 오류 없이 적절한 품질을 갖추고 있는가?',
                        '모든 문서 자료는 오류 없이 적절한 품질을 갖추고 있으며, 다운로드가 가능하도록 지원하고 있는가?'
                    ],
                answer:[
                    5,
                    5,
                    5,
                ]
            },
            {
                type: '웹 접근성',
                titles:
                    [
                        '해당 강좌는 ‘한국형 웹 콘텐츠 접근성 지침 2.1 기준’을 준수하고 있는가?'
                    ],
                answer:[
                    5,
                ]
            },
            {
                type: '저작권',
                titles:
                    [
                        '해당 강좌는 포함하고 있는 모든 콘텐츠와 관련된 저작권 문제를 해결하였으며, 국제저작권 표시방법을 준수하고 있는가?'
                    ],
                answer:[
                    5,
                ]
            }
        ]
    },
    {
        type: 'audit',
        contents: [
            {
                type: '자체 검수',
                titles:
                    [
                        '본 체크리스트를 기반으로 원격수업 콘텐츠에 대한 자체 검수를 수행하였는가?'
                    ],
                answer:[
                    5
                ]
            },
            {
                type: '테스팅',
                titles:
                    [
                        '콘텐츠가 오류 없이 잘 동작하는가?'
                    ],
                answer:[
                    5
                ]
            }
            ],
    }
    ]

export const question2 = [

    {
        type: 'design',
        contents: [
            {
                type: '학습내용',
                titles: [
                    '학습내용이 강좌의 목적에 맞게 타당하게 선정·조직되었는가?',
                    '학습내용이 오타나 오류 없이 정확한 정보를 담고 있는가?',
                    '학습내용이 이해하기 쉽게 구체적으로 표현되었는가?',
                    '학습내용의 난이도가 대상 학습자의 수준에 적합한가?',
                    '학습자가 성공적인 학습 경험을 하며 학습을 완료할 수 있도록 적절한 학습 분량으로 구성하였는가?',
                    '학습내용이 종교, 지역, 이념, 성, 계층, 다문화가정 등과 관련하여 윤리적으로 문제가 없는가?'
                ],
                answer:[
                    4,
                    4,
                    4,
                    4,
                    4,
                    4,
                ]
            },
            {
                type: '교수설계',
                titles: [

                    '강좌 수강을 통해 이루고자 하는 성과 및 학습목표가 구체적으로 제시되어 있는가?',
                    '학습목표를 고려하여 적절한 교수학습 전략을 설계하였는가?',
                    '학습목표를 고려하여 다양한 학습 요소를 포함하여 설계하였는가?',
                    '학습자의 학습동기를 유발하고 지속적으로 유지시켜, 학습에 집중할 수 있도록 유도하기 위한 전략을 수립하였는가?'
                ],
                answer:[
                    4,
                    4,
                    4,
                    4
                ]
            }
        ]
    },
    {
        type: 'development',
        contents: [
            {
                type: '동영상',
                titles: [
                    '학습자의 주의집중 시간을 고려하여 적절하게 분할하여 제시하고 있는가?',
                    '일관되고 적절한 수준의 동영상과 오디오 품질을 유지하는가?',
                    '학습자의 이해를 돕기 위한 텍스트 오버레이 형식이 제시되는가?',
                    '동영상 내 텍스트는 학습자의 인지부하를 고려하여 제시되는가?',
                    '동영상 내에 학습자가 학습내용을 확인할 수 있도록 학습목표 및 주제 등이 제시되는가?'
                ],
                answer: [
                    5,
                    5,
                    5,
                    5,
                    5,
                ]
            },
            {
                type: '기타자료',
                titles:
                    [
                        '모든 텍스트 자료는 오류 없이 적절한 품질을 갖추고 있는가?',
                        '모든 이미지 자료는 오류 없이 적절한 품질을 갖추고 있는가?',
                        '모든 문서 자료는 오류 없이 적절한 품질을 갖추고 있으며, 다운로드가 가능하도록 지원하고 있는가?'
                    ],
                answer:[
                    5,
                    5,
                    5,
                ]
            },
            {
                type: '웹 접근성',
                titles:
                    [
                        '해당 강좌는 ‘한국형 웹 콘텐츠 접근성 지침 2.1 기준’을 준수하고 있는가?'
                    ],
                answer:[
                    5,
                ]
            },
            {
                type: '저작권',
                titles:
                    [
                        '해당 강좌는 포함하고 있는 모든 콘텐츠와 관련된 저작권 문제를 해결하였으며, 국제저작권 표시방법을 준수하고 있는가?'
                    ],
                answer:[
                    5,
                ]
            }
        ]
    },
    {
        type: 'audit',
        contents: [
            {
                type: '자체 검수',
                titles:
                    [
                        '본 체크리스트를 기반으로 원격수업 콘텐츠에 대한 자체 검수를 수행하였는가?'
                    ],
                answer:[
                    5
                ]
            },
            {
                type: '테스팅',
                titles:
                    [
                        '콘텐츠가 오류 없이 잘 동작하는가?'
                    ],
                answer:[
                    5
                ]
            }
        ],
    }
]

export const question3 = [

    {
        type: 'design',
        contents: [
            {
                type: '학습내용',
                titles: [
                    '학습내용이 강좌의 목적에 맞게 타당하게 선정·조직되었는가?',
                    '학습내용이 오타나 오류 없이 정확한 정보를 담고 있는가?',
                    '학습내용이 이해하기 쉽게 구체적으로 표현되었는가?',
                    '학습내용의 난이도가 대상 학습자의 수준에 적합한가?',
                    '학습자가 성공적인 학습 경험을 하며 학습을 완료할 수 있도록 적절한 학습 분량으로 구성하였는가?',
                    '학습내용이 종교, 지역, 이념, 성, 계층, 다문화가정 등과 관련하여 윤리적으로 문제가 없는가?'
                ],
                answer:[
                    4,
                    4,
                    4,
                    4,
                    4,
                    4
                ]
            },
            {
                type: '교수설계',
                titles: [

                    '강좌 수강을 통해 이루고자 하는 성과 및 학습목표가 구체적으로 제시되어 있는가?',
                    '학습목표를 고려하여 적절한 교수학습 전략을 설계하였는가?',
                    '학습목표를 고려하여 다양한 학습 요소를 포함하여 설계하였는가?',
                    '학습자의 학습동기를 유발하고 지속적으로 유지시켜, 학습에 집중할 수 있도록 유도하기 위한 전략을 수립하였는가?'
                ],
                answer:[
                    4,
                    4,
                    4,
                    4
                ]
            }
        ]
    },
    {
        type: 'development',
        contents: [
            {
                type: '동영상',
                titles: [
                    '학습자의 주의집중 시간을 고려하여 적절하게 분할하여 제시하고 있는가?',
                    '일관되고 적절한 수준의 동영상과 오디오 품질을 유지하는가?',
                    '학습자의 이해를 돕기 위한 텍스트 오버레이 형식이 제시되는가?',
                    '동영상 내 텍스트는 학습자의 인지부하를 고려하여 제시되는가?',
                    '동영상 내에 학습자가 학습내용을 확인할 수 있도록 학습목표 및 주제 등이 제시되는가?'
                ],
                answer: [
                    5,
                    5,
                    5,
                    5,
                    5,
                ]
            },
            {
                type: '기타자료',
                titles:
                    [
                        '모든 텍스트 자료는 오류 없이 적절한 품질을 갖추고 있는가?',
                        '모든 이미지 자료는 오류 없이 적절한 품질을 갖추고 있는가?',
                        '모든 문서 자료는 오류 없이 적절한 품질을 갖추고 있으며, 다운로드가 가능하도록 지원하고 있는가?'
                    ],
                answer:[
                    5,
                    5,
                    5,
                ]
            },
            {
                type: '웹 접근성',
                titles:
                    [
                        '해당 강좌는 ‘한국형 웹 콘텐츠 접근성 지침 2.1 기준’을 준수하고 있는가?'
                    ],
                answer:[
                    5,
                ]
            },
            {
                type: '저작권',
                titles:
                    [
                        '해당 강좌는 포함하고 있는 모든 콘텐츠와 관련된 저작권 문제를 해결하였으며, 국제저작권 표시방법을 준수하고 있는가?'
                    ],
                answer:[
                    5,
                ]
            }
        ]
    },
    {
        type: 'audit',
        contents: [
            {
                type: '자체 검수',
                titles:
                    [
                        '본 체크리스트를 기반으로 원격수업 콘텐츠에 대한 자체 검수를 수행하였는가?'
                    ],
                answer:[
                    5
                ]
            },
            {
                type: '테스팅',
                titles:
                    [
                        '콘텐츠가 오류 없이 잘 동작하는가?'
                    ],
                answer:[
                    5
                ]
            }
        ],
    }
]

export const question4 = [

    {
        type: 'design',
        contents: [
            {
                type: '학습내용',
                titles: [
                    '학습내용이 강좌의 목적에 맞게 타당하게 선정·조직되었는가?',
                    '학습내용이 오타나 오류 없이 정확한 정보를 담고 있는가?',
                    '학습내용이 이해하기 쉽게 구체적으로 표현되었는가?',
                    '학습내용의 난이도가 대상 학습자의 수준에 적합한가?',
                    '학습자가 성공적인 학습 경험을 하며 학습을 완료할 수 있도록 적절한 학습 분량으로 구성하였는가?',
                    '학습내용이 종교, 지역, 이념, 성, 계층, 다문화가정 등과 관련하여 윤리적으로 문제가 없는가?'
                ],
                answer:[
                    4,
                    4,
                    4,
                    4,
                    4,
                    4
                ]
            },
            {
                type: '교수설계',
                titles: [

                    '강좌 수강을 통해 이루고자 하는 성과 및 학습목표가 구체적으로 제시되어 있는가?',
                    '학습목표를 고려하여 적절한 교수학습 전략을 설계하였는가?',
                    '학습목표를 고려하여 다양한 학습 요소를 포함하여 설계하였는가?',
                    '학습자의 학습동기를 유발하고 지속적으로 유지시켜, 학습에 집중할 수 있도록 유도하기 위한 전략을 수립하였는가?'
                ],
                answer:[
                    4,
                    4,
                    4,
                    4
                ]
            }
        ]
    },
    {
        type: 'development',
        contents: [
            {
                type: '동영상',
                titles: [
                    '학습자의 주의집중 시간을 고려하여 적절하게 분할하여 제시하고 있는가?',
                    '일관되고 적절한 수준의 동영상과 오디오 품질을 유지하는가?',
                    '학습자의 이해를 돕기 위한 텍스트 오버레이 형식이 제시되는가?',
                    '동영상 내 텍스트는 학습자의 인지부하를 고려하여 제시되는가?',
                    '동영상 내에 학습자가 학습내용을 확인할 수 있도록 학습목표 및 주제 등이 제시되는가?'
                ],
                answer: [
                    5,
                    5,
                    5,
                    5,
                    4,
                ]
            },
            {
                type: '기타자료',
                titles:
                    [
                        '모든 텍스트 자료는 오류 없이 적절한 품질을 갖추고 있는가?',
                        '모든 이미지 자료는 오류 없이 적절한 품질을 갖추고 있는가?',
                        '모든 문서 자료는 오류 없이 적절한 품질을 갖추고 있으며, 다운로드가 가능하도록 지원하고 있는가?'
                    ],
                answer:[
                    5,
                    5,
                    5,
                ]
            },
            {
                type: '웹 접근성',
                titles:
                    [
                        '해당 강좌는 ‘한국형 웹 콘텐츠 접근성 지침 2.1 기준’을 준수하고 있는가?'
                    ],
                answer:[
                    5,
                ]
            },
            {
                type: '저작권',
                titles:
                    [
                        '해당 강좌는 포함하고 있는 모든 콘텐츠와 관련된 저작권 문제를 해결하였으며, 국제저작권 표시방법을 준수하고 있는가?'
                    ],
                answer:[
                    5,
                ]
            }
        ]
    },
    {
        type: 'audit',
        contents: [
            {
                type: '자체 검수',
                titles:
                    [
                        '본 체크리스트를 기반으로 원격수업 콘텐츠에 대한 자체 검수를 수행하였는가?'
                    ],
                answer:[
                    5
                ]
            },
            {
                type: '테스팅',
                titles:
                    [
                        '콘텐츠가 오류 없이 잘 동작하는가?'
                    ],
                answer:[
                    5
                ]
            }
        ],
    }
]

export const question5 = [

    {
        type: 'design',
        contents: [
            {
                type: '학습내용',
                titles: [
                    '학습내용이 강좌의 목적에 맞게 타당하게 선정·조직되었는가?',
                    '학습내용이 오타나 오류 없이 정확한 정보를 담고 있는가?',
                    '학습내용이 이해하기 쉽게 구체적으로 표현되었는가?',
                    '학습내용의 난이도가 대상 학습자의 수준에 적합한가?',
                    '학습자가 성공적인 학습 경험을 하며 학습을 완료할 수 있도록 적절한 학습 분량으로 구성하였는가?',
                    '학습내용이 종교, 지역, 이념, 성, 계층, 다문화가정 등과 관련하여 윤리적으로 문제가 없는가?'
                ],
                answer:[
                    4,
                    4,
                    4,
                    4,
                    4,
                    3
                ]
            },
            {
                type: '교수설계',
                titles: [

                    '강좌 수강을 통해 이루고자 하는 성과 및 학습목표가 구체적으로 제시되어 있는가?',
                    '학습목표를 고려하여 적절한 교수학습 전략을 설계하였는가?',
                    '학습목표를 고려하여 다양한 학습 요소를 포함하여 설계하였는가?',
                    '학습자의 학습동기를 유발하고 지속적으로 유지시켜, 학습에 집중할 수 있도록 유도하기 위한 전략을 수립하였는가?'
                ],
                answer:[
                    4,
                    4,
                    4,
                    4
                ]
            }
        ]
    },
    {
        type: 'development',
        contents: [
            {
                type: '동영상',
                titles: [
                    '학습자의 주의집중 시간을 고려하여 적절하게 분할하여 제시하고 있는가?',
                    '일관되고 적절한 수준의 동영상과 오디오 품질을 유지하는가?',
                    '학습자의 이해를 돕기 위한 텍스트 오버레이 형식이 제시되는가?',
                    '동영상 내 텍스트는 학습자의 인지부하를 고려하여 제시되는가?',
                    '동영상 내에 학습자가 학습내용을 확인할 수 있도록 학습목표 및 주제 등이 제시되는가?'
                ],
                answer: [
                    5,
                    4,
                    5,
                    5,
                    5,
                ]
            },
            {
                type: '기타자료',
                titles:
                    [
                        '모든 텍스트 자료는 오류 없이 적절한 품질을 갖추고 있는가?',
                        '모든 이미지 자료는 오류 없이 적절한 품질을 갖추고 있는가?',
                        '모든 문서 자료는 오류 없이 적절한 품질을 갖추고 있으며, 다운로드가 가능하도록 지원하고 있는가?'
                    ],
                answer:[
                    5,
                    5,
                    5,
                ]
            },
            {
                type: '웹 접근성',
                titles:
                    [
                        '해당 강좌는 ‘한국형 웹 콘텐츠 접근성 지침 2.1 기준’을 준수하고 있는가?'
                    ],
                answer:[
                    5,
                ]
            },
            {
                type: '저작권',
                titles:
                    [
                        '해당 강좌는 포함하고 있는 모든 콘텐츠와 관련된 저작권 문제를 해결하였으며, 국제저작권 표시방법을 준수하고 있는가?'
                    ],
                answer:[
                    5,
                ]
            }
        ]
    },
    {
        type: 'audit',
        contents: [
            {
                type: '자체 검수',
                titles:
                    [
                        '본 체크리스트를 기반으로 원격수업 콘텐츠에 대한 자체 검수를 수행하였는가?'
                    ],
                answer:[
                    5
                ]
            },
            {
                type: '테스팅',
                titles:
                    [
                        '콘텐츠가 오류 없이 잘 동작하는가?'
                    ],
                answer:[
                    5
                ]
            }
        ],
    }
]






export const question_origin = [

    {
        type: 'design',
        contents: [
            {
                type: '학습내용',
                titles: [
                    '학습내용이 강좌의 목적에 맞게 타당하게 선정·조직되었는가?',
                    '학습내용이 오타나 오류 없이 정확한 정보를 담고 있는가?',
                    '학습내용이 이해하기 쉽게 구체적으로 표현되었는가?',
                    '학습내용의 난이도가 대상 학습자의 수준에 적합한가?',
                    '학습자가 성공적인 학습 경험을 하며 학습을 완료할 수 있도록 적절한 학습 분량으로 구성하였는가?',
                    '학습내용이 종교, 지역, 이념, 성, 계층, 다문화가정 등과 관련하여 윤리적으로 문제가 없는가?'
                ]
            },
            {
                type: '교수설계',
                titles: [

                    '강좌 수강을 통해 이루고자 하는 성과 및 학습목표가 구체적으로 제시되어 있는가?',
                    '학습목표를 고려하여 적절한 교수학습 전략을 설계하였는가?',
                    '학습목표를 고려하여 다양한 학습 요소를 포함하여 설계하였는가?',
                    '학습자의 학습동기를 유발하고 지속적으로 유지시켜, 학습에 집중할 수 있도록 유도하기 위한 전략을 수립하였는가?'
                ]
            }
        ]
    },
    {
        type: 'development',
        contents: [
            {
                type: '동영상',
                titles: [
                    '학습자의 주의집중 시간을 고려하여 적절하게 분할하여 제시하고 있는가?',
                    '일관되고 적절한 수준의 동영상과 오디오 품질을 유지하는가?',
                    '학습자의 이해를 돕기 위한 텍스트 오버레이 형식이 제시되는가?',
                    '동영상 내 텍스트는 학습자의 인지부하를 고려하여 제시되는가?',
                    '동영상 내에 학습자가 학습내용을 확인할 수 있도록 학습목표 및 주제 등이 제시되는가?'
                ]
            },
            {
                type: '기타자료',
                titles:
                    [
                        '모든 텍스트 자료는 오류 없이 적절한 품질을 갖추고 있는가?',
                        '모든 이미지 자료는 오류 없이 적절한 품질을 갖추고 있는가?',
                        '모든 문서 자료는 오류 없이 적절한 품질을 갖추고 있으며, 다운로드가 가능하도록 지원하고 있는가?'
                    ]
            },
            {
                type: '웹 접근성',
                titles:
                    [
                        '해당 강좌는 ‘한국형 웹 콘텐츠 접근성 지침 2.1 기준’을 준수하고 있는가?'
                    ]
            },
            {
                type: '저작권',
                titles:
                    [
                        '해당 강좌는 포함하고 있는 모든 콘텐츠와 관련된 저작권 문제를 해결하였으며, 국제저작권 표시방법을 준수하고 있는가?'
                    ]
            }
        ]
    },
    {
        type: 'audit',
        contents: [
            {
                type: '자체 검수',
                titles:
                    [
                        '본 체크리스트를 기반으로 원격수업 콘텐츠에 대한 자체 검수를 수행하였는가?'
                    ]
            },
            {
                type: '테스팅',
                titles:
                    [
                        '콘텐츠가 오류 없이 잘 동작하는가?'
                    ]
            }
        ],
    }
]


