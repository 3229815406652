/** 프로그램 신청 상세 팝업*/
import React, {useEffect, useState, useCallback} from 'react';
import Popup from "reactjs-popup";
import {useDispatch, useSelector} from "react-redux";
import {CustomLoading} from "components/Custom";
import {showLoading, hideLoading} from 'react-redux-loading-bar'
import moment from 'moment/dist/moment';

import ProgramApplyForm from './Form';
import * as actions from 'store/actions/content';
import * as api from 'lib/content';

//graphql 관련
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import * as aQuery from "queries/program";
import { APPLY_STATUS_CATEGORIES_QUERY } from '../../../queries/program';


const handleSubmit = ({mode = '',
                       popupClose,
                       view = {},
                       user = {},
                       applied_data={},
                       onSuccess,
                       onFailure,
                       applyStatusList,
                       dispatch,
                       applyStatus,
                       apply_id,
                       setModeTitle,type}) => async (formData) => {
    const {id, files, bigo} = formData;
    let changeStatus = null;
    const {data:apply_number} = await api.gets({type:type,appied_program:view.id,apply_status:'5ff6b769bf55b944295d63e8'})
    const program_type = (type == 'studyprogram-applies') ? '프로그램' : (type == 'workshopprogram-applies') ? '워크숍' : '지원사업'
    const type2='gjudec-2021-1-hakgi-middle-students'
    applyStatusList.map((item,idx)=>{
        if(!view.apply_maxnumber || view.apply_maxnumber == 0){
            if(item.apply_status_category_title == 'apply_completed'){
                changeStatus = item.id
            }
        }
        else{
            if( Number(view.apply_maxnumber) <= Number(apply_number.length)){
                if(mode == 'm'){
                    if(applyStatus.apply_status_category_title == 'apply_completed_over_capacity'){
                        if(item.apply_status_category_title == 'apply_completed_over_capacity'){
                            changeStatus = item.id
                        }
                    }
                    else{
                        if(item.apply_status_category_title == 'apply_completed_over_capacity'){
                            changeStatus = item.id
                        }
                    }
                }
                else if(mode == ''){
                    if(item.apply_status_category_title == 'apply_completed_over_capacity'){
                        changeStatus = item.id
                    }
                }
                else{
                    if(item.apply_status_category_title == 'apply_completed_over_capacity'){
                        changeStatus = item.id
                    }
                }
            }
            else{
                if(item.apply_status_category_title == 'apply_completed'){
                    changeStatus = item.id
                }
            }

        }
    })
    let params = {};
    params = {apply_user:user,appied_program:view.id,author:user
        ,apply_status:{_id:changeStatus},apply_text_bigo:bigo
    };
    dispatch(showLoading())
    const {data:apply_data} = await api.gets({type:type,appied_program:view.id,apply_status:'5ff6b774bf55b944295d63e9'})

    try {
        let result_data = null;
        let cw_uuid=''
        /*switch(_.get(view,'title')){
            case "고급해석학의 기초 개념":
                cw_uuid="3ac14b9338105042fb34d84f3b6cb1ed";
                break;
            case "대학생의 자기이해와 진로탐색":
                cw_uuid="1358b691f12335f56dd1883f2ff74a10";
                break;
            case "생명과학":
                cw_uuid="ceb77fa919de0a15902c0feae2430ace";
                break;
            case "성공적 대학생활을 위한 자기주도적 학습의 이해":
                cw_uuid="42c3c8871148987f005f5b994807cba2";
                break;
            case "성인간호학":
                cw_uuid="3cf2d728104cba79c8d3a8e85d542e06";
                break;
            case "원격수업력량강화 워크숍":
                cw_uuid="e87a8d6aeb169ad821fbee0910f3684d";
                break;
            case "효과적인 수업자료의 개발":
                cw_uuid="b69bd5106b2144b113ad45684c82b336";
                break;

        }*/
        cw_uuid = _.get(view,'cw_uuid')
        const {data:student_data} = await api.gets({type:type2,cw_uuid,stu_id : user.username})
        console.log(student_data)
        if(mode==''){
            let studentdata ={
                semester_id : "gjudec_2021_1hakgi",
                cw_uuid : cw_uuid,
                cw_id : _.get(view,'title'),
                stu_hp_phone_number : user.hp,
                stu_id : user.username,
                spoc_id :"gjudec",
                code_semester : "1",
                division : 1,
                code_year : '2021',
                stu_email : user.email,
                __v : 0,
                stu_name : user.name,
                cw_kor_title : _.get(view,'title'),
            }

            console.log(studentdata,5555)
            if(!view.apply_maxnumber || view.apply_maxnumber == 0){
                console.log('[jarry- 수강 신청 확인 mode null,1111]',view,user)

                console.log(type,params,user)
                console.log(params)
                console.log(studentdata,1111)
                result_data = await api.create({type:type,...params},[{key:"apply_files",files}]);
                await api.create({type:type2, ...studentdata});
                setModeTitle(program_type+' 신청')
            }
            else{
                if( Number(view.apply_maxnumber) > Number(apply_number.length)){
                    console.log('[jarry- 수강 신청  mode null 2222]',view,user)
                    console.log(type,params)
                    result_data = await api.create({type:type,...params},[{key:"apply_files",files}]);
                    await api.create({type:type2},...studentdata);
                    setModeTitle(program_type+' 신청')
                }
                else{
                    if(view.excessapplypermit){
                        if(view.reservationwaiting > 0){
                            if(view.reservationwaiting > apply_data.length){
                                console.log('[jarry- 수강 신청  mode null 3333]',view,user)
                                console.log(type,params)
                                result_data = await api.create({type:type,...params},[{key:"apply_files",files}]);
                                await api.create({type:type2,...studentdata});
                                setModeTitle(program_type+' 신청')
                            }
                            else{
                                throw new Error(program_type+' 대기 인원이 가득 찼습니다.')
                            }
                        }
                        else if(!view.reservationwaiting){
                            result_data = await api.create({type:type,...params},[{key:"apply_files",files}]);
                            await api.create({type:type2,...studentdata});
                            console.log('[jarry- 수강 신청  mode null 4444]',view,user)
                            console.log(type,params)
                            setModeTitle(program_type+' 신청')
                        }
                        else{
                            throw new Error(program_type+' 대기 인원이 가득 찼습니다.')
                        }
                    }
                    else{
                        throw new Error(program_type+' 신청 인원이 가득 찼습니다.')
                    }

                }
            }

        }
        else{
            if(mode=='d'){

                if(!view.apply_maxnumber || view.apply_maxnumber == 0){
                    let studentdata ={
                        semester_id : "gjudec_2021_1hakgi",
                        cw_uuid : cw_uuid,
                        cw_id : _.get(view,'title'),
                        stu_hp_phone_number : user.hp,
                        stu_id : user.username,
                        spoc_id :"gjudec",
                        code_semester : "1",
                        division : 1,
                        code_year : '2021',
                        stu_email : user.email,
                        __v : 0,
                        stu_name : user.name,
                        cw_kor_title : _.get(view,'title'),
                    }
                    result_data = await api.update({type:type,id:apply_id,...params},[{key:"apply_files",files}]);
                    console.log('[jarry- 수강 신청  mode d 1111]',view,user)
                    console.log(type,params)
                    console.log(program_type)

                    console.log(mode)
                    let result_data2=await api.create({type:type2, ...studentdata});
                    setModeTitle(program_type+' 신청')
                    console.log(result_data2)
                    console.log(studentdata)

                }
                else if( Number(view.apply_maxnumber) > Number(apply_number.length)){
                    console.log('[jarry- 수강 신청  mode d 2222]',view,user)
                    console.log(type,params)
                    result_data = await api.update({type:type,id:apply_id,...params},[{key:"apply_files",files}]);
                    //await api.update({type:type2,...studentdata});
                    setModeTitle(program_type+' 신청')
                }
                else{
                    if(view.excessapplypermit){
                        if(view.reservationwaiting){
                            if(view.reservationwaiting > 0){
                                if(view.reservationwaiting > apply_data.length){
                                    console.log('[jarry- 수강 신청  mode d 3333]',view,user)
                                    console.log(type,params)
                                    result_data = await api.update({type:type,id:apply_id,...params},[{key:"apply_files",files}]);
                                    //await api.update({type:type2,...studentdata});
                                    setModeTitle(program_type+' 신청')
                                }
                                else{
                                    throw new Error(program_type+' 대기 인원이 가득 찼습니다.')
                                }
                            }
                            else{
                                throw new Error(program_type+' 대기 인원이 가득 찼습니다.')
                            }
                        }
                        else if(!view.reservationwaiting){
                            console.log('[jarry- 수강 신청  mode d 4444]',view,user)
                            console.log(type,params)
                            result_data = await api.update({type:type,id:apply_id,...params},[{key:"apply_files",files}]);
                            setModeTitle(program_type+' 신청')
                        }
                        else{
                            throw new Error(program_type+' 신청 인원이 가득 찼습니다.')
                        }
                    }
                    else{
                        throw new Error(program_type+' 신청 인원이 가득 찼습니다.')
                    }
                }
            }
            else{
                console.log('[jarry- 수강 신청  mode null d x ]',view,user)
                console.log(type,params)
                result_data = await api.update({type:type,id:apply_id,...params},[{key:"apply_files",files}]);
                //await api.update({type:type2,...studentdata});
                setModeTitle(program_type+' 신청 수정')
            }
        }
        const {data, status} = result_data;
        if(status !== 200){
            throw new Error('에러 발생');
        }
        else{
            onSuccess && onSuccess();
        }
    } catch (e) {
        console.log(e, program_type+' 신청/수정 실패')
        // 수강 신청 실 프로세스
        swal({
            title: program_type+`신청/수정 실패`, text: e.message,
            icon: "warning", timer: 3000, buttons: false,
        });
        onFailure && onFailure();
    } finally {
        dispatch(hideLoading())
        //팝업 닫기
        popupClose && popupClose();
    }
}

const handleCancel = ({dispatch,popupClose,applyStatusList,applyStatus,apply_id,onSuccess,onFailure,setModeTitle,type,view,user}) => async ()=> {
    console.log('cancle 들어옴')

    let changeStatus = null;
    applyStatusList.map((item,idx)=>{
        if(item.apply_status_category_title == 'apply_cancael_completed'){
            changeStatus = item.id
        }
    })
    const program_type = (type == 'studyprogram-applies') ? '프로그램' : (type == 'workshopprogram-applies') ? '워크숍' : '지원사업'
    const params = {apply_status:{_id:changeStatus}};
    dispatch(showLoading())
    console.log(applyStatusList)

    try {
        console.log('trytry')
        const type2= 'gjudec-2021-1-hakgi-middle-students'
        let cw_uuid=''
        /*switch(_.get(view,'title')){
            case "고급해석학의 기초 개념":
                cw_uuid="3ac14b9338105042fb34d84f3b6cb1ed";
                break;
            case "대학생의 자기이해와 진로탐색":
                cw_uuid="1358b691f12335f56dd1883f2ff74a10";
                break;
            case "생명과학":
                cw_uuid="ceb77fa919de0a15902c0feae2430ace";
                break;
            case "성공적 대학생활을 위한 자기주도적 학습의 이해":
                cw_uuid="42c3c8871148987f005f5b994807cba2";
                break;
            case "성인간호학":
                cw_uuid="3cf2d728104cba79c8d3a8e85d542e06";
                break;
            case "원격수업력량강화 워크숍":
                cw_uuid="e87a8d6aeb169ad821fbee0910f3684d";
                break;
            case "효과적인 수업자료의 개발":
                cw_uuid="b69bd5106b2144b113ad45684c82b336";
                break;

        }*/

        console.log(view,'view')
        cw_uuid = _.get(view,'cw_uuid')
        console.log(user,'user')
        const {data:student_data} = await api.gets({type:type2,cw_uuid,stu_id : user.username})
        console.log(student_data,'test')
        await api.del({type:type2,id:student_data[0].id})
        console.log(student_data,1111)
        /*swal({
            title: `정말로 취소하시겠습니까?`,
            icon: "warning", timer: 3000, buttons: {
                cancle:{
                    text:"돌아가기",
                    value:false
                },
                confirm:{
                    text:"수강취소",
                    value:false
                }
            }
        }).then((value) => {
            if(value == true){
               return window.location.reload();
            }
            else{
            }
        });*/

        const {data, status} = await api.update({type:type,id:apply_id,...params});
        console.log('type??',type)
        console.log(apply_id)
        console.log(params)
        setModeTitle(program_type+'신청 취소')
        onFailure && onFailure();
        if(status !== 200){
            throw new Error('에러 발생');
        }
        else{
            onSuccess && onSuccess();
        }
    } catch (e) {
        console.log(e, program_type+'신청 취소 실패')
        // 수강 신청 실 프로세스
        swal({
            title: program_type+`신청 취소 실패`, text: e.message,
            icon: "warning", timer: 3000, buttons: false,
        });
        onFailure && onFailure();
    } finally {
        dispatch(hideLoading())
        //팝업 닫기
        popupClose && popupClose();
    }
}



const TYPE = `workshopprograms`;
const ProgramApplyWrite = ({trigger, id = '', mode = '', type,onSuccess, onFailure,defaultId, setModeTitle,applis,reload,setReload,applied_data}) => {
        const now = new Date();
        const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}));// 로그인 한 유저 정보
        const {id:userId ,username} =user;
        const dispatch = useDispatch();
        const {view = {}, errorMsg = ''} = useSelector(({content}) => ({...content})); //해당 프로그램 상세 정보
        const [loading, setLoading] = useState(true);   //데이터 로딩중인가?
        const [open, setOpen] = useState(false);    //open 상태인가?
        const [chClass, setChClass] = useState('popup_content');
        // 사용자 지원 상태 목록 관련
        const [getApplyStatus, {data: applyStatusData}] = useLazyQuery(aQuery.APPLY_STATUS_CATEGORIES_QUERY);
        const [applyStatusList, setApplyStatusList] = useState([]);
        /*console.log(applis)*/
        const {apply_files=[],apply_status:applyStatus,id:apply_id} = applis
        //사용자 지원 상태 목록 관련

        useEffect(() => {
            getApplyStatus({variables: {limit: 9, sort: "createdAt:DESC"}})
        }, [])
        //사용자 지원 상태 목록 관련
        useEffect(() => {
            applyStatusData && applyStatusData.applyStatusCategories && setApplyStatusList(applyStatusData.applyStatusCategories);
        }, [applyStatusData])
        // id 값으로 해당 프로그램의 데이터 정보를 가져온다
        useEffect(() => {
            dispatch(actions.viewRequest({type: ((type == 'studyprogram-applies') ? 'studyprograms' : (type == 'workshopprogram-applies') ? 'workshopprograms' : 'supportprograms'), id}));
        }, [id])
        // 유저 데이터 | id 값으로 필터링 한 해당 프로그램의 상세 데이터
        useEffect(() => {
        }, [user, view,applyStatusList])

        useEffect(() => {
            open && console.log(open)
            if (open === true) {
                setLoading(false)
            }
        }, [open])


        return (
            <Popup
                trigger={trigger}
                position="right center"
                onOpen={() => setOpen(true)}
                onClose={() => {
                    setLoading(true)
                    setOpen(false)
                }}
                modal
                closeOnDocumentClick={false}>
                {popupClose => {
                    if (loading) return null
                    return (<div className="popup_overlay">
                        {(mode=='can') ? setChClass('popup_content sub') :setChClass('popup_content')}
                        <div className={chClass}>
                            <div className="program_popup">

                                <div className="modal_header">
                                    <h5 className="modal_title">
                                        {/*{(mode == '' || mode=='d' || mode=='can') &&  '신청하기'||'수정하기'||'신청취소' }*/}
                                        {
                                            (mode == '' && '신청하기') ||
                                            (mode=='d' && '수정하기') ||
                                            (mode=='can' && '신청취소')
                                        }
                                    </h5>
                                    <button onClick={popupClose} type="button" className="overlay_close_x close">
                                        {/*<button onClick={close} type="button" className="overlay_close_x close">*/}
                                        <span className="element-invisible" aria-hidden="true">X</span>
                                    </button>
                                </div>
                                <div className="modal_body">
                                    {(mode =='can') ?
                                        <ProgramApplyForm
                                            data={view}
                                            mode={'can'}
                                            applied_data={applied_data}
                                            user={user}
                                            type={type}
                                            defaultId={defaultId}
                                            handleCancel={popupClose}
                                            reload={reload}
                                            bigo={applis.apply_text_bigo}
                                            setReload={setReload}
                                            cancelSubmit={handleCancel({dispatch,popupClose,applyStatusList,applyStatus,apply_id,onSuccess,onFailure,setModeTitle,type,view, user})}
                                            onSubmit={handleSubmit({mode, popupClose, view, user, onSuccess, onFailure, applyStatusList, dispatch,applyStatus,apply_id,setModeTitle,type})}
                                        />
                                        :
                                        <ProgramApplyForm
                                            data={view}
                                            mode={mode}
                                            type={type}
                                            handleCancel={popupClose}
                                            apply_files={apply_files}
                                            reload={reload}
                                            bigo={applis.apply_text_bigo}
                                            setReload={setReload}
                                            cancelSubmit={handleCancel({dispatch,popupClose,applyStatusList,applyStatus,apply_id,onSuccess,onFailure,setModeTitle,type,view, user})}
                                            onSubmit={handleSubmit({mode, popupClose, view, user, onSuccess, onFailure, applyStatusList, dispatch,applyStatus,apply_id,setModeTitle,type})}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>)
                }}
            </Popup>

        )
    }
;

export default ProgramApplyWrite;
