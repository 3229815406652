import React, {useState} from 'react';
import FaqItem from './FaqItem';
import Pagination from '../Pagination';

const Faq = ({faqlist=[]}) => {
  
    return (
        faqlist.map((item,index)=><FaqItem title={item.title} body={item.body} key={index} />)
  );
};

export default Faq;
