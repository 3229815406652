import React, { useRef, useEffect } from "react";

import BreadCrumb from '../../components/BreadCrumb';
import SubLeftMenu from '../../components/SubLeftMenu';
import {Helmet} from "react-helmet";
import route from '../../route';
import KakaoMap from '../../components/Custom/CustomKakaoMap';

const Map = () => {

  /*const { kakao } = window;

  useEffect(() => {
    const container = document.getElementById('mapbox');
    const options = {
      center: new kakao.maps.LatLng(33.450701, 126.570667),
      level: 3
    };
    const map = new kakao.maps.Map(container, options);
  }, []);*/


  return (
    <div className="map content introduction">
      <Helmet title="오시는 길"/>
      <BreadCrumb list={route.depth1} />
      <div className="sub">
        <div className="wrap">
          <SubLeftMenu
            title={route.depth1.title}
            lnbList={route.depth1.list}
          />
          <div className="sub__content introduction_content">
            <h1 className="sub__title introduction_title">
              <span className="">오시는 길</span>
            </h1>
            <div className="map__content">
              <div className="mapbox">
                <KakaoMap/>

                {/*<iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3261.2180500148193!2d126.90362801524505!3d35.176117480315185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35718c565626526b%3A0x7e85baeda973a225!2sChonnam%20National%20University%20Yongbong%20Campus!5e0!3m2!1sen!2skr!4v1617955583867!5m2!1sen!2skr"
                  allowFullScreen="" aria-hidden="false"
                  tabIndex="0" />*/}


              </div>
              <div className="map__info">
                {/*<div className="map__info-header">
                  <h2>서영대학교 광주캠퍼스</h2>
                  <p>
                    (61268) 광주광역시 북구 서강로 1(운암동)
                  </p>
                </div>*/}
                {/*<ul>
                <li>
                    <h3 className="i1">전화번호</h3>
                    <p>
                      062-520-5114
                    </p>
                  </li>*/}
                  {/*<li>
                    <h3 className="i2">버스</h3>
                    <p>
                      63, 67, 670, 71, 77, 82, 84, 21, 22, 23, 25, 60, 61, 62, 63, 64, 67, 71, 81, 82, 85, 86, 94, 95, 96, 97, 98
                    </p>
                  </li>
                  <li>
                    <h3 className="i3">고속버스, 시외버스(유·스퀘어 종합버스터미널) 이용</h3>
                    <p>
                      택시 : 용봉동캠퍼스까지 15분 소요, 요금은 교통 체증여부에 따라 다르나 5,000원 정도임<br/>
                      시내버스: 광천동 종합버스터미널에서 목적지 정류소에 맞는 버스에 탑승<br/>
                      <div className="wayInfo">
                        <span className="waydash">-</span>전남대정문 통과 후 용봉탑 앞 로터리 : 마을버스 777<br/>
                        <span className="waydash">-</span>전남대 정류소(정문) : 풍암26, 첨단30, 상무64, 518<br/>
                        <span className="waydash">-</span>전남대후문 정류소 : 일곡38
                      </div>
                    </p>
                  </li>
                  <li>
                    <h3 className="i4">철도편(광주역) 이용</h3>
                    <p>
                      택시 : 용봉동캠퍼스까지 5분 거리에 있으며, 요금은 3,500원 정도임<br/>
                      시내버스: 광주역에서 목적지 정류소에 맞는 버스에 탑승하여 오면 됨<br/>
                      <div className="wayInfo">
                        <span className="waydash">-</span>전남대 정류소(정문) : 첨단30, 금남57, 두암81(상행), 일곡 180, 518<br/>
                        <span className="waydash">-</span>전남대후문 정류소 : 진월 07, 용봉83, 일곡180
                      </div>
                    </p>
                  </li>
                  <li>
                    <h3 className="i4">철도편(송정역) 이용</h3>
                    <p>
                      택시 : 용봉동캠퍼스까지 30분 정도 소요, 요금은 교통 체증여부에 따라 다르나 15,000원 정도임<br/>
                      시내버스: 송정리역 정류장에서 목적지 정류소에 맞는 버스에 탑승, 1시간 10분이상 소요<br/>
                      <div className="wayInfo">
                        <span className="waydash">-</span>전남대 정류소(정문) : 좌석02, 송정19, 일곡 38<br/>
                        <span className="waydash">-</span>전남대후문 정류소 : 160, 송정19, 일곡 38
                      </div>
                    </p>
                  </li>
                  <li>
                    <h3 className="i5">항공편(광주공항) 이용</h3>
                    <p>
                      택시 : 용봉동캠퍼스까지 택시로 약 30~40분 소요되며, 요금은 교통체증 여부에 따라 다르나 약 14,000원 정도임<br/>
                      시내버스: 공항에서 용봉동캠퍼스로 직접 오는 시내버스는 없으며 공항버스를 타고 나와 시내버스로 갈아타야 함<br/>
                      <div className="wayInfo">
                        <span className="waydash">-</span>공항버스를 타고 광주-송정간 도로에서 내려 일곡 38번 버스를 타면 전남대후문 정류소에 도착<br/>
                        <span className="waydash">-</span>공항버스(1000번)를 타고 광천동 종합버스터미널에서 내려 버스를 갈아타고 오면 됨. 버스 노선은 고속버스, 시외버스(광천동 종합버스터미털) 이용 시를 참고
                      </div>
                    </p>
                  </li>
                  <li>
                    <h3 className="i6">자동차편 이용</h3>
                    <p>
                      <div className="wayInfo">
                        <span className="waydash">-</span>고속도로를 이용하여 용봉동캠퍼스에 올 경우 서광주IC와 동광주IC 사이에 있는 용봉IC로 빠져 나온 뒤 찾아오시는 길 안내의 약도에 따라 운행하면 10분 정도 소요<br/>
                        <span className="waydash">-</span>용봉IC는 출구만 있고, 입구는 없기 때문에 고속도로를 타기 위해서는 전남대 정문에서 신안3거리를 지나 운암고가 밑에서 우회전 하여 광주문화예술회관으로 나간 뒤 서광주IC를 통해 고속도로로 나감
                      </div>
                    </p>
                  </li>
                  <li>
                    <h3 className="i6">시내(마을)버스 이용시</h3>
                    <p>
                      <div className="wayInfo">
                        <span className="waydash">-</span>전남대 정류소(정문)는 풍암26, 첨단30, 금남 57, 상무64, 두암81(상행), 일곡180, 518 버스가 정차함<br/>
                        <span className="waydash">-</span>전남대후문 정류소는 진월07, 문흥18, 일곡 180, 용전 184, 송정19, 일곡28, 일곡38, 문흥80, 용봉83, 충효187, 419 버스가 정차함<br/>
                        <span className="waydash">-</span>전남대정문 통과 후 용봉탑 앞 로터리 마을버스777 정차함
                      </div>
                    </p>
                  </li>

                </ul>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Map;
