// 프로그램 신청
import React, {useEffect, useMemo, useState} from 'react';
import BreadCrumb from '../../components/BreadCrumb';
import route from '../../route';
import {programlist} from '../../data';
import ProgramList from '../../components/ProgramList';
import Pagination from '../../components/Pagination';
import {useRouteMatch, generatePath, Link} from 'react-router-dom';


import SubLeftMenu from '../../components/SubLeftMenu';
import {useTranslation} from 'react-i18next'
import {Helmet} from "react-helmet";
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';
import moment from 'moment/dist/moment';
import {CustomPagination} from 'components/Custom';
import {apiUrl} from "config";
import {useParams, useHistory} from 'react-router-dom';

//여기서부터 새로추가
import {BoardHeader, BoardList} from 'components/Board';
import BoardWrite from 'components/Write/WorkWrite';
import {useLocation} from 'react-router-dom';
import qs from 'qs';
import * as api from 'lib/content';


const TYPE = 'workshopprograms';
const TYPE2 = 'workshopprogram-applies';
const WorkShop = ({data = {},type,setReset_data=()=>{}}) => {
    const dispatch = useDispatch();
    const match = useRouteMatch()
    const {list = [], totalCount = 0, loading} = useSelector(({content}) => ({...content}));
    // const [perPage, setPerPage] = useState(12)
    // const [page, setPage] = useState(0)

    const [category, setCategory] = useState('yeokryangganghwawokeusyop')

    // 새로추가한 변수 및 객체
    const {search} = useLocation();
    const {page: _page = 0, perPage: _perPage = 10} = qs.parse(search, {ignoreQueryPrefix: true});
    const {t, i18n} = useTranslation()
    const [perPage, setPerPage] = useState(_perPage && +_perPage || 10)
    const [page, setPage] = useState(_page && +_page || 0)
    const [params, setParams] = useState({_limit: perPage, _sort: `createdAt:desc`, _start: page * perPage})
    const [filter, setFilter] = useState({});
    const {user,isLoggedIn} = useSelector(({auth}) => ({...auth}))
    const [reload, setReload] = useState(false);    //open 상태인가?
    const [applyCount, setApplyCount] = useState([]);
    const {id} = useParams();


    // 신청 취소
    const handleDelete = () => {
        swal({
            title: `정말 취소하시겠습니까?`, text: '',
            buttons: {
                confirm: {
                    text: '수강취소하기',
                    value: true,
                    className: 'swal_ok'
                },
                cancle: {
                    text: '닫기',
                    value: false,
                    className: 'swal_close'
                }
            }
        })
    }



    // const [category, setCategory] = useState([
    //     {
    //         key: '',
    //         value: '전체',
    //         ative: true
    //     },
    //     {
    //         key: 'software',
    //         value: '소프트웨어',
    //         ative: false
    //     },
    //     {
    //         key: 'hardware',
    //         value: '하드웨어',
    //         ative: false
    //     },
    //     {
    //         key: 'business',
    //         value: '비즈니스',
    //         ative: false
    //     },
    //     {
    //         key: 'entrepreneurship',
    //         value: '창업',
    //         ative: false
    //     },
    //     {
    //         key: 'webtoon',
    //         value: '웹툰',
    //         ative: false
    //     }
    // ])

    //const {t} = useTranslation()
    const pageCount = Math.ceil(totalCount / perPage);

    const updateParams = (data) => {
        setParams({...params, ...data});
    };


    // const handleChange = (value) => {
    //     console.log(value, '키워드 입력 및 타입 셀렉트 시')
    // }

    //page 이동
    useEffect(() => {
        const {_start, _limit, classification_eq} = params;
        if (_limit !== perPage || _start !== page * perPage) {
            updateParams({...params, _start: page * perPage, _limit: perPage})
        }
    }, [page, perPage])

    useEffect(() => {
        const filter = category && {program_category_eq:category} || {}
        dispatch(actions.listRequest({type: TYPE, ...params,...filter}))
    }, [params,category])

    useEffect(() => {
        dispatch(actions.listRequest({type: TYPE, ...params, ...filter}))
    }, [params, filter,reload])


    // useEffect(() => {
    //     updateParams({...params, program_category_eq:category})
    // }, [category])
    // console.log(list);
    // console.log(apiUrl);
    // // console.log(`${apiUrl}/${list.thumbnailimages.url}`);
    // console.log(route.program)


    ////// 여기부터 새로추가!!

    //신청자수 추출
    async function fetchMyAPI(type,id) {
        if(id){
            try {
                const {status:apply_status,data:apply_data} = await api.gets({type:TYPE2,appied_program:id,apply_status:'5ff6b769bf55b944295d63e8'})
                // const {status:apply_over_status,data:apply_over_data} = await api.gets({type:type,appied_program:id,apply_status:'5ff6b774bf55b944295d63e9'})
                if(apply_status !== 200 || apply_over_status != 200){
                    throw new Error('에러 발생');
                }
                else{
                    setApplyCount(apply_data.length);
                    setApplyOverCount(apply_over_data.length);
                }
            } catch (e) {
                console.log('데이터 로딩 실패',e.message);
            } finally {
            }
        }
    }

    useEffect(() => {
        if (!_.isEmpty(id)) {
            fetchMyAPI(type, id)
        }
    },[id])





    // 테이블 상단값 표기
    const columns = useMemo(() => [
        {
            Header: "번호", accessor: 'id', className: `text-center`, minWidth: '50px',
            Cell: ({row, idx, totalCount}) => {
                const index = totalCount - (page * perPage) - idx;
                return index > 0 && (<div>{index}</div>) || '-'
            }
        },
        {
            Header: "프로그램명",
            accessor: 'title',
            className: `text-center`,
            width: 'calc(100% - 190px)',
            padding: '0 15px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textAlign: 'left',
            textOverflow: 'ellipsis'
        },
        {
            Header: "장소", accessor: 'program_address', className: `text-center`, minWidth: '170px',
           /* Cell: ({row: {createdAt = new Date}, idx, totalCount}) => (<>{moment(createdAt).format(`YYYY-MM-DD`)}</>)*/
        },
        {
            Header: "진행기간", accessor: 'learning_startdate,learning_enddate', className: `text-center`, minWidth: '170px',
            Cell: ({row: {learning_startdate,learning_enddate = new Date}, idx, totalCount}) => (<>{moment(learning_startdate).format(`YYYY-MM-DD`)} ~<br/>   {moment(learning_enddate).format(`YYYY-MM-DD`)}</>)
        },
        {Header: "신청/정원", accessor: 'apply_count', className: `text-center`, minWidth: '80px',
            Cell: ({row: {apply_maxnumber=0,id}, idx, totalCount}) => {
                if(!_.isEmpty(apply_maxnumber)){
                    if ((apply_maxnumber == applyCount) || (apply_maxnumber < applyCount)){
                        return(<>정원초과</>)
                    }
                    else{
                        return (<>{applyCount}/{apply_maxnumber}</>)
                    }
                }
                else{
                    return(<>무제한</>)
                }
            }
        },
        {
            Header: "상태", accessor: 'learning_startdate,learning_enddate', className: `text-center`, minWidth: '80px',
            Cell: ({row: {apply_startdate,apply_enddate = new Date}, idx, totalCount}) => {
                let today_day = moment().format(`YYYY-MM-DD HH:mm`);
                let state_now;
                //날짜 비교
                if(moment(apply_startdate).isBefore(today_day) && moment(apply_enddate).isAfter(today_day) ){
                   state_now = "접수중"
                }
                else if(moment(apply_startdate).isSame(today_day) || moment(apply_enddate).isSame(today_day)){
                   state_now = "접수중"
                }
                else if((moment(apply_startdate).isAfter(today_day))){
                   state_now = "접수대기중"
                }
                else if(moment(apply_enddate).isBefore(today_day)){
                   state_now = "종료"
                }
                return (
                <>
                    {state_now}
                </>
                )
            }
        }

    ], [perPage, page])

    const handleReset =() =>{
        setParams ({_limit: 10, _sort: `createdAt:DESC`, _start: 0})
        setPage(0)
        setFilter({})
    }

    const handleChange = ({keyword = "", type = ""}) => {
        if (!keyword || !type) {
            setFilter({})
            setParams ({_limit: 10, _sort: `createdAt:DESC`, _start: 0})
            setPage(0)
            return;
        }
        const filters = type.split(',') || [];
        const _filter = {};
        _.forEach(filters, (field, idx) => {
            _filter[`_where[_or][${idx}][${field}]`] = keyword
        })
        setFilter({..._filter})
    }


    return (
        <>
            <div className="program content introduction">
                <Helmet title="역량강화 워크숍"/>
                <BreadCrumb list={route.depth4}/>
                <div className="sub">
                    <div className="wrap">
                        <SubLeftMenu
                            title={route.depth4.title}
                            lnbList={route.depth4.list}
                        />
                        <div className="sub__content introduction_content workshop_board" >
                            <h1 className="sub__title introduction_title">
                                <span className="">역량강화 워크숍</span>
                            </h1>
                            {loading && (<div className={`text-center p-4`}>로딩중...</div>) ||
                            <>

                                <div className="board">
                                    <BoardHeader totalCount={totalCount}
                                                 title={'역량강화 워크숍'}
                                                 onReset={handleReset}
                                                 onChange={handleChange}/>
                                    <BoardList
                                        totalCount={totalCount}
                                        path={match.path}
                                        loading={loading}
                                        data={list}
                                        user={user}
                                        columns={columns}/>

                                    <CustomPagination
                                        pageSize={perPage}
                                        pageIndex={page}
                                        pageCount={pageCount}
                                        onPageChange={({selected}) => setPage(selected)}/>
                                    <div className={'submit_container'}>
                                        {
                                            !_.isEmpty(_.get(user,'role')) &&
                                            <button className={'apply_cancel_btn'} onClick={handleDelete}>
                                                신청취소
                                            </button>
                                            &&
                                            _.get(user,'role.id') == '5fed5533258df16ef195ae04' &&
                                            <BoardWrite
                                                setReload={setReload}
                                                reload={reload}
                                                type={TYPE}
                                                trigger={<button className={'submit_container_btn'}>새 프로그램 작성</button>}
                                                title={"역량강화 워크숍"}
                                                user={user}
                                            />
                                        }
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WorkShop;
