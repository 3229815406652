import React from 'react';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/all';
import { NavLink } from 'react-router-dom';


const PanelContentList = ({ toggle, contentList={}, setToggle }) => {


    /*const data = {
        section: [
            {
                title:'원격수업을 위한 콘텐츠 개발의 이해',
                url:'work_01'
            },
            {
                title:'원격수업을 위한 저작권 이용 가이드',
                url:'work_02'
            },
            {
                title:'마이크로러닝을 적용한 수업설계 전략',
                url:'work_03'
            },
        ]
    };*/

  return (
    <div className={`PanelContentList ${toggle ? 'active' : ''}`}>
      <div className="top">
        강좌목차
        <button onClick={() => setToggle(!toggle)}>
          { toggle ? <FaAngleDoubleLeft /> : <FaAngleDoubleRight />}

        </button>
      </div>

      <div className="sectionBlock">
        <div className={'section'}>
          <ul>
            {
                !_.isEmpty(contentList) && contentList.section.map((section_data,index)=>
                  <li key={index}>
                    <NavLink to={section_data.url} activeClassName={"active"}>{section_data.title}</NavLink>
                  </li>
              )
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PanelContentList;
