/** 프로그램 신청 상세 팝업*/
import React, {useEffect, useState, useCallback} from 'react';
import Popup from "reactjs-popup";
import {useDispatch, useSelector} from "react-redux";
import {CustomLoading} from "components/Custom";
import {showLoading, hideLoading} from 'react-redux-loading-bar'
import moment from 'moment/dist/moment';
import * as actions from 'store/actions/content';
import * as api from 'lib/content';
import {useForm} from "react-hook-form/dist/index.ie11";
//graphql 관련
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import * as aQuery from "queries/program";
import { APPLY_STATUS_CATEGORIES_QUERY } from '../../../queries/program';

const WritePopup = ({trigger, title='', id = '',parent_id='', mode = '',type='',onSuccess=()=>{}, onFailure=()=>{},setCounsultingState, consultingState, setModeTitle='',applis,reload,setReload,user='',data}) => {
    const { register, handleSubmit, watch, errors, setValue,  clearErrors } = useForm();
    /*console.log(data.consulting_status);*/
    const listid = data.id;
    const Change = () => async (formData) => {
        /*console.log(type);*/
        try {
            setCounsultingState (formData.state);
            let parmas = {consulting_status:formData.state};
            const {data,status} = await api.update({type: type, id:listid, ...parmas});
            /*console.log(listid);*/
            swal({
                title: (mode=='m' ? '상태 변경 완료' : `상태 변경 완료`), text: (mode=='m' ? ' 상담 상태가 변경되었습니다.' : '상담 상태가 변경되었습니다.'),
                icon: "success", timer: 3000, buttons: false,
            });
        } catch (e) {
            //console.log(e, program_type+' 신청/수정 실패')
            // 수강 신청 실 프로세스
            swal({
                title: `상태 수정 실패`, text: e.message,
                icon: "warning", timer: 3000, buttons: false,
            });
        } finally {
            setReload(!reload)
        }
    }


    const handleCancel = ({dispatch,popupClose,applyStatusList,applyStatus,apply_id,onSuccess,onFailure,setModeTitle,type}) => async ()=> {
        popupClose && popupClose();

    }

        const now = new Date();
        //const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}));// 로그인 한 유저 정보
        /*const {id:userId ,username} =user;*/
        const dispatch = useDispatch();
        //const [loading, setLoading] = useState(true);   //데이터 로딩중인가?
        const [open, setOpen] = useState(false);    //open 상태인가?
        const {user_, isLoggedIn} = useSelector(({auth}) => ({...auth}))
        return (
            <Popup
                trigger={trigger}
                position="right center"
                onOpen={() => setOpen(true)}
                onClose={() => {
                    //setLoading(true)
                    setOpen(false)
                }}
                modal
                closeOnDocumentClick={false}>
                {popupClose => {
                    //if (loading) return null
                    return (<div className="popup_overlay">
                        <div className="popup_content state_change_height">
                            <div className="program_popup">
                                <div className="modal_header">
                                    <h5 className="modal_title">
                                        {title}
                                    </h5>
                                    <button onClick={popupClose} type="button" className="overlay_close_x close">
                                        {/*<button onClick={close} type="button" className="overlay_close_x close">*/}
                                        <span className="element-invisible" aria-hidden="true">X</span>
                                    </button>
                                </div>
                                <div className="modal_body">
                                    <form onSubmit={handleSubmit(Change())}>
                                        <div className="stateform_wrap">
                                            <h1 className="state_title">
                                                현재 상담 상태를 변경합니다.
                                            </h1>
                                            <select name="state" ref={register} className="state_select">
                                                <option value={false} selected={data.consulting_status == false ? "true" : ""}> 상담대기 </option>
                                                <option value={true} selected={data.consulting_status == true ? "true" : ""} > 상담완료 </option>
                                            </select>
                                            <div className="course_overlay_footer">
                                                <button type="submit"  className="overlay_close"> 변경하기 </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>)
                }}
            </Popup>

        )
    }
;

export default WritePopup;
