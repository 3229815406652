import React, {useEffect, useMemo, useState, useCallback} from 'react';
import BreadCrumb from 'components/BreadCrumb';
import SubLeftMenu from 'components/SubLeftMenu';
import * as actions from 'store/actions/content';
import {useSelector, useDispatch} from 'react-redux';
import {Helmet} from "react-helmet";
import {useTranslation} from 'react-i18next'
import moment from 'moment/dist/moment';
import * as api from 'lib/content';
import {apiUrl} from 'config';
import {useRouteMatch, generatePath, Link} from 'react-router-dom';
import route from 'route';
import {BoardHeader, BoardCustomList} from 'components/Board';
import {CustomPagination} from 'components/Custom'

// const TYPE = 'workshopprogram-applies';
const WorkShop = () => {
    return (
        <div className="myProgram">
            Hello
        </div>
    );
};

export default WorkShop;
