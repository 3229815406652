/** 전남CKL 온에어 */
import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';

import OnairNotice from './Notice';
import OnairNoticeDetail from './NoticeDetail';
import OnairNews from './News';
import OnairNewsDetail from './NewsDetail';
import OnairEventStory from './EventStory';
import OnairEventStoryDetail from './EventStoryDetail';
import OnairFaq from './Faq';

import {NotFound} from 'components/Errors';

const Onair = ({match}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => dispatch(actions.init())
    }, []);

    return (
        <Switch>
            <Route path={`${match.path}/notice/:id`} render={() => <OnairNoticeDetail/>}/>
            <Route path={`${match.path}/notice`} render={() => <OnairNotice/>}/>
            <Route path={`${match.path}/news/:id`} render={() => <OnairNewsDetail/>}/>
            <Route path={`${match.path}/news`} render={() => <OnairNews/>}/>
            <Route path={`${match.path}/eventstory/:id`} render={() => <OnairEventStoryDetail/>}/>
            <Route path={`${match.path}/eventstory`} render={() => <OnairEventStory/>}/>
            <Route path={`${match.path}/faq`} render={() => <OnairFaq/>}/>
            <Route path={`${match.path}/center`} render={() => <div className="content" style={{textAlign:'center',fontSize:'2.5rem',fontWeight:'400',margin:'15.26rem'}}>페이지 준비중입니다.</div>}/>
            <Route path={`${match.path}/qna`} render={() => <div className="content" style={{textAlign:'center',fontSize:'2.5rem',fontWeight:'400',margin:'15.26rem'}}>페이지 준비중입니다.</div>}/>
            <Route path={`${match.path}/clean`} render={() => <div className="content" style={{textAlign:'center',fontSize:'2.5rem',fontWeight:'400',margin:'15.26rem'}}>페이지 준비중입니다.</div>}/>

            <Route path="*" component={NotFound} status={404}/>
        </Switch>
    );
};

export default Onair;
