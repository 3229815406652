import React, {useEffect, useState} from 'react';
import {Link, useRouteMatch, generatePath} from 'react-router-dom';
import {CustomLoading} from 'components/Custom'
import ReactMarkdown from "react-markdown";
import {useParams, useHistory} from 'react-router-dom';
import {apiUrl} from "config";
import moment from 'moment/dist/moment';
import usePromise from "utils/usePromise";
import * as api from 'lib/content';
import BoardWrite from 'components/Write/BoardWrite';
import StateChange from 'components/Write/StateChange';
import {useSelector, useDispatch} from 'react-redux';
import Quill from "../Editor/Quill";

const BoardDetail = ({admUser,id = '', data = {}, type = '',reload = false, consultingState,setCounsultingState,setReload = () => {} }) => {
    const match = useRouteMatch()
    const {title = '', view_count = 0, body = null, attached_file = [], createdAt = new Date, author = {}} = data;
    // const [description, setDescription] = useState(body);
    const created = moment(createdAt).format(`YYYY.MM.DD`);
    const history = useHistory();
    const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}))
    const body2 = useSelector((state) => state.content.view.body)
    const consulting_status = data.consulting_status;

    const handleDelete = ({id = '', type = '', url = ''}) => {
        swal({
            title: `정말로 취소하시겠습니까?`, text: '',
            icon: "warning", buttons: {
                cancle: {
                    text: '닫기',
                    value: false,
                    className: ''
                },
                confirm: {
                    text: '취소 진행',
                    value: true,
                    className: 'btn_error'
                }
            }
        }).then(
            async (result) => {
                try {
                    if (!result) return;
                    let result_data = null;
                    result_data = await api.del({type: type, id: id});


                    const {data, status} = result_data;
                    if (status !== 200) {
                        throw new Error('에러 발생');
                    }
                    swal({
                        title: `신청 취소 완료`, text: '상담신청이 취소되었습니다.',
                        icon: "success", timer: 3000, buttons: false,
                    }).then(() => {
                        history.push(url);
                    })
                } catch (e) {
                    //console.log(e, program_type+' 신청/수정 실패')
                    // 수강 신청 실 프로세스
                    swal({
                        title: `수정 실패`, text: e.message,
                        icon: "warning", timer: 3000, buttons: false,
                    });
                } finally {
                    //dispatch(hideLoading())
                    //팝업 닫기

                }
            }
        )
    }


    /** 다음글 */
    const {data: [nextContent = null]} = usePromise(() => api.gets({
        type,
        _sort: 'createdAt:ASC',
        id_gt: id,
        _limit: 1
    }), [id], []);

    /** 이전글 */
    const {data: [prevContent = null]} = usePromise(() => api.gets({
        type,
        _sort: 'createdAt:DESC',
        id_lt: id,
        _limit: 1
    }), [id], []);

    const emailSecurity = (userEmail) => {
        var id = userEmail.split('@')[0];
        var mail = userEmail.split('@')[1];
        var maskingId = function (id) {
            var splitId = id.substring(0, 1);
            for (var i = 1; i < id.length; i++) {
                splitId += '*';
            }
            return splitId;
        };

        var maskingMail = function (mail) {
            var splitMail = '';
            if(!_.isEmpty(mail)){
                for (var i = 1; i < mail.length; i++) {
                    splitMail += '*';
                }
                splitMail += mail.substring(mail.length - 1, mail.length);
            }
            return splitMail;
        };
        if(!_.isEmpty(maskingMail)){
            userEmail = maskingId(id) + '@' + maskingMail(mail);
        }
        else{
            userEmail = maskingId(id)
        }
        return userEmail;
    }


    /** 현재 페이지 - 페이징 */
    const {data: pagerCount} = usePromise(() => api.count({type, id_gt: id}), [id], 0);
    const page = Math.floor(pagerCount / 10)

    // 목록 돌아가기 버튼을 사용하기 위한 url 글자 변환
    const pageurl = match.path.replace('/:id', '?');

    const nowUrl = window.location.pathname;

    if (_.isEmpty(data)) return (<CustomLoading loading={true}/>);
    return (
        <div className="boardDetail">
            <h2 className="boardDetail__title">{title}</h2>
            <div className="boardDetail__info">
                {<span>{_.get(author, 'username', '') && emailSecurity(_.get(author, 'username', ''))} </span>}
                <span>{created}</span>
                <span>조회수: {view_count}</span>
                 <div className="consultingState_box">
                  <div className={consulting_status == true ?  'finish_button ':'standby_button'}>{consulting_status == true ? "상담완료" : "상담대기"}</div>
                 </div>
            </div>

            <div className="boardDetail__content scbd">
                {/*<ReactMarkdown*/}
                {/*    transformImageUri={(uri) => uri.match(/^\/uploads/) && `${apiUrl}${uri}` || uri}*/}
                {/*    escapeHtml={false}*/}
                {/*    source={body}/>*/}
                <Quill isView={true} initialValue={body}/>
            </div>

            {/* 첨부파일 */}
            {attached_file.length && (
                <div className="attachFiles">
                    <div className="hd">
                        <h1>첨부파일[{attached_file.length}]</h1>
                    </div>
                    <div className="bd">
                        <ul className="bd_ul">
                            {attached_file.map((file, idx) => {
                                const {name, url} = file
                                return (<li key={idx}>
                                    <button onClick={(e) => api.download(`${apiUrl}${url}`, name)}
                                            className="download">{name}
                                    </button>
                                </li>)
                            })}
                        </ul>
                    </div>
                </div>
            ) || null}


            {/* 목록 */}
            <div className="boardDetail__list">
                {/*<Link to={generatePath("/onair/news?page=:page", {id:null,page:page})}>목록</Link>*/}
                {/*<Link to={generatePath(pageurl, {id:null, page:page})}>목록</Link>*/}
                {
                    !_.isEmpty(_.get(user, 'role')) && (
                        ((_.get(data, 'author.id') == _.get(user, 'id')) ||
                            (_.get(user, 'role.id') == '5fed5533258df16ef195ae04')) &&
                            (nowUrl.includes('/community/clean')) ?
                            <>
                                {/*<div className="consultingState_change">*/}
                                {/*    <span className="">상태 변경</span>*/}
                                {/*</div>*/}

                                { admUser == true ?
                                <StateChange
                                    setReload={setReload}
                                    reload={reload}
                                    type={type}
                                    parent_id={id}
                                    trigger={
                                    <div className="consultingState_change">
                                        <span className="">상태 변경</span>
                                    </div>
                                    }
                                    title={"상태변경"}
                                    user={user}
                                    setCounsultingState={setCounsultingState}
                                    consultingState={consultingState}
                                    data={data}
                                />
                                    : <></>}

                            <BoardWrite
                                setReload={setReload}
                                reload={reload}
                                type={type}
                                parent_id={id}
                                trigger={<button className={'delete_btn'}>답변하기</button>}
                                title={"답변"}
                                user={user}
                            />
                                <BoardWrite
                                    setReload={setReload}
                                    reload={reload}
                                    type={type}
                                    trigger={<button className={'delete_btn'}>수정</button>}
                                    title={""}
                                    data={data}
                                    user={user}
                                    mode={'m'}
                                    id={id}
                                />
                                <button className={'delete_btn'} onClick={() => handleDelete({id, type, url: generatePath(pageurl + "page=:page", {id: null, page: page})})}>삭제</button>
                            </>
                                :
                             <>
                             { admUser == true ?
                                 <StateChange
                                     setReload={setReload}
                                     reload={reload}
                                     type={type}
                                     parent_id={id}
                                     trigger={
                                         <div className="consultingState_change">
                                             <span className="">상태 변경</span>
                                         </div>
                                     }
                                     title={"상태변경"}
                                     user={user}
                                     setCounsultingState={setCounsultingState}
                                     consultingState={consultingState}
                                     data={data}
                                 />
                                 :<></>}
                            <BoardWrite
                                setReload={setReload}
                                reload={reload}
                                type={type}
                                trigger={<button className={'delete_btn'}>수정</button>}
                                title={""}
                                data={data}
                                user={user}
                                mode={'m'}
                                id={id}
                            />
                            <button className={'delete_btn'} onClick={() => handleDelete({id,type,url: generatePath(pageurl + "page=:page", {id: null, page: page})})}>신청취소</button>
                            </>)
                }
                <Link to={generatePath(pageurl + "page=:page", {id: null, page: page})}>목록</Link>
                {/*<Link to={generatePath(match.path+"?page=:page", {id:null,page:page})}>목록</Link>*/}
                {/*<Link to={generatePath(match.path, {id: "?page="+page})}>목록</Link>*/}
            </div>

            <div className="boardDetail__pager">
                {nextContent && (
                    <Link to={generatePath(match.path, {id: nextContent.id})}>
                        <span>다음글</span>
                        <p>{nextContent.title}</p>
                    </Link>
                )}
                {prevContent && (
                    <Link to={generatePath(match.path, {id: prevContent.id})}>
                        <span>이전글</span>
                        <p>{prevContent.title}</p>
                    </Link>
                )}
            </div>
        </div>
    );
};

export default BoardDetail;
