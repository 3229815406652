import React, {useState, useEffect} from 'react';
import {Helmet} from "react-helmet";
import RegisterForm from 'components/Forms/RegisterForm';
import {useDispatch, useSelector} from "react-redux";
import * as authActions from 'store/actions/auth';
import * as api from 'lib/auth'
import axios from 'axios';
import {Link, Redirect} from 'react-router-dom'
import moment from 'moment/dist/moment';

//graphql 관련
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import * as auth from "queries/auth";
//import * as cQuery from "queries/content";

const handleSubmit = ({dispatch}) => async (formData) => {
    const {userName = '', email_check_value, password = '',regiNum='',birth_type='', phone="", birth = '',emailSend='',smsSend='',gender='',hp='',name='',organization='',identity='',zip_code='',addr1='',addr2='',major='' } = formData;
    if(email_check_value=="true"){
    }
    else{
        return swal('이메일 중복체크를 해주세요','','success')
    }
    const params = {
        username: userName, 
        email: userName, 
        password: password,
        name:name,
        nickname:name,
        hp:hp,
        isEmailAlarm:emailSend ? 'y' : 'n',
        isSmsAlarm:smsSend ? 'y' : 'n',
        organization:organization,
        user_num:regiNum,
        identity:identity,
        major:major
    }
    try {
        console.log(params)
        const {data, status} = await api.createUser(params);
        if(status !== 200){
            throw new Error('에러 발생');
        }
        else{
            swal('회원가입이 완료되었습니다.','','success')
            .then((value)=>{
                value&& window.location.replace('/member/register/complete')
            })
        }
    } catch (e){
        swal(e.message,'','warning')
    } finally{

    }
}





const Register = () => {
    const [userData,setUserData] = useState(null);
    const [emailcheckdata,setEmailCheckData] = useState(false);
    const [params,setParams] = useState()
    const [email_id,setEmailId] = useState()
    const [getusers , {data: usersData}] = useLazyQuery(auth.AUTH_EMIAL_CHECK_QUERY,params);
    const email_check = (email) =>{
        console.log(email)
        if(!/^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i.test(email)){
            setEmailCheckData(false);
            return swal('이메일 형식이 아닙니다','','warning')
        }
        if(!email){
            setEmailCheckData(false);
            return swal('이메일을 입력해주세요','','warning')
        }
        //const {fetchPolicy,onCompleted} = params
        setEmailId(email)
        setParams({variables:{"limit": 1,"where": {"email": email}},fetchPolicy: 'no-cache',onCompleted: ({users = []})=>{
            setUserData(users)
        }})
    }
    useEffect(()=>{
        if(!_.isEmpty(params)){
            console.log(params)
            getusers(params)
        }
    },[params])
    useEffect(() => {
        if(!_.isEmpty(email_id)){
            if(!_.isEmpty(userData)){
                swal('이미 가입된 회원입니다','','warning')
                setEmailCheckData(false);
            }
            else{
                swal('사용할 수 있는 이메일입니다','','success')
                setEmailCheckData(true);
            }
        }
    }, [userData])



    //메인 페이지 배너 관련
    //useEffect(() => {
        // console.log(mainbannerData);
    //}, [usersData]);

    //메인 페이지 배너 관련
    //const [getRelationSites, {data: rs}] = useLazyQuery(cQuery.AUTH_EMIAL_CHECK_QUERY);
    //const [relationSites, setRelationSites] = useState([]);

  const dispatch = useDispatch();
  return (
    <div className="content">
        <Helmet title="회원가입"/>
        <Link className="close" title="홈페이지로 이동" to="/"><span className="invisible-btn">홈페이지로 이동</span></Link>
        <h2 className="page_title">회원가입</h2>
            <RegisterForm onSubmit={handleSubmit(dispatch)} email_check={email_check} emailcheckdata = {emailcheckdata}/>
    </div>
  );
};

export default Register;
