import React, {useEffect, useState} from 'react';
import {useRouteMatch, generatePath, Link} from 'react-router-dom';
import useWindowSize from 'utils/useWindowSize';
import AuditButton from 'components/Write/AuditWrite/AuditButton';


const BoardList = ({loading = false, data = [], columns = [], totalCount = 0, user={}, isLink = true, searchData, handleReset, tempData, title, setTitle, prop,setProp}) => {
    const {width: widthSize = window.innerWidth} = useWindowSize();
    const [reload, setReload] = useState(true)
    const match = useRouteMatch()
    const _data = _.compact(data)
    

    return (<>
        <table className={'auditcontent_table'}>
            <thead>
            <tr>
                <th className={'th_header top'} colSpan={4}>강좌 기본정보</th>
                <th className={'th_header top'}>검수 정보</th>
                {_.get(user,'role.id') == '5fed5533258df16ef195ae04' ?
                    <th className={'th_header top'} colSpan={2}>검수 단계</th>
                    :
                    <th className={'th_header top'}>총합 점수</th>
                }
                <th className={'th_header top'} colSpan={2}>검수 상태</th>
            </tr>
            <tr>
                <th className={'th_header bottom'}>개발연도</th>
                <th className={'th_header bottom th_title'}>강좌명</th>
                <th className={'th_header bottom th_title'}>교수</th>
                <th className={'th_header bottom'}>차시</th>
                <th className={'th_header bottom'}>검수 신청일</th>
                {_.get(user, 'role.id') == '5fed5533258df16ef195ae04' ?
                    <>
                        <th className={'th_header bottom'}>검수{/* | 재검*/}</th>
                        <th className={'th_header bottom'}>승인</th>
                    </>
                    :
                    <th className={'th_header bottom'}>총합 점수</th>
                }
                <th className={'th_header bottom'}>검수상태</th>
                <th className={'th_header bottom'}>상태</th>
            </tr>
            </thead>
            <tbody>
                <>
                    {
                        (
                            title || prop ?
                                (
                                    tempData.length == 0 ?
                                        <tr>
                                            <td colSpan="9">해당하는 강좌가 없습니다.</td>
                                        </tr>
                                        :
                                        tempData.map((item, index) => {
                                            return <AuditButton key={index} data={item} user={user} setReload={setReload} reload={reload}/>
                                        })
                                )
                                :
                                data.map((item, index) => {
                                    return <AuditButton key={index} data={item} user={user} setReload={setReload} reload={reload}/>
                                })
                        )
                    }
                </>

            </tbody>
        </table>

    </>);
};

export default BoardList;
