/** 공지사항 상세 */
import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {useParams, useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';

import BreadCrumb from 'components/BreadCrumb';
import route from 'route';
import SubLeftMenu from 'components/SubLeftMenu';
import {BoardDetail} from 'components/Board';

const TYPE = `board-youtubes`;
const YoutubeProgramDetail = () => {
    const dispatch = useDispatch();
    const {view = {}, loading, errorMsg = ''} = useSelector(({content}) => ({...content}));
    const {id} = useParams();
    const history = useHistory();
    const [reload,setReload] = useState(true);
    useEffect(() => {
        dispatch(actions.viewRequest({type: TYPE, id}))
        // history.push('/');
    }, [id,reload])

    //컨텐츠 데이터 로딩 실패시
    useEffect(() => {
        if (!loading && _.isEmpty(view) && errorMsg) {
            history.push('/community/notice');
        }
    }, [view, loading, errorMsg])
    return (
        <div className="onair content introduction">
            <Helmet title="유튜브 원격수업 우수사례"/>
            <BreadCrumb list={route.depth4}/>
            <div className="sub">
                <div className="wrap">
                    <SubLeftMenu
                        title={route.depth4.title}
                        lnbList={route.depth4.list}/>
                    <div className="sub__content introduction_content">
                        <h1 className="sub__title mb_0 introduction_title">
                            <span className="">공지사항</span>
                        </h1>
                        <BoardDetail type={TYPE} data={view} id={id} setReload={setReload} reload={reload}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default YoutubeProgramDetail;
