import React, {useEffect, useMemo, useState} from 'react';
import {Helmet} from "react-helmet";
import {useForm} from "react-hook-form/dist/index.ie11";
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import * as actions from 'store/actions/content';
import moment from 'moment/dist/moment';
import {useTranslation} from 'react-i18next'
import Popup from "reactjs-popup";
import {apiUrl} from "config";
import * as api from 'lib/content';
//components
import {CustomPagination} from 'components/Custom'
import BreadCrumb from 'components/BreadCrumb';
import SubLeftMenu from 'components/SubLeftMenu';
import {BoardHeader, BoardList} from 'components/Board_c';
import {BoardHeader_e, BoardList_e} from 'components/Board_e';
import BoardWrite from 'components/Write/ConsultingWrite';
import DeletePop from 'components/Write/Delete_pop';


import qs from 'qs';
import route from 'route';
const TYPE = `board-consultings`;
const Consulting = (onSubmit) => {
    const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}));
    const dispatch = useDispatch();
    const {search} = useLocation();
    const {page: _page = 0, perPage: _perPage = 10} = qs.parse(search, {ignoreQueryPrefix: true});
    const {t, i18n} = useTranslation()
    const {list = [], totalCount = 0, loading} = useSelector(({content}) => ({...content}));
    const [perPage, setPerPage] = useState(_perPage && +_perPage || 10)
    const [page, setPage] = useState(_page && +_page || 0)
    const [filter, setFilter] = useState({});
    const [params, setParams] = useState({_limit: perPage, _sort: `createdAt:DESC`, _start: page * perPage})
    const [reload, setReload] = useState(false);    //open 상태인가?
    const [comp, setComp] = useState('program');
    const [opendele, setOpenDele] = useState(false);
    const writelist = list;
    const _data = _.compact(list)
    const [selectCheck, setSelectCheck] = useState("");
    const [consultingState,setCounsultingState] = useState(false);
    const [admUser, catchAdmUser] = useState(false);
    const [banState,setBanState] = useState(false);
    let users;
/*

    const state = history.state;

    useEffect(()=>{
        if (!_.isEmpty(state)){
            console.log(state,"1234")
            //setActive(_.get(state, 'comp'));
        }
    }, [state])

*/

    //유저아이디 추출
    if(!_.isEmpty(user)){
       users =  (_.get(user,'id'));
    }

    //관리자인지 접속 확인
    useEffect(() => {
        if(users){
            if(users == "6080cbefb629f15efd7de790"){
                catchAdmUser(true)
            }
            else { catchAdmUser(false) }
        }
    },[users]);


    //선택한 체크박스값 추출
    const selectvalue = e => {
        if (e.target.checked){
            setSelectCheck(e.target.value);
        }
        else {
            setSelectCheck("")
        };
    };


    const { register, handleSubmit, watch, errors, setValue,  clearErrors } = useForm();
    const columns = useMemo(() => [
        {
            Header: "번호", accessor: 'id', className: `text-center`, minWidth: '70px',
            Cell: ({row, idx, totalCount}) => {
                const index = totalCount - (page * perPage) - idx;
                return index > 0 && (<div>{index}</div>) || '-'
            }
        },
        {
            Header: "제목",
            accessor: 'title',
            className: `text-center`,
            width: 'calc(100% - 190px)',
            padding: '0 15px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textAlign: 'left',
            textOverflow: 'ellipsis'
        },
        {
            Header: "신청자", accessor: 'max_people', className: `text-center`, minWidth: '150px',
            Cell: ({row: {author=""}, idx, totalCount}) => (<>{_.get(author,'name')}</>)
        },
        {
            Header: "상태", accessor: 'consulting_status', className: `text-center`, minWidth: '100px',
            Cell: ({row: {consulting_status = false}, idx, totalCount}) => (<div className={consulting_status == false ? 'standby_button':'finish_button'}> {consulting_status == false ? '상담대기':'상담완료'}</div>)
        },
        {
            Header: "등록일", accessor: 'createdAt', className: `text-center`, minWidth: '170px',
            Cell: ({row: {date = new Date, place=''}, idx, totalCount}) => (<>{moment(date).format(`YYYY-MM-DD`)}<p>{place}</p></>)
        },

    ], [perPage, page])

    const columns2 = useMemo(() => [
        {
            Header: "선택", accessor: 'checkbox', className: `text-center`, minWidth: '70px',
            Cell: ({row, idx, totalCount}) => {
                //const index = totalCount - (page * perPage) - idx;
                return <input ref={register({})} type={'checkbox'} name = {`checklist`} value={_.get(row,'id')} />
            }
        },
        {
            Header: "번호", accessor: 'id', className: `text-center`, minWidth: '70px',
            Cell: ({row, idx, totalCount}) => {
                const index = totalCount - (page * perPage) - idx;
                return index > 0 && (<div>{index}</div>) || '-'
            }
        },
        {
            Header: "제목",
            accessor: 'title',
            className: `text-center`,
            width: 'calc(100% - 190px)',
            padding: '0 15px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textAlign: 'left',
            textOverflow: 'ellipsis'
        },
        {
            Header: "신청자", accessor: 'max_people', className: `text-center`, minWidth: '150px',
            Cell: ({row: {author=""}, idx, totalCount}) => (<>{_.get(author,'name')}</>)
        },
        {
            Header: "상태", accessor: 'max_people', className: `text-center`, minWidth: '100px',
            Cell: ({row: {consulting_status = false}, idx, totalCount}) => (<div className={consulting_status == false ? 'standby_button':'finish_button'}> {consulting_status == false ? '상담대기':'상담완료'}</div>)
        },
        {
            Header: "등록일", accessor: 'createdAt', className: `text-center`, minWidth: '170px',
            Cell: ({row: {date = new Date, place=''}, idx, totalCount}) => (<>{moment(date).format(`YYYY-MM-DD`)}<p>{place}</p></>)
        },

    ], [perPage, page])


    const pageCount = Math.ceil(totalCount / perPage);
    const updateParams = (data) => {
        setParams({...params, ...data});
    };

    const handleChange = ({keyword = "", type = ""}) => {

        if (!keyword || !type) {
            setFilter({})
            setParams ({_limit: 10, _sort: `createdAt:DESC`, _start: 0})
            setPage(0)
            return;
        }

        const filters = type.split(',') || [];
        const _filter = {};
        _.forEach(filters, (field, idx) => {
            _filter[`_where[_or][${idx}][${field}]`] = keyword
        })
        setFilter({..._filter})
    }

    const handleReset =() =>{
        setParams ({_limit: 10, _sort: `createdAt:DESC`, _start: 0})
        setPage(0)
        setFilter({})
    }

    //page 이동
    useEffect(() => {
        const {_start, _limit} = params;
        if (_limit !== perPage || _start !== page * perPage) {
            updateParams({...params, _start: page * perPage, _limit: perPage})
        }
    }, [page, perPage])


    useEffect(() => {
        dispatch(actions.listRequest({
            type: TYPE,
            ...params,
            ...filter
        }))
    }, [params, filter]);


    let newlist = [];

    //삭제기능
    const test = () => async(formData) => {
        const {checklist = []} = formData;

        //체크 선택안할 시 삭제 진행x
        try{
            if(_.isEmpty(checklist)){
                throw new Error ("신청 건 선택이 되지 않았습니다.")
            }
            swal({
                title: `정말로 취소하시겠습니까?`, text: '',
                icon: "warning", buttons: {
                    cancle: {
                        text: '닫기',
                        value: false,
                        className: ''
                    },
                    confirm: {
                        text: '취소',
                        value: true,
                        className: 'btn_error'
                    }
                }
            }).then(
                async (value) => {
                    try {
                        let result_data = null;

                        // 취소 누를 경우 빠져나오기
                        if (!value) return;

                        // 삭제 누를 경우 삭제 진행
                        if(!_.isEmpty(checklist)){
                            if(Array.isArray(checklist)){
                                checklist.map(async(item)=>{
                                    result_data = await api.del({type:TYPE,id:item});
                                })
                            }
                            else{
                                result_data = await api.del({type:TYPE,id:checklist});
                            }
                        }
                        swal({
                            title: `취소 완료`, text: '컨설팅 취소가 완료되었습니다.',
                            icon: "success", timer: 3000, buttons: false,
                        })

                        //삭제 후 새로고침
                        window.location.reload();
                        
                    } catch (e) {
                        swal({
                            title: `컨설팅 취소 실패`, text: e.message,
                            icon: "warning", timer: 3000, buttons: false,
                        });
                    } finally {
                        setOpenDele(false);
                    }
                }
            )} catch(e) {
            swal({
                title: `취소할 신청 건을 선택해주십시오`, text: e.message,
                icon: "warning", timer: 3000, buttons: false,
            })
          } finally {
            setOpenDele(false);
        }
    }
    // }}


    //팝업막기
    useEffect(()=>{
        const banWrite = () => {
            try{
                if(banState == true){
                    swal({
                        title: `신청 불가능`, text: '지금은 신청기간이 아닙니다.',
                        icon: "warning", buttons: {
                        cancle: {
                            text: '확인',
                                value: true,
                                className: ''
                        }
                    }
                    }).then(value => {
                            try{
                                setComp('program');
                                setBanState(false);
                            }
                            catch(e){
                                console.log(e);
                            }finally {
                            }
                        }
                    )
                }
            }catch (e){
                console.log(e)
            }finally {
            }
        }
        banWrite();
    },[banState])

    console.log(banState);

    return (
        <div className="onair content introduction">
            <Helmet title="원격수업 컨설팅"/>
            <BreadCrumb list={route.depth4}/>
            <div className="sub">
                <div className="wrap">
                    <SubLeftMenu
                        title={route.depth4.title}
                        lnbList={route.depth4.list}/>
                    <div className="sub__content introduction_content">
                        <h1 className="sub__title introduction_title consulting_headbox">
                            <span className="">원격수업 컨설팅</span>
                        </h1>

                        <ul className={'consulting_detail_button'}>
                            <li className={comp=='program' ? 'active' : ''} onClick={() => setComp('program')}>소개</li>
                            <li className={comp=='notice' ? 'active' : ''} onClick={() => {setComp('notice'),setBanState(true)}}>신청하기</li>
                        </ul>


                        {comp == 'program' ?
                            <div className="consulting_info">
                                <h2 className="consulting_info_title">
                                    <img src="/assets/images/title_logo_green.png" title="logo"/> 목적
                                </h2>
                                <p className="consulting_info_box">
                                    광주교육대학교를 통한 컨설팅 실시로 대학의 수업 전문성 신장
                                </p>

                                <h2 className="consulting_info_title">
                                    <img src="/assets/images/title_logo_green.png" title="logo"/> 대상
                                </h2>
                                <p className="consulting_info_box">
                                    광주교육대학교 내 대학 전임교원 및 강사
                                </p>

                                <h2 className="consulting_info_title">
                                    <img src="/assets/images/title_logo_green.png" title="logo"/> 수업 컨설팅 접수방법
                                </h2>
                                <p className="consulting_info_box">
                                    <span style={{fontWeight:"700" }}>- 신청시기</span> : 추후 공지 <br/>
                                    <span style={{fontWeight:"700"}}>- 신청방법</span> : 광주교육대학교 통합 누리집(gnue2.gesturecloud.io)을 통해 신청
                                </p>

                                <h2 className="consulting_info_title">
                                    <img src="/assets/images/title_logo_green.png" title="logo"/> 기대 효과
                                </h2>
                                <p className="consulting_info_box">
                                    광주교육대학교 교수자의 상황, 행동, 관행, 효율성, 성취 점검을 통한 성찰 기회 제공
                                </p>
                            </div>
                            :
                            <div className = "consulting_board_box">
                                <div className="board">
                                    <BoardHeader totalCount={totalCount}
                                                 title={'원격수업 컨설팅'}
                                                 onReset={handleReset}
                                                 onChange={handleChange}/>
                                    <BoardList
                                        totalCount={totalCount}
                                        loading={loading}
                                        data={list}
                                        user={user}
                                        columns={columns}
                                        setCounsultingState={setCounsultingState}
                                        consultingState={consultingState}
                                        admUser={admUser}
                                        users={users}
                                    />
                                    <CustomPagination
                                        pageSize={perPage}
                                        pageIndex={page}
                                        pageCount={pageCount}
                                        onPageChange={({selected}) => setPage(selected)}/>
                                    <div className={'submit_container'}>
                                   {/*     {*/}
                                   {/*     !_.isEmpty(_.get(user,'role')) &&*/}
                                   {/*     _.get(user,'role.id') == '5fed5533258df16ef195ae04' &&*/}
                                   {/*     <BoardWrite*/}
                                   {/*         setReload={setReload}*/}
                                   {/*         reload={reload}*/}
                                   {/*         type={TYPE}*/}
                                   {/*         trigger={<button>신청하기</button>}*/}
                                   {/*         title={"원격수업 컨설팅"}*/}
                                   {/*         user={user}*/}
                                   {/*     />*/}
                                   {/*}*/}
                                    </div>
                                </div>
                                <div className="consulting_button_box">
                                    <div className="application_form_button">신청서 양식</div>
                                    {user && banState == true ?
                                        <BoardWrite
                                            setReload={setReload}
                                            reload={reload}
                                            loading={loading}
                                            type={TYPE}
                                            trigger={ <button className="application_button" onClick={()=>{setBanState(true)}}>신청하기</button> }
                                            title={"원격수업 컨설팅"}
                                            user={user}
                                        />
                                        : <></>
                                    || <></> }

                                    {user &&
                                     <button className="application_cancel" onClick={()=>{setOpenDele(true)}}>신청 취소하기</button>
                                    || <></> }
                                </div>

                                { opendele == true ?
                                    <DeletePop
                                        columns2={columns2}
                                        test={test}
                                        handleSubmit={handleSubmit}
                                        totalCount={totalCount}
                                        handleReset={handleReset}
                                        handleChange={handleChange}
                                        loading={loading}
                                        list={list}
                                        user={user}
                                        selectCheck={selectCheck}
                                        setOpenDele={setOpenDele}
                                        admUser={admUser}
                                        users={users}
                                    />
                                    :
                                    <></>
                                }


                                {/*<div className={opendele == true ? "delete_pop" : "delete_pop display_none"}  >*/}
                                    {/*<BoardHeader totalCount={totalCount}*/}
                                    {/*             title={'원격수업 컨설팅'}*/}
                                    {/*             onReset={handleReset}*/}
                                    {/*             onChange={handleChange}/>*/}
                                    {/*<BoardList*/}
                                    {/*    totalCount={totalCount}*/}
                                    {/*    loading={loading}*/}
                                    {/*    data={list}*/}
                                    {/*    user={user}*/}
                                    {/*    columns={columns}/>*/}
                                    {/*<CustomPagination*/}
                                    {/*    pageSize={perPage}*/}
                                    {/*    pageIndex={page}*/}
                                    {/*    pageCount={pageCount}*/}
                                    {/*    onPageChange={({selected}) => setPage(selected)}/>*/}
                                {/*    <div className="delete_close" onClick={()=>{setOpenDele(false)}}>닫기</div>*/}
                                {/*</div>*/}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Consulting;