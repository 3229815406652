/** 프로그램 신청 상세 팝업*/
import React, {useEffect, useState, useCallback} from 'react';
import Popup from "reactjs-popup";
import {useDispatch, useSelector} from "react-redux";
import {CustomLoading} from "components/Custom";
import {showLoading, hideLoading} from 'react-redux-loading-bar'
import moment from 'moment/dist/moment';

import WriteForm from './WriteForm';
import * as actions from 'store/actions/content';
import * as api from 'lib/content';

//graphql 관련
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import * as aQuery from "queries/program";
import { APPLY_STATUS_CATEGORIES_QUERY } from '../../../queries/program';


const handleSubmit = ({type,popupClose,user,mode,setReload,reload,id,parent_id,default_id=''}) => async (formData) => {
    const {name,user_num,organization,email,hp} = formData
    const title = `${name} / ${user_num} / ${organization}`
    const params = {title,hp,email,apply_complete:'notcompleted',
            appied_program:id,apply_status:'5ff6b769bf55b944295d63e8',apply_user:_.get(user,'id'),program_type:'workshop'}
    try {
        let result_data = null;
        if(mode == ''){
            result_data =  await api.create({type:type,...params});
        }
        if (mode == 'd'){
            result_data = await api.update({type:type,id:default_id,...params});
        }


        const {data, status} = result_data;
        if(status !== 200){
            throw new Error('에러 발생');
        }
        swal({
            title: '프로그램 신청 완료', text:'프로그램 신청이 완료되었습니다.',
            icon: "success", timer: 3000, buttons: false,
        });
    } catch (e) {
        //console.log(e, program_type+' 신청/수정 실패')
        // 수강 신청 실 프로세스
        swal({
            title: `프로그램 등록/수정 실패`, text: e.message,
            icon: "warning", timer: 3000, buttons: false,
        });
    } finally {
        //dispatch(hideLoading())
        //팝업 닫기
        setReload(!reload)
        popupClose && popupClose();
    }
}

const handleCancel = ({dispatch,popupClose,applyStatusList,applyStatus,apply_id,onSuccess,onFailure,setModeTitle,type}) => async ()=> {


    popupClose && popupClose();
    /*let changeStatus = null;
    applyStatusList.map((item,idx)=>{
        if(item.apply_status_category_title == 'apply_cancael_completed'){
            changeStatus = item.id
        }
    })
    const program_type = (type == 'study-program-applies') ? '프로그램' : '지원사업'
    const params = {apply_status:{_id:changeStatus}};
    dispatch(showLoading())
    try {
        /!*swal({
            title: `정말로 취소하시겠습니까?`,
            icon: "warning", timer: 3000, buttons: {
                cancle:{
                    text:"돌아가기",
                    value:false
                },
                confirm:{
                    text:"수강취소",
                    value:false
                }
            }
        }).then((value) => {
            if(value == true){
               return window.location.reload();
            }
            else{
            }
        });*!/

        const {data, status} = await api.update({type:type,id:apply_id,...params});
        setModeTitle(program_type+'신청 취소')
        onFailure && onFailure();
        if(status !== 200){
            throw new Error('에러 발생');
        }
        else{
            onSuccess && onSuccess();
        }
    } catch (e) {
        console.log(e, program_type+'신청 취소 실패')
        // 수강 신청 실 프로세스
        swal({
            title: program_type+`신청 취소 실패`, text: e.message,
            icon: "warning", timer: 3000, buttons: false,
        });
        onFailure && onFailure();
    } finally {
        dispatch(hideLoading())
        //팝업 닫기
    }*/
}




const WritePopup = ({trigger, title='', id = '',parent_id='', mode = '', type='',onSuccess=()=>{}, onFailure=()=>{}, setModeTitle='',applis,reload,setReload,user='',data={}}) => {
        const now = new Date();
        //const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}));// 로그인 한 유저 정보
        /*const {id:userId ,username} =user;*/
        const dispatch = useDispatch();
        //const [loading, setLoading] = useState(true);   //데이터 로딩중인가?
        const [open, setOpen] = useState(false);    //open 상태인가?
        const {user_, isLoggedIn} = useSelector(({auth}) => ({...auth}))
        return (
            <Popup
                trigger={trigger}
                position="right center"
                onOpen={() => setOpen(true)}
                onClose={() => {
                    //setLoading(true)
                    setOpen(false)
                }}
                modal
                closeOnDocumentClick={false}>
                {popupClose => {
                    //if (loading) return null
                    return (<div className="popup_overlay">
                        <div className="popup_content workshop">
                            <div className="program_popup">

                                <div className="modal_header">
                                    <h5 className="modal_title">
                                        {title}
                                        {/*

                                        {
                                            (_.get(user_, 'role._id') == '5fed5533258df16ef195ae04') ?
                                                {title}{(mode == '' || mode == 'm') && '답변 작성하기' || '수정하기'}
                                            :
                                        {title}{(mode == '' || mode == 'm') && '작성하기' || '수정하기'}

                                        }
*/}


                                    </h5>
                                    <button onClick={popupClose} type="button" className="overlay_close_x close">
                                        {/*<button onClick={close} type="button" className="overlay_close_x close">*/}
                                        <span className="element-invisible" aria-hidden="true">X</span>
                                    </button>
                                </div>
                                <div className="modal_body">
                                    <WriteForm
                                        data={data}
                                        type={type}
                                        handleCancel={popupClose}
                                        reload={reload}
                                        setReload={setReload}
                                        cancelSubmit={()=>console.log('취소')}
                                        onSubmit={handleSubmit({type,popupClose,user,mode,setReload,reload,default_id:id,id:_.get(data,'id'),parent_id})}
                                        user={user}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>)
                }}
            </Popup>

        )
    }
;

export default WritePopup;
