import React from 'react';
import {Helmet} from "react-helmet";

const Result = () => {
    return (
    <div className="introduction content">
        <Helmet title="역량 진단평가 결과"/>
        {/*<BreadCrumb list={route.depth1} />*/}
        <div className="sub">
            <div className="wrap">
                {/*<SubLeftMenu*/}
                {/*    title={route.depth1.title}*/}
                {/*    lnbList={route.depth1.list}*/}
                {/*/>*/}
                <div className="sub__content introduction_content result_content">
                    <h1 className="sub__title introduction_title">
                        <span className="">역량 진단평가 결과</span>
                    </h1>
                    {/*<p className="introduction__title">*/}
                    {/*  빛나는 작은아이디어가 <span>글로벌 콘텐츠</span>가 되는 그날까지*/}
                    {/*</p>*/}

                    {/*<div className="introduction__desc">*/}
                    {/*    <img src="/assets/images/cks.png" alt=""/>*/}
                    {/*    /!*<p>*!/*/}
                    {/*    /!*  시작의 목소리에 귀 기울이고, 성장하는 전남형 인재육성 및 지역의 문제를 해결하는*!/*/}
                    {/*    /!*  4차 산업혁명시대 선도 콘텐츠 비즈니스 육성*!/*/}
                    {/*    /!*</p>*!/*/}
                    {/*</div>*/}

                    <div className="introduction__info">
                        <div>
                            페이지 준비중 입니다.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default Result;
