// 프로그램 신청
import React, {useEffect, useMemo, useState, useRef} from 'react';
import BreadCrumb from '../../components/BreadCrumb';
import route from '../../route';
import {programlist} from '../../data';
import ProgramList from '../../components/ProgramList';
import Pagination from '../../components/Pagination';
import {useRouteMatch, generatePath, Link, useHistory} from 'react-router-dom';
import SubLeftMenu from '../../components/SubLeftMenu';
import {useTranslation} from 'react-i18next'
import {Helmet} from "react-helmet";
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';
import moment from 'moment/dist/moment';
import {CustomPagination} from 'components/Custom';
import {apiUrl} from "config";
import AuditWrite from 'components/Write/AuditWrite';
import AuditButton from 'components/Write/AuditWrite/AuditButton';
import ContentsSlide from '../../components/Slide/ContentsSlide';
import {BoardList} from 'components/Board_a';

const TYPE = 'audit-contents';
const AuditContent = ({
                          fields_result = [
                              {title: '전체', value: ''},
                              {title: '승인', value: 'audit_end'},
                              {title: '재검토', value: 'audit_start'}
                          ],
                          fields_year = [
                              {title: '전체', value: 'year'},
                              {title: '2020', value: '2020'},
                              {title: '2021', value: '2021'}
                          ],
                          fields_num = [
                              {title: '전체', value: 'audit_num'},
                              {title: '13', value: '13'},
                              {title: '26', value: '26'}
                          ]
                      }) => {
    const dispatch = useDispatch();

    const [perPage, setPerPage] = useState(12)
    const [reload, setReload] = useState(true)
    const [page, setPage] = useState(0)
    const [auditData, setAuditData] = useState([])
    const {list = [], totalCount = 0, loading} = useSelector(({content}) => ({...content}));
    const [params, setParams] = useState({_limit: perPage, _sort: `createdAt:desc`, _start: page * perPage})
    const {user, isLoggedIn, loading:userLoading} = useSelector(({auth}) => ({...auth}));
    const [videoSlide,setVideoSlide] = useState(false);
    const history = useHistory();
    const [eventClick,setEventClick] = useState(false);
    const [programData,setProgramData] = useState({});
    const [title, setTitle] = useState();
    const [prop, setProp] = useState();
    const [status, setStatus] = useState('');
    const [year, setYear] = useState('');
    const [audit_num, setAudit_num] = useState('');
    const [tempData, setTempData] = useState([]);


    //영상비디오 출력
    useEffect(()=>{ const showVideo =()=>{
        setVideoSlide(true)
    }},[])

    /*///////검색기능 추가 /////////*/
    const [filter, setFilter] = useState({});
    const [searchParams, setSearchParams] = useState({type:'audit-contents',_limit: perPage, _sort: `createdAt:desc`, _start: page * perPage})
    const [keyword, setKeyword] = useState([]);

    const {t} = useTranslation()

    const updateParams = (data) => {
        setSearchParams({...searchParams, ...data});
    };



    const [radioBT,setRadioBT] = useState('audit_title');
    const onChangeText = () => {
        if (event.target.value && event.target.value == 'audit_title') {
            setRadioBT('audit_title')
        }else if (event.target.value && event.target.value == 'audit_prop'){
            setRadioBT('audit_prop')
        }
    }
    useEffect(() => {
        setTempData(auditData.filter(item => item.audit_title.includes(event.target.value)))
    },[title])

    useEffect(() => {
        setTempData(auditData.filter(item => item.audit_prop.includes(event.target.value)))
    },[prop])



    // 페이지 이동
    useEffect(() => {
        const {_start, _limit} = params;
        if (_limit !== perPage || _start !== page * perPage) {
            updateParams({...params, _start: page * perPage, _limit: perPage})
        }
    }, [page, perPage])

    useEffect(() => {dispatch(actions.listRequest({type: TYPE, ...searchParams, ...filter}))
        //console.log(list)
    }, [filter,reload])

    const searchData = () => {
        dispatch(actions.listRequest({type: TYPE, ...searchParams, ...filter}))
    }


    const onChangeSelect = (e) => {
        const {value, name} = e.target;
        if (name == "status"){
            setFilter({...filter, "_where[0][status]": e.target.value})
            setStatus(e.target.value)

        }
        else if (name == "year"){
            setFilter({...filter, "_where[0][year]": e.target.value})
            setYear(e.target.value)
        }
        else if (name == "audit_num"){
            setFilter({...filter, "_where[0][audit_num]": e.target.value})
            setAudit_num(e.target.value)
        }
    }


    const handleReset =() =>{
        setTitle('')
        setProp('')
        setStatus('')
        setYear('')
        setAudit_num('')
        setSearchParams({_limit: 10, _sort: `createdAt:DESC`, _start: 0})
        setPage(0)
        setFilter({})
    }

///////////////////////////////////////

    useEffect(() => {
        if (!_.isEmpty(list)) {
            setAuditData(list)
        }
    }, [list])

    /*useEffect(() => {
        dispatch(actions.listRequest({type:"audit-contents", ...params}))
    }, [reload])*/

    useEffect(()=>{
        if(!userLoading){
            if(_.isEmpty(user)){
                swal({title: '접근 권한이 없습니다.', text: '관리자만 접근할 수 있습니다.', icon: "warning", timer: 2000}).then(
                    ()=>{
                        history.push(`/`);
                    }
                );
            }
            else{
                if(_.get(user,'role.id') != '5fed5533258df16ef195ae04' && _.get(user,'role.id') != '602a265ab2b9050bdac24bcd'){
                    swal({title: '접근 권한이 없습니다.', text: '관리자만 접근할 수 있습니다.', icon: "warning", timer: 2000}).then(
                        ()=>{
                            history.push(`/`);
                        }
                    );
                }
            }
        }
    },[userLoading]);



    return (
        <>
            <div className="program content introduction">
                <Helmet title="품질관리 시스템"/>
                <BreadCrumb list={route.depth3}/>
                <div className="sub">
                    <div className="wrap">
                        <SubLeftMenu
                            title={route.depth3.title}
                            lnbList={route.depth3.list}
                        />
                        <div className="sub__content introduction_content">
                            <h1 className="sub__title introduction_title">
                                <span className="">품질관리 시스템</span>
                            </h1>
                            <ul className="tabs audittab">
                                <li className={'auditcate active'}><span>전체강좌</span></li>
                                {/*<li><span>정규강좌</span></li>
                                <li><span>비정규강좌</span></li>*/}
                            </ul>
                            <div className={'search_container'}>
                                <div className="leftBox">
                                    {/*<div className={'search_select'}>*/}
                                    {/*    사전접수*/}
                                    {/*    <select>*/}
                                    {/*        <option>*/}
                                    {/*            전체*/}
                                    {/*        </option>*/}
                                    {/*    </select>*/}
                                    {/*</div>*/}
                                    <div className={'search_select'}>
                                        <div className="leftBox">
                                            검수단계
                                        </div>
                                        <div className="rightBox">
                                            <select name="status" onChange={onChangeSelect} value={status}>
                                                {/*<select onChange={e => setSearchParams({...searchParams, "_where[0][status]":e.target.value})}>*/}
                                                {fields_result.map((option, idx) => {
                                                    const {title = '', value = ''} = option;
                                                    return (<option key={idx} value={value}>{title}</option>)
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className={'search_select'}>
                                        <div className="leftBox">
                                            개발연도
                                        </div>
                                        <div className="rightBox">
                                            <select name="year" onChange={onChangeSelect} value={year}>
                                                {/*<select onChange={e => setSearchParams({...searchParams, "_where[0][year]":e.target.value})}>*/}
                                                {fields_year.map((option, idx) => {
                                                    const {title = '', value = ''} = option;
                                                    return (<option key={idx} value={value}>{title}</option>)
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className={'search_select'}>
                                        <div className="leftBox">
                                            총 차시
                                        </div>
                                        <div className="rightBox">
                                            <select name="audit_num" onChange={onChangeSelect} value={audit_num}>
                                                {/*<select onChange={e => setSearchParams({...searchParams, "_where[0][audit_num]": e.target.value})}>*/}
                                                {fields_num.map((option, idx) => {
                                                    const {title = '', value = ''} = option;
                                                    return (<option key={idx} value={value}>{title}</option>)
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="rightBox">
                                    <div className={'search_radio'}>
                                        <div className="leftBox">
                                            강좌정보
                                        </div>
                                        <div className="rightBox">
                                            <label>
                                                <input type={'radio'} checked={radioBT=='audit_title' ? true:false} name={'audit_info'} onClick={() => onChangeText()} value="audit_title"/>강좌 명
                                            </label>
                                            <label>
                                                <input type={'radio'} name={'audit_info'} onClick={() => onChangeText()} value="audit_prop"/>교수자
                                            </label>
                                        </div>
                                    </div>
                                    <div className={'search_form'}>
                                        {
                                            radioBT == "audit_title" ?
                                                <input type={'text'} value={title} onChange={e => setTitle(event.target.value)}/>
                                                :
                                                <input type={'text'} value={prop} onChange={e => setProp(event.target.value)}/>
                                        }
                                        <button onClick={searchData}><img src="/assets/images/system_search_button.png" alt="검색버튼"/></button>
                                        <button className="reset__btn" onClick={handleReset}>초기화</button>
                                    </div>
                                </div>

                            </div>
                            <div className={'write_container'}>
                                {/*<AuditWrite title={'검수 결과'} trigger={
                                    <button>
                                        새로운 강좌 작성
                                    </button>
                                } type={'write'}/>*/}
                                <AuditWrite title={'검수 신청서 신규작성'}
                                            setReload={setReload}
                                            reload={reload}
                                            trigger={
                                                <button className={'write_button'}>
                                                    검수 신청서 신규작성
                                                </button>
                                            } type={'write'}/>
                            </div>

                            <div className="auditBoxCont">
                                <BoardList data={auditData}
                                           user={user}
                                           searchData={searchData}
                                           handleReset={handleReset}
                                           title={title}
                                           setTitle={setTitle}
                                           prop={prop}
                                           setProp={setProp}
                                           tempData={tempData}/>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                eventClick == true ?
                    <ContentsSlide
                        setEventClick = {setEventClick}
                        programData = {programData}
                    /> : <></>
            }
        </>
    );
};

export default AuditContent;
