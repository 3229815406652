import React, {useEffect, useMemo, useState} from 'react';
import {useRouteMatch, generatePath, Link} from 'react-router-dom';
import {apiUrl} from 'config';
import moment from 'moment/dist/moment';
import Popup from "reactjs-popup";
import * as api from 'lib/content';

const ProgramItem = ({data = {},
                         item={},
                         path,
                         user,
                         admUser,
                         setProgram,
                         setEventClick,
                         eventclick,
                         programName,
                         dataName,
                         setProgramData,
                         studyNumbers,
                         setProgramNumber,
                         idx,
                         programData,
                         activeCount,
                         setStudyProgramNumber,
                         studyNumber,
                         setClickedproid,
                         percent,
                         setPercent,
                     }) => {

    const [parmas, setParmas] = useState({});
    // const [eventclick, setEventClick] = useState(false);

    const [allStudy,setAllStudy] = useState([]);
    const {
        id = '',
        title = '',
        allowednumber = null,
        apply_startdate = new Date(),
        apply_allowednumber = 0,
        apply_maxnumber = 0,
        apply_enddate = new Date(),
        learning_enddate = new Date(),
        learning_startdate = new Date(),
        maxnumber = null,
        thumbnailimages = {},
        study_program_applies = [],
        program_address = "",
        props = "",
        dev_org = "",
        chapter = "",
        cw_uuid = ""
    } = data;
    const today = moment().format(`YYYY-MM-DD`);
    const d_day = moment(apply_enddate).diff(today, 'days');
    const applied = applied_num + applied_over;
    const apply_started = moment(apply_startdate).format(`YYYY-MM-DD`);
    const apply_ended = moment(apply_enddate).format(`YYYY-MM-DD`);
    const learning_ended = moment(learning_enddate).format(`YYYY-MM-DD`);
    const learning_started = moment(learning_startdate).format(`YYYY-MM-DD`);
    const applied_num = study_program_applies.filter(item => item.apply_status == "5ff6b769bf55b944295d63e8").length  // 수강 신청 완료 상태 개수
    const applied_over = study_program_applies.filter(item => item.apply_status == "5ff6b774bf55b944295d63e9").length // 수강 신청 완료(정원 초과) 개수
    const [reload,setReload] = useState(true);
    const [paramsGrade, setParamsGrade] = useState({ })
    const [percentNum, setPercentNum] = useState(0);
    const [courseCount, setCourseCount] = useState(0)
    const InnerData = ({data = {}}) => {
        console.log(data)
        return (
            <>
                {
                    activeCount <= 90 ?

            <div className="programList__item">
                {/*{(d_day > 0 && d_day < 7) && <span className="d_day">D-{d_day}</span>}*/}
                <div className="thumb" >
                    <Link to={`/content/${_.get(data,'id')}`}>
                        <img src={!_.isEmpty(_.get(data, 'thumbnail')) ? _.get(data, 'thumbnail') : `${apiUrl}${_.get(data, 'thumbnailimages.0.url')}`}  alt=""/>
                    </Link>
                </div>
                <div className="info">
                    <div className="playTime">
                        <span className="mini_title">진행률</span> <span className="playTime_value">{_.get(item,'check_true',0)}/{_.get(item,'all_block_count',0)}</span>
                    </div>
                    <div className='info_div_one'>
                        <div>

                            {/*<p className='div_one_p'> 신청가능 </p>*/}
                            <h2>
                                <Link to={`/content/${_.get(data,'id')}`}>
                                    {_.get(data,'title')}
                                </Link>
                            </h2>
                            {
                                (!_.isEmpty(_.get(data,'dev_org')) && (!_.isEmpty(_.get(data,'props'))) ?
                                        <>
                                            {/*<img src='/assets/images/info_date.png'/>*/}
                                            {_.get(data,'props')} | {_.get(data,'dev_org')}
                                        </>
                                        : (!_.isEmpty(_.get(data,'dev_org'))) ?
                                            <>
                                                {/*<img src='/assets/images/info_date.png'/>*/}
                                                {_.get(data,'dev_org')}
                                            </>
                                            : (!_.isEmpty(_.get(data,'props'))) &&
                                            <>
                                                {/*<img src='/assets/images/info_date.png'/>*/}
                                                {_.get(data,'props')}
                                            </>
                                )
                            }
                            <div className="chapter_prop">
                                {_.get(data,'chapter')}
                            </div>
                        </div>

                        <div className="bottom_button_box">
                            <button className="left_button_confirm left_active" onClick={() => {setEventClick(true),setClickedproid(_.get(data, 'cw_uuid')), setProgram(title)}}>
                                {/*<Link to="/member/mycontent/progressRate">*/}
                                    학습진도현황 상세확인
                                {/*</Link>*/}
                            </button>

                            <div className="left_button_course">
                                <a href={`https://eclass-lms.seoyeong.ac.kr/courses/${cw_uuid}/home`} target="_blank">
                                    학습하기
                               </a>
                            </div>
                        </div>

                        {/*<div className="bottom_button_box">*/}
                        {/*    {}*/}
                        {/*    <button className="left_button">*/}
                        {/*        /!*{`/content/${id}`}*!/*/}
                        {/*        <Link to="#">*/}
                        {/*            강의만족도 설문*/}
                        {/*        </Link>*/}
                        {/*    </button>*/}
                        {/*    <button className="right_button" >*/}
                        {/*        <Link to="#">*/}
                        {/*            이수증출력*/}
                        {/*        </Link>*/}
                        {/*      </button>*/}
                        {/*</div>*/}

                    </div>
                    {/*<div>*/}

                    {/*    /!*{dev_org && props && (*/}
                    {/*        <p className="date">*/}
                    {/*            <img src='/assets/images/info_date.png'/>*/}
                    {/*            {apply_started} ~{apply_ended}*/}
                    {/*            {dev_org} | {props}*/}
                    {/*        </p>) || <>*/}
                    {/*    <p><p className="date"><img src='/assets/images/info_date.png'/></p></p>*/}
                    {/*    </> }*!/*/}

                    {/*    /!*<span className={`status ${1 === 2 && 'disabled'}`}>*/}
                    {/*    {(today < apply_ended && today > apply_started) ? '신청가능' : '신청마감'}*/}
                    {/*    <span className={`status ${1 === 2 && 'disabled'}`}>*/}
                    {/*        진행 중*/}
                    {/*    {(today < apply_ended && today > apply_started) ? '신청가능' : '신청마감'}*/}
                    {/*</span>*!/*/}
                    {/*</div>*/}
                </div>



                {/* 호버 레이아웃 */
                }
                <div className="hoverLayout">
                    <ul>
                        <li>
                            신청기간 : 상시
                        </li>
                        <li>
                            진행기간 : 상시
                        </li>
                    </ul>
                    {/*<p>View more</p>*/}
                    {/*<h3>{title}</h3>
                        <ul>
                        <li>
                        신청기간 : 상시
                        </li>
                        <li>
                        진행기간 : 상시
                        </li>
                    {/*<li>
                            장소 : {program_address}
                        </li>*/}
                    {/*<li>
                            신청 / 정원 : {apply_allowednumber} / {apply_maxnumber}
                        </li>
                        </ul>*/}
                </div>



                {/*/!* 팝업 *!/*/}
                {/*<div className = {eventclick == true ? "Mycontent_pop" : "Mycontent_pop disnone" }>*/}
                {/*    <div className ="inner_box">*/}
                {/*        {title} <br/>*/}
                {/*        { id }<br/><br/>*/}
                {/*        <div className="popClose" onClick={() => {setEventClick (false)}}>닫기</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*/!* 팝업 *!/*/}

            </div>
                :<></>
                }
           </>


        )
    }

    return (
                <InnerData data={data}/>

    );
};

export default ProgramItem;