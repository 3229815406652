import React, {useEffect, useState, useCallback} from 'react';
import {useForm} from "react-hook-form/dist/index.ie11";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import * as api from 'lib/content';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/users';
import {CustomPagination} from 'components/Custom';

const defaultValues = "";

const EmailSend = () => {
    const { register, handleSubmit, watch, errors, setValue,reset,onChange,clearErrors } = useForm({defaultValues});
    const onSubmit = async(data, e) => {
    const {email,sendemail,texta,title} = data;
         try{
              const parames = {
                  to:[`${email}`],
                  from: `${sendemail}`,
                  subject: `${title}`,
                  text: "Hello world!",
                  html: `${texta}`,
              }

                const {status} = await api.emailSend({...parames });
                console.log("전송완료");
                console.log(status);
            }
          catch(e){
                console.log(e)
            }
          finally {

            }

    }




    return (
        <div>
            <Helmet title="테스트 페이지"/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>

            <form onSubmit={handleSubmit(onSubmit)}>
                <p>받는 이메일</p>
                <input name="email" ref={register} />

                <p>보내는 이메일</p>
                <input name="sendemail" ref={register} />

                <p>제목</p>
                <input name="title" ref={register} />

                <p>내용</p>
                <textarea name="texta" ref={register} />

                <p>
                  <button title="이메일발송" type="submit">
                     이메일 발송
                  </button>
                </p>

            </form>

            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>

        </div>
    );
};

export default EmailSend;
