//검수 신청서 작성
import React, {useEffect, useState, useCallback,useMemo} from 'react';
import {useForm} from "react-hook-form/dist/index.ie11";
import moment from 'moment/dist/moment';
import * as api from 'lib/content';
import route from 'route';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';
import Quill from "../../Editor/Quill";
import {apiUrl} from "config";
import {question_origin} from 'surveyData'

const defaultValues = {
    appied_program: {
        _id:'',
    },
    attached_files:[]
};


const AuditForm = ({
                       onSubmit,
                       data,
                       mode = '',
                       type,
                       handleCancel,
                       cancelSubmit,
                       apply_files,
                       reload,
                       bigo,
                       setReload,
                       user = '',
                       onDelete
                   }) => {
    const [comp, setComp] = useState('design')
    const { register, handleSubmit, watch, errors, setValue,reset,onChange,clearErrors } = useForm({defaultValues});

    const [question, setQuestion] = useState(question_origin)
    const [logData, setLogData] = useState([])
    const [reply, setReply] = useState('');
    const [replyState, setReplyState] = useState('');

    useEffect(()=>{
        console.log('질문지가 바뀜');
        console.log(bigo);
    },[question])
    

    useEffect(()=>{
        if(!_.isEmpty(_.get(data,'answer_data'))){
            setQuestion(_.get(data,'answer_data'))
        }
    },[data]);

    useEffect(()=>{
        !_.isEmpty(errors) ?
            swal({
                title: '입력하지 않은 항목이 있습니다.', text: '입력하지 않은 항목이 있습니다.',
                icon: "warning", timer: 3000, buttons: false,
            })
            :
            console.log('에러 없음')
    },[errors])


    useEffect(()=>{
        async function getLogs() {
            try{
                const {data:log_data,
                    status:log_status} = await api.gets({type: 'audit-logs',
                    audit_content:_.get(data,'id'),
                    _sort:"createdAt:desc"
                });
                console.log(log_data[0])
                setLogData(log_data[0])
                if(!_.isEmpty(_.get(log_data,'0.answer_data'))){
                    setQuestion(_.get(log_data,'0.answer_data'));
                }
                if(!_.isEmpty(_.get(log_data,'0.audit_reple'))){
                    setReply(_.get(log_data,'0.audit_reple'))
                }
                //setValue('audit_result',_.get(log_data,'0.audit_status'))
            }
            catch (e) {
                swal({
                    title: `로그데이터를 받아오지 못했습니다.`, text: e.message,
                    icon: "warning", timer: 3000, buttons: false,
                });
            } finally {
            }
        }
        getLogs()
    },[data]);


    //선택지 초기화
    const onReset = async() => {
            try{
                swal({
                    title: '선택지 초기화', text: '평가한 내역을 초기화하시겠습니까?',
                    icon: "warning", buttons: {
                        cancle: {
                            text: '취소',
                            value: false,
                            className: ''
                        },
                        confirm: {
                            text: '초기화 진행',
                            value: true,
                            className: 'btn_error'
                        }
                    },
                }).then ( async (value) => {
                    if(value == true) {
                        setQuestion(question_origin);
                        setReply('');
                    }else{
                        return
                    }
                });

            }catch(e){
                console.log(e)
            }finally {
            }
        }

        console.log(question_origin);


        //검수자의견 탭메뉴 바꿔도 변경되지 않게 만들기
        const quillBoard = useMemo(()=>{
          return(
                <>
                  {!_.isEmpty(logData) ?
                    <Quill initialValue={reply} onChange={(content, delta, source, editor) => {
                        if (errors.bigo && errors.bigo.type === 'required') {
                            clearErrors('bigo');
                        }
                        if (editor.getLength() === 1) {
                            setValue('bigo', '');
                        } else {
                            setValue('bigo', content)
                        }} }
                    />
                    :
                    <Quill initialValue={''} onChange={(content, delta, source, editor) => {
                        if (errors.bigo && errors.bigo.type === 'required') {
                            clearErrors('bigo');
                        }
                        if (editor.getLength() === 1) {
                            setValue('bigo', '');
                        } else {
                            setValue('bigo', content)
                        }} }
                    />
                  }
                          <div style={{display: 'none'}}>
                          <textarea initialValue={_.get(logData, 'audit_reple')} name={'bigo'} ref={register()} />
                        </div>
                </>
          )
        },[_.get(logData, 'audit_reple'), logData,reply])


    return (

        // 검수버튼 누르면 나오는 팝업
        <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className={'audit_top'}>
                    <h3 className={'write_title'}>검수 강좌 기본 정보</h3>
                    <span className={'write_info'}>검수할 강좌의 기본 정보를 조회합니다.</span>
                </div>
                <div className={'audit_middle'}>
                    <div className={'Audit_Form'}>
                        <span className="auditName">강좌명</span>
                        <div className={'audit_text'}>{_.get(data,'audit_title')}</div>
                    </div>
                    <div className={'Audit_Form'}>
                        <span className="auditName">개발기관</span>
                        <div className={'audit_text'}>{_.get(data,'audit_org')}</div>
                    </div>
                    <div className={'Audit_Form'}>
                        <div className={'calander_select'}>
                            <div className="Audit_form_box_left">
                                <span className="auditName">개발연도</span>
                                <div className={'audit_subtext'}>{_.get(data,'year')}</div>
                            </div>
                            <div className="Audit_form_box_right">
                                <span className={'auditName'}>총 차시</span>
                                <div className={'audit_subtext'}>{_.get(data,'audit_num')}</div>
                            </div>
                        </div>
                    </div>
                    <div className={'Audit_Form'}>
                        <div className={'calander_select'}>
                            <div className="Audit_form_box_left">
                                <span className="auditName">강좌분류</span>
                                <div className={'audit_subtext'}>공동 활용 콘텐츠</div>
                            </div>
                            <div className="Audit_form_box_right">
                                <span className={'auditName'}>제출일자</span>
                                <div className={'audit_subtext'}>{moment(_.get(data,'createdAt')).format('YYYY.MM.DD')}</div>
                            </div>
                        </div>
                    </div>

                    {/*다운로드파일 확인*/}
                    <div className={'Audit_Form'}>
                        <div className="filetitle">
                            <span className="auditName">첨부파일</span>
                        </div>
                        <div className={'audit_text'}>
                            {
                                _.isEmpty(_.get(data,'content_files')) == false ?
                                _.get(data,'content_files').map(item => {
                                    const {name, url} = item
                                    return(
                                        <a/* onClick={(e) => api.download(`${apiUrl}${url}`, _.get(item,'name'))}*/ href={`${apiUrl}${url}`} target={'_blank'} className="filedown">{name}
                                        </a>
                                    )
                                }) : <>첨부파일이 없습니다.</>
                            }
                        </div>
                    </div>


                    {/*수업계획서 확인*/}
                    <div className={'Audit_Form'}>
                        <div className="filetitle">
                            <span className="auditName">수업계획서</span>
                        </div>
                        <div className={'audit_text'}>
                            {
                                _.isEmpty(_.get(data,'attached_files')) == false ?
                                    _.get(data,'attached_files').map(item => {
                                        const {name, url} = item
                                        return(
                                            <button type={'button'} onClick={(e) => api.download(`${apiUrl}${url}`, _.get(item,'name'))} className="filedown">{name}
                                            </button>
                                        )
                                    }) : <>첨부파일이 없습니다.</>
                            }
                        </div>
                    </div>


                    {/*검수자 의견*/}
                    <div className={'Audit_Form'}>
                        <div className="filetitle">
                            <span className="auditName">검수자의견</span>
                        </div>
                        <div className={'audit_text'}>
                            {quillBoard}
                        </div>
                    </div>

                </div>


                <div className={'filter_button'}>
                    <ul>
                        <li className={`tab ` + (comp == 'design' ? 'active' : '')} onClick={() => setComp('design')}>
                            설계
                        </li>
                        <li className={`tab ` + (comp == 'development' ? 'active' : '')} onClick={() => setComp('development')}>
                            개발
                        </li>
                        <li className={`tab ` + (comp == 'audit' ? 'active' : '')} onClick={() => setComp('audit')}>
                            검수
                        </li>
                    </ul>
                </div>
                <input type={'hidden'} value={_.get(data,'id')} ref={register()}
                       name={`id`}/>
                {
                    !_.isEmpty(question) &&
                    question.map((item, question_index) => {
                        const {type, contents = []} = item;
                        let num = 0;
                        return <div className={"row_audit " + type + (type == comp ? ' display' : '')}>
                            <div className="col">
                                <div className="ptable">
                                    <input type={'hidden'} value={type} ref={register()}
                                           name={`answer_data[${question_index}].type`}/>
                                    <table role="table">
                                        <thead>
                                        <tr role="row">
                                            <th colSpan="1" role="columnheader" className={'th_s_result_s'}>번호
                                            </th>
                                            <th colSpan="1" role="columnheader" className={'th_m_result'}>준거
                                            </th>
                                            <th colSpan="1" role="columnheader" className={'th_l_result'}>검수항목
                                            </th>
                                            <th colSpan="1" role="columnheader" className={'th_s_result'}>평가점수
                                                {
                                                    type != 'design' &&
                                                    <th>5</th>
                                                }
                                                <th>4</th>
                                                <th>3</th>
                                                <th>2</th>
                                                <th>1</th>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody role="rowgroup">
                                        {
                                            contents.map((content, index) => {
                                                const {titles, type: content_type, answer} = content;
                                                return (
                                                    <>
                                                        {
                                                            titles.map((title_item, idx) => {
                                                                //console.log(_.get(answer,`${idx}`))
                                                                !_.isEmpty(answer) && setTimeout(()=> setValue(`answer_data[${question_index}].contents[${index}].answer[${idx}]`,`${_.get(answer,`${idx}`)}`),1000)
                                                                num = num + 1;
                                                                return (
                                                                    <tr role="row" key={title_item}>
                                                                        <td role="cell">{num}</td>
                                                                        {idx == 0 &&
                                                                        <td role="cell"
                                                                                         rowSpan={titles.length}>
                                                                            <input type={'hidden'} value={content_type} ref={register()}
                                                                                   name={`answer_data[${question_index}].contents[${index}].type`}/>
                                                                            {content_type}
                                                                        </td>
                                                                        }
                                                                        <td role="cell" className={'td_p p_result'}>
                                                                            <input type={'hidden'} value={title_item} ref={register()}
                                                                                   name={`answer_data[${question_index}].contents[${index}].titles[${idx}]`}/>
                                                                            {title_item}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                // register({required:true})
                                                                                type != 'design' &&
                                                                                <input type={'radio'} ref={register(
                                                                                    {
                                                                                        required:true
                                                                                    }
                                                                                )}
                                                                                       value={5}
                                                                                       name={`answer_data[${question_index}].contents[${index}].answer[${idx}]`}
                                                                                       className={'result_radio'}/>
                                                                            }
                                                                            <input type={'radio'} ref={register(
                                                                                {
                                                                                    required:true
                                                                                })}
                                                                                   value={4}
                                                                                   name={`answer_data[${question_index}].contents[${index}].answer[${idx}]`}
                                                                                   className={'result_radio'}/>
                                                                            <input type={'radio'} ref={register(
                                                                                {
                                                                                    required:true
                                                                                })}
                                                                                   value={3}
                                                                                   name={`answer_data[${question_index}].contents[${index}].answer[${idx}]`}
                                                                                   className={'result_radio'}/>
                                                                            <input type={'radio'} ref={register(
                                                                                {
                                                                                    required:true
                                                                                })}
                                                                                   value={2}
                                                                                   name={`answer_data[${question_index}].contents[${index}].answer[${idx}]`}
                                                                                   className={'result_radio'}/>
                                                                            <input type={'radio'} ref={register(
                                                                                {
                                                                                    required:true
                                                                                })}
                                                                                   value={1}
                                                                                   name={`answer_data[${question_index}].contents[${index}].answer[${idx}]`}
                                                                                   className={'result_radio'}/>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    })
                }
                {/*<div className={'audit_middle'}>
                    <div className={'Audit_Form sub_audit_form'}>
                        <span>수행단계</span>
                        <div className={'audit_text'}>강좌신청</div>
                    </div>
                    <div className={'bar_box'}>---</div>
                    <path className={'bar_box'}>----</path>
                </div>*/}
                {/*<div className={'bottom_select'}>
                    <select>
                        <option>
                            50 개씩
                        </option>
                    </select>
                    <p>행 클릭 시 강좌 기초 정보로 이동합니다.</p>
                    <p>정보 확정이 된 후에 검수 위원 배정 버튼 클릭 시 위원 배정이 가능합니다.</p>
                </div>*/}
               {/* <div className="row_audit">
                    <div className="col">
                        <div className="ptable">
                            <table role="table">
                                <thead>
                                <tr role="row">
                                    <th colSpan="1" role="columnheader" className={'th_s'}>단계
                                        <button className="caret1"></button>
                                        <button className="caret2"></button>
                                    </th>
                                    <th colSpan="1" role="columnheader" className={'th_m'}>상태
                                        <button className="caret1"></button>
                                        <button className="caret2"></button>
                                    </th>
                                    <th colSpan="1" role="columnheader" className={'th_l'}>메시지
                                    </th>
                                    <th colSpan="1" role="columnheader" className={'th_s'}>액션
                                    </th>
                                </tr>
                                </thead>
                                <tbody role="rowgroup">
                                <tr role="row">
                                    <td role="cell">최종</td>
                                    <td role="cell">
                                        <span className={'bottom_select'}>
                                            <select className={'bottom_sub_select'}>
                                                <option>
                                                    승인됨
                                                </option>
                                            </select>
                                        </span>
                                    </td>
                                    <td role="cell" className={'td_p'}>
                                            <p>[발신전용][고급해석학의 기초개념]검수가 완료되었습니다.
                                            결과를 확인하여 주시기 바랍니다. 기타 문의사항은 광주전남권역대학원격교육
                                            지원센터(ysb619@jnu.ac.kr) 또는 062-530-5029, 2595로
                                            연락부탁드립니다. 감사합니다.</p>
                                        <span className={'span_1'}>발송일</span><span className={'span_2'}>2020.12.16 T10:20:22.998</span>
                                    </td>
                                    <td role="cell"><button>저장</button></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>*/}
                {/*<div className={'audit_bottom'}>*/}
                {/*    <span>*/}
                {/*        강좌개발계획서*/}
                {/*    </span>*/}
                {/*    <input type={'file'}/>*/}
                {/*</div>*/}
            </div>
            <div className={'audit_footer'}>
                <button className={'delete_button'} onClick={onDelete} type={'button'}>삭제</button>
                <button className={'submit_button'} onClick={()=> onReset()} type={'button'}>평가초기화</button>
                <button className={'submit_button'} >{type=='edit' ? '수정' : '평가저장'}</button>
            </div>
            {/*<div className={'audit_footer'}>*/}
            {/*    <button>목록</button>*/}
            {/*    <button>등록</button>*/}
            {/*</div>*/}
        </form>
    )
}


export default AuditForm;
