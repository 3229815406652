/** 컨텐츠 관련 API */
import axios from 'axios';
import qs from 'qs';
import {public_url, apiUrl} from 'config';

/**
 * 컨텐츠 목록
 * @param type: Collection Types
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const gets = ({type, ...params}) => axios.get(`${apiUrl}/${type}`, {params: {...params}})

/**
 * 컨텐츠 목록
 * @param to: Array Types
 * @param from: Admin Email
 * @param subject: String Types
 * @param text: text Types
 * @param html: Emailtemplate Types
 */
export const emailSend = (params) => axios.post(`${apiUrl}/email`, params)

/**
 * 컨텐츠 등록(파일업로드 포함)
 * @param type
 * @param params
 * @param fileData
 * @returns {Promise<AxiosResponse<any>>}
 */
export const create = ({type, ...params}, fileData = [],config) => {
    const formData = new FormData();
    //new Form
    const headers = {};
    if (!_.isEmpty(fileData)) {
        headers['Content-Type'] = 'multipart/form-data';
        for (const f of fileData) {
            const {key = '', files = []} = f;
            if (files.length) {
                console.log(Object.entries(files))
                for (const [idx, file] of Object.entries(files)) {
                    formData.append(`files.${key}`, file, file.name);
                }
            }
        }
    }
    formData.append('data', JSON.stringify({...params}));
    return axios.post(`${apiUrl}/${type}`, formData,config, {headers})
}


/** 구버전 컨텐츠 생성 */
export const create2 = ({type, ...params}) => axios.post(`${apiUrl}/${type}`, {...params})

/**
 * 컨텐츠 갯수
 * @param type
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const count = ({type, ...params}) => axios.get(`${apiUrl}/${type}/count`, {params: {...params}})

/**
 * 컨텐츠 상세
 * @param type
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const get = ({type, id}) => axios.get(`${apiUrl}/${type}/${id}`)

/**
 * 컨텐츠 수정
 * @param type
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const update = ({type, id, ...params}, fileData = []) => {
    const formData = new FormData();
    const headers = {};
    if (fileData.length) {
        headers['Content-Type'] = 'multipart/form-data';
        for (const f of fileData) {
            const {key = '', files = []} = f;
            if (files.length) {
                for (const [idx, file] of Object.entries(files)) {
                    formData.append(`files.${key}`, file, file.name);
                }
            }
        }
    }
    formData.append('data', JSON.stringify({...params}));
    return axios.put(`${apiUrl}/${type}/${id}`, formData, {headers})
}

/**
 * 구버전 컨텐츠 수정
 * @param type
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const update2 = ({type, id, ...params}) => axios.put(`${apiUrl}/${type}/${id}`, {...params})

/**
 * 컨텐츠 삭제
 * @param type
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const del = ({type, id}) => axios.delete(`${apiUrl}/${type}/${id}`)


/**
 * File Download
 * @param url
 * @param filename
 * @returns {Promise<*>}
 */
export const download = async (url, filename) => {
    try {
        const {data} = await axios.get(url, {responseType: 'blob'})
        var blob = new Blob([data], {type: data.type});
        var blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
        var tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename);

        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        setTimeout(function () {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }, 200)
    } catch (e) {
        console.log(e, 'download err')
        return swal({
            title: `파일 다운로드에 실패하였습니다.`, text: ' ',
            icon: "warning", timer: 2000, buttons: false
        });
    }
}

/**
 * 파일 업로드
 * @param file
 * @returns {Promise<AxiosResponse<any>>}
 */
export const upload = (file) => {
    const formData = new FormData();
    formData.append(`files`, file);
    return axios.post(`${apiUrl}/upload`, formData)
}
