import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {BsArrowLeft} from 'react-icons/all';
import route from 'route';
import MobileNavItem from './MobileNavItem';

const {
    depth1,
    depth2,
    depth3,
    depth4,
    depth5
} = route;

const MobileNav = ({openMobileNav, onChangeOpenMobileNav}) => {
    const location = useLocation()

    useEffect(() => {
        return () => {
            onChangeOpenMobileNav(false)
        };
    }, [location.pathname]);

    return (
        <>
            <div
                className={`dim ${openMobileNav ? 'active' : ''}`}
                onClick={() => onChangeOpenMobileNav(false)}
            ></div>

            <nav className={`mobileNav ${openMobileNav ? 'active' : ''}`}>
                <header className="mobileNav__header">
                    <div className="wrap">
                        메뉴
                        <button onClick={() => onChangeOpenMobileNav(false)}>
                            <BsArrowLeft/>
                        </button>
                    </div>
                </header>
                <div className="mobileNav__links">
                    <MobileNavItem data={depth1}/>
                    <MobileNavItem data={depth2}/>
                    <MobileNavItem data={depth3}/>
                    <MobileNavItem data={depth4}/>
                    <MobileNavItem data={depth5}/>
                </div>
            </nav>
        </>
    );
};

export default MobileNav;
