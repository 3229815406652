import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import {Link} from "react-router-dom";
import {Motion, spring} from 'react-motion';

const Policy = () => {
    const [height, setHeight] = useState(0);

    return (
        <>
            <Helmet title="홈 > 개인정보 처리방침"/>
            {/*<div className="path_wrap">*/}
            {/*    <div className="path">*/}
            {/*        <ol>*/}
            {/*            <li><a href="/">홈</a></li>*/}
            {/*            <li>개인정보 처리방침</li>*/}
            {/*        </ol>*/}
            {/*    </div>*/}

            {/*    <div className="share_wrap" style={{display: 'none'}}>*/}
            {/*        <button className="sh_icon"*/}
            {/*                onClick={() => setHeight(height > 0 ? 0 : 110)}>*/}
            {/*            <i className="xi xi-share-alt"/> 공유*/}
            {/*        </button>*/}
            {/*        <Motion defaultStyle={{height: 0}} style={{height: spring(height)}}>*/}
            {/*            {({height}) =>*/}
            {/*                (*/}
            {/*                    <div className="sh_container" style={{height}}>*/}
            {/*                        <div className={`sh_sns`}>*/}
            {/*                            공유*/}
            {/*                            <p className="close" onClick={() => setHeight(height > 0 ? 0 : 110)}>*/}
            {/*                                <i className="xi xi-close"/>*/}
            {/*                            </p>*/}
            {/*                            <ul>*/}
            {/*                                <li>*/}
            {/*                                    <button title="facebook" className="facebook">*/}
            {/*                                        <i className="xi xi-facebook"/>*/}
            {/*                                    </button>*/}
            {/*                                </li>*/}
            {/*                                <li>*/}
            {/*                                    <button title="twitter" className="twitter">*/}
            {/*                                        <i className="xi xi-twitter"/>*/}
            {/*                                    </button>*/}
            {/*                                </li>*/}
            {/*                                <li>*/}
            {/*                                    <button title="kakao" className="kakao">*/}
            {/*                                        <i className="xi xi-kakaotalk"/>*/}
            {/*                                    </button>*/}
            {/*                                </li>*/}
            {/*                                <li>*/}
            {/*                                    <button title="link" className="link">*/}
            {/*                                        <i className="xi xi-link"/>*/}
            {/*                                    </button>*/}
            {/*                                </li>*/}
            {/*                            </ul>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                )}*/}
            {/*        </Motion>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="modal_content">
                <div className="basic_page_content">
                    <h2 className="title">광주교육대학교 개인정보처리방침</h2>
                    <div id="content_1" className="modal_content">
                        <h3>개인정보의 수집 및 이용목적</h3>
                        광주교육대학교는 기본적인 서비스를 제공하기 위한 정보를 수집하고 있습니다.
                        광주교육대학교는 이용자의 기본적 인권 침해의 우려가 있는 민감 정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 등)는
                        수집하지 않습니다.

                        - 수집항목 : 성명, 생년월일, 성별, 이동전화번호, 이메일, 패스워드, 지역
                        - 수집 목적 : 본인 인증 및 서비스 이용에 따른 본인 식별/인증 절차에 이용)
                        - 수집항목 : 이동전화번호, 이메일
                        - 수집 목적 : 이벤트/경품 당첨 결과 안내 및 서비스 관련 고지 사항 전달, 본인 의사 확인, 불만 처리 등 원활한 의사소통 경로 확보)

                    </div>
                    <div id="content_2" className="modal_content">
                        <h3>개인정보의 보유기간 및 이용기간</h3>
                        광주교육대학교는 회원님의 가입일로부터 서비스를 제공하는 기간 동안에 한하여 회원님의 개인정보를 보유 및 이용하게 됩니다.
                        회원 탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집/이용목적을 달성하거나 보유/이용기간이 종료된 경우,
                        사업폐지 등의 사유 발생시 당해 개인정보를 지체없이 파기합니다.
                    </div>

                </div>
            </div>
        </>
    )
}

export default Policy;
