import React, {useEffect, useState} from 'react';
import {Link, useRouteMatch, generatePath} from 'react-router-dom';
import {CustomLoading} from 'components/Custom'
import ReactMarkdown from "react-markdown";
import {useParams, useHistory} from 'react-router-dom';
import {apiUrl} from "config";
import moment from 'moment/dist/moment';
import usePromise from "utils/usePromise";
import * as api from 'lib/content';
import BoardWrite from 'components/Write/BoardWrite';
import {useSelector, useDispatch} from 'react-redux';
import Quill from "../Editor/Quill";

const BoardDetail = ({
                         id = '', data = {}, type = '', reload = false, setReload = () => {
    }
                     }) => {
    const match = useRouteMatch()
    const {title = '', view_count = 0, body = null, attached_file = [], createdAt = new Date, author = {}} = data;
    // const [description, setDescription] = useState(body);
    const created = moment(createdAt).format(`YYYY.MM.DD`);
    const history = useHistory();
    const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}))
    const body2 = useSelector((state) => state.content.view.body)
    const [thisUser,setThisUser] = useState(true);

    //운영자 또는 글쓴이만 수정,삭제 보이게끔
    useEffect(()=>{
        if( ((_.get(user, 'role.type') == "admin") || (_.get(data, 'author.id') == _.get(user, 'id')) ) == false ) {
            setThisUser(false);
        }else {  setThisUser(true); }
    },[user,data]);


    const handleDelete = ({id = '', type = '', url = ''}) => {
        swal({
            title: `정말로 삭제하시겠습니까?`, text: '',
            icon: "warning", buttons: {
                cancle: {
                    text: '취소',
                    value: false,
                    className: ''
                },
                confirm: {
                    text: '삭제',
                    value: true,
                    className: 'btn_error'
                }
            }
        }).then(
            async (result) => {
                try {
                    if (!result) return;
                    let result_data = null;
                    result_data = await api.del({type: type, id: id});


                    const {data, status} = result_data;
                    if (status !== 200) {
                        throw new Error('에러 발생');
                    }
                    swal({
                        title: `게시글 삭제 완료`, text: '게시물 삭제가 완료되었습니다.',
                        icon: "success", timer: 3000, buttons: false,
                    }).then(() => {
                        history.push(url);
                    })
                } catch (e) {
                    //console.log(e, program_type+' 신청/수정 실패')
                    // 수강 신청 실 프로세스
                    swal({
                        title: `게시글 등록/수정 실패`, text: e.message,
                        icon: "warning", timer: 3000, buttons: false,
                    });
                } finally {
                    //dispatch(hideLoading())
                    //팝업 닫기

                }
            }
        )
    }


    /** 다음글 */
    const {data: [nextContent = null]} = usePromise(() => api.gets({
        type,
        _sort: 'createdAt:ASC',
        id_gt: id,
        _limit: 1
    }), [id], []);

    /** 이전글 */
    const {data: [prevContent = null]} = usePromise(() => api.gets({
        type,
        _sort: 'createdAt:DESC',
        id_lt: id,
        _limit: 1
    }), [id], []);

    const emailSecurity = (userEmail) => {
        var id = userEmail.split('@')[0];
        var mail = userEmail.split('@')[1];

        // var maskingId = function (id) {
        //     var splitId = id.substring(0, 1);
        //     for (var i = 1; i < id.length; i++) {
        //         splitId += '*';
        //     }
        //     return splitId;
        // };

        var maskingId = function (id) {
            var splitId = '';

            for (var i = 0; i < id.length; i++) {
                splitId += i < id.length - 2 ? id.charAt(i) : '*';
            }
            return splitId;
        };

        var maskingMail = function (mail) {
            var splitMail = '';
            if(!_.isEmpty(mail)){
                for (var i = 1; i < mail.length; i++) {
                    splitMail += '*';
                }
                splitMail += mail.substring(mail.length - 1, mail.length);
            }
            return splitMail;
        };
        if(!_.isEmpty(maskingMail)){
            userEmail = maskingId(id) + '@' + maskingMail(mail);
        }
        else{
            userEmail = maskingId(id)
        }
        return userEmail;
    }


    /** 현재 페이지 - 페이징 */
    const {data: pagerCount} = usePromise(() => api.count({type, id_gt: id}), [id], 0);
    const page = Math.floor(pagerCount / 10)

    // 목록 돌아가기 버튼을 사용하기 위한 url 글자 변환
    const pageurl = match.path.replace('/:id', '?');

    const nowUrl = window.location.pathname;

    if (_.isEmpty(data)) return (<CustomLoading loading={true}/>);
    return (
        <div className="boardDetail">
            <h2 className="boardDetail__title">{title}</h2>
            <div className="boardDetail__info">
                {<span>{_.get(author, 'username', '') && emailSecurity(_.get(author, 'username', ''))} </span>}
                <span>{created}</span>
                <span>조회수: {view_count}</span>
            </div>

            <div className="boardDetail__content scbd">
                {/*<ReactMarkdown*/}
                {/*    transformImageUri={(uri) => uri.match(/^\/uploads/) && `${apiUrl}${uri}` || uri}*/}
                {/*    escapeHtml={false}*/}
                {/*    source={body}/>*/}
                <Quill isView={true} initialValue={body}/>
            </div>

            {/* 첨부파일 */}
            {attached_file.length && (
                <div className="attachFiles">
                    <div className="hd">
                        <h1>첨부파일[{attached_file.length}]</h1>
                    </div>
                    <div className="bd">
                        <ul className="bd_ul">
                            {attached_file.map((file, idx) => {
                                const {name, url} = file
                                return (<li key={idx}>
                                    <button onClick={(e) => api.download(`${apiUrl}${url}`, name)}
                                            className="download">{name}
                                    </button>
                                </li>)
                            })}
                        </ul>
                    </div>
                </div>
            ) || null}


            {/* 목록 */}
            <div className="boardDetail__list">
                {/*<Link to={generatePath("/onair/news?page=:page", {id:null,page:page})}>목록</Link>*/}
                {/*<Link to={generatePath(pageurl, {id:null, page:page})}>목록</Link>*/}
                {
                    !_.isEmpty(_.get(user, 'role')) && (
                            thisUser == true ? (nowUrl.includes('/community/clean')) ?
                            <>
                                {
                                    (_.get(user, 'role.type') == "admin") && (type=="board-cleans") ?
                                    <BoardWrite
                                        setReload={setReload}
                                        reload={reload}
                                        type={type}
                                        //게시글의 id를 넘겨줌
                                        parent_id={id}
                                        trigger={<button className={'delete_btn'}>답변하기</button>}
                                        title={"답변"}
                                        user={user}
                                        authorinfo={_.get(data,'author')}
                                    /> : <></>
                                }

                                <BoardWrite
                                    setReload={setReload}
                                    reload={reload}
                                    type={type}
                                    trigger={<button className={'delete_btn'}>수정</button>}
                                    title={""}
                                    data={data}
                                    user={user}
                                    mode={'m'}
                                    id={id}
                                />
                                <button className={'delete_btn'} onClick={() => handleDelete({id, type, url: generatePath(pageurl + "page=:page", {id: null, page: page})})}>삭제</button>
                            </>
                                :
                             <>
                            <BoardWrite
                                setReload={setReload}
                                reload={reload}
                                type={type}
                                trigger={<button className={'delete_btn'}>수정</button>}
                                title={""}
                                data={data}
                                user={user}
                                mode={'m'}
                                id={id}
                            />
                            <button className={'delete_btn'} onClick={() => handleDelete({id,type,url: generatePath(pageurl + "page=:page", {id: null, page: page})})}>삭제</button>
                            </>
                              : <></>

                    )
                }
                <Link to={generatePath(pageurl + "page=:page", {id: null, page: page})}>목록</Link>
                {/*<Link to={generatePath(match.path+"?page=:page", {id:null,page:page})}>목록</Link>*/}
                {/*<Link to={generatePath(match.path, {id: "?page="+page})}>목록</Link>*/}
            </div>
            <div className="boardDetail__pager">
            {
                type == "board-answers" ? <></> :
                        <>
                        {nextContent && (
                            <Link to={generatePath(match.path, {id: nextContent.id})}>
                                <span>다음글</span>
                                <p>{nextContent.title}</p>
                            </Link>
                        )}
                        {prevContent && (
                            <Link to={generatePath(match.path, {id: prevContent.id})}>
                                <span>이전글</span>
                                <p>{prevContent.title}</p>
                            </Link>
                        )}
                        </>

            }
            </div>

        </div>
    );
};

export default BoardDetail;
