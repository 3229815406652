import React, {useEffect, useMemo, useState} from 'react';
import {useRouteMatch, generatePath, Link} from 'react-router-dom';
import BreadCrumb from 'components/BreadCrumb';
import route from 'route';
import {AiOutlineSearch} from 'react-icons/ai';
import {GrPowerReset} from 'react-icons/gr';
import Pagination from 'components/Pagination';
import SubLeftMenu from 'components/SubLeftMenu';
import {useTranslation} from 'react-i18next'
import {Helmet} from "react-helmet";
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';
import moment from 'moment/dist/moment';
import {CustomPagination} from 'components/Custom';
import {apiUrl} from "config";
import LocalDataList from "components/LocalDataList";

const TYPE = 'localstories';
const LocalData = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch()
    const {list = [], totalCount = 0, loading} = useSelector(({content}) => ({...content}));
    const [perPage, setPerPage] = useState(12)
    const [page, setPage] = useState(0)
    const [params, setParams] = useState({_limit: perPage, _sort: `createdAt:DESC`, _start: page * perPage})
    const [filter, setFilter] = useState({});
    const [keyword, setKeyword] = useState('');
    const {t} = useTranslation()
    const pageCount = Math.ceil(totalCount / perPage);


    const onReset = () => {
        setFilter({});
        setKeyword('');
        setParams({_limit: 12, _sort: `createdAt:DESC`, _start: 0})
        setPage(0)
    }

    const updateParams = (data) => {
        setParams({...params, ...data});
    };
    const handleChange = (value) => {
        // console.log(value, '키워드 입력 및 타입 셀렉트 시')
        /*console.log(value.target.value)*/

    }
    //page 이동
    useEffect(() => {
        const {_start, _limit, classification_eq} = params;
        if (_limit !== perPage || _start !== page * perPage) {
            updateParams({...params, _start: page * perPage, _limit: perPage})
        }
    }, [page, perPage])

    useEffect(() => {
        dispatch(actions.listRequest({type: TYPE, ...params, ...filter}))
    }, [params, filter])


    const onChange = () => {
        if (!keyword) {
            setFilter({})
            return;
        }
        const _filter = {};

        _filter[`_where[_or][0][title_contains]`] = keyword
        _filter[`_where[_or][1][body_contains]`] = keyword
        setFilter({..._filter})
    }





    return (
        <div className="localdata">
            <Helmet title='지역 스토리'/>
            <BreadCrumb list={route.dataset}/>
            <div className="sub">
                <SubLeftMenu
                    title={route.dataset.title}
                    lnbList={route.dataset.list}
                />
                <div className="localdata__layout">

                    <h1 className="sub__title">
                        <span className="">지역 스토리</span>
                    </h1>

                    <div className="wrap">
                        {/*<div className="localdata__filter">*/}
                        {/*  <div className="card">*/}
                        {/*    <div className="card-header">구분</div>*/}
                        {/*    <div className="list-group list-group-flush">*/}
                        {/*      {loading && (<div className={`text-center p-4`}>로딩중...</div>) ||*/}
                        {/*      <>*/}
                        {/*        {*/}
                        {/*          localStroyList&& localStroyList.length > 0*/}
                        {/*              ? localStroyList.map((item,idx) =>*/}
                        {/*                  <label htmlFor={''} className="list-group-item" key={idx}>*/}
                        {/*                    <input type="checkbox" id={''} value={item.title}/>*/}
                        {/*                    {item.title}*/}
                        {/*                  </label>)*/}
                        {/*              : <p className="null__list">등록된 프로그램이 없습니다.</p>*/}
                        {/*        }*/}
                        {/*        /!*{*!/*/}
                        {/*        /!*  localCountList&& localCountList.length > 0*!/*/}
                        {/*        /!*      ? localCountList.map((item,idx) =>*!/*/}
                        {/*        /!*          <label htmlFor={''} className="list-group-item" key={idx}>*!/*/}
                        {/*        /!*            <input type="checkbox" id={''} value={item}/>*!/*/}
                        {/*        /!*            {console.log(item.connection.aggregate.count)}*!/*/}
                        {/*        /!*          </label>)*!/*/}
                        {/*        /!*      : <p className="null__list">등록된 프로그램이 없습니다.</p>*!/*/}
                        {/*        /!*}*!/*/}
                        {/*      </>}*/}
                        {/*    </div>*/}

                        {/*  </div>*/}
                        {/*  /!*<div className="card mt-3">*!/*/}
                        {/*    /!*<div className="card-header">지역별</div>*!/*/}
                        {/*    /!*<div className="list-group list-group-flush">*!/*/}
                        {/*      /!*{loading && (<div className={`text-center p-4`}>로딩중...</div>) ||*!/*/}
                        {/*      /!*<>*!/*/}
                        {/*      /!*  {*!/*/}
                        {/*      /!*    locationList&& locationList.length > 0*!/*/}
                        {/*      /!*        ? locationList.map((item,idx) =>*!/*/}
                        {/*      /!*            <label htmlFor={''} className="list-group-item" key={idx}>*!/*/}
                        {/*      /!*              <input type="checkbox" id={''} value={item.title} />*!/*/}
                        {/*      /!*              {item.title}*!/*/}
                        {/*      /!*            </label>)*!/*/}
                        {/*      /!*        : <p className="null__list">등록된 프로그램이 없습니다.</p>*!/*/}
                        {/*      /!*  }*!/*/}
                        {/*      /!*</>}*!/*/}
                        {/*    /!*</div>*!/*/}
                        {/*  /!*</div>*!/*/}
                        {/*</div>*/}

                        <div className="localdata__content">

                            <div className="localdata__search">
                                <input type="text"
                                       placeholder={'검색어를 입력하세요'}
                                       value={keyword}
                                       onChange={(e) => setKeyword(e.target.value)}
                                />

                                <button
                                    type="button"
                                    onClick={onChange}>
                                    <AiOutlineSearch/>
                                </button>
                                <button
                                    type="button"
                                    onClick={onReset}
                                    className="localdata__reset"
                                >

                                    초기화
                                </button>
                            </div>
                            {/*끌어올 데이터 컴포넌트로 */}
                            <LocalDataList loading={loading}
                                           list={list}/>

                            <CustomPagination
                                pageSize={perPage}
                                pageIndex={page}
                                pageCount={pageCount}
                                onPageChange={({selected}) => setPage(selected)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocalData;
