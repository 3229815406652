import React, {useEffect, useMemo, useState} from 'react';
import { Link } from 'react-router-dom';
import {useParams, useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import BreadCrumb from 'components/BreadCrumb';
import ProgramDetailTop from 'components/ProgramDetail/ProgramDetailTop';
import Detail from 'components/ProgramDetail/Detail';
import Table from 'components/ProgramDetail/DetailTable';
import Communication from 'components/ProgramDetail/Communication';
import {apiUrl} from "config";
import route from 'route';
import SubLeftMenu from 'components/SubLeftMenu';
import * as actions from 'store/actions/content';
import {Helmet} from "react-helmet";
 
const TYPE = 'studyprograms';
const StudyProgramDetail = () => {
  const dispatch = useDispatch();
  const {view = {}, loading, errorMsg = ''} = useSelector(({content}) => ({...content}));
  const history = useHistory();
  const {id} = useParams();
  const [tab, setTab] = useState('detail');

  const handleChange = (value) => {
    /*console.log(value, '키워드 입력 및 타입 셀렉트 시')*/
  }


  useEffect(() => {
    dispatch(actions.viewRequest({type: TYPE, id}));
  }, [id])

  console.log(route.depth2.title)

  return (
    <div className="program programDetail content">
      <Helmet title="프로그램 신청"/>
      <BreadCrumb list={route.depth3} />
      <div className="sub">
        <div className="wrap">
          <SubLeftMenu
            title={route.depth2.title}
            lnbList={route.depth2.list}
          />
          <div className="sub__content">
            <ProgramDetailTop
            data ={view}
            type = {'studyprogram-applies'}
            />

            <div className="programDetail__tab">
              <button
                className={tab === 'detail' ? 'active' : ''}
                onClick={() => setTab('detail')}
              >강좌소개</button>
              {/*<button*/}
              {/*  className={tab === 'communication' ? 'active' : ''}*/}
              {/*  onClick={() => setTab('communication')}*/}
              {/*>커뮤니케이션</button>*/}
            </div>

            {/* 상세정보 */}
            { tab === 'detail' && (
              <Detail data ={view}
              />
            )}

            {/* 커뮤니케이션 */}
            {/*{ tab === 'communication' && (*/}
            {/*  <Communication />*/}
            {/*)}*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudyProgramDetail;
