import React, {useEffect, useState} from 'react';
import { Link,useRouteMatch, generatePath } from 'react-router-dom';
import {useParams, useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';
import moment from 'moment/dist/moment';
import {Helmet} from "react-helmet";
import route from 'route';
import usePromise from "utils/usePromise";
import * as api from 'lib/content';

import BreadCrumb from 'components/BreadCrumb';
import { CklDataSlide } from 'components/Slide';
import SubLeftMenu from 'components/SubLeftMenu';
import ReactMarkdown from "react-markdown";
import {apiUrl} from "config";

const TYPE = `board-interviews`;
const CklDataDetail = () => {
    const match = useRouteMatch()
    const dispatch = useDispatch();
    const {view = {}, loading, errorMsg = ''} = useSelector(({content}) => ({...content}));
    const {id} = useParams();
    const history = useHistory();

    useEffect(() => {
        dispatch(actions.viewRequest({type: TYPE, id}))
    }, [id])

    //컨텐츠 데이터 로딩 실패시
    useEffect(() => {
        if (!loading && _.isEmpty(view) && errorMsg) {
            history.push('/dataset/ckldata');
        }
    }, [view, loading, errorMsg])

    /** 현재 페이지 - 페이징 */
    const {data: pagerCount} = usePromise(() => api.count({type:TYPE, id_gt: id}), [id], 0);
    const page = Math.floor(pagerCount / 6)

    // 목록 돌아가기 버튼을 사용하기 위한 url 글자 변환
    const pageurl = match.path.replace('/:id','?');

    useEffect(() => {
        // console.log(view)
    }, [view])
  return (
    <div className="program type2">
        <Helmet title='CKL 성과물'/>
      <BreadCrumb list={route.dataset} />
      <div className="sub">
        <div className="wrap">
          <SubLeftMenu
            title={route.dataset.title}
            lnbList={route.dataset.list}
          />
          <div className="sub__content">
            <div className="cklDetails">
              <header className="cklDetails__header">
                <h2>{view.title}</h2>
                <div className="info">
                  <span>{moment(view.createdAt).format(`YYYY-MM-DD hh:mm:ss`)}</span>
                  <span>성과발표 전시회</span>
                </div>
              </header>

              <div className="cklDetails__body">
                {/*<CklDataSlide />*/}
                <ReactMarkdown
                        transformImageUri={(uri) => uri.match(/^\/uploads/) && `${apiUrl}${uri}` || uri}
                        escapeHtml={false}
                        source={view.body}/>
              </div>

              <div className="actions">
                <Link to={generatePath(pageurl+"page=:page", {id:null, page:page})}>목록으로 가기</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CklDataDetail;
