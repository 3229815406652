/** 프로그램 신청 상세 팝업*/
import React, {useEffect, useState, useCallback} from 'react';
import Popup from "reactjs-popup";
import {useDispatch, useSelector} from "react-redux";
import {useParams, useHistory} from 'react-router-dom';
import {CustomLoading} from "components/Custom";
import {showLoading, hideLoading} from 'react-redux-loading-bar'
import moment from 'moment/dist/moment';

import WriteForm from './WriteForm';
import * as actions from 'store/actions/content';
import * as api from 'lib/content';

//graphql 관련
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import * as aQuery from "queries/program";
import { APPLY_STATUS_CATEGORIES_QUERY } from '../../../queries/program';
import BoardWrite from 'components/Write/WorkWrite';




const WorkshopDeleteEdit = ({type,data,setReload,reload}) => {
    const history = useHistory();
    const handleDelete = ({id = '', type = '', url = ''}) => {
        swal({
            title: `정말로 삭제하시겠습니까?`, text: '',
            icon: "warning", buttons: {
                cancle: {
                    text: '취소',
                    value: false,
                    className: ''
                },
                confirm: {
                    text: '삭제',
                    value: true,
                    className: 'btn_error'
                }
            }
        }).then(
            async (result) => {
                try {
                    if (!result) return;
                    let result_data = null;
                    result_data = await api.del({type: type, id: id});


                    const {data, status} = result_data;
                    if (status !== 200) {
                        throw new Error('에러 발생');
                    }
                    swal({
                        title: `프로그램 삭제 완료`, text: '프로그램 삭제가 완료되었습니다.',
                        icon: "success", timer: 3000, buttons: false,
                    }).then(() => {
                        history.push(url);
                    })
                } catch (e) {
                    //console.log(e, program_type+' 신청/수정 실패')
                    // 수강 신청 실 프로세스
                    swal({
                        title: `프로그램 삭제 실패`, text: e.message,
                        icon: "warning", timer: 3000, buttons: false,
                    });
                } finally {
                    //dispatch(hideLoading())
                    //팝업 닫기

                }
            }
        )
    }
        return (

                <>
                <BoardWrite
                    setReload={setReload}
                    reload={reload}
                    type={type}
                    data={data}
                    trigger={
                        <button className="workshop_form_bodify_button">
                            수정
                        </button>}
                    title={"역량강화 워크숍"}
                    mode={'m'}
                    id={_.get(data,'id')}
                    />

                <button onClick={()=>{handleDelete({id:_.get(data,'id'), type, url:'/resource/competencyworkshop'})}} className="workshopform_delete_button">
                    삭제
                </button>
                </>

        )
    }
;

export default WorkshopDeleteEdit;
