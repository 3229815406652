//검수 신청서 작성
import React, {useEffect, useState, useCallback} from 'react';
import {useForm} from "react-hook-form/dist/index.ie11";
import moment from 'moment/dist/moment';
import * as api from 'lib/content';
import route from 'route';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';
import Quill from "../../Editor/Quill";

import {question_origin} from 'surveyData'

const defaultValues = {
    appied_program: {
        _id:'',
    },
    attached_files:[]
};


const AuditStatusForm = ({
                       onSubmit,
                       data,
                       mode = '',
                       type,
                       handleCancel,
                       cancelSubmit,
                       apply_files,
                       reload,
                       bigo,
                       setReload,
                       user = ''
                   }) => {
    const [comp, setComp] = useState('design')
    const { register, handleSubmit, watch, errors, setValue,  clearErrors } = useForm({defaultValues});
    const [question, setQuestion] = useState(question_origin)
    const [logData, setLogData] = useState();

    useEffect(()=>{
        async function getLogs() {
            try{
                const {data:log_data,
                    status:log_status} = await api.gets({type: 'audit-logs',
                    audit_content:_.get(data,'id'),
                    _sort:"createdAt:desc"
                });

                log_data.map((value)=>{
                    console.log(value.id)
                })
                setLogData(log_data[0])
                setValue('audit_result',_.get(log_data,'0.audit_status'))
            }
            catch (e) {
                swal({
                    title: `로그데이터를 받아오지 못했습니다.`, text: e.message,
                    icon: "warning", timer: 3000, buttons: false,
                });
            } finally {
            }
        }
        getLogs()
    },[data])


    useEffect(()=>{
        if(!_.isEmpty(_.get(data,'answer_data'))){
            setQuestion(_.get(data,'answer_data'))
        }
    },[data])
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={'audit_middle'}>
                <input type={'hidden'} value={_.get(logData,'audit_reple')} name='audit_reple' ref={register()}/>
                <input type={'hidden'} value={_.get(logData,'design_score')} name='design_score' ref={register()}/>
                <input type={'hidden'} value={_.get(logData,'development_score')} name='development_score' ref={register()}/>
                <input type={'hidden'} value={_.get(logData,'audit_score')} name='audit_score' ref={register()}/>
                <input type={'hidden'} value={_.get(logData,'final_score')} name='final_score' ref={register()}/>
                <div className={'Audit_Form'}>
                    <span className="auditName">강좌명</span>
                    <div className={'audit_text'}>{_.get(data,'audit_title')}</div>
                </div>
                <div className={'Audit_Form'}>
                    <span className="auditName">개발기관</span>
                    <div className={'audit_text'}>{_.get(data,'audit_org')}</div>
                </div>
                <div className={'Audit_Form'}>
                    <span className="auditName">총 차시</span>
                    <div className={'audit_text'}>{_.get(data,'audit_num')}</div>
                </div>
                <div className={'Audit_Form'}>
                    <span className="auditName">교수</span>
                    <div className={'audit_text'}>{_.get(data,'audit_prop')}</div>
                </div>
                <div className={'Audit_Form'}>
                    <span className="auditName">단계</span>
                    <div className={'audit_text'}>

                        { _.isEmpty(logData) ? '검수 전' :
                            _.isEmpty(_.get(logData,'audit_status'))?'미검수' :
                                _.get(logData,'audit_status') == 'audit_start' ? '재검':
                                '검수 완료'}
                    </div>
                </div>
            </div>
            <input type={'hidden'} value={_.get(logData,'id')} ref={register()}
                   name={`id`}/>
            <div className={'audit_middle'}>
                <div className={'Audit_other_box'}>
                    <div className={'part'}>
                        <div className={'audit_text bottom_audit_text'}>
                            <span>설계</span>
                            <span>{_.get(logData,'design_score',0)}/40</span>
                        </div>
                    </div>
                    <div className={'part'}>
                        <div className={'audit_text bottom_audit_text'}>
                            <span>개발</span>
                            <span>{_.get(logData,'development_score',0)}/50</span>
                        </div>
                    </div>
                    <div className={'part'}>
                        <div className={'audit_text bottom_audit_text'}>
                            <span>검수</span>
                            <span>{_.get(logData,'audit_score',0)}/10</span>
                        </div>
                    </div>
                    <div className={' part'}>
                        <div className={'audit_text bottom_audit_text'}>
                            <span>총점</span>
                            <span>{_.get(logData,'final_score',0)}/100</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'audit_middle'}>
                <div className={'insert_Form'}>
                    <span>강좌 승인</span>
                    <select ref={register} name={'audit_result'}>
                        <option value={'audit_end'}>승인</option>
                        <option value={'audit_start'}>재검</option>
                        <option value={''}>미승인</option>
                    </select>
                </div>
            </div>
            <div className={'audit_footer'}>
                <button className={'submit_button'}>{type=='edit' ? '수정' : '등록'}</button>
            </div>
        </form>
    )
}


export default AuditStatusForm;
