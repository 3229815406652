// 마이콘텐츠
import React, {useEffect, useMemo, useState, useCallback} from 'react';
import BreadCrumb from 'components/BreadCrumb';
import SubLeftMenu from 'components/SubLeftMenu';
import * as actions from 'store/actions/content';
import {useSelector, useDispatch} from 'react-redux';
import {Helmet} from "react-helmet";
import {useTranslation} from 'react-i18next'
import moment from 'moment/dist/moment';
import * as api from 'lib/content';
import {apiUrl} from 'config';
import {useRouteMatch, generatePath, Link,useParams} from 'react-router-dom';
import {useLocation} from "react-router";
import route from 'route';
import {CustomPagination} from 'components/Custom'
import useWindowSize from 'utils/useWindowSize';
import {Radar} from 'react-chartjs-2';


// customapi 관련
import axios from 'axios';
import usePromise from 'utils/usePromise'

const TYPE = 'evaluates';
const MyEvaluationDetail = () => {
    const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}));
    const {id = '', username = ''} = user;
    const evalId = useParams();
    const dispatch = useDispatch();
    const {list = [], totalCount = 0, loading} = useSelector(({content}) => ({...content}));
    const [formDatas,setFormDatas] = useState([]);
    const now = new Date();
    const [sum,setSum] = useState([])
    const [labels,setLabels] = useState([])
    const [subLabels,setSubLabels] = useState([])
    const [results,setResults] = useState([]);
    const [surveyType,setSurveyType] = useState({});
    const [dataType,setDataType] = useState('');
    const [chartData, setChartData] = useState({});    //open 상태인가?
    const [options, setOptions] = useState({});    //open 상태인가?
    const [name,setName] = useState("");
    const [params, setParams] = useState({
        type: 'evaluates',
        _id:evalId.id
    });

    //아이디로 넘어온 값 리스트 받아오기
    useEffect(() => {
        dispatch(actions.listRequest({
            type: TYPE,
            ...params,
        }))
    }, []);

    //formdata넣어주기
    useEffect(()=>{
        list && console.log(list);
        const arrData = list.map(item => _.get(item,'evaluate_result'));
        setFormDatas(arrData[0]);
    },[list]);

    //surveyType 지정
    useEffect(()=>{
        setSurveyType(_.get(list,'0.evaluate_type'))
    },[list]);

    // dataType 지정
    useEffect(()=>{
            if(surveyType == "yeokryangjindanpyeongga" ){
            setDataType(4);
            setName("교수역량 진단평가");
            }else if (surveyType == "grasha-Riechmanntichingseutailgeomsa"  ){
            setDataType(2);
            setName("티칭스타일 진단평가");
            }else if (surveyType == "sueopjagajindanchekeuriseuteu"  ){
            setDataType(3);
            setName("수업역량 진단평가");
            }else if (surveyType == "onrainsueopyeokryangjindandogu"  ){
            setDataType(1);
            setName("온라인수업 역량도구");
         }
    },[surveyType]);


    const sumData = ({values}) => {
        let sum_Data = 0;
        let length = 0;
        if (!_.isEmpty(values)){
            values.map((item,index)=>{
                if(!_.isEmpty(item)){
                    sum_Data += (item*1)
                    length++;
                }
            })}
        return sum_Data/length
    }

    const [chartDataA, setChartDataA] = useState({});
    const [chartDataB, setChartDataB] = useState({});
    const [chartDataC, setChartDataC] = useState({});
    const [sumA,setSumA] = useState([])
    const [sumB,setSumB] = useState([])
    const [sumC,setSumC] = useState([]);


    useEffect(()=>{
        let sumsum = []
        if(!_.isEmpty(formDatas) && !_.isEmpty(surveyType)){
            if(surveyType != 'yeokryangjindanpyeongga'){
                const first_eval = _.get(_.get(formDatas,surveyType,{}),'first');
                const second_eval = _.get(_.get(formDatas,surveyType,{}),'second');
                const third_eval = _.get(_.get(formDatas,surveyType,{}),'third');
                const fourth_eval = _.get(_.get(formDatas,surveyType,{}),'fourth');
                const fifth_eval = _.get(_.get(formDatas,surveyType,{}),'fifth');
                const none = _.get(_.get(formDatas,surveyType,{}),'none');
                sumsum[0] = sumData({values:first_eval});
                sumsum[1] = sumData({values:second_eval});
                sumsum[2] = sumData({values:third_eval});
                sumsum[3] = sumData({values:fourth_eval});
                sumsum[4] = sumData({values:fifth_eval});
                setSum(sumsum)
            }
            else{
                let sumsumA = []
                let sumsumB = []
                let sumsumC = []
                let sumsum = []

                const first_eval_a = _.get(_.get(formDatas,surveyType,{}),'first_a');
                const first_eval_b = _.get(_.get(formDatas,surveyType,{}),'first_b');
                const first_eval_c = _.get(_.get(formDatas,surveyType,{}),'first_c');
                const second_eval_d = _.get(_.get(formDatas,surveyType,{}),'second_d');
                const second_eval_e = _.get(_.get(formDatas,surveyType,{}),'second_e');
                const second_eval_f = _.get(_.get(formDatas,surveyType,{}),'second_f');
                const third_eval_g = _.get(_.get(formDatas,surveyType,{}),'third_g');
                const third_eval_h = _.get(_.get(formDatas,surveyType,{}),'third_h');
                const third_eval_i = _.get(_.get(formDatas,surveyType,{}),'third_i');
                const none = _.get(_.get(formDatas,surveyType,{}),'none');
                sumsumA[0] = sumData({values:first_eval_a});
                sumsumA[1] = sumData({values:first_eval_b});
                sumsumA[2] = sumData({values:first_eval_c});
                setSumA(sumsumA)
                sumsumB[0] = sumData({values:second_eval_d});
                sumsumB[1] = sumData({values:second_eval_e});
                sumsumB[2] = sumData({values:second_eval_f});
                setSumB(sumsumB)
                sumsumC[0] = sumData({values:third_eval_g});
                sumsumC[1] = sumData({values:third_eval_h});
                sumsumC[2] = sumData({values:third_eval_i});
                setSumC(sumsumC)
                //차트용 변수
                sumsum[0] = sumData({values:first_eval_a});
                sumsum[1] = sumData({values:first_eval_b});
                sumsum[2] = sumData({values:first_eval_c});
                sumsum[3] = sumData({values:second_eval_d});
                sumsum[4] = sumData({values:second_eval_e});
                sumsum[5] = sumData({values:second_eval_f});
                sumsum[6] = sumData({values:third_eval_g});
                sumsum[7] = sumData({values:third_eval_h});
                sumsum[8] = sumData({values:third_eval_i});
                setSum(sumsum)
            }
        }
        /*setSum(sumsum)*/
    },[formDatas,dataType,surveyType])

    useEffect(() => {
        if (dataType == 1) {
            setLabels([
                '수업구성 및 진행',
                '수업방법',
                '비언어적 표현',
                '학생들과 관계 및 태도',
                '영상콘텐츠 활용'
            ])
            setChartData(
                {
                    labels: [
                        '수업구성 및 진행',
                        '수업방법',
                        '비언어적 표현',
                        '학생들과 관계 및 태도',
                        '영상콘텐츠 활용'
                    ],
                    datasets: [{
                        label:'검사 결과',
                        data: sum,
                        fill: true,
                        backgroundColor: "rgba(34, 202, 236, .2)",
                        borderColor: 'rgb(75, 137, 220)'
                    }],
                }
            )
            setOptions(
                {
                    scale: {
                        min: 0,
                        max: 5,
                        stepSize: 1
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem) {
                                return tooltipItem.yLabel;
                            }
                        }
                    },
                    animation: {
                        duration: 0
                    }
                }
            )
        } else if (dataType == 2) {
            setLabels( [
                '전문가',
                '엄격한 교수자',
                '위임자',
                '역할모델',
                '촉진자'
            ])
            setResults(['교수자가 가지고 있는 지식과 전문적인 기술을 바탕으로 학습자를 지도, 안내함.',
                '교수자로서의 책임감 및 권위를 중요시하며 자신이 가진 지식을 학습자에게 전달하는 것을 목표로 학습자들이 자신의 가치관, 규칙, 기대수준을 따라오도록 그 과정을 감독함',
                '자신의 경험 및 사례를 바탕으로 수업을 리드하며, 평소의 자신의 언행을 통해 학습자에게 모델을 제시함.',
                '학습자의 학습 스킬을 개발하도록 도와주는 것이 학습 내용을 이해시키는 것 못지 않게 중요하다고 생각함.',
                '학습자가 주도적으로 학습을 진행하고 그 과정 및 결과에 대해 스스로 책임질 수 있도록 도와줌.'])
            setChartData(
                {
                    labels: [
                        '전문가',
                        '엄격한 교수자',
                        '위임자',
                        '역할모델',
                        '촉진자'
                    ],
                    datasets: [{
                        label:'검사 결과',
                        data: sum,
                        fill: true,
                        backgroundColor: "rgba(34, 202, 236, .2)",
                        borderColor: 'rgb(75, 137, 220)'
                    }]
                }
            )
            setOptions(
                {
                    scale: {
                        min: 0,
                        max: 5,
                        stepSize: 1
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem) {
                                return tooltipItem.yLabel;
                            }
                        }
                    },
                    animation: {
                        duration: 0
                    }
                }
            )
        } else if (dataType == 3) {
            setLabels([
                '기본소양',
                '온라인 교육에 대한 이해',
                '수업설계',
                '수업개발',
                '수업운영'
            ])
            setChartData(
                {
                    labels: [
                        '기본소양',
                        '온라인 교육에 대한 이해',
                        '수업설계',
                        '수업개발',
                        '수업운영'
                    ],
                    datasets: [{
                        label:'검사 결과',
                        data: sum,
                        fill: true,
                        backgroundColor: "rgba(34, 202, 236, .2)",
                        borderColor: 'rgb(75, 137, 220)'
                    }]
                }
            )
            setOptions(
                {
                    scale: {
                        min: 0,
                        max: 5
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem) {
                                return tooltipItem.yLabel;
                            }
                        }
                    },
                    animation: {
                        duration: 0
                    }
                },
            )
        }else if (dataType == 4) {
            setLabels([
                '기본역량',
                '수업역량',
                '학생지원역량'
            ])
            setSubLabels([
                '교육목표의 이해',
                '열정적 태도',
                '윤리의식',
                '수업계획',
                '수업 운영',
                '수업 평가',
                '학생이해',
                '학생상담',
                '학생지원'
            ])
            setChartDataA(
                {
                    labels: [
                        '교육목표의 이해',
                        '열정적 태도',
                        '윤리의식'
                    ],
                    datasets: [{
                        label:'기본역량',
                        data: sumA,
                        fill: true,
                        backgroundColor: "rgba(235, 105, 163, .2)",
                        borderColor: 'rgb(221, 123, 167)'
                    }]
                }
            )

            setChartDataB(
                {
                    labels: [
                        '수업계획',
                        '수업 운영',
                        '수업 평가'
                    ],
                    datasets: [{
                        label:'수업역량',
                        data: sumB,
                        fill: true,
                        backgroundColor: "rgba(123, 209, 82, .2)",
                        borderColor: 'rgb(88, 184, 43)'
                    }]
                }
            )

            setChartDataC(
                {
                    labels: [
                        '학생이해',
                        '학생상담',
                        '학생지원'
                    ],
                    datasets: [{
                        label:'학생지원역량',
                        data: sumC,
                        fill: true,
                        backgroundColor: "rgba(34, 202, 236, .2)",
                        borderColor: 'rgb(75, 137, 220)'
                    }]
                }
            )

            setOptions(
                {
                    scale: {
                        min: 0,
                        max: 5
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem) {
                                return tooltipItem.yLabel;
                            }
                        }
                    },
                    animation: {
                        duration: 0
                    }
                },
            )
        }
    }, [dataType,sum]);


        if (!_.isEmpty(formDatas) && surveyType == 'yeokryangjindanpyeongga') {
            const {yeokryangjindanpyeongga} = formDatas
            const {info} = yeokryangjindanpyeongga

            return (
                <div className="myProgram">
                    <div className="onair content introduction">
                        <Helmet title="마이페이지 > 나의역량진단평가"/>
                        <BreadCrumb list={route.depth6}/>
                        <div className="sub">
                            <div className="wrap">
                                <SubLeftMenu
                                    title={route.depth6.title}
                                    lnbList={route.depth6.list}
                                />
                                <div className="sub__content">
                                    <h1 className="sub__title">
                                        <span className="">나의역량진단평가</span>
                                    </h1>
                                    <div className="boardDetail">
                                        <h2 className="boardDetail__title">
                                            {name}
                                        </h2>

                                            {
                                                surveyType == 'yeokryangjindanpyeongga' ?
                                                    <>
                                                        <div className="evalBox">
                                                            <table className={'evaluate_table elemental'}>
                                                                <tr>
                                                                    <th className={'dc_way elemental'}>소속 대학교</th>
                                                                    <td>{info.univ}</td>
                                                                    <th className={'dc_way elemental'}>성별</th>
                                                                    <td>{info.sex}</td>
                                                                </tr>

                                                                <tr>
                                                                    <th className={'dc_way elemental'}>직위</th>
                                                                    <td>{info.spot}</td>
                                                                    <th className={'dc_way elemental'}>현재 학교 근무 경력</th>
                                                                    <td>{info.career[0]}년 {info.career[1]}개월</td>
                                                                </tr>
                                                                <tr>
                                                                    <th className={'dc_way elemental'}>전공계열(소속 단과대학 또는 학과)
                                                                    </th>
                                                                    <td>{info.major}</td>
                                                                    <th className={'dc_way elemental'}>주로 사용하는 수업 방법</th>
                                                                    <td>1순위 : {info.method[0]}<br/>
                                                                        2순위 : {info.method[1]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th className={'dc_way elemental'}>지난 해 프로그램 참여 횟수</th>
                                                                    <td>{info.participation}</td>
                                                                    <th className={'dc_way elemental'}>지난 해 강의평가 수준</th>
                                                                    <td>{info.evaluation}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th className={'dc_way elemental'}>평균 수강 학생 수</th>
                                                                    <td>{info.avg_student}</td>
                                                                    <th className={'dc_way elemental'}></th>
                                                                    <td></td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        <Radar data={chartDataA} options={options}/>
                                                        <Radar data={chartDataB} options={options}/>
                                                        <Radar data={chartDataC} options={options}/>

                                                    </>
                                                    :
                                                    <Radar data={chartData} options={options}/>

                                            }

                                            <div className="ta_div" id="edit-answer7">
                                                <table className={'evaluate_table'}>
                                                    <thead>
                                                    {surveyType == 'yeokryangjindanpyeongga' ? <>
                                                            <tr>
                                                                {labels.map((data, index) => <th colSpan={3} key={index}
                                                                                                 className={'dc_way'}>{data}</th>)}
                                                            </tr>
                                                            <tr>
                                                                {subLabels.map((data, index) => <th key={data + '_' + index}
                                                                                                    className={'dc_way'}>{data}</th>)}
                                                            </tr>
                                                        </> :

                                                        labels.map((data, index) => <th key={index}
                                                                                        className={'dc_way'}>{data}</th>)
                                                    }

                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        {
                                                            sum.map((value, index) => <td key={'value_' + index}>{value.toFixed(1)}</td>)
                                                        }
                                                    </tr>
                                                    <tr>
                                                        {
                                                            sum.map((value, index) => {
                                                                    if (value >= 3.5) {
                                                                        return <td key={'result_' + index}>높음</td>
                                                                    } else if (value >= 3.0 && value < 3.5) {
                                                                        return <td key={'result_' + index}>보통</td>
                                                                    } else if (value < 3.0) {
                                                                        return <td key={'result_' + index}>낮음</td>
                                                                    }
                                                                }
                                                            )
                                                        }
                                                    </tr>

                                                    {
                                                        !_.isEmpty(results) &&
                                                        <tr>
                                                            {results.map((value, index) =>
                                                                <td key={'result_' + index}>{value}</td>
                                                            )}
                                                        </tr>
                                                    }


                                                    </tbody>
                                                </table>
                                            </div>

                                        <div className="listButtonArea">
                                            <div className="listButton">
                                                <Link to="/member/MyEvaluation">
                                                    목록으로
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )
        }
        else{
            return (

                            <div className="myProgram">
                                <div className="onair content introduction">
                                    <Helmet title="마이페이지 > 나의역량진단평가"/>
                                    <BreadCrumb list={route.depth6}/>
                                    <div className="sub">
                                        <div className="wrap">
                                            <SubLeftMenu
                                                title={route.depth6.title}
                                                lnbList={route.depth6.list}
                                            />
                                            <div className="sub__content">
                                                <h1 className="sub__title">
                                                    <span className="">나의역량진단평가</span>
                                                </h1>
                                                <div className="boardDetail">
                                                    <h2 className="boardDetail__title">
                                                        {name}
                                                    </h2>
                                                <div className="evalBox">
                                                    <Radar data={chartData} options={options}/>

                                                    <div className="ta_div" id="edit-answer7">
                                                        <table className={'evaluate_table'}>
                                                            <thead>
                                                            {
                                                                labels.map((data, index) =>
                                                                    <th key={index} className={'dc_way'}>{data}</th>)
                                                            }

                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                {
                                                                    sum.map((value, index) => <td key={'value_' + index}>{value.toFixed(1)}</td>)
                                                                }
                                                            </tr>
                                                            <tr>
                                                                {
                                                                    sum.map((value, index) => {
                                                                            if (value >= 3.5) {
                                                                                return <td key={'result_' + index}>높음</td>
                                                                            } else if (value >= 3.0 && value < 3.5) {
                                                                                return <td key={'result_' + index}>보통</td>
                                                                            } else if (value < 3.0) {
                                                                                return <td key={'result_' + index}>낮음</td>
                                                                            }
                                                                        }
                                                                    )
                                                                }
                                                            </tr>
                                                                {
                                                                    !_.isEmpty(results) &&
                                                                    <tr>
                                                                        {results.map((value, index) =>
                                                                            <td key={'result_' + index}>{value}</td>
                                                                        )}
                                                                    </tr>
                                                                }

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                  </div>

                                                    <div className="listButtonArea">
                                                        <div className="listButton">
                                                            <Link to="/member/MyEvaluation">
                                                                목록으로
                                                            </Link>
                                                        </div>
                                                    </div>


                                                 </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

            )
        }
    }
;





export default MyEvaluationDetail;
