/** 공지사항 상세 */
import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {useParams, useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';
import Quill from "components/Editor/Quill";
import * as api from 'lib/content';
import {apiUrl} from 'config';
import BreadCrumb from 'components/BreadCrumb';
import route from 'route';
import SubLeftMenu from 'components/SubLeftMenu';
import {BoardDetail} from 'components/Board_c';
import {BoardHeader, BoardList} from 'components/Board_c';
import BoardWrite from 'components/Write/BoardWrite';

const TYPE = `board-consultings`;
const ConsultingDetail = () => {
    //접속한 유저정보 확인
    const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}));
    const dispatch = useDispatch();
    const {view = {}, loading, errorMsg = ''} = useSelector(({content}) => ({...content}));
    const {id} = useParams();
    const history = useHistory();
    const [reload,setReload] = useState(true);
    const [consultingState,setCounsultingState] = useState(false);
    const [admUser, catchAdmUser] = useState(false);
    let users,authors;

    //유저아이디 추출
    if(!_.isEmpty(user)){
        users =  (_.get(user,'id'));
    }

    //관리자인지 접속 확인
    useEffect(() => {
        if(users){
            if(users == "6080cbefb629f15efd7de790"){
                catchAdmUser(true)
            }
            else { catchAdmUser(false) }
        }
    },[users]);

    //게시글 쓴 회원아이디 추출
    if(!_.isEmpty(view)){
        // authors =  (_.get(author,'id'));
    }



    useEffect(() => {
        dispatch(actions.viewRequest({type: TYPE, id}))
    }, [id,reload])
    //컨텐츠 데이터 로딩 실패시

    useEffect(() => {
        if (!loading && _.isEmpty(view) && errorMsg) {
            history.push('/resource/consulting');
        }
    }, [view, loading, errorMsg]);

    //상담상태변경값 저장
    useEffect( () => {
        setCounsultingState(consultingState);
    },[consultingState] )
    useEffect(()=>{
        user && console.log(user)
    },[user])
    return (
        <div className="onair content introduction">
            <Helmet title="원격수업 컨설팅"/>
            <BreadCrumb list={route.depth4}/>
            <div className="sub">
                <div className="wrap">
                    <SubLeftMenu
                        title={route.depth4.title}
                        lnbList={route.depth4.list}/>
                    <div className="sub__content introduction_content ">
                        <h1 className="sub__title introduction_title consulting_headbox">
                            <span className="">원격수업 컨설팅</span>
                        </h1>
                            <div className="boardDetail">
                                <div className="boardDetail__content scbd con_detail">
                                    <BoardDetail type={TYPE} data={view} id={id} setReload={setReload} admUser={admUser} reload={reload} setCounsultingState={setCounsultingState} consultingState={consultingState}/>
                                </div>
                            </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConsultingDetail;
