import React, {useState,useEffect} from 'react';
import useWindowSize from 'utils/useWindowSize';
import {useRouteMatch, generatePath, Link} from 'react-router-dom';
import ProgramItem from './ProgramItem';

const Index = ({ list = [], title = '', headerHidden, description = '' ,path=''} ) => {

    const [lists,setLists] = useState([]);
    useEffect(()=>{
        list = list.filter(item => _.get(item,'program_category') !== "hiddencourse");
        setLists(list);
    },[list]);

    console.log(list);


  return (
    <div className="programList">
      { !headerHidden && (
        <div className="programList__title">
          <h2>{title}</h2>
          {description && <p>{description}</p>}
        </div>
      )}
      <div className="programList__list">
        {
            lists.length > 0
            ? lists.map(item => /*console.log(item)*/<ProgramItem key={item.id} data={item} path={path}/>)
            : <p className="null__list">등록된 게시물이 없습니다.</p>
        }
      </div>
    </div>
  );
};

export default Index;
