import React, {useEffect} from 'react';
import { Route, Switch } from 'react-router-dom';


import Repository from './StudyProgram';
import AuditContent from './AuditContent';
import ExellentContent from './ExellentContent';
import YoutubeProgram from './YoutubeProgram';
import ProgramStudyProgramDetail from './StudyProgramDetail';
import ProgramDetailTable from './ProgramDetailTable';
import YoutubeProgramDetail from './YoutubeProgramDetail';

const Content = ({ match }) => {
    useEffect(()=>{
        match &&  console.log(`${match.path}/repository/:id`)
    },[match])

    return (
        <Switch>
            <Route path={`${match.path}/repository`} render={() => <Repository/>} />
            <Route path={`${match.path}/auditcontent`} render={() => <AuditContent/>} />
            <Route path={`${match.path}/exellentcontent`} render={() => <ExellentContent/>} />
            <Route path={`${match.path}/howtovideo`} render={() => <div className="content" style={{textAlign:'center',fontSize:'2.5rem',fontWeight:'400',margin:'15.26rem'}}>페이지 준비중입니다.</div>} />
            <Route path={`${match.path}/youtube/:id`} render={() => <YoutubeProgramDetail/>}/>
            <Route path={`${match.path}/youtube`} render={() => <YoutubeProgram/>}/>
            <Route path={`${match.path}/:id/table`} render={() => <ProgramDetailTable/>}/>
            <Route path={`${match.path}/:id`} render={() => <ProgramStudyProgramDetail/>}/>
        </Switch>
    );
};

export default Content;

//<div className="content" style={{textAlign:'center',fontSize:'2.5rem',fontWeight:'400',margin:'15.26rem'}}>페이지 준비중입니다.</div>