import React, {useState, useEffect} from 'react';
import VideoPlayer from '../../components/VideoPlayer';
import Chat from '../../components/Chat';
import Pagination from '../../components/VideoLayout/Pagination';
import PanelContentList from '../../components/VideoLayout/PanelContentList';
import Header from 'components/VideoLayout/Header'
import {Comments} from 'components/Commet';
import {FaAngleDoubleLeft} from 'react-icons/all';
import {useParams, useHistory} from 'react-router-dom';
import * as actions from 'store/actions/content';

import {useSelector, useDispatch} from 'react-redux';

import {Helmet} from "react-helmet";
import * as api from 'lib/content';
import usePromise from "utils/usePromise";
import {useRouteMatch, generatePath, Link} from 'react-router-dom';

const PanelLayout = ({video, courseId = ''}) => {
    const dispatch = useDispatch();
    const {list = [], totalCount = 0, loading} = useSelector(({content}) => ({...content}));
    const [toggle, setToggle] = useState(false);
    const [view_counts, setViewCounts] = useState(false);
    const [contentList, setContentList] = useState();
    const [loadings, setLoading] = useState(true);
    const [like, setLike] = useState(false);
    const [modify, setModify] = useState(false)
    const [viewLoading, setViewLoading] = useState(true);
    const [comments_data, setCommentsData] = useState(false);
    const {user, isLoggedIn} = useSelector(({auth}) => ({...auth}))
    const match = useRouteMatch()
    const {course_id,id:module_id} = useParams();
    const [reload, setReload] = useState(true)

    async function getLikes() {
        const {data: like} = await api.gets({
            type: 'likes', course_id: `${course_id}_${module_id}`
        });
        setLike(like)
    }

    async function getCounts() {
        const {data: viewCounts} = await api.gets({
            type: 'views-counts', course_id: `${course_id}_${module_id}`
        });
        setViewCounts(viewCounts)
        setLoading(false)
    }

    async function getCourseList() {
        const {data} = await api.gets({
            type: 'content-lists', content_name: `${course_id}`
        });
        setContentList(_.get(data,'0.content_lists'))
        console.log(_.get(data,'0.content_lists'))
    }

    useEffect(() => {
        dispatch(actions.listRequest({type: 'comments', course_id: `${course_id}_${module_id}`}))
        getLikes()
    }, [reload])


    const handleSubmit = ({mode, setReload, reload}) => async (formData) => {
        // console.log(formData,1111111111111)
        const {name = '익명', body, id = '', mode = ''} = formData;
        const params = {name, body, course_id: `${course_id}_${module_id}`};
        // console.log(mode)

        try {
            let result_data = null;
            if (mode == '') {
                result_data = await api.create({type: 'comments', ...params});
            }
            if (mode == 'm') {
                /*console.log('댓글 수정')*/
                setModify(false)
                result_data = await api.update({type: 'comments', id: id, ...params});
            }

            const {data, status} = result_data;
            if (status !== 200) {
                throw new Error('에러 발생');
            }
            swal({
                title: (mode == 'm' ? '댓글 수정 완료' : `댓글 작성 완료`),
                text: (mode == 'm' ? '댓글 수정이 완료되었습니다.' : '댓글 작성이 완료되었습니다.'),
                icon: "success",
                timer: 3000,
                buttons: false,
            });
        } catch (e) {
            //console.log(e, program_type+' 신청/수정 실패')
            // 수강 신청 실 프로세스
            swal({
                title: `댓글 등록/수정 실패`, text: e.message,
                icon: "warning", timer: 3000, buttons: false,
            });
        } finally {
            //dispatch(hideLoading())
            //팝업 닫기
            setReload(!reload)
        }
    }
    const handleDelte = () => async ({author, comment}) => {
        console.log('delete')
        console.log(comment)
        const {name = '', body = '', course_id = '', id = ''} = comment
        console.log(name)
        console.log(body)
        console.log(course_id)
        console.timeLog(id)
        try {
            const {data, status} = await api.del({
                type: 'comments', id
            });
            console.log(data)
            console.log(status)

            if (status !== 200) {
                throw new Error('에러 발생');
            }
            swal({
                title: (status == 200 && '댓글 삭제 완료'), text: (status == 200 && '댓글 삭제 완료'),
                icon: "success", timer: 3000, buttons: false,
            });
        } catch (e) {
            //console.log(e, program_type+' 신청/수정 실패')
            // 수강 신청 실 프로세스
            swal({
                title: `댓글 삭제 실패`, text: e.message,
                icon: "warning", timer: 3000, buttons: false,
            });
        } finally {
            //dispatch(hideLoading())
            //팝업 닫기
            setReload(!reload)
        }

    }

    async function viewAdd(count = 0, id = '', mode = '') {
        try {
            let result_data
            let result_data2
            if (mode != 'm') {
                const params = {count: count + 1};
                result_data = await api.update({type: 'views-counts', id: id, ...params});
            } else {
                const params = {course_id: id, count: count + 1};
                const params2 = {course_id: id, like_count: 0};
                console.log(id)
                result_data = await api.create({type: 'views-counts', ...params});
                result_data2 = await api.create({type: 'likes', ...params2});
                const {data2, status} = result_data2;
                console.log(status)
                if (status !== 200) {
                    throw new Error('에러 발생');
                }
            }
            // console.log(result_data)
            /*if(_.isEmpty(view_counts))){
                console.log('hello')
            }*/

            const {data, status} = result_data;
            console.log(result_data)
            if (status !== 200) {
                console.log(status)
                throw new Error('에러 발생');
            }
            /*swal({
                title: (mode=='m' ? '댓글 수정 완료' : `댓글 작성 완료`), text: (mode=='m' ? '댓글 수정이 완료되었습니다.' : '댓글 작성이 완료되었습니다.'),
                icon: "success", timer: 3000, buttons: false,
            });*/
        } catch (e) {
            //console.log(e, program_type+' 신청/수정 실패')
            // 수강 신청 실 프로세스
            swal({
                title: `댓글 등록/수정 실패`, text: e.message,
                icon: "warning", timer: 3000, buttons: false,
            });
        } finally {
            //dispatch(hideLoading())
            //팝업 닫기
            setViewLoading(false);
        }
    }


    const likeHandle = () => async () => {
        try {
            const params = {like_count: _.get(like, '0.like_count') + 1};
            let result_data = await api.update({type: 'likes', id: _.get(like, '0.id'), ...params});

            console.log(result_data)

            const {data, status} = result_data;
            if (status !== 200) {
                throw new Error('에러 발생');
            }
            /*swal({
                title: (mode=='m' ? '댓글 수정 완료' : `댓글 작성 완료`), text: (mode=='m' ? '댓글 수정이 완료되었습니다.' : '댓글 작성이 완료되었습니다.'),
                icon: "success", timer: 3000, buttons: false,
            });*/
        } catch (e) {
            //console.log(e, program_type+' 신청/수정 실패')
            // 수강 신청 실 프로세스
            swal({
                title: `댓글 등록/수정 실패`, text: e.message,
                icon: "warning", timer: 3000, buttons: false,
            });
        } finally {
            //dispatch(hideLoading())
            //팝업 닫기
            setReload(!reload)
        }
    }

    useEffect(() => {
        getCounts();
        getCourseList();
        /*console.log(view_counts)
        console.log(viewLoading)*/
        if (!loadings) {
            if (!_.isEmpty(view_counts) && viewLoading) {
                viewAdd(_.get(view_counts, '0.count'), _.get(view_counts, '0.id'));
            } else if (_.isEmpty(view_counts) && viewLoading) {
                viewAdd(_.get(view_counts, '0.count'), `${course_id}_${module_id}`, 'm');
            }
        }
    }, [loadings])

    return (
        <>
            <Header/>
            <div className={'panelLayout'}>
                <Helmet title="원격수업 역량강화 워크숍"/>
                <div className="panelLayout__content panelLayout__content--module2">
                    <div
                        className={`dim ${toggle ? 'active' : ''}`}
                        onClick={() => setToggle(false)}
                    ></div>

                    <PanelContentList toggle={toggle} contentList={contentList} setToggle={setToggle}/>

                    <button className={'mobileShowContentList'} onClick={() => setToggle(true)}>
                        <FaAngleDoubleLeft/> 강좌목차
                    </button>

                    <div className={'Video'}>
                        <div className="Video__header Video__header--border">
                            <strong>동영상</strong>
                            {/* <div className="actions"> */}
                            {/*  <button className={'action1'} /> */}
                            {/*  <button className={'action2'} /> */}
                            {/* </div> */}
                        </div>
                        <VideoPlayer video={`http://34.84.15.163:6061/assets/video/${course_id}/${module_id}.mp4`}/>
                    </div>
                    <Comments like={like} view_counts={view_counts} likeHandle={likeHandle}
                              onSubmit={handleSubmit({mode: '', setReload, reload})} comments={list}
                              course_id={courseId}
                              user={user}
                              onDlete={handleDelte({setReload, reload})}
                              setModify={setModify} modify={modify}

                    />
                    {/*<div className={'chatbox'}>
          <Chat />
        </div>*/}
                    {/* <Pagination /> */}
                </div>
            </div>
        </>
    );
};

export default PanelLayout;
