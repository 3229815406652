import React, {useEffect, useState, useCallback} from 'react';
import {useForm} from "react-hook-form/dist/index.ie11";
import moment from 'moment/dist/moment';
import * as api from 'lib/content';
import route from 'route';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'store/actions/content';
import Quill from "../../Editor/Quill";


const defaultValues = {
    appied_program: {
        _id: ''
    },
    attached_files: [],
    author: '',
    email: '',
    phone:''
};
const file_del = (type, id, reload, setReload) => async () => {
    try {
        const {status} = await api.del({type: type, id: id})
        if (status !== 200) {
            throw new Error('에러 발생');
        } else {
            setReload(!reload)
            swal({
                title: `파일 삭제 완료`,
                icon: "success", timer: 10000, buttons: false,
            });
        }
    } catch (e) {
        swal({
            title: `파일 삭제 실패`, text: e.message,
            icon: "warning", timer: 3000, buttons: false,
        });
    } finally {
    }
}
const file_type_check = (value) => {
    //const {files: files} = data
    var type_check = true
    Object.entries(value).map((item, idx) => {
        var file_kind = item[1].name.lastIndexOf('.')
        var file_name = item[1].name.substring(file_kind + 1, item[1].length)
        var file_type = file_name.toLowerCase()
        var check_file_type = ['pdf', 'hwp', 'doc', 'docx', 'pptx', 'ppt']
        if (check_file_type.indexOf(file_type) == -1) {
            type_check = false;
            /*swal({
                title: `파일 유형이 맞지 않습니다.`, text: 'pdf,hwp,dox,docs,pptx,ppt 형식의 파일만 업로드할 수 있습니다.',
                icon: "warning", timer: 3000, buttons: false,
            });*/
        }
    })
    if (type_check == false) {
        return '파일형식이 맞지 않습니다.';
    }
}
const WriteForm = ({
                       onSubmit,
                       data,
                       mode = '',
                       type,
                       handleCancel,
                       cancelSubmit,
                       apply_files,
                       reload,
                       bigo,
                       setReload,
                       user = ''
                   }) => {
    const [validation, setValidation] = useState({});
    const [status, setStatus] = useState('');
    const [secret, setSecret] = useState(false);
    const [filesApplys, setFilesApplys] = useState();
    /*const {user,isLoggedIn} = useSelector(({auth}) => ({...auth}))*/


    // form에 user정보 자동완성
    useEffect(() => {
        setValue('author', (_.get(user, 'name')));
        
        // user에 hp가 없음
        if (!_.isEmpty(_.get(user, 'hp'))){
            const hp_num = _.get(user, 'hp').split('-');
            setValue('phone0', hp_num[0]);
            setValue('phone1', hp_num[1]);
            setValue('phone2', hp_num[2]);
        }if (!_.isEmpty(_.get(user, 'email'))){
            const email_split = _.get(user, 'email').split('@');
            setValue('email1', email_split[0]);
            setValue('email2', email_split[1]);
        }
    }, [user])




    /*console.log(_.get(user, 'hp').split('-'))*/

    const tel_options = ['02', '031', '032', '033', '041', '042', '043', '044', '051', '052', '053', '054', '055', '061', '062', '063', '064', '010', '011', '016', '017', '018', '019', '0130', '070', '080', '0507', '0506', '0505', '0506', '0505', '0504', '0503', '0502', '0303'];
    /// 생년월일 - 연도
    function repeatNumYear() {
        let arr = [];
        for (let i=1900; i<2022; i++){
            arr.push(<option value={i}>{i}</option>);
        }
        return arr;
    }
    /// 생년월일 - 월
    function repeatNumMonth() {
        let arr = [];
        for (let i=1; i<13; i++){
            arr.push(<option value={i}>{i}</option>);
        }
        return arr;
    }
    /// 생년월일 - 일
    function repeatNumDay() {
        let arr = [];
        for (let i=1; i<32; i++){
            arr.push(<option value={i}>{i}</option>);
        }
        return arr;
    }

    const {register, handleSubmit, watch, errors, setValue, clearErrors} = useForm({defaultValues});
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={'clean_form_left'}>


                <div className="modal_sub_title2">
                    이름
                </div>
                <div className="input_container2">
                    <input type={"text"} name="author" ref={
                        register(
                            {
                                required: '이름을 적어주세요'
                            }
                        )
                    }
                    />
                    {/*{errors.title && (
                    <small className="text-danger">{errors.title.message}</small>
                )}*/}
                </div>

                <div className="modal_sub_title2">
                    생년월일
                </div>
                <div className="input_container2">
                    <select name={'birth_y'}>
                        {repeatNumYear()}
                    </select>
                    <span className="clean_dash2">년</span>
                    <select name={'birth_m'}>
                        {repeatNumMonth()}
                    </select>
                    <span className="clean_dash2">월</span>
                    <select name={'birth_d'}>
                        {repeatNumDay()}
                    </select>
                    <span className="clean_dash2">일</span>
                    {/*{errors.title && (
                    <small className="text-danger">{errors.title.message}</small>
                )}*/}
                </div>


                <div className="modal_sub_title2">
                    핸드폰번호
                </div>
                <div className="input_container4">
                    <input type={"number"} name="phone0" maxLength="3" ref={
                        register(
                            {
                                required: '핸드폰번호를 적어주세요'
                            }
                        )
                    }
                        /*defaultValue={_.get(data,'title')}*/
                    />
                    <span className="clean_dash">-</span>
                    <input type={"number"} name="phone1" maxLength="4" ref={
                        register(
                            {
                                required: '핸드폰번호를 적어주세요'
                            }
                        )
                    }
                        /*defaultValue={_.get(data,'title')}*/
                    />
                    <span className="clean_dash">-</span>
                    <input type={"number"} name="phone2" maxLength="4" ref={
                        register(
                            {
                                required: '핸드폰번호를 적어주세요'
                            }
                        )
                    }
                        /*defaultValue={_.get(data,'title')}*/
                    />
                    {/*{errors.title && (
                    <small className="text-danger">{errors.title.message}</small>
                )}*/}
                </div>



                {/*<div className="modal_sub_title2">*/}
                {/*    전화번호*/}
                {/*</div>*/}
                {/*<div className="input_container4">*/}
                {/*    <select name={'tel'}>*/}
                {/*        <option value={''}>선택</option>*/}
                {/*        {*/}
                {/*            tel_options.map((item, idx) =>*/}
                {/*                 <option key={idx} value={item}>{item}</option>*/}
                {/*            )*/}
                {/*        }*/}
                {/*    </select>*/}
                {/*    <span className="clean_dash">-</span>*/}
                {/*    <input type={"number"} name="tel" maxLength="4" ref={*/}
                {/*        register(*/}
                {/*            {*/}
                {/*                required: '전화번호를 적어주세요'*/}
                {/*            }*/}
                {/*        )*/}
                {/*    }*/}
                {/*    />*/}
                {/*    <span className="clean_dash">-</span>*/}
                {/*    <input type={"number"} name="tel" maxLength="4"/>*/}
                {/*</div>*/}

                <div className="modal_sub_title2">
                    비밀번호
                </div>
                <div className="input_container2">
                    <input type={"password"} name="password" ref={
                        register(
                            {
                                required: '비밀번호를 적어주세요'
                            }
                        )
                    }
                        /*defaultValue={_.get(data,'title')}*/
                    />
                    {/*{errors.title && (
                    <small className="text-danger">{errors.title.message}</small>
                )}*/}
                </div>
            </div>


            <div className={'clean_form_right'}>

                <div className="modal_sub_title2">
                    비밀번호확인
                </div>
                <div className="input_container2">
                    <input type={"password"} name="password" ref={
                        register(
                            {
                                required: '비밀번호확인을 적어주세요'
                            }
                        )
                    }
                        /*defaultValue={_.get(data,'title')}*/
                    />
                    {/*{errors.title && (
                    <small className="text-danger">{errors.title.message}</small>
                )}*/}
                </div>
            </div>

            <div className={'clean_form_item'}>
                <div className="modal_sub_title">
                    이메일
                </div>
                <div className="input_container3">
                    <input type={"text"} name="email1" ref={
                        register(
                            {
                                required: '이메일을 적어주세요'
                            }
                        )
                    }
                        /*defaultValue={_.get(data,'title')}*/
                    />
                    <span className="clean_dash2">@</span>
                    <input type={"text"} name="email2" ref={
                        register(
                            {
                                required: '이메일을 적어주세요'
                            }
                        )
                    }
                        /*defaultValue={_.get(data,'title')}*/
                    />
                    <select name={'email3'} hideSelectedOptions={true}>
                        <option value={''}>직접입력</option>
                        <option value={'naver.com'}>naver.com</option>
                        <option value={'gmail.com'}>gmail.com</option>
                        <option value={'daum.com'}>daum.com</option>
                    </select>
                    {/*{errors.title && (
                    <small className="text-danger">{errors.title.message}</small>
                )}*/}
                </div>

                <div className="modal_sub_title">
                    제목
                </div>
                <div className="input_container2">
                    <input type={"text"} name="title" ref={
                        register(
                            {
                                required: '제목을 적어주세요'
                            }
                        )
                    }
                           defaultValue={_.get(data, 'title')}
                    />
                    {errors.title && (
                        <small className="text-danger">{errors.title.message}</small>
                    )}
                </div>

                <div className="modal_sub_title">
                    내용
                </div>
                {/*// 2. 비고작성 부분*/}
                <div className="input_container2">
                    <Quill initialValue={_.get(data, 'body')}
                           onChange={(content, delta, source, editor) => {
                               if (errors.bigo && errors.bigo.type === 'required') {
                                   clearErrors('bigo');
                               }
                               if (editor.getLength() === 1) {
                                   setValue('bigo', '');
                               } else {
                                   setValue('bigo', content)
                               }
                           }}/>

                    <div style={{display: 'none'}}>
                        <textarea defaultValue={_.get(data, 'body')} name={'bigo'} ref={register({required: true})}/>
                    </div>

                    {errors.bigo &&
                    errors.bigo.type === 'required' && (
                        <small className="text-danger description">필수 항목입니다.</small>)
                    }
                </div>

                <div className="modal_sub_title">
                    첨부파일
                </div>
                {/*// 1. 사용자에게 파일 첨부를 받는다*/}
                <div className="input_container2">
                    {/*<input type="hidden" name="id" value={_.get(data,'id')}  ref={register({ required: true })} />*/}
                    <input type="file" multiple name="files" ref={
                        register()
                    }/>
                    {errors.files && (
                        <small className="text-danger">{errors.files.message}</small>
                    )}
                    {(!_.isEmpty(_.get(data, 'attached_file', [])) && mode == 'm') && <div className="files_container">
                        <span className="files_title">업로드 된 파일</span>
                        {_.get(data, 'attached_file').map((item, index) => <span className="file_once" key={index}><span
                            className="files_item">{item.name}</span> <span className="files_delete"
                                                                            onClick={file_del('upload/files', item.id, reload, setReload)}>삭제</span></span>)}

                    </div>}
                </div>
            </div>

            <div className="course_overlay_footer">
                <button className="btn btn_info form-submit">{mode == 'm' ? '수정하기' : '작성하기'}</button>
                <button onClick={handleCancel} type="button" className="overlay_close">취소하기</button>
            </div>
        </form>
    )
}


export default WriteForm;