/** 인증 및 유저 Action 관리 */
import {createAction} from 'redux-actions'
import {createReduxActions} from 'utils/reduxUtils'


//인증 요청
export const AUTHORIZATION = 'user/AUTHORIZATION';
export const authorization = createAction(AUTHORIZATION)

//나의 유저 정보 Load
export const {
    LOAD_REQUEST, LOAD_SUCCESS, LOAD_FAILURE,
    loadRequest, loadSuccess, loadFailure
} = createReduxActions('user', 'LOAD');

//로그아웃
export const LOGOUT = 'user/LOGOUT';
export const logout = createAction(LOGOUT)
