import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next'

const BoardHeader = ({
                         totalCount = 0, title = '', onChange, onReset, onKeyPress,
                         fields = [
                             {title: '제목', value: 'title_contains'},
                             {title: '제목+내용', value: ['title_contains', 'body_contains']},
                         ]
                     }) => {
    const [params, setParams] = useState({keyword: '', type: !_.isEmpty(fields) && fields[0].value || 'title_contains'})
    const {t} = useTranslation()

    const handleReset = () => {
        setParams({keyword: '', type: !_.isEmpty(fields) && fields[0].value || 'title_contains'})
        onReset()
    }

    // 검색창 입력 시에만 조건초기화 버튼 출력
    let [chBtnName, setChBtnName] = useState('reset__btn disabled')
    useEffect(()=>{
        if(!_.isEmpty(_.get(params,'keyword'))){
            setChBtnName('reset__btn')
        }
        else{
            setChBtnName('reset__btn disabled')
        }
    },[params])



    return (
        <div className="board__header">
            <p>
                {/*{t('boardCountLabel', {totalCount, title: t(title)})}*/}
            </p>
            <div className="board__search">
                {!_.isEmpty(fields) && (
                    <div className="select">
                        <select value={params.type} onChange={e => setParams({...params, type: e.target.value})}>
                            {fields.map((option, idx) => {
                                const {title = '', value = ''} = option;
                                return (<option key={idx} value={value}>{title}</option>)
                            })}
                        </select>
                    </div>
                )}
                <div className="input">
                    <input type="text"
                           value={params.keyword}
                           onChange={e => {
                               setParams({...params, keyword: e.target.value})
                           }}
                           placeholder="검색어를 입력해주세요."/>
                </div>
                <button onClick={() => onChange(params)}>검색</button>
                {onReset && (<button className={chBtnName} onClick={handleReset}>조건 초기화</button>)}
            </div>
        </div>
    );
};

export default BoardHeader;
