require('babel-polyfill');

const environment = {
    development: {
        isProduction: false
    },
    production: {
        isProduction: true
    }
}[process.env.NODE_ENV || 'development'];

module.exports = Object.assign({
    AuthToken: 'a305980743325990024eb18f139661e7',    //API 접근 인증 토큰, obenef!234 -> md5
    host: process.env.HOST || 'gnue2.gesturecloud.io',
    port: process.env.PORT || 5000,
    publicUrl: 'https://gnue2.gesturecloud.io',
    apiUrl: 'https://gnue2.gesturecloud.io/api',  //strapi URL
    //apiUrl: 'http://34.84.15.163:1347',  //strapi URL
    app: {
        title: 'GNUE - 광주교육대학교',
        description: '광주교육대학교 입니다.',
        head: {
            defaultTitle: "GNUE - 광주교육대학교",
            // titleTemplate: '전남콘텐츠코리아랩: %s',
            titleTemplate: '%s',
            meta: [
                {name: 'description', content: 'GNUE - 광주교육대학교'},
                {charset: 'utf-8'},
                {property: 'og:site_name', content: 'GNUE - 광주교육대학교'},
                {property: 'og:image', content: 'https://gnue2.gesturecloud.io/assets/images/logo-small.png'},
                {property: 'og:locale', content: 'ko_KR'},
                {property: 'og:title', content: 'GNUE - 광주교육대학교'},
                {property: 'og:description', content: 'GNUE - 광주교육대학교'},
                {property: 'og:card', content: 'summary'},
                {property: 'og:site', content: '@erikras'},
                {property: 'og:creator', content: '@erikras'},
                {property: 'og:image:width', content: '200'},
                {property: 'og:image:height', content: '200'}
            ]
        }
    },
    daumEditorPath: 'libraries/daumEditor',
    daum_api_key: "13a17c0d59f85d1660e9cf7236720ea4",
    //첫번째 path 와 마지막 path로 구별
    routing: {},
}, environment);
