import React from 'react';
import Popup from "reactjs-popup";
// import { Link } from 'react-router-dom';

const contentStyle = {
    border:0,
    padding:0,
    background:"transparent",
};
class MemberPopup extends React.Component {

    handleOpen = () => {
    }

    handleClose = () => {
    }


    render() {
        const {trigger, children, title} = this.props;
        return (
            <Popup
                className="pop_member"
                modal
                trigger={trigger}
                // contentStyle={contentStyle}
                // closeOnDocumentClick={false}
                // onOpen={this.handleOpen}
                // onClose={this.handleClose}
                // ref={ref => this.popup = ref}
            >
                {close => (<div className="member_popup">
                    <button type="button" className="pop_close" style={{display:"none"}} onClick={close}>닫기</button>
                    {children}
                </div>)}
            </Popup>
        );
    }
}

export default MemberPopup;
